import React, { useState } from "react";
import { connect } from "react-redux";
import CreateTeam from "../../../components/Team/CreateTeam/CreateTeam"
import { Paper } from "@material-ui/core";
import Teams from "../../../components/Team/Teams/Teams";
import styles from "./Teams.module.css";

const ResearcherTeams = (props) => {
  const [updateTeams, setUpdateTeams] = useState(true);

  const onChildNewTeamAdded = () => {
    setUpdateTeams(true);
  };

  const childTeamsUpdated = () => {
    setUpdateTeams(false);
  };

  return (
    <div>
      <CreateTeam
        className={styles.area}
        onNewTeamAdded={onChildNewTeamAdded}
      ></CreateTeam>
      <Paper className="fullWindowPaper">
        <h2 className={styles.title}>Hóparnir í kerfinu</h2>
        <Teams updateTeams={updateTeams} teamsUpdated={childTeamsUpdated} />
      </Paper>
    </div>
  );
};

const mapStateToProps = ({ auth }) => ({
  userType: auth.userType,
});

export default connect(mapStateToProps)(ResearcherTeams);
