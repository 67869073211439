import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Typography,
} from "@material-ui/core";
import moment from "moment";
import DataTab from "../../../components/UI/DataTab/DataTab";
import AthleteInfo from "../../../components/Athlete/Info/Info"
import AthleteDataCategories from "../../../components/Athlete/DataCategories/DataCategories";

import AthleteChartMeasurementsTable from "../../../components/Athlete/Chart/MeasurementsTable/MeasurementsTable"
import AthleteChartWorkoutBars from "../../../components/Athlete/Chart/WorkoutBars/WorkoutBars";
import AthleteChartSleepWeekOverview from "../../../components/Athlete/Chart/SleepWeekOverview/SleepWeekOverview";
import DialogWithButton from "../../../components/UI/DialogWithButton/DialogWithButton";
import aboutPhysicalMeasurements from "../AboutPhysicalMeasurements.js";
import aboutPsychologicalTest from "../AboutPsychologicalTest.js";

const AthleteOverview = (props) => {
 
  let athleteId = null;
  if (props.userType!=="athlete") {
    athleteId = props.match.params.id;
  } else {
    athleteId = props.athlete.id;
  }
  return (
    <Typography component="div">
      <AthleteInfo/>
      <AthleteDataCategories/>
      <DataTab text="Niðurstöður mælinga" color="#6e6280" icon="ruler">
        <span>Nánari útskýring á mælingu:
          <DialogWithButton
            title="Nánar um hvað var prófað í mælingu"
            iconName="info-circle"
            content={aboutPhysicalMeasurements}
          />
        </span>
        <AthleteChartMeasurementsTable athleteId={athleteId} category="PHYSICAL" color="#aba1c9" colors={["rgba(72, 74, 72)", "rgba(138, 0, 237)", "rgba(69, 88, 173)"]}/>
      </DataTab>
      <DataTab icon="seedling" color="#6DA58A" text="Sálfræðipróf">
        <AthleteChartMeasurementsTable athleteId={athleteId} category="PSYCHOLOGICAL" color="#9ab5a8" colors={["rgba(72, 74, 72)", "rgba(2, 201, 148)", "rgba(60, 98, 105)"]}/>
        Nánari útskýringar á niðurstöðu:
        <DialogWithButton
          title="Nánar um niðurstöður"
          iconName="info-circle"
          content={aboutPsychologicalTest}
        />
      </DataTab>
      <DataTab icon="dumbbell" color="#a86c3e" text="Æfingar vikunnar">
        <AthleteChartWorkoutBars
          athleteId={athleteId}
          date={new Date()}
        />
      </DataTab>
      <DataTab icon="chart-bar" color="#54638f" text="Svefnyfirlit">
        <AthleteChartSleepWeekOverview
          athleteId={athleteId}
          date={new Date()}
          Page="Athlete"
        />
      </DataTab>
    </Typography>
  );
};

const mapStateToProps = (store) => {
  if (store.auth.athlete) {
    var athlete = store.auth.athlete;
  }
  return {
    userType: store.auth.userType,
    athlete: athlete,
  };
};

export default connect(mapStateToProps, null)(AthleteOverview);
