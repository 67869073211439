import { Component } from "react";
import { connect } from "react-redux";

/*import styles from "./Empty.module.css";*/

class Empty extends Component {
  render() {
    return (
      null
    );
  }
}

const mapStateToProps = state => ({
});

export default connect(
  mapStateToProps
)(Empty);