import React, { Component } from "react";

import { Button, Paper } from "@material-ui/core";
import styles from "./Filezone.module.css";
import { connect } from "react-redux";
import { DropzoneArea } from "material-ui-dropzone";
import DayPickerInput from "react-day-picker/DayPickerInput";
import InputLabel from "@material-ui/core/InputLabel";
import axios from "axios";
import CustomizedSnackbars from "../../UI/CustomizedSnackbars";
import DialogWithButton from "../../UI/DialogWithButton/DialogWithButton";
import rules from "./MeasurementsFileRules.js";
import { green } from "@material-ui/core/colors";
import CircularProgress from "@material-ui/core/CircularProgress";

import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from "react-day-picker/moment";

import moment from "moment";

import { Select, MenuItem, TextField } from "@material-ui/core";
import TeamSelect from "../../UI/TeamSelect";

var dropzoneKey = 0;

class MeasurementsFilezone extends Component {
  constructor(props) {
    super(props);
    this.state = {
      snackbarOpen: false,
      snackbarMessage: "INITIAL",
      snackbarVariant: "info",
      accept: ".csv, .xlsx, .xls",
      files: [],
      name: "",
      selected: "",
      date: new Date(),
      comment: "",
      loading: false,
    };
  }

  componentDidMount() {
  }

  handleSubmit = () => {
    this.setState({
      loading: true,
    });
    let form_data = new FormData();
    form_data.append("name", this.state.name);
    form_data.append("file", this.state.files[0]);
    form_data.append("team", this.state.selected);
    var date = moment(this.state.date);
    form_data.append("date_of_measurement", date.format("YYYY-MM-DD"));
    form_data.append("comment", this.state.comment);
    form_data.append("category", this.props.category);

    let config = {
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    };

    axios
      .post(
        process.env.REACT_APP_API + "api/measurementsfiles/",
        form_data,
        config
      )
      .then((response) => {
        dropzoneKey++;
        this.props.onNewFileAdded();
        this.setState({
          files: [],
          name: "",
          comment: "",
          snackbarOpen: true,
          loading: false,
          snackbarMessage: "Skjali var bætt við. Skoðið athugasemdir og niðurstöður áður en samþykkið.",
          snackbarVariant: "success",
        });
      })
      .catch((error) => {
        this.setState({
          snackbarOpen: true,
          snackbarMessage:
            "Það kom upp villa við að hlaða inn skjali, vinsamlegast reynið aftur.",
          snackbarVariant: "error",
          loading: false,
        });
      });
  };

  handleFileChange(files) {
    var name = this.state.name;
    if (name === "") {
      //Deleting the file extension so not in name
      name = files[0].name.split(".").slice(0, -1).join(".");
    }
    this.setState({
      files: files,
      name: name,
    });
  }

  handleChange = (team) => {
    this.setState({ selected: team });
  };

  handleNameChange = (event) => {
    this.setState({ name: event.target.value });
  };

  handleCommentChange = (event) => {
    this.setState({ comment: event.target.value });
  };

  handleDateChange = (event) => {
    this.setState({ date: event.target.value });
  };

  closeSnackbar = () => {
    this.setState({
      snackbarOpen: false,
    });
  };

  renderOptions() {
    return this.state.teams.map((dt, i) => {
      return (
        <MenuItem value={dt.id} key={dt.id} name={dt.name}>
          {dt.name}
        </MenuItem>
      );
    });
  }

  render() {
    const { name, comment, files } = this.state;
    const buttonProgress = {
      color: green[500],
      position: "absolute",
      marginTop: 7,
      marginLeft: -47,
    };

    const Dropzone = (
      <DropzoneArea
        key={dropzoneKey}
        onChange={this.handleFileChange.bind(this)}
        filesLimit={1}
        acceptedFiles={[
          "text/csv",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        ]}
        dropzoneText={
          "Dragið skjal hingað eða smellið á kassann (.xlsx eða .csv)"
        }
        showPreviewsInDropzone={true}
        showAlerts={false}
        showFileNames={true}
        initialFiles={files}
      />
    );

    return (
      <div>
        <Paper className={styles.box}>
          <div className={styles.allContent}>
            <h2 className={styles.title}>{this.props.title}</h2>
            <h3>
              Leiðbeiningar:
              <DialogWithButton
                title="Reglur fyrir mælingaskjöl"
                iconName="info-circle"
                content={rules}
              />
            </h3>
            <div className={styles.inputs}>{Dropzone}</div>
            <div className={styles.inputs}>
              <div className={styles.input}>
                <TextField
                  id="name_input"
                  label="Nafn á skrá"
                  onChange={this.handleNameChange}
                  value={name}
                  maxLength="100"
                />
              </div>
              <div className={styles.input}>
                *{" "}
                <TeamSelect setGroup={this.handleChange}/>
              </div>
              <div className={styles.input}>
                <InputLabel shrink>Dagsetning mælingar</InputLabel>
                {/* CSS to be found in day-picker.css in public folder */}*{" "}
                <DayPickerInput
                  onDayChange={(day) => this.setState({ date: day })}
                  formatDate={formatDate}
                  parseDate={parseDate}
                  format="LL"
                  placeholder={`${formatDate(new Date(), "LL", "is")}`}
                  dayPickerProps={{
                    locale: "is",
                    localeUtils: MomentLocaleUtils,
                  }}
                />
              </div>
              <div className={styles.input}>
                <TextField
                  id="comment_input"
                  label="Athugasemd"
                  fullWidth
                  onChange={this.handleCommentChange}
                  value={comment}
                />
              </div>
              <div className={styles.button}>
                <Button
                  onClick={this.handleSubmit}
                  variant="contained"
                  color="primary"
                  disabled={!this.state.files.length || this.state.selected == "" || this.state.loading}
                >
                  Vista
                </Button>
                {this.state.loading && (
                  <CircularProgress size={24} style={buttonProgress} />
                )}
                <CustomizedSnackbars
                  variant={this.state.snackbarVariant}
                  message={this.state.snackbarMessage}
                  open={this.state.snackbarOpen}
                  closeSnackbar={this.closeSnackbar}
                />
              </div>
            </div>
          </div>
        </Paper>
      </div>
    );
  }
}

function handleErrors(response) {
  if (!response.ok) {
    this.setState({
      error: true,
      snackbarOpen: true,
      snackbarMessage: "Ekki tókst að hlaða inn skjali",
      snackbarVariant: "error",
    });
  }
  return response;
}

const mapStateToProps = (store) => {
  return {};
};

export default connect(mapStateToProps)(MeasurementsFilezone);
