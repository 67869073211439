import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  Avatar,
  CircularProgress,
  Button,
  Modal,
  Backdrop,
  Fade,
} from "@material-ui/core";

import Pagination from "@material-ui/lab/Pagination";
import ConfirmDialog from "../../../components/UI/ConfirmDialog";
import styles from "./Settings.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomizedSnackbars from "../../../components/UI/CustomizedSnackbars";
import { Link } from "react-router-dom";
import AthleteAddEmailForm from "../../../components/Athlete/AddEmailForm/AddEmailForm";
import { noValue } from "../../../utils";
import AddUserButton from "../../../components/User/AddUserButton/AddUserButton";
import ChangeTeamButton from "../../../components/Team/ChangeTeamButton/ChangeTeamButton";

const TeamSettings = (props) => {
  // Team props
  const { userType } = props;

  const [team, setTeam] = useState("");
  const [teamLoaded, setTeamLoaded] = useState(false);
  const [id, setId] = useState("");
  console.log(id);
  const [coaches, setCoaches] = useState([]);
  const [teamPlayers, setTeamPlayers] = useState([]);
  const [teamPlayersLoaded, setteamPlayersLoaded] = useState(true);
  const [coachesLoaded, setCoachesLoaded] = useState(false);
  const [infoChanged, setInfoChanged] = useState(false);

  // userType of the current logged in user
  const currUserType = props.userType;

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("INITIAL");
  const [snackbarVariant, setSnackbarVariant] = useState("info");
  const [error, setError] = useState(false);

  // Pagination
  const [teamPageCount, setTeamPageCount] = useState(1); // total page count for athlete list
  const [coachPageCount, setCoachPageCount] = useState(1); // total page count for athlete list
  const [teamOffset, setTeamOffset] = useState(0); // for offset and limit
  const [coachOffset, setCoachOffset] = useState(0); // for offset and limit

  // Update list
  const [updateCoaches, setUpdateCoaches] = useState(false);
  const [updateTeam, setUpdateTeam] = useState(false);
  // const [updateTeamName, setUpdateTeamName] = useState(false)

  // Add modal for email
  const [openModalEmail, setOpenModalEmail] = useState(false);

  // Set id for player for adding email
  const [emailId, setEmailId] = useState(0);

  // Delete warning
  const [openDeleteAthleteWarning, setOpenDeleteAthleteWarning] =
    useState(false);
  const [openDeleteCoachWarning, setOpenDeleteCoachWarning] = useState(false);
  const [deleteWarningMessage, setDeleteWarningMessage] = useState("");
  const [deleteId, setDeleteId] = useState(0);

  const teamPath = "/group/" + id;
  const [editToggle, setEditToggle] = useState(false);

  let edit = (
    <FontAwesomeIcon
      icon="edit"
      style={{
        color: "grey",
        float: "right",
        marginTop: "20px",
        marginRight: "15px",
        cursor: "pointer",
      }}
      className={styles.theicons}
      onClick={() => {
        setEditToggle(true);
      }}
    />
  );

  useEffect(() => {
    if (props.location.state != undefined && (id === "" || id === undefined)) {
      setTeamPlayers(props.location.state.teamPlayers);
      setTeam(props.location.state.team);
      setId(props.location.state.id);
      setTeamLoaded(true);
    } else {
      getTeamFromUrl().then((result) => {
        setTeam(result);
        setId(result.id);
        setTeamLoaded(true);
      });
    }
  }, [id]);

  useEffect(() => {
    if (id > 0 && teamLoaded) {
      fetch(
        process.env.REACT_APP_API +
          "api/athleteinteams/?limit=20&offset=" +
          teamOffset +
          "&ordering=athlete__last_name&ordering=athlete__first_name&team=" +
          id,
        {
          method: "GET",
          headers: {
            Authorization: `JWT ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      )
        .then(handleErrors)
        .then((res) => res.json())
        .then(
          (result) => {
            setTeamPlayers(result.results);
            setteamPlayersLoaded(true);
            setTeamPageCount(Math.ceil(result.count / 20));
          },
          (error) => {
            setError(true);
            setSnackbarOpen(true);
            setSnackbarMessage(
              "Villa kom upp við að sækja einstaklinga fyrir hóp."
            );
            setSnackbarVariant("error");
          }
        );
    }
  }, [teamLoaded, teamOffset, updateTeam]);

  useEffect(() => {
    if (id > 0 && teamLoaded) {
      fetch(
        process.env.REACT_APP_API +
          "api/coachofteams/?limit=20&offset=" +
          coachOffset +
          "&ordering=coach__last_name&ordering=coach__first_name&id=&team=" +
          id,
        {
          method: "GET",
          headers: {
            Authorization: `JWT ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      )
        .then(handleErrors)
        .then((res) => res.json())
        .then(
          (result) => {
            setCoaches(result.results);
            setCoachesLoaded(true);
            setCoachPageCount(Math.ceil(result.count / 20));
          },
          (error) => {
            setError(true);
            setSnackbarOpen(true);
            setSnackbarMessage(
              "Villa kom upp við að sækja leiðbeinendur fyrir hóp."
            );
            setSnackbarVariant("error");
          }
        );
    }
  }, [teamLoaded, coachOffset, updateCoaches]);

  function getTeamFromUrl() {
    var parts = window.location.href.split("/");
    let temp = parts.pop() || parts.pop(); // handle potential trailing slash
    let teamId = parts.pop();
    return fetch(process.env.REACT_APP_API + "api/teams/" + teamId + "/", {
      method: "GET",
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        return result;
      })
      .catch((error) => {
        setError(true);
        setSnackbarOpen(true);
        setSnackbarMessage("Villa kom upp við að sækja hóp.");
        setSnackbarVariant("error");
      });
  }

  const handleTeamPageChange = (value) => {
    let teamPageOffset = (value - 1) * 20;
    setTeamOffset(teamPageOffset);
  };

  const handleCoachPageChange = (value) => {
    let coachPageOffset = (value - 1) * 20;
    setCoachOffset(coachPageOffset);
  };

  const handleAddEmailClick = (id) => {
    setEmailId(id);
    setOpenModalEmail(true);
  };

  const handleDeleteAthleteClick = (name, id) => {
    setOpenDeleteAthleteWarning(true);
    setDeleteId(id);
    setDeleteWarningMessage(
      "Ertu viss um að þú viljir fjarlægja " + name + " úr hópnum?"
    );
  };

  const handleDeleteCoachClick = (name, id) => {
    setOpenDeleteCoachWarning(true);
    setDeleteId(id);
    setDeleteWarningMessage(
      "Ertu viss um að þú viljir fjarlægja " + name + " úr hópnum?"
    );
  };

  const handleCoachDelete = (userId) => {
    fetch(process.env.REACT_APP_API + "api/coachofteams/" + userId + "/", {
      method: "DELETE",
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    })
      .then(handleErrors)
      .then((res) => {
        if (res.ok) {
          setUpdateCoaches(!updateCoaches);
        }
      })
      .then(
        (result) => {},
        (error) => {
          setError(true);
          setSnackbarOpen(true);
          setSnackbarMessage(
            "Villa kom upp við að fjarlægja leiðbeinanda úr hóp."
          );
          setSnackbarVariant("error");
        }
      );
  };

  const handleAthleteDelete = (userId) => {
    fetch(process.env.REACT_APP_API + "api/athleteinteams/" + userId + "/", {
      method: "DELETE",
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    })
      .then(handleErrors)
      .then((res) => {
        if (res.ok) {
          setUpdateTeam(!updateTeam);
        }
      })
      .then(
        (result) => {},
        (error) => {
          setError(true);
          setSnackbarOpen(true);
          setSnackbarMessage(
            "Villa kom upp við að fjarlægja einstakling úr hóp."
          );
          setSnackbarVariant("error");
        }
      );
  };

  const handleCloseDeleteCoachWarning = (confirmed) => {
    setOpenDeleteCoachWarning(false);
    if (confirmed) {
      handleCoachDelete(deleteId);
    }
  };

  const handleCloseDeleteAthleteWarning = (confirmed) => {
    setOpenDeleteAthleteWarning(false);
    if (confirmed) {
      handleAthleteDelete(deleteId);
    }
  };

  const handleAthleteCreated = () => {
    setTeamOffset(0);
    setUpdateTeam(!updateTeam);
  };

  const handleCoachCreated = () => {
    setCoachOffset(0);
    setUpdateCoaches(!updateCoaches);
  };

  const closeSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleClose = () => {
    setOpenModalEmail(false);
  };

  let snackbar = (
    <CustomizedSnackbars
      variant={snackbarVariant}
      message={snackbarMessage}
      open={snackbarOpen}
      closeSnackbar={closeSnackbar}
    />
  );

  function handleErrors(response) {
    if (!response.ok) {
      setError(true);
    }
    return response;
  }

  let club = (
    <TableRow>
      <TableCell component="th" scope="row">
        Enginn þjónustuþegi fannst í þessum hóp.
      </TableCell>
      <TableCell></TableCell>
      <TableCell className={styles.show}></TableCell>
      <TableCell className={styles.show}></TableCell>
      <TableCell className={styles.show}></TableCell>
      <TableCell className={styles.show}></TableCell>
    </TableRow>
  );

  if (teamPlayersLoaded) {
    if (teamPlayers[0] && teamPlayers[0].athlete) {
      club = teamPlayers
        .sort((a, b) => a.athlete.last_name.localeCompare(b.athlete.last_name))
        .sort((a, b) =>
          a.athlete.first_name.localeCompare(b.athlete.first_name)
        )
        .map((player, index) => {
          let profile_picture = null;
          let color = "white";
          profile_picture = player.athlete.profile_picture;
          if (player.athlete.user === null) {
            color = "#ffdede";
          }
          const { first_name, last_name, email, date_of_birth } =
            player.athlete;
          let year_of_birth = "";
          if (date_of_birth !== null) {
            year_of_birth = date_of_birth.substr(0, date_of_birth.indexOf("-"));
          }

          // This is for backward compatability, if athlete exists without user
          let has_logged_in = false;

          if (player.athlete.user) {
            has_logged_in = player.athlete.user.has_logged_in;
          }

          let pic = profile_picture;
          if (noValue(pic)) {
            pic = first_name;
          }

          let mailto = "mailto: " + email;

          return (
            <>
              <TableRow
                key={"team" + index}
                style={{ backgroundColor: color }}
                className={styles.athleteInfo}
              >
                <TableCell component="td" scope="row">
                  <div className={styles.row}>
                    <div className={styles.avatar}>
                      <Avatar
                        src={pic}
                        alt={`${first_name} ${last_name}`}
                        className={styles.avatar}
                      />
                    </div>
                    {`${first_name} ${last_name}`}
                  </div>
                </TableCell>
                <TableCell className={styles.show}>
                  {email ? (
                    <>
                      <a className="smallLink" href={mailto}>
                        {email}
                      </a>
                    </>
                  ) : (
                    <div className={styles.noEmail} style={{ color: "red" }}>
                      <Button
                        className={styles.noEmailButton}
                        onClick={() => handleAddEmailClick(player.athlete.id)}
                      >
                        Bæta við netfangi
                      </Button>
                    </div>
                  )}
                </TableCell>
                <TableCell className={styles.show}>{year_of_birth}</TableCell>
                <TableCell className={styles.show}>
                  {has_logged_in === true ? (
                    <FontAwesomeIcon
                      icon="check"
                      className={styles.primaryColor}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon="times"
                      style={{
                        color: "red",
                      }}
                    />
                  )}
                </TableCell>
                <TableCell>
                  <Button
                    onClick={() =>
                      handleDeleteAthleteClick(
                        first_name + " " + last_name,
                        player.id
                      )
                    }
                  >
                    Fjarlægja
                  </Button>
                </TableCell>
              </TableRow>
            </>
          );
        });
    }
  } else {
    club = <CircularProgress />;
  }

  let clubCoaches = (
    <TableRow>
      <TableCell component="th" scope="row">
        Enginn leiðbeinandi fannst í þessum hóp.
      </TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell className={styles.show}></TableCell>
    </TableRow>
  );

  let team_logo = null;
  if (!noValue(team.team_logo)) {
    team_logo = (
      <img src={team.team_logo} alt={team.name} className={styles.team_logo} />
    );
  }
  if (coachesLoaded) {
    if (coaches[0] && coaches[0].coach) {
      clubCoaches = coaches
        .sort((a, b) => a.coach.last_name.localeCompare(b.coach.last_name))
        .sort((a, b) => a.coach.first_name.localeCompare(b.coach.first_name))
        .map((coach, index) => {
          let profile_picture = null;
          let color = "white";
          profile_picture = coach.coach.profile_picture;

          const { first_name, last_name, email } = coach.coach;

          let pic = profile_picture;
          if (noValue(pic)) {
            pic = first_name; // Make the profile pic be the first letter in the coach's name
          }

          const { has_logged_in } = coach.coach.user;

          const mailto = "mailto: " + email;

          return (
            <>
              <TableRow
                key={"coaches" + index}
                style={{ backgroundColor: color }}
              >
                <TableCell component="td" scope="row">
                  <div className={styles.row}>
                    <div className={styles.avatar}>
                      <Avatar
                        src={pic}
                        alt={`${first_name} ${last_name}`}
                        className={styles.avatar}
                      />
                    </div>
                    {`${first_name} ${last_name}`}
                  </div>
                </TableCell>
                <TableCell color="inherit" className={styles.show}>
                  {" "}
                  <a href={mailto} className="smallLink">
                    {email}
                  </a>
                </TableCell>
                <TableCell className={styles.show}>
                  {has_logged_in === true ? (
                    <FontAwesomeIcon
                      icon="check"
                      className={styles.primaryColor}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon="times"
                      style={{
                        color: "red",
                      }}
                    />
                  )}
                </TableCell>

                <TableCell>
                  {currUserType === "researcher" ? (
                    <Button
                      onClick={() =>
                        handleDeleteCoachClick(
                          first_name + " " + last_name,
                          coach.id
                        )
                      }
                    >
                      Fjarlægja
                    </Button>
                  ) : (
                    <></>
                  )}
                </TableCell>
              </TableRow>
            </>
          );
        });
    }
  } else {
    clubCoaches = <CircularProgress />;
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.center}>
          <div className={styles.paper}>
            <div className={styles.settingsLink}>
              <ChangeTeamButton
                // handleUpdate={info}
                text="Breyta upplýsingum um hóp"
                // id= {team.id}
                // name= {team.name}
                team={team}
                userType={userType}
                edit={edit}
                editToggle={editToggle}
                setEditToggle={setEditToggle}
                team_logo={props.team_logo}
                infoChanged={infoChanged}
                className={styles.button}
              />
            </div>
            <Paper>
              {snackbar}
              <Modal
                open={openModalEmail}
                onClose={() => handleClose()}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={openModalEmail}>
                  <div className={styles.modalContent}>
                    <h2 id="simple-modal-title">Bæta við netfangi</h2>
                    <AthleteAddEmailForm
                      changeUserType="athlete"
                      id={emailId}
                    />
                  </div>
                </Fade>
              </Modal>
              <div className={styles.header}>
                {team.name}
                {team_logo}
              </div>
              <div className={styles.title}>Leiðbeinendur</div>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Nafn</TableCell>
                    <TableCell className={styles.show}>Netfang</TableCell>
                    <TableCell className={styles.show}>Virkur</TableCell>
                    <TableCell>Aðgerðir</TableCell>
                  </TableRow>
                </TableHead>
                {clubCoaches}
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell className={styles.show}></TableCell>
                  <TableCell className={styles.show}></TableCell>
                  <TableCell>
                    <AddUserButton
                      handleCreated={handleCoachCreated}
                      text="Bæta við leiðbeinanda"
                      createUserType="coach"
                      id={id}
                    />
                  </TableCell>
                </TableRow>
              </Table>
              <div className={styles.pagination}>
                <div>
                  <Pagination
                    count={coachPageCount}
                    variant="outlined"
                    shape="rounded"
                    onChange={(e, v) => handleCoachPageChange(v)}
                  />
                </div>
              </div>
              <div className={styles.title}>Þjónustuþegar</div>
              <div>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Nafn</TableCell>
                      <TableCell className={styles.show}>Netfang</TableCell>
                      <TableCell className={styles.show}>Fæðingarár</TableCell>
                      <TableCell className={styles.show}>Virkur</TableCell>
                      <TableCell>Aðgerðir</TableCell>
                    </TableRow>
                  </TableHead>
                  {club}
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell className={styles.show}></TableCell>
                    <TableCell className={styles.show}></TableCell>
                    <TableCell className={styles.show}></TableCell>
                    <TableCell>
                      <AddUserButton
                        handleCreated={handleAthleteCreated}
                        text="Bæta við þjónustuþega"
                        createUserType="athlete"
                        id={id}
                      />
                    </TableCell>
                  </TableRow>
                </Table>
                <div className={styles.pagination}>
                  <div>
                    <Pagination
                      count={teamPageCount}
                      variant="outlined"
                      shape="rounded"
                      onChange={(e, v) => handleTeamPageChange(v)}
                    />
                  </div>
                </div>
              </div>
              <ConfirmDialog
                open={openDeleteCoachWarning}
                onClose={(e) => handleCloseDeleteCoachWarning(e)}
                message={deleteWarningMessage}
                title={"Fjarlægja leiðbeinanda úr hóp"}
                redButton={true}
                confirmText={"Fjarlægja"}
              />
              <ConfirmDialog
                open={openDeleteAthleteWarning}
                onClose={(e) => handleCloseDeleteAthleteWarning(e)}
                message={deleteWarningMessage}
                title={"Fjarlægja einstakling úr hóp"}
                redButton={true}
                confirmText={"Fjarlægja"}
              />
            </Paper>
            <div className={styles.button}>
              <Button
                variant="outlined"
                color="primary"
                size="large"
                component={Link}
                to={{ pathname: teamPath }}
              >
                Til baka
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (store) => {
  return {
    userType: store.auth.userType,
    userId: store.auth.name,
    name: store.auth.fullName,
    profilePicture: store.auth.team_logo,
    coach: store.auth.coach,
    researcher: store.auth.researcher,
  };
};

export default connect(mapStateToProps)(TeamSettings);
