import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import styles from "./ResetPassword.module.css";
import { Link } from "react-router-dom";
import { Button, Paper } from "@material-ui/core";
import CustomizedSnackbars from "../../components/UI/CustomizedSnackbars";

class ResetPassword extends Component {
  state = {
    email: ""
  };

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const {
      email,
    } = this.state;
    const data = {
      email: email,
    };

    fetch(process.env.REACT_APP_API + "reset_password/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
    .then(
      (result) => {
        if (result.ok) {
          this.setState({
            snackbarOpen: true,
            snackbarMessage: "Sé aðgangur til hefuru fengið sendan póst með nánari leiðbeiningum.",
            snackbarVariant: "success",
          });
          setTimeout(() => {
            this.props.push("/");
          }, 5000);
        }
        else {
          console.log(result)
          this.setState({
            error: true,
            snackbarOpen: true,
            snackbarMessage: "Villa hjá vefþjóni.",
            snackbarVariant: "error",
          });
        }
      },
      (error) => {
        console.log(error)
        this.setState({
          error: true,
          snackbarOpen: true,
          snackbarMessage: "Villa hjá vefþjóni.",
          snackbarVariant: "error",
        });
      }
    );
  };
  
  closeSnackbar = () => {
    this.setState({
      snackbarOpen: false,
    });
  };
  render() {
    
    return (
      <Paper className={styles.paper}>
        <div className={styles.container}>
          <form
            id="ResetPasswordForm"
            className={styles.ResetPasswordForm}
            onSubmit={this.handleSubmit}
          >
            <h2 className={styles.ResetPassword}>Gleymt lykilorð</h2>
            <div className={styles.inputField}>
              <label className="label" htmlFor="email">
                Netfang *
              </label>
              <input
                type="email"
                id="email"
                onChange={this.handleChange}
                required
              />
            </div>
            <div className={styles.buttons}>
              <Button
                variant="outlined"
                color="primary"
                style={{ marginRight: "10px" }}
                component={Link}
                to="/"
              >
                Hætta við{" "}
              </Button>
              <Button type="submit" variant="contained" color="primary">
                Staðfesta{" "}
              </Button>
            </div>
          </form>
          <CustomizedSnackbars
            variant={this.state.snackbarVariant}
            message={this.state.snackbarMessage}
            open={this.state.snackbarOpen}
            closeSnackbar={this.closeSnackbar}
          />
        </div>
      </Paper>
    );
  }
}

const mapStateToProps = () => ({

});

export default connect(mapStateToProps, {
 push
})(ResetPassword);
