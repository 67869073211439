import React, { Component } from "react";
import { connect } from "react-redux";
import CustomizedSnackbars from "../../../UI/CustomizedSnackbars";
import ReactApexChart from "react-apexcharts";
import styles from "./SleepWeekOverview.module.css";
import {dayNameFromNumber, subtractDays, dateToIcelandicString} from "../../../../utils";

var colors = ["#00004d", "#00004d", "#00004d", "#00004d", "#00004d", "#00004d", "#00004d"];

class AthleteChartSleepWeekOverview extends Component {
  constructor(props) {
    super(props);
    let day = this.props.date;
    let week = [
      dateToIcelandicString(subtractDays(day, 6)),
      dateToIcelandicString(subtractDays(day, 5)),
      dateToIcelandicString(subtractDays(day, 4)),
      dateToIcelandicString(subtractDays(day, 3)),
      dateToIcelandicString(subtractDays(day, 2)),
      dateToIcelandicString(subtractDays(day, 1)),
      dateToIcelandicString(day)
    ];
    let weekdays = [
      dayNameFromNumber(subtractDays(day, 6).getDay()),
      dayNameFromNumber(subtractDays(day, 5).getDay()),
      dayNameFromNumber(subtractDays(day, 4).getDay()),
      dayNameFromNumber(subtractDays(day, 3).getDay()),
      dayNameFromNumber(subtractDays(day, 2).getDay()),
      dayNameFromNumber(subtractDays(day, 1).getDay()),
      dayNameFromNumber(day.getDay()),
    ];

    this.state = {
      snackbarOpen: false,
      snackbarMessage: "INITAL",
      snackbarVariant: "info",
      error: false,
      loaded: false,
      week: week,
      weekdays: weekdays,
      options: {
        tooltip: {
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            let timeSlept = 0;
            if (w.globals.seriesRangeStart[seriesIndex][dataPointIndex] < 0) {
              timeSlept =
                w.globals.seriesRangeStart[seriesIndex][dataPointIndex] * -1 +
                w.globals.seriesRangeEnd[seriesIndex][dataPointIndex];
            } else {
              timeSlept =
                w.globals.seriesRangeEnd[seriesIndex][dataPointIndex] -
                w.globals.seriesRangeStart[seriesIndex][dataPointIndex];
            }

            let hours = Math.floor(timeSlept);
            let minutes = Math.round((timeSlept - hours) * 60);
            let timeSleptShow = hours + " klst og " + minutes + " mín";

            let value = w.globals.seriesRangeStart[seriesIndex][dataPointIndex];
            if (value < 0) {
              value = 24 + value;
            }
            hours = Math.floor(value);
            hours = pad(hours, 2);
            minutes = Math.round((value - hours) * 60);
            minutes = pad(minutes, 2);
            let startTime = hours + ":" + minutes;

            value = w.globals.seriesRangeEnd[seriesIndex][dataPointIndex];
            hours = Math.floor(value);
            hours = pad(hours, 2);
            minutes = Math.round((value - hours) * 60);
            minutes = pad(minutes, 2);
            let endTime = hours + ":" + minutes;
            let sleepQualityClass = "";
            if (timeSlept > 7) {
              sleepQualityClass = "good_sleep_tooltip";
            } else {
              sleepQualityClass = "bad_sleep_tooltip";
            }
            return (
              '<div class="sleep_tooltip ' +
              sleepQualityClass +
              '">' +
              "<span>" +
              startTime +
              " - " +
              endTime +
              "</br> tími: " +
              timeSleptShow +
              "</span>" +
              "</div>"
            );
          },
        },
        plotOptions: {
          bar: {
            toolbar: {
              show: false,
            },
            endingShape: "rounded",
            distributed: true,
            horizontal: true,
            columnWidth: "40%",
          },
        },
        colors: colors,
        fill: {
          colors: colors,
        },
        grid: {
          borderColor: "#e7e7e7",
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        markers: {
          size: 1,
        },
        xaxis: {
          title: {
            text: "",
            style: {
              fontSize: 16,
            },
            offsetY: 10,
          },
          labels: {
            formatter: (value) => {
              if (value < 0) {
                value = 24 + value;
              }
              let hours = Math.floor(value);
              hours = pad(hours, 2);
              let minutes = Math.round((value - hours) * 60);
              minutes = pad(minutes, 2);
              return hours + " : " + minutes;
            },
          },
          yaxis: {
            categories: weekdays,
          },
        },
      },
      series: [
        {
          data: [
            {
              color: {
                pattern: "stripes",
              },
            },
          ],
        },
      ],
    };
  }

  componentDidMount() {
   if (!this.state.loaded) {
     this.fetchData();
   }
  }

  componentDidUpdate(prevProps) {
    //Typical usage, don't forget to compare the props
    if (this.props.date!==prevProps.date) {
      this.fetchData();
    }
  }

  handleErrors = (response) =>{
    if (!response.ok) {
      this.setState({ error: true });
    }
    return response;
  }

  fetchData  = () => {
    const id = this.props.athleteId;
    if (id!==0) {
      const queryString = "?athlete=" + this.props.athleteId;

      let day = this.props.date;
      var sixBefore = subtractDays(day, 6).toISOString().substring(0, 10);
      var until = day.toISOString().substring(0,10);
      let week = [
        dateToIcelandicString(subtractDays(day, 6)),
        dateToIcelandicString(subtractDays(day, 5)),
        dateToIcelandicString(subtractDays(day, 4)),
        dateToIcelandicString(subtractDays(day, 3)),
        dateToIcelandicString(subtractDays(day, 2)),
        dateToIcelandicString(subtractDays(day, 1)),
        dateToIcelandicString(day)
      ];
      let weekdays = [
        dayNameFromNumber(subtractDays(day, 6).getDay()),
        dayNameFromNumber(subtractDays(day, 5).getDay()),
        dayNameFromNumber(subtractDays(day, 4).getDay()),
        dayNameFromNumber(subtractDays(day, 3).getDay()),
        dayNameFromNumber(subtractDays(day, 2).getDay()),
        dayNameFromNumber(subtractDays(day, 1).getDay()),
        dayNameFromNumber(day.getDay()),
      ];

      this.setState({
        week: week,
        weekdays: weekdays
      });

      fetch(
        process.env.REACT_APP_API +
          "api/measurements/" +
          queryString +
          "&type__name=WithingsSleepSummary&date_of_measurement__gte=" +
          sixBefore +
          "&date_of_measurement__lte=" +
          until,
        {
          method: "GET",
          headers: {
            Authorization: `JWT ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      )
        .then(this.handleErrors)
        .then((res) => res.json())
        .then(
          (result) => {
            let data = [];
            var weekdaysWithData = new Map();
            var allResults = [];
            var i;
            var dateExists = false;

            for (i = 0; i < this.state.weekdays.length; i++) {
              weekdaysWithData.set(this.state.weekdays[i], [0, 0]);
            }
            for (var k = 0; k < result.results.length; k++) {
              dateExists = false;
              for (var j = 0; j < allResults.length; j++) {
                if (allResults[j].date===result.results[k].data.date) {
                  allResults[j].startdate =
                    (allResults[j].startdate +
                      result.results[k].data.startdate) /
                    2;
                  allResults[j].enddate =
                    (allResults[j].enddate + result.results[k].data.enddate) /
                    2;
                  dateExists = true;
                }
              }
              if (!dateExists) {
                allResults.push({
                  startdate: result.results[k].data.startdate,
                  enddate: result.results[k].data.enddate,
                  date: result.results[k].data.date,
                });
              }
            }
            for (i = 0; i < allResults.length; i++) {
              let date = new Date(allResults[i].date);
                let sleepData = allResults[i];
                /* Multiplying to get milliseconds instead of seconds */
                let offsetDate = new Date(sleepData.startdate * 1000);
                let offsetInMilliseconds = - (offsetDate.getTimezoneOffset()*60000)
                let startDate = new Date(sleepData.startdate * 1000 + offsetInMilliseconds);
                let endDate = new Date(sleepData.enddate * 1000 + offsetInMilliseconds);
                let millisecondsInDay = 1000 * 60 * 60 * 24;
                let startTime = startDate.getTime() % millisecondsInDay;
                let endTime = endDate.getTime() % millisecondsInDay;
                let millisecondsInHour = 1000 * 60 * 60;

                startTime /= millisecondsInHour;
                endTime /= millisecondsInHour;

                if (startTime > endTime) {
                  startTime -= 24;
                }

                startTime = Math.round(startTime * 1000) / 1000;
                endTime = Math.round(endTime * 1000) / 1000;

                weekdaysWithData.set(dayNameFromNumber(date.getDay()), [
                  startTime,
                  endTime,
                ]);
            }

            for (i = 0; i < this.state.weekdays.length; i++) {
              if((weekdaysWithData.get(this.state.weekdays[i])[1] - weekdaysWithData.get(this.state.weekdays[i])[0]) < 7) {
                colors[i] = "#8a0e0e";
              }
              else {
                colors[i] = "#00004d";
              }
              data.push({
                x: this.state.weekdays[i],
                y: weekdaysWithData.get(this.state.weekdays[i]),
              });
            }
            let series1 = { data: [] };
            if (result.results.length > 0) {
              series1 = { data: data };
            }
            let seriesAll = [series1];
            this.setState({ series: seriesAll, loaded: true });
          },
          (error) => {
            console.log(error)
            this.setState({
              error: true,
              snackbarOpen: true,
              snackbarMessage:
                "Það kom upp villa við að sækja svefnupplýsingar.",
              snackbarVariant: "error",
            });
          }
        );
    }
  }

  closeSnackbar = () => {
    this.setState({
      snackbarOpen: false,
    });
  };

  render() {
    return (
      <div id="chart">
        {this.state.week !== undefined && (
          <div>
            Vikan: {this.state.week[0]} - {this.state.week[6]}
          </div>
        )}
        {(this.state.loaded) && (<ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="rangeBar"
          className={styles.chart}
        />
        )}
        <CustomizedSnackbars
          variant={this.state.snackbarVariant}
          message={this.state.snackbarMessage}
          open={this.state.snackbarOpen}
          closeSnackbar={this.closeSnackbar}
        />
      </div>
    );
  }
}

function pad(n, width, z) {
  z = z || "0";
  n = n + "";
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

const mapStateToProps = (store) => {
  if (store.auth.athlete) {
    var athlete = store.auth.athlete;
  } else {
    var athlete = store.router.location.state;
  }
  return {
    userType: store.auth.userType,
    userId: store.auth.id,
    name: store.auth.fullName,
    profilePicture: store.auth.profilePicture,
    athlete: athlete,
  };
};

export default connect(mapStateToProps, null)(AthleteChartSleepWeekOverview);
