import React, { useState, useEffect } from "react";
import CustomizedSnackbars from "../../UI/CustomizedSnackbars";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  FormControl,
  Avatar,
  Button,
  Checkbox,
} from "@material-ui/core";
import styles from "./TeamAccess.module.css";
import {noValue} from "../../../utils";

const UserTeamAccess = (props) => {
  // Messages
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("INITIAL");
  const [snackbarVariant, setSnackbarVariant] = useState("info");
  const [error, setError] = useState(false);
  const [closeModal, setCloseModal] = useState(true);
  const [teamCoaches, setTeamCoaches] = useState([]);

  const { teamId, userType, athleteId } = props;

  // Permissions checked or not
  const [allowSleep, setAllowSleep] = useState(true);
  const [allowBody, setAllowBody] = useState(true);
  const [allowPhysical, setAllowPhysical] = useState(true);
  const [allowPsychological, setAllowPsychological] = useState(true);
  const [allowActivity, setAllowActivity] = useState(true);

  const [sleepId, setSleepId] = useState(0);
  const [bodyId, setBodyId] = useState(0);
  const [physicalId, setPhysicalId] = useState(0);
  const [psychologicalId, setPsychologicalId] = useState(0);
  const [activityId, setActivityId] = useState(0);

  useEffect(() => {
    // Fetch team coaches
    if (teamId!==undefined) {
      // Fetch team coaches
      fetch(process.env.REACT_APP_API + "api/coachofteams/?team=" + teamId, {
        method: "GET",
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then(
          (result) => {
            let tmpCoaches = [];
            for (let i = 0; i < result.results.length; i++) {
              tmpCoaches.push(result.results[i]);
            }
            setTeamCoaches(tmpCoaches);
          },
          (error) => {
            setError(true);
            setSnackbarOpen(true);
            setSnackbarMessage("Villa kom upp við að sækja meðferðaraðila hópsins.");
            setSnackbarVariant("error");
          }
        );
    }
    // Fetch measurement categories
    fetch(process.env.REACT_APP_API + "api/measurementstohide/?team=" + teamId, {
      method: "GET",
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          for (var i = 0; i < result.results.length; i++) {
            if (result.results[i].category === "SLEEP") {
              onChangeSleep(false);
              setSleepId(result.results[i].id);
            } else if (result.results[i].category === "BODY") {
              onChangeBody(false);
              setBodyId(result.results[i].id);
            } else if (result.results[i].category === "PHYSICAL") {
              onChangePhysical(false);
              setPhysicalId(result.results[i].id);
            } else if (result.results[i].category === "ACTIVITY") {
              onChangeActivity(false);
              setActivityId(result.results[i].id);
            } else if (result.results[i].category === "PSYCHOLOGICAL") {
              onChangePsychological(false);
              setPsychologicalId(result.results[i].id);
            }
          }
        },
        (error) => {
          setError(true);
          setSnackbarOpen(true);
          setSnackbarMessage("Villa kom upp við að sækja tegundir mælinga.");
          setSnackbarVariant("error");
        }
      );
  }, [teamId]);

  let queryString = "api/measurementstohide/";

  // CATEGORY = PHYSICAL
  const updatePhysical = () => {
    if (allowPhysical) {
      if (physicalId > 0) {
        // If physical measurements was already banned in /measurementstohide, delete, else do nothing
        fetch(process.env.REACT_APP_API + queryString + physicalId + "/", {
          method: "DELETE",
          headers: {
            Authorization: `JWT ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        })
          .then((res) => {})
          .then(
            (result) => {},
            (error) => {
              setCloseModal(false);
              setError(true);
              setSnackbarOpen(true);
              setSnackbarMessage("Villa kom upp við að eyða bannmerkingu.");
              setSnackbarVariant("error");
            }
          );
      }
    } else {
      if (physicalId === 0) {
        // If physical measurements  was not already banned in /measurementstohide, post, else do nothing
        const data = {
          category: "PHYSICAL",
          athlete: athleteId,
          team: teamId
        };

        let formData = new FormData();
        for (let name in data) {
          formData.append(name, data[name]);
        }

        fetch(process.env.REACT_APP_API + "api/measurementstohide/", {
          method: "POST",
          headers: {
            Authorization: `JWT ${localStorage.getItem("token")}`,
          },
          body: formData,
        })
          .then((res) => res.json())
          .then(
            (result) => {},
            (error) => {
              setCloseModal(false);
              setError(true);
              setSnackbarOpen(true);
              setSnackbarMessage("Villa kom upp við að bæta við bannmerkingu.");
              setSnackbarVariant("error");
            }
          );
      }
    }
  };

  // CATEGORY = PSYCHOLOGICAL
  const updatePsychological = () => {
    if (allowPsychological) {
      // If psychological data should be permitted, delete it from /measurementstohide
      if (psychologicalId > 0) {
        // If psychological data was already banned in /measurementstohide, delete, else do nothing
        fetch(process.env.REACT_APP_API + queryString + psychologicalId + "/", {
          method: "DELETE",
          headers: {
            Authorization: `JWT ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        })
          .then((res) => {})
          .then(
            (result) => {},
            (error) => {
              setCloseModal(false);
              setError(true);
              setSnackbarOpen(true);
              setSnackbarMessage("Villa kom upp við að eyða bannmerkingu.");
              setSnackbarVariant("error");
            }
          );
      }
    } else {
      // If psychological data should not be permitted, post it to /measurementstohide
      if (psychologicalId === 0) {
        // If psychological data was not already banned in /measurementstohide, post, else do nothing
        const data = {
          category: "PSYCHOLOGICAL",
          athlete: athleteId,
          team: teamId
        };

        let formData = new FormData();
        for (let name in data) {
          formData.append(name, data[name]);
        }

        fetch(process.env.REACT_APP_API + "api/measurementstohide/", {
          method: "POST",
          headers: {
            Authorization: `JWT ${localStorage.getItem("token")}`,
          },
          body: formData,
        })
          .then((res) => res.json())
          .then(
            (result) => {},
            (error) => {
              setCloseModal(false);
              setError(true);
              setSnackbarOpen(true);
              setSnackbarMessage("Villa kom upp við að bæta við bannmerkingu.");
              setSnackbarVariant("error");
            }
          );
      }
    }
  };

  // CATEGORY = BODY
  const updateBody = () => {
    if (allowBody) {
      // If body measurements should be permitted, delete it from /measurementstohide
      if (bodyId > 0) {
        // If body measurements was already banned in /measurementstohide, delete, else do nothing
        fetch(process.env.REACT_APP_API + queryString + bodyId + "/", {
          method: "DELETE",
          headers: {
            Authorization: `JWT ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        })
          .then((res) => {})
          .then(
            (result) => {},
            (error) => {
              setCloseModal(false);
              setError(true);
              setSnackbarOpen(true);
              setSnackbarMessage("Villa kom upp við að eyða bannmerkingu.");
              setSnackbarVariant("error");
            }
          );
      }
    } else {
      // If body measurements should not be permitted, post it to /measurementstohide
      if (bodyId === 0) {
        // If body measurements was not already banned in /measurementstohide, post, else do nothing
        const data = {
          category: "BODY",
          athlete: athleteId,
          team: teamId
        };

        let formData = new FormData();
        for (let name in data) {
          formData.append(name, data[name]);
        }

        fetch(process.env.REACT_APP_API + "api/measurementstohide/", {
          method: "POST",
          headers: {
            Authorization: `JWT ${localStorage.getItem("token")}`,
          },
          body: formData,
        })
          .then((res) => res.json())
          .then(
            (result) => {},
            (error) => {
              setCloseModal(false);
              setError(true);
              setSnackbarOpen(true);
              setSnackbarMessage("Villa kom upp við að bæta við bannmerkingu.");
              setSnackbarVariant("error");
            }
          );
      }
    }
  };

  // CATEGORY = SLEEP
  const updateSleep = () => {
    if (allowSleep) {
      // If sleep should be permitted, delete it from /measurementstohide
      if (sleepId > 0) {
        // If sleep was already banned in /measurementstohide, delete, else do nothing
        fetch(process.env.REACT_APP_API + queryString + sleepId + "/", {
          method: "DELETE",
          headers: {
            Authorization: `JWT ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        })
          .then((res) => {})
          .then(
            (result) => {},
            (error) => {
              setCloseModal(false);
              setError(true);
              setSnackbarOpen(true);
              setSnackbarMessage("Villa kom upp við að eyða bannmerkingu.");
              setSnackbarVariant("error");
            }
          );
      }
    } else {
      // If sleep should not be permitted, post it to /measurementstohide
      if (sleepId === 0) {
        // If sleep was not already banned in /measurementstohide, post, else do nothing
        const data = {
          category: "SLEEP",
          athlete: athleteId,
          team: teamId
        };

        let formData = new FormData();
        for (let name in data) {
          formData.append(name, data[name]);
        }

        fetch(process.env.REACT_APP_API + "api/measurementstohide/", {
          method: "POST",
          headers: {
            Authorization: `JWT ${localStorage.getItem("token")}`,
          },
          body: formData,
        })
          .then((res) => res.json())
          .then(
            (result) => {},
            (error) => {
              setCloseModal(false);
              setError(true);
              setSnackbarOpen(true);
              setSnackbarMessage("Villa kom upp við að bæta við bannmerkingu.");
              setSnackbarVariant("error");
            }
          );
      }
    }
  };

  // CATEGORY = ACTIVITY
  const updateActivity = () => {
    if (allowActivity) {
      // If activity data should be permitted, delete it from /measurementstohide
      if (activityId > 0) {
        // If activity data was already banned in /measurementstohide, delete, else do nothing
        fetch(process.env.REACT_APP_API + queryString + activityId + "/", {
          method: "DELETE",
          headers: {
            Authorization: `JWT ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        })
          .then((res) => {})
          .then(
            (result) => {},
            (error) => {
              setCloseModal(false);
              setError(true);
              setSnackbarOpen(true);
              setSnackbarMessage("Villa kom upp við að eyða bannmerkingu.");
              setSnackbarVariant("error");
            }
          );
      }
    } else {
      // If activity data should not be permitted, post it to /measurementstohide
      if (activityId === 0) {
        // If activity data was not already banned in /measurementstohide, post, else do nothing
        const data = {
          category: "ACTIVITY",
          athlete: athleteId,
          team: teamId
        };

        let formData = new FormData();
        for (let name in data) {
          formData.append(name, data[name]);
        }

        fetch(process.env.REACT_APP_API + "api/measurementstohide/", {
          method: "POST",
          headers: {
            Authorization: `JWT ${localStorage.getItem("token")}`,
          },
          body: formData,
        })
          .then((res) => res.json())
          .then(
            (result) => {},
            (error) => {
              setCloseModal(false);
              setError(true);
              setSnackbarOpen(true);
              setSnackbarMessage("Villa kom upp við að bæta við bannmerkingu.");
              setSnackbarVariant("error");
            }
          );
      }
    }
  };

  const updatePermissions = () => {
    updatePhysical();
    updatePsychological();
    updateBody();
    updateSleep();
    updateActivity();

    setSnackbarOpen(true);
    if (!error) {
      setSnackbarMessage(
        "Aðgangur Meðferðaraðila að þínum upplýsingum hefur verið uppfærður."
      );
      setSnackbarVariant("success");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updatePermissions();
  };

  const onChangeSleep = () => {
    setAllowSleep(!allowSleep);
  };

  const onChangeBody = () => {
    setAllowBody(!allowBody);
  };

  const onChangePhysical = () => {
    setAllowPhysical(!allowPhysical);
  };

  const onChangeActivity = () => {
    setAllowActivity(!allowActivity);
  };

  const onChangePsychological = () => {
    setAllowPsychological(!allowPsychological);
  };

  const closeSnackbar = () => {
    setSnackbarOpen(false);
  };

  let snackbar = (
    <CustomizedSnackbars
      variant={snackbarVariant}
      message={snackbarMessage}
      open={snackbarOpen}
      closeSnackbar={closeSnackbar}
    />
  );

  let coaches = teamCoaches
    .sort((a, b) => a.coach.last_name.localeCompare(b.coach.last_name))
    .sort((a, b) => a.coach.first_name.localeCompare(b.coach.first_name))
    .map((coach, index) => {
      let profile_picture = null;
      let color = "white";
      profile_picture = coach.coach.profile_picture;

      const { first_name, last_name, email, about } = coach.coach;
      var memberSince = new Date(coach.member_since).toLocaleDateString();

      let pic = profile_picture;

      return (
        <>
          <TableRow key={"coaches" + index} style={{ backgroundColor: color }}>
            <TableCell component="td" scope="row">
              <div className={styles.row}>
                <div className={styles.avatar}>
                  <Avatar
                    src={pic}
                    alt={`${first_name} ${last_name}`}
                    className={styles.avatar}
                  />
                </div>
                {`${first_name} ${last_name}`}
              </div>
            </TableCell>
            <TableCell className={styles.show}> {email}</TableCell>
            <TableCell className={styles.show}> {about}</TableCell>
            <TableCell className={styles.show}> {memberSince}</TableCell>
          </TableRow>
        </>
      );
    });

  return (
    <div>
      {" "}
      <>{snackbar} </>
      <h3>Meðferðaraðilar hóps</h3>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Nafn</TableCell>
            <TableCell className={styles.show}>Netfang</TableCell>
            <TableCell className={styles.show}>Um</TableCell>
            <TableCell className={styles.show}>Meðferðaraðili síðan</TableCell>
          </TableRow>
        </TableHead>
        {coaches}
      </Table>
      {userType === "athlete" ? (
        <>
          <h3>Aðgangsstýring</h3>
          <p>Veldu tegundir mælinga sem meðferðaraðilar hóps hafa aðgang að</p>
          <form id="submitForm" onSubmit={(e) => handleSubmit(e)}>
            <FormControl>
              <div>
                <Checkbox
                  checked={allowPhysical}
                  onChange={(e) => onChangePhysical(e)}
                  color="primary"
                  name="checkedF"
                />
                Niðurstöður mælinga
              </div>
              <div>
                <Checkbox
                  checked={allowPsychological}
                  onChange={() => onChangePsychological()}
                  color="primary"
                  name="checkedF"
                />
                Sálfræði
              </div>
              <div>
                <Checkbox
                  checked={allowBody}
                  onChange={() => onChangeBody()}
                  color="primary"
                  name="checkedF"
                />
                Líkamsmælingar
              </div>
              <div>
                <Checkbox
                  checked={allowSleep}
                  onChange={() => onChangeSleep()}
                  color="primary"
                  name="checkedF"
                />
                Svefn
              </div>
              <div>
                <Checkbox
                  checked={allowActivity}
                  onChange={() => onChangeActivity()}
                  color="primary"
                  name="checkedF"
                />
                Dagleg virkni
              </div>
            </FormControl>
            <div>
              <Button type="submit" variant="contained" color="primary">
                Vista breytingar
              </Button>
            </div>
          </form>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default UserTeamAccess;
