import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { Menu, MenuItem, IconButton, Avatar } from "@material-ui/core";
import LinkMenuItem from "../UI/LinkMenuItem";
import { logout } from "../../actions/authActions";
import { ATHLETE, RESEARCHER, COACH } from "../../actions/authTypes";
import {noValue} from "../../utils";

const NavBarDropDown = (props) => {
  const [dropdown, setDropdown] = useState(null);

  const handleMenu = (event) => {
    setDropdown(event.currentTarget);
  };

  const handleClose = () => {
    setDropdown(null);
  };

  const handleLoggingOut = () => {
    const { logout } = props;
    logout();
  };

  
  const { userType, profilePicture, name } = props;
  const open = Boolean(dropdown);
  let connectService = null;
  let accountInfo = null;
  let teams = null;

  if (userType === ATHLETE) {
    connectService = (
      <LinkMenuItem
        title="Tengja þjónustur"
        link="/connect"
        close={() => handleClose()}
      />
    );
  }
  accountInfo = (
    <LinkMenuItem
      title="Aðgangurinn minn"
      link={"/account"}
      close={() => handleClose()}
    />
  );

  let pic = profilePicture;
  if (noValue(pic)) {
    pic = name;
  }

  return (
    <div>
      <IconButton
        aria-owns={open ? "menu-appbar" : null}
        aria-haspopup="true"
        onClick={(e) => handleMenu(e)}
        color="inherit"
      >
        <Avatar src={pic} alt={name} />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={dropdown}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={open}
        onClose={() => handleClose()}
      >
        {accountInfo}
        {connectService}
        {teams}
        <MenuItem
          onClick={() => {
            handleLoggingOut();
          }}
        >
          Skrá út
        </MenuItem>
      </Menu>
    </div>
  );
};

const mapStateToProps = ({ auth }) => {
  return {
    userType: auth.userType,
    profilePicture: auth.profilePicture,
    name: auth.fullName,
  };
};

export default connect(mapStateToProps, { logout })(NavBarDropDown);
