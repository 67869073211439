const aboutPsychologicalTest = 
`Test of Performance Strategies (TOPS) 

TOPS mælir sálfræðilega færni og notkun á hugrænum aðferðum íþróttafólks í KEPPNI og á ÆFINGUM. Færnin sem metin er er eftirfarandi:

    • Markmiðasetning
    • Slökun
    • Sjálfsstýring (Automaticity)
    • Virkjunargeta (Activation)
    • Sjónmyndir
    • Sjálfstal
    • Tilfinningastjórnun
    • Athyglisstjórnun
    • Neikvæður þankagangur (Keppni)
    • Athyglisstjórnun (Æfing)

Mælir frá skalanum 1 (Aldrei) til 5 – (Alltaf). Því nær sem skor er 5 því betri er maður í því. 

Allar þessar aðferðir er hægt að þjálfa eins og hverja aðra færni.

The Sport Mental Toughness Questionnaire (SMTQ) 

SMTQ metur andlega hörku íþróttamanna. Íþróttamaðurinn leggur mat á hversu vel ákveðnar staðhæfingar eiga við hann á skalanum: 1 (Alls ekki satt) – 4 (Mjög satt). 

Í listanum er andlegri hörku skipt niður í þrjá undirflokka: 

        ◦ Sjálfstraust (6 Spurningar). Hægt að fá hæst 24. 
        ◦ Tileinkun (Constancy; dedication) (4 spurningar). Hæst er hægt að fá 16.
        ◦ Stjórn (4 spurningar). Hæst er hægt að fá 16. 

Heildarskor fyrir andlegan styrk er samanlögð niðurstaða úr þessum þremur undirflokkum lögð saman. Því er hæst hægt að fá 56.

The Sport Anxiety Scale Questionnaire (SAS-2)

SAS-2 metur hugrænan og líkamlegan kvíða íþróttafólks fyrir eða á meðan keppni stendur. Svarmöguleikarnir eru á skalanum 1 (Alls ekki) – 4 (Mjög mikið). 

Í listanum eru þrír undirflokkar: 
    • Áhyggjur (5 spurningar) 
    • Líkamleg kvíðaeinkenni (5 spurningar) 
    • Einbeitingaskortur (5 spurningar)

Hæst getur maður fengið 20 í hverjum flokk. Meðaltal hvers undirflokks er tekinn saman í lokaskor. Heildarskor listans er því á bilinu 15 – 60. Því hærra skor, því meiri kvíðaeinkenni. 

The Task and Ego Orientaion in Sport Questionnaire (TEOSQ)

TEOSQ metur hvernig einstaklingar skilgreina árangur í íþróttum. Tveir þættir eru metnir, task og ego, eða hvort íþróttamaður sé verkefnismiðaða hugsun eða niðurstöðumiðaða hugsun 

Mikilvægt er að átta sig á að þetta eru aðeins hneigðir íþróttamannsins og er ekki endilega hægt að greina á milli þess hvað er gott eða slæmt. Til að greina í sundur þessa tvo þætti eða  er ágætt að miða við að einstaklingar sem eru verkefnamiðaðir eru líklegri til að einblína á að öðlast færni eða þekkingu og að gera sitt besta. Niðurstöðumiðuð hugsun snýst meira um eigin ágæti og að persónulegir yfirburðir séu lykilatriði í að skilgreina árangur. Slíkir einstaklingar dæma eigin framistöðu eða færni frekar í samanburði við aðra.

Þessi listi er mældur á skalanum 1 – 5 þar sem 5 er hæst. Maður er ekki annað hvort eða með verkefnismiðað eða niðurstöðumiðað hugarfar, heldur er maður með visst magn af báðum.

Perceived Motivational Climate in Sport Questionnaire - 2 (PMCSQ)

PMCSQ er spurningalisti um hvernig íþróttafólk metur andrúmsloftið í sínu liði. Einstaklingurinn svarar hversu sammála (mjög ósammála, frekar ósammála, hvorki sammála né ósammála, frekar sammála, mjög sammála) hann er ákveðnum fullyrðingum um andrúmsloftið í sínu liði, bæði í sambandi við liðsfélaga og þjálfara.  

PMCSQ skiptist í 2 undirflokka (Task og Ego eins og í TEOSQ) sem innihalda hver um sig 3 undirflokka. Því hærra sem skorið er því meira af því er til staðar)

Verkefnamiðað andrúmsloft (Skor á bilinu 17 – 85)

    • Lærdómsmiðað andrúmsloft. Að maður eigi að sýna hugrekki og læra (4 – 20)
    • Hlutverk og framlag manns í liðinu er mikilvægt (5 – 25)
    • Hvatning til að leggja sig fram og bæta sig (8 – 40) 

Niðurstöðumiðað andrúmsloft (Skor á bilinu 16 – 80)

    • Refsing fyrir mistök (6 – 30) 
    • Ákveðnir leikmenn fá meiri athygli en aðrir (7 – 35)
    • Keppni milli liðsfélaga (3 – 15) `;

export default aboutPsychologicalTest ;