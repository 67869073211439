import React, { useState } from "react";
import { PropTypes } from "prop-types";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import styles from "./PasswordChangeForm.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomizedSnackbars from "../../UI/CustomizedSnackbars";

const UserPasswordChangeForm = (props) => {
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showNewPassword2, setShowNewPassword2] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("INITIAL");
  const [snackbarVariant, setSnackbarVariant] = useState("info");
  const [error, setError] = useState(false);
  // const [successMessage, setSuccessMessage] = useState(<></>);
  // const [errorMessage, setErrorMessage] = useState(<></>);

  const { handlePassClick } = props;

  const onChangeNewPassword = (e) => {
    setNewPassword(e.target.value);
  };

  const onChangeNewPassword2 = (e) => {
    setNewPassword2(e.target.value);
  };

  const onChangePassword = (e) => {
    setPassword(e.target.value);
  };

  // CHANGE PASSWORD HERE
  const onSubmit = (e) => {
    e.preventDefault();

    const passChange = {
      new_password1: newPassword,
      new_password2: newPassword2,
      old_password: password,
    };

    var formData = new FormData();
    for (var name in passChange) {
      formData.append(name, passChange[name]);
    }

    // Change the password here
    fetch(process.env.REACT_APP_API + "password/", {
      method: "POST",
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
      body: formData,
    })
      .then((res) => {})
      .then(
        (result) => {
          setError(false);
          setSnackbarOpen(true);
          setSnackbarMessage("Lykilorði hefur verið breytt.");
          setSnackbarVariant("success");
          setTimeout(() => {
            handlePassClick(true);
          }, 2000);
        },
        (error) => {
          setError(true);
          setSnackbarOpen(true);
          setSnackbarVariant("error");
          if (newPassword!==newPassword2) {
            setSnackbarMessage("Nýju lykilorðin þurfa að passa saman.");
          } else {
            setSnackbarMessage("Ekki tókst að breyta lykilorði.");
          }
        }
      );
  };

  const onClickPassword = () => {
    setShowPassword(!showPassword);
  };

  const onClickNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const onClickNewPassword2 = () => {
    setShowNewPassword2(!showNewPassword2);
  };

  const onCancel = () => {
    handlePassClick(true);
  };

  const closeSnackbar = () => {
    setSnackbarOpen(false);
  };

  let snackbar = (
    <CustomizedSnackbars
      variant={snackbarVariant}
      message={snackbarMessage}
      open={snackbarOpen}
      closeSnackbar={closeSnackbar}
    />
  );

  return (
    <Grid container justify="center" alignItems="center">
      {snackbar}
      <Card style={{ width: "350px", height: "400px", padding: "20px" }}>
        <FontAwesomeIcon
          icon="times"
          style={{ color: "grey", cursor: "pointer" }}
          className={styles.theicons}
          onClick={() => onCancel()}
        />
        <form onSubmit={onSubmit} className={styles.formLogin}>
          <div className={styles.divider} />
          <FormControl>
            <InputLabel style={{ marginLeft: 27 }} error={error}>
              Núverandi lykilorð
            </InputLabel>
            <Input
              className={styles.passwordField}
              type={showPassword ? "text" : "password"}
              name="password"
              value={password}
              onChange={(e) => onChangePassword(e)}
              error={error}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Toggle password visibility"
                    onClick={() => onClickPassword()}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <FormControl>
            <InputLabel style={{ marginLeft: 27 }} error={error}>
              Nýtt lykilorð
            </InputLabel>
            <Input
              className={styles.passwordField}
              type={showNewPassword ? "text" : "password"}
              name="newPassword"
              value={newPassword}
              onChange={(e) => onChangeNewPassword(e)}
              error={error}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Toggle password visibility"
                    onClick={() => onClickNewPassword()}
                  >
                    {showNewPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <FormControl>
            <InputLabel style={{ marginLeft: 27 }} error={error}>
              Nýtt lykilorð aftur
            </InputLabel>
            <Input
              className={styles.passwordField}
              type={showNewPassword2 ? "text" : "password"}
              name="newPassword2"
              value={newPassword2}
              onChange={(e) => onChangeNewPassword2(e)}
              error={error}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Toggle password visibility"
                    onClick={() => onClickNewPassword2()}
                  >
                    {showNewPassword2 ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>

          <div className={styles.divider} />
          <Button type="submit" variant="contained" color="primary" disabled={(newPassword !== newPassword2 || newPassword === "" || password === "" || newPassword.length < 8)}>
            Breyta lykilorði
          </Button>
        </form>
      </Card>
    </Grid>
  );
};

export default UserPasswordChangeForm;
