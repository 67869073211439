import React, { useState, useEffect } from "react";
import CustomizedSnackbars from "../../UI/CustomizedSnackbars";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";
import styles from "./ChangeEmailButton.module.css";
import UserEmailChangeForm from "../EmailChangeForm/EmailChangeForm";
import { logout } from "../../../actions/authActions";
import EditIcon from '@material-ui/icons/Edit';

const UserChangeEmailButton = (props) => {
    const button = (
      <Button
        type="submit"
        variant="contained"
        color="secondary"
        onClick={() => handleClick()}
        startIcon={<EditIcon/>} 
        >
        Breyta Netfangi
      </Button>
    )

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("INITIAL");
    const [snackbarVariant, setSnackbarVariant] = useState("info");
    const [changeEmailForm, setChangeEmailForm] = useState(<></>);
    const [emailButton, setEmailButton] = useState(button)
    const [emailToggle, setEmailToggle] = useState(false);

    useEffect(() => {
        
    }, []);

    const closeSnackbar = () => {
        setSnackbarOpen(false);
    };

    const handleClick = (closed) => {
      if (closed) {
        setEmailToggle(false);
        setChangeEmailForm(<></>);
        setEmailButton( button );
      } else {
        setEmailToggle(!emailToggle);
        setChangeEmailForm(
          <>
            <UserEmailChangeForm
              handleClose={handleClose}
              userId={props.userId}
            />
          </>
        );
        setEmailButton(<></>);
      }
    };

    let snackbar = (
        <CustomizedSnackbars
            variant={snackbarVariant}
            message={snackbarMessage}
            open={snackbarOpen}
            closeSnackbar={closeSnackbar}
        />
    )

    const handleClose = (changed) => {
      handleClick(true)
      if (changed) {
        const { logout } = props;
        logout();
      }
    };

    return (
      <div className={styles.emailChange}>
        {snackbar}
        {emailButton}
        {changeEmailForm}
      </div>
    )
}

const mapStateToProps = (store) => {
    return {
      userType: store.auth.userType,
    };
};

export default connect(mapStateToProps, { logout })(UserChangeEmailButton);

