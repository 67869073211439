import React from 'react';
import styles from './Footer.module.css'
import {Card, Grid, Typography} from "@material-ui/core";
import Collaboration from "../Collaboration/Collaboration"

const Footer = () => {
    return (
        <Card className={styles.footnote}>
            <Collaboration />
        </Card>
    );
};

export default Footer;