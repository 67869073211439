import React, { Component } from "react";
import { connect } from "react-redux";
import CustomizedSnackbars from "../../../UI/CustomizedSnackbars";

import ReactApexChart from "react-apexcharts";
import moment from "moment";
import styles from "./SleepHeartRate.module.css";

import {dayNameFromNumber, dateToIcelandicString} from "../../../../utils";

var colors = ["rgba(191, 85, 236, 1)"];

class AthleteChartSleepHeartRate extends Component {
  constructor(props) {
    super(props);

    let date = new Date();
    let today = new Date();
    let weekAgo = new Date();
    weekAgo.setDate(date.getDate() - 6);
    let oneDayAgo = new Date();
    oneDayAgo.setDate(date.getDate() - 1);
    let twoDaysAgo = new Date();
    twoDaysAgo.setDate(date.getDate() - 2);
    let threeDaysAgo = new Date();
    threeDaysAgo.setDate(date.getDate() - 3);
    let fourDaysAgo = new Date();
    fourDaysAgo.setDate(date.getDate() - 4);
    let fiveDaysAgo = new Date();
    fiveDaysAgo.setDate(date.getDate() - 5);
    let week = [
      dateToIcelandicString(weekAgo),
      dateToIcelandicString(fiveDaysAgo),
      dateToIcelandicString(fourDaysAgo),
      dateToIcelandicString(threeDaysAgo),
      dateToIcelandicString(twoDaysAgo),
      dateToIcelandicString(oneDayAgo),
      dateToIcelandicString(today)
    ];
    let weekdays = [
      dayNameFromNumber(weekAgo.getDay()),
      dayNameFromNumber(fiveDaysAgo.getDay()),
      dayNameFromNumber(fourDaysAgo.getDay()),
      dayNameFromNumber(threeDaysAgo.getDay()),
      dayNameFromNumber(twoDaysAgo.getDay()),
      dayNameFromNumber(oneDayAgo.getDay()),
      dayNameFromNumber(today.getDay()),
    ];

    this.state = {
      snackbarOpen: false,
      snackbarMessage: "INITAL",
      snackbarVariant: "info",
      error: false,
      loaded: false,

      //BÆTT INN
      week: week,
      weekdays: weekdays,

      options: {
        plotOptions: {
          bar: {
            endingShape: "rounded",
            distributed: false,
            horizontal: false,
            columnWidth: "60%",
            dataLabels: {
              position: "top",
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val + " bpm";
          },
          offsetY: 20,
        },
        colors: colors,
        fill: {
          colors: colors,
        },
        chart: {
          toolbar: {
            show: false,
          },
          type: "bar",
          dropShadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2,
          },
        },

        title: {
          text: "Hjartslátturinn minn seinustu nótt var ...",
          align: "center",
          position: "top",
          style: {
            fontSize: 16,
          },
        },
        grid: {
          borderColor: "#e7e7e7",
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        xaxis: {
          categories: ["Lægsti púls", "Hæsti púls", "Meðal púls"],
          color: ["black"],
          position: "bottom",
          labels: {
            style: {
              fontSize: 14,
            },
          },

          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },

          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },

          labels: {
            formatter: (value) => {
              return value + " bpm ";
            },

            style: {
              fontSize: 14,
            },
          },
        },
      },
      series: [
        {
          name: "Púls",
          data: [],
        },
      ],
    };
  }

  componentDidUpdate(prevProps) {
    //Typical usage, don't forget to compare the props

    if (this.props.day!==prevProps.day) {
      this.fetchData();
    }
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    const id = this.props.athleteId;
    if (id!==0) {
      const queryString = "?athlete=" + this.props.athleteId;
      var yesterday = moment().subtract(1, "d").format("YYYY-MM-DD");
      var day = this.props.day;
      fetch(
        process.env.REACT_APP_API +
          "api/measurements/" +
          queryString +
          "&type__name=WithingsSleepSummary&date_of_measurement__gte=" +
          day +
          "&date_of_measurement__lte=" +
          day,
        {
          method: "GET",
          headers: {
            Authorization: `JWT ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      )
        .then(handleErrors)
        .then((res) => res.json())
        .then(
          (result) => {
            if (result.results.length!==0) {
              var yesterday = moment().format("YYYY-MM-DD");

              let data = [];
              let dataArray = [];
              let infoArray = [];
              var i;

              for (i = 0; i < result.results.length; i++) {
                let date = new Date(result.results[i].date_of_measurement);
                //if (this.state.week.includes(date.toDateString())) {
                if (result.results[i].date_of_measurement===day) {
                  dataArray.push(result.results[i].data.data);
                  break;
                }
                // }
              }
              infoArray = dataArray[Object.keys(dataArray)[0]];

              let heartRateData = [];
              var heartRateMin = infoArray.hr_min;
              var heartRateMax = infoArray.hr_max;
              var heartRateAverage = infoArray.hr_average;

              heartRateData.push(heartRateMin);
              heartRateData.push(heartRateMax);
              heartRateData.push(heartRateAverage);

              var labelMap = [
                "Lægsti púls",
                "Hæsti púls",
                "Meðal púls",
              ];

              for (i = 0; i < heartRateData.length; i++) {
                data.push({
                  x: labelMap[i],
                  y: heartRateData[i],
                });
              }

              let series1 = { data: data };
              let seriesAll = [series1];
              this.setState({ series: seriesAll, loaded: true });
            } else {
              let series1 = { data: [] };
              let seriesAll = [series1];
              this.setState({
                error: true,
                series: seriesAll,
                snackbarOpen: true,
                snackbarMessage:
                  "Engin gögn eru til staðar fyrir svefn þessa nótt.",
                snackbarVariant: "warning",
              });
            }
          },
          (error) => {
            this.setState({
              error: true,
              snackbarOpen: true,
              snackbarMessage:
                "Það kom upp villa við að sækja svefnupplýsingar.",
              snackbarVariant: "error",
            });
          }
        );
    }
  }

  closeSnackbar = () => {
    this.setState({
      snackbarOpen: false,
    });
  };

  render() {
    return (
      <div id="chart">
        {(this.state.loaded) && (
          <ReactApexChart
            options={this.state.options}
            series={this.state.series}
            type="bar"
            className={styles.chart}
          />
        )}
        <CustomizedSnackbars
          variant={this.state.snackbarVariant}
          message={this.state.snackbarMessage}
          open={this.state.snackbarOpen}
          closeSnackbar={this.closeSnackbar}
        />
      </div>
    );
  }
}

function handleErrors(response) {
  if (!response.ok) {
    this.setState({ error: true });
  }
  return response;
}

const mapStateToProps = (store) => {
  if (store.auth.athlete) {
    var athlete = store.auth.athlete;
  } else {
    var athlete = store.router.location.state;
  }
  return {
    userType: store.auth.userType,
    userId: store.auth.id,
    name: store.auth.fullName,
    profilePicture: store.auth.profilePicture,
    athlete: athlete,
  };
};

export default connect(mapStateToProps, null)(AthleteChartSleepHeartRate);
