import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./Item.module.css";
import { connect } from "react-redux";
import 'moment/locale/is';
import moment from 'moment';
import DialogWithButton from '../../UI/DialogWithButton/DialogWithButton'
import ConfirmDialog from '../../UI/ConfirmDialog'
import CustomizedSnackbars from '../../UI/CustomizedSnackbars'
import axios from 'axios';
import ReactTooltip from 'react-tooltip'
import CircularProgress from "@material-ui/core/CircularProgress";
import { Link } from "react-router-dom";
import { noValue } from "../../../utils";

class MeasurementsFileItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDeleteWarning: false,
      openConfirmWarning: false,
      openDeconfirmWarning: false,
      snackbarOpen: false,
      snackbarMessage: "INITAL",
      snackbarVariant: "info",
      fileDeleted: false,
      loading_confirmed: false,
      loading_delete: false
    };
  }

  componentDidMount() {
    if (this.props.measurementsFile.parsable == null) {
      this.checkIfLoadingDone()
    }
    if (this.props.measurementsFile.file_parsing_confirmed && this.props.measurementsFile.event == null) {
      this.checkIfLoadingConfirmationDone()
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      if (this.props.measurementsFile.file_parsing_confirmed && this.props.measurementsFile.event == null) {
        this.checkIfLoadingConfirmationDone()
      }
    }
  }

  closeSnackbar = () => {
    this.setState({
      snackbarOpen: false
    });
  }

  handleCloseDeleteWarning = confirmed => {
    this.setState({     openDeleteWarning: false      })
    if (confirmed) {
      this.deleteFile(this.props.measurementsFile.id)
    }
  };

  handleOpenDeleteWarning = () => {
    this.setState({     openDeleteWarning: true      })
  };

  handleCloseConfirmWarning = confirmed => {
    this.setState({     openConfirmWarning: false      })
    if (confirmed) {
      this.confirmFile(this.props.measurementsFile.id);
    }
  };

  handleOpenConfirmWarning = () => {
    this.setState({     openConfirmWarning: true      })
  };

  handleCloseDeconfirmWarning = confirmed => {
    this.setState({     openDeconfirmWarning: false      })
    if (confirmed) {
      this.deconfirmFile(this.props.measurementsFile.id);
    }
  };

  handleOpenDeconfirmWarning = () => {
    this.setState({     openDeconfirmWarning: true      })
  };

  downloadFile = url => {
    setTimeout(() => {
      const response = {
        file: url,
      };
      // server sent the url to the file!
      // now, let's download:
      window.open(response.file);
      // you could also do:
      // window.location.href = response.file;
    }, 100);
  }

  checkIfLoadingDone = () => {
    fetch(process.env.REACT_APP_API + "api/measurementsfiles/" + this.props.measurementsFile.id + "/", {
      method: "GET",
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`,
        "Content-Type": "application/json"
      },
    })
    .then((res) => res.json())
    .then((result) => {
        if (result.parsable == null) {
          setTimeout(() => {
            this.checkIfLoadingDone()
          }, 2500);
        }
        else {
          this.props.updateFiles()
        }
      }
    )
  }

  checkIfLoadingConfirmationDone = () => {
    fetch(process.env.REACT_APP_API + "api/measurementsfiles/" + this.props.measurementsFile.id + "/", {
      method: "GET",
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`,
        "Content-Type": "application/json"
      },
    })
    .then((res) => res.json())
    .then((result) => {
        if (result.event == null) {
          setTimeout(() => {
            this.checkIfLoadingDone()
          }, 2500);
        }
        else {
          this.setState({
            snackbarOpen: true,
            snackbarMessage: 'Mælingar komnar inn í kerfið.',
            snackbarVariant: 'success'
          });
          this.props.updateFiles()
        }
      }
    )
  }

  deleteFile = id => {
    this.setState({
      loading_delete : true
    });
    fetch(process.env.REACT_APP_API + "api/measurementsfiles/" + id + "/", {
      method: "DELETE",
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`,
        "Content-Type": "application/json"
      },
    })
    .then(res => res)
    .then(
      (res) => {
        if (res.ok) {
          this.setState({
            snackbarOpen: true,
            snackbarMessage: 'Skjali hefur verið eytt úr kerfinu.',
            snackbarVariant: 'success',
            fileDeleted: true,
            loading_delete : false
          });
        }
        else {
          this.setState({
            snackbarOpen: true,
            snackbarMessage: 'Þú hefur ekki heimild til að eyða skjali sem þú settir ekki inn.',
            snackbarVariant: 'error',
            loading_delete: false
          });
        }
      },
      (error) => {
        this.setState({
          snackbarOpen: true,
          snackbarMessage: 'Það kom upp villa við að eyða skjali úr kerfinu. Vinsamlegast reynið aftur.',
          snackbarVariant: 'error',
          loading_delete: false
        });
        this.handleErrors();
      }
    )
  }
  
  confirmFile = id => {
    let form_data = new FormData();
    form_data.append('file_parsing_confirmed', true);

    let config = {
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`
      }
    }
    this.setState({
      loading_confirmed : true
    });
    axios.patch(process.env.REACT_APP_API + "api/measurementsfiles/" + id + "/", form_data, config)
    .then( (response) => {
      this.setState({
        snackbarOpen: true,
        snackbarMessage: 'Verið að setja mælingar inn í kerfið.',
        snackbarVariant: 'info',
        loading_confirmed: false
      });
      this.props.updateFiles();
    })
    .catch((error) => {
      this.setState({
        snackbarOpen: true,
        snackbarMessage: 'Það kom upp villa við að setja mælingar inn í kerfið. Vinsamlegast reynið aftur.',
        snackbarVariant: 'error',
        loading_confirmed: false
      });
      this.handleErrors();
    });
  }
  
  deconfirmFile = id => {
    let form_data = new FormData();
    form_data.append('file_parsing_confirmed', false);

    let config = {
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`
      }
    }

    this.setState({
      loading_confirmed : true
    });

    axios.patch(process.env.REACT_APP_API + "api/measurementsfiles/" + id + "/", form_data, config)
    .then( (response) => {
      this.props.updateFiles();
      this.setState({
        snackbarOpen: true,
        snackbarMessage: 'Mælingar eru farnar úr kerfinu.',
        snackbarVariant: 'success',
        loading_confirmed: false
      });
    })
    .catch((error) => {
      this.setState({
        snackbarOpen: true,
        snackbarMessage: 'Það kom upp villa við að fjarlægja mælingar úr kerfinu. Vinsamlegast reynið aftur.',
        snackbarVariant: 'error',
        loading_confirmed: false
      });
      this.handleErrors();
    });
  }

  render() {
    var color = '';
    if(this.props.measurementsFile.event !== null) {
      color = 'green'
    }
    else if(this.props.measurementsFile.parsable === null) {
      color = 'grey'
    }
    else if(!this.props.measurementsFile.parsable) {
      color = 'red'
    }
    else {
      color = 'yellow'
    }
    
    var date = moment(this.props.measurementsFile.timestamp)
    const timestamp = 'sett inn: ' + date.format('DD.MM.YYYY');
    const deleteWarningMessage =  'Ertu viss um að þú viljir eyða "' +  this.props.measurementsFile.name + '"?';
    const confirmWarningMessage = 'Ertu viss um að þú viljir staðfesta niðurstöður fyrir "'  + this.props.measurementsFile.name + '"? Vertu viss um að skoða niðurstöður skjals(mælistika) fyrst! Mælingarnar munu vera sýnilegar viðeigandi þjónustuþegum og meðferðaraðilum. Ef nýr notandi er í skránni verður einnig stofnaður fyrir hann aðgangur og sendur tölvupóstur.'
    const deconfirmWarningMessage = '"Ertu viss um að þú viljir fjarlægja mælingar frá "'  + this.props.measurementsFile.name + '"? Allar mælingar munu hverfa úr kerfinu og ekki lengur vera sýnilegar viðeigandi þjónustuþegum og meðferðaraðilum.'

    date = moment(this.props.measurementsFile.date_of_measurement);
    const date_of_measurement = 'dags. mælingar: ' + date.format('DD.MM.YYYY');
    let trash = null;
    if(!this.props.measurementsFile.file_parsing_confirmed || this.props.measurementsFile.parsable === null) {
      trash = (
        <span data-tip data-for='file_delete' className={[styles.textInfo, styles.itemInfo].join(' ')} onClick={() => this.handleOpenDeleteWarning()}>
          <span className={styles.hoverIcon}>
            <FontAwesomeIcon className={[styles.itemInfo].join(' ')} icon="trash" color="red" />
          </span>
          <ReactTooltip place="top" type="dark" id='file_delete'>
            <span>Eyða skjali</span>
          </ReactTooltip>
        </span>
      )
    }

    let confirm = null;
    let deConfirm = null;
    let loading = null;
    
    if(this.props.measurementsFile.parsable && !this.props.measurementsFile.file_parsing_confirmed) {
      confirm = (
        <span data-tip data-for='file_confirm' className={[styles.textInfo, styles.itemInfo].join(' ')}>
          <span className={styles.hoverIcon} onClick={() => this.handleOpenConfirmWarning()}>
            <FontAwesomeIcon className={[styles.itemInfo].join(' ')} icon="check" color="green" />
          </span>
          <ReactTooltip place="top" type="dark" id='file_confirm'>
            <span>Samþykkja niðurstöður skjals</span>
          </ReactTooltip>
        </span>
      )
    }
    if (this.props.measurementsFile.parsable && this.props.measurementsFile.file_parsing_confirmed && this.props.measurementsFile.event == null) {
      // Loading the confirmation of a file result
      loading = (<CircularProgress/>)
    }

    if(this.props.measurementsFile.parsable && this.props.measurementsFile.file_parsing_confirmed && this.props.measurementsFile.event !== null) {
      deConfirm = (
        <span data-tip data-for='file_deconfirm' className={[styles.textInfo, styles.itemInfo].join(' ')}>
          <span className={styles.hoverIcon} onClick={() => this.handleOpenDeconfirmWarning()}>
            <FontAwesomeIcon className={[styles.itemInfo].join(' ')} icon="times" color="red" />
          </span>
          <ReactTooltip place="top" type="dark" id='file_deconfirm'>
            <span>Fjarlægja mælingar skjals</span>
          </ReactTooltip>
        </span>
      )
    }
    
    var renderedFile = null;
    if (this.props.measurementsFile.parsed_file != null) {
      renderedFile= (
        <span data-tip data-for='rendered_file' className={[styles.textInfo, styles.itemInfo, styles.hoverIcon].join(' ')} onClick={() => this.downloadFile(this.props.measurementsFile.parsed_file)}>
          <FontAwesomeIcon icon="ruler" color="black"/>
        </span>
      )
    }

    if (this.state.fileDeleted) {
      return (
        <CustomizedSnackbars variant={this.state.snackbarVariant} message={this.state.snackbarMessage} open={this.state.snackbarOpen} closeSnackbar={this.closeSnackbar}/>
      )
    }

    if(this.state.loading_delete || this.state.loading_confirmed) {
      loading = (<CircularProgress size={25}/>);
      trash = null;
      confirm = null;
      deConfirm = null;
    }
    if(this.props.measurementsFile.parsable === null) {
      loading = (<CircularProgress size={25}/>);
    }

    let team_url = ""
    if (this.props.measurementsFile.team) {
      team_url = "/group/" + this.props.measurementsFile.team.id
    }
    let uploaded_by = ""
    if(!noValue(this.props.measurementsFile.uploaded_by)) {
      uploaded_by = this.props.measurementsFile.uploaded_by.email
    }
    else {
      uploaded_by = "Notandi ekki til"
    }
    let teamName = ""
    if (this.props.measurementsFile.team) {
      teamName = this.props.measurementsFile.team.name
    }
    return (
      <div key={"measFile"+this.props.measurementsFile.id} className={color === 'red' ? styles.background_red : color === 'yellow' ? styles.background_yellow : color === 'grey' ? styles.background_grey : styles.background_green}>
        <span data-tip data-for='file_name' className={[styles.link, styles.textInfo, styles.itemInfo].join(' ')} onClick={() => this.downloadFile(this.props.measurementsFile.file)}>
          {this.props.measurementsFile.name}
        </span>
        <ReactTooltip place="top" type="dark" id='file_name'>
          <span>Sækja skjal</span>
        </ReactTooltip>
        <span data-tip data-for='file_comment' className={[styles.textInfo, styles.itemInfo].join(' ')}>
          <DialogWithButton title="Athugasemdir" iconName="comment" content={this.props.measurementsFile.comment}/>
        </span>
        <ReactTooltip place="top" type="dark" id='file_comment'>
          <span>Athugasemdir</span>
        </ReactTooltip>
        {renderedFile}
        <ReactTooltip place="top" type="dark" id='rendered_file'>
          <span>Sækja niðurstöður skjals</span>
        </ReactTooltip>
        <span className={[styles.textInfo, styles.itemInfo].join(' ')}>{timestamp}</span>
        <span className={[styles.textInfo, styles.itemInfo].join(' ')}>{uploaded_by}</span>
        <Link to={team_url} className={[styles.itemInfo, styles.textInfo, styles.team_link].join(' ')}>{teamName}</Link>
        <span className={[styles.itemInfo, styles.textInfo].join(' ')}>{date_of_measurement}</span>
        {confirm}
        {trash}
        {deConfirm}
        {loading}
        <ConfirmDialog  open={this.state.openDeleteWarning} onClose={this.handleCloseDeleteWarning} message={deleteWarningMessage} title={"Eyða skjali"} redButton={true} confirmText={"Eyða"} />
        <ConfirmDialog  open={this.state.openConfirmWarning} onClose={this.handleCloseConfirmWarning} message={confirmWarningMessage} title={"Samþykkja niðurstöður"} redButton={false} confirmText={"Samþykkja"} /> 
        <ConfirmDialog  open={this.state.openDeconfirmWarning} onClose={this.handleCloseDeconfirmWarning} message={deconfirmWarningMessage} title={"Fjarlægja mælingar"} redButton={true} confirmText={"Fjarlægja"} /> 
        <CustomizedSnackbars variant={this.state.snackbarVariant} message={this.state.snackbarMessage} open={this.state.snackbarOpen} closeSnackbar={this.closeSnackbar}/>
      </div>
    );
  }

  handleErrors = response => {
    this.setState({       error: true     });
  }
}


const mapStateToProps = store => {
  return {
    
  };
};

export default connect(
  mapStateToProps,
  null
)(MeasurementsFileItem);
