import React, { Component, useEffect, useState } from "react";
import CustomizedSnackbars from "../../../UI/CustomizedSnackbars";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import styles from "./DayActivity.module.css";
import { Typography } from "@material-ui/core";

const AthleteChartDayActivity = (props) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("INITIAL");
  const [snackbarVariant, setSnackbarVariant] = useState("info");
  const [error, setError] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [steps, setSteps] = useState(0);
  const [calories, setCalories] = useState(0);
  const [totalCalories, setTotalCalories] = useState(0);
  const [distance, setDistance] = useState(0);

  useEffect(() => {
    const id = props.athleteId;
    if (id!==0 && id!==undefined) {
      const queryString = "?athlete=" + props.athleteId;
      // var day = moment().subtract(1, "d").format("YYYY-MM-DD");

      fetch(
        process.env.REACT_APP_API +
          "api/measurements/" +
          queryString +
          "&type__name=WithingsActivitySummary&date_of_measurement__gte=" +
          props.day +
          "&date_of_measurement__lte=" +
          props.day,

        {
          method: "GET",
          headers: {
            Authorization: `JWT ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      )
        .then(handleErrors)
        .then((res) => res.json())
        .then(
          (result) => {
            if (result.results.length!==0) {
              setLoaded(true);
              let dataArray = [];
              let infoArray = [];
              var i;
              for (i = 0; i < result.results.length; i++) {
                dataArray.push(result.results[i].data);
              }

              infoArray = dataArray[Object.keys(dataArray)[0]];

              let steps = infoArray.steps;

              let calories = infoArray.calories.toFixed(0);
              let totalCalories = infoArray.totalcalories.toFixed(0);
              let distance = infoArray.distance.toFixed(0);

              setSteps(steps);
              setLoaded(true);
              setCalories(calories);
              setTotalCalories(totalCalories);
              setDistance(distance);
            } else {
              setSteps(0);
              setLoaded(true);
              setCalories(0);
              setTotalCalories(0);
              setDistance(0);
              setSnackbarOpen(true);
              setSnackbarMessage(
                "Engin gögn til staðar frá úri fyrir þennan dag."
              );
              setSnackbarVariant("warning");
            }
          },
          (error) => {
            setError(true);
            setSnackbarOpen(true);
            setSnackbarMessage(
              "Það kom upp villa við að sækja upplýsingar um æfingar."
            );
            setSnackbarVariant("error");
          }
        );
    }
  }, [props.day]);

  const closeSnackbar = () => {
    setSnackbarOpen(false);
  };

  function handleErrors(response) {
    if (!response.ok) {
      setError(true);
    }
    return response;
  }

  var stepsString = steps.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  var caloriesString = calories
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  var totalCaloriesString = totalCalories
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  var distanceString = distance
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ".");

  return (
    <div className={styles.workout}>
      <div>
        <Grid className={styles.container}>
          <Grid className={styles.cardContent}>
            <Card className={styles.cardWidth}>
              <CardContent>
                <Typography
                  fontSize="12"
                  className={styles.title1}
                  color="textSecondary"
                >
                  Skref dagsins:
                </Typography>
                <Typography variant="subtitle2" align="center">
                  {stepsString} skref
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid className={styles.cardContent}>
            <Card className={styles.cardWidth}>
              <CardContent>
                <Typography className={styles.title2} color="textSecondary">
                  Brenndar hitaeiningar á æfingu:
                </Typography>
                <Typography variant="subtitle2" align="center">
                  {caloriesString} cal
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid className={styles.cardContent}>
            <Card className={styles.cardWidth}>
              <CardContent>
                <Typography
                  className={styles.title2}
                  color="textSecondary"
                  gutterBottom
                >
                  Samtals hitaeiningar:
                </Typography>
                <Typography variant="subtitle2" align="center">
                  {totalCaloriesString} cal
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid className={styles.cardContent}>
            <Card className={styles.cardWidth}>
              <CardContent>
                <Typography
                  className={styles.title2}
                  color="textSecondary"
                  gutterBottom
                >
                  Samtals vegalengd:
                </Typography>
                <Typography variant="subtitle2" align="center">
                  {distanceString} metrar
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <CustomizedSnackbars
          variant={snackbarVariant}
          message={snackbarMessage}
          open={snackbarOpen}
          closeSnackbar={closeSnackbar}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (store) => {
  if (store.auth.athlete) {
    var athlete = store.auth.athlete;
  } else {
    var athlete = store.router.location.state;
  }
  return {
    userType: store.auth.userType,
    userId: store.auth.id,
    athlete: athlete,
  };
};

export default connect(mapStateToProps, null)(AthleteChartDayActivity);
