import moment from "moment";
import MomentLocaleUtils, {
  formatDate
} from "react-day-picker/moment";

export function noValue(value) {
    return (value==="" || value===undefined || value===null || value==="unknown")
}

export function getStatisticsPercentage(valueName, measWithStat, gender = true) {
    let minValue = gender ? measWithStat.team_events_gender_min : measWithStat.team_events_min;
    const maxValue = gender ? measWithStat.team_events_gender_max : measWithStat.team_events_max;

    // min===max returns 100%
    if (maxValue===minValue) {
        return 100;
    }

    // Adding 10% margin under min so no value ends in the middle
    minValue = minValue - (maxValue - minValue)*0.1;

    // Make max 100% and min - margin 0%, then find the percentage of value
    return Math.round(
    ((measWithStat[valueName] - minValue) /
        (maxValue - minValue)) *
        1000
    ) / 10;
}

export function subtractDays(date, amount) {
  return new Date(moment(date).subtract(amount, "day"))
}

export function dayNameFromNumber(num) {
    let day = "";
    switch (num) {
      case 0:
        day = "Sun";
        break;
      case 1:
        day = "Mán";
        break;
      case 2:
        day = "Þri";
        break;
      case 3:
        day = "Mið";
        break;
      case 4:
        day = "Fim";
        break;
      case 5:
        day = "Fös";
        break;
      case 6:
        day = "Lau";
        break;
      default:
        day = "";
    }
    return day;
}

export function dateToIcelandicString(date) {
  return formatDate(date.toISOString().slice(0,10), "LL", "is" )
}