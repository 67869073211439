import React, { useEffect, useState } from "react";
import styles from './terms.module.css'
import {Card, Grid, Typography} from "@material-ui/core";



const Terms = () => {
  const [researchers, setResearchers] = useState([]);

  useEffect(() => {
    // Fetching researchers
    fetch(process.env.REACT_APP_API + "researchers", {
        method: "GET",
        headers: {
          "content-type": "application/json",
        },
      })
      .then((res) => res.json())
      .then((result) => {
        //console.log(result.results)
        /* TODO decide if want to list researchers and then use researcheritem*/
      })
      .catch((err) => {
      });
    })

    return (
      <div>
      <Grid container justify="center" alignItems="center" align="center">
        <Card className={styles.card}>
          <Typography variant="h5" className={styles.title}>
            <b>Persónuverndarstefna DataWell</b>
          </Typography>
          <p></p>
          <p className={styles.text}>DataWell er rannsóknarverkefni og stendur styttingin fyrir ,,Data for Wellbeing purposes” sem á íslensku útleggst sem ,,Lýðheilsugagnalind.” Með þessari persónuverndarstefnu vill DataWell kynna grundvallarstefnu sína í persónuvernd og veita upplýsingar um vinnslu persónuupplýsinga í DataWell kerfinu.</p><br/>
          <p className={styles.text}>Markmið rannsóknarverkefnisins er að safna gögnum um almenna heilsu á einn og sama stað og er í kerfinu hægt að deila þeim gögnum með þriðja aðila, sem hver og einn notandi velur sjálfur. Er því ekki gefinn aðgangur til þriðja aðila nema að notandi kjósi það sjálfur. Með því að taka þátt og deila gögnum í gegnum DataWell er veitt samþyki fyrir því að nota megi gögnin í ofangreindri rannsókn, en þá undir nafnleynd. Einnig heimilar þáttakandi að gögnin séu geymd í gagnasafni við Háskólann í Reykjavík og að þau séu notuð á þann hátt sem viðkomandi sjálfur velur eftir að aðgangur að kerfinu hefur verið virkjaður. Aðilar verkefnisins eru eftirfarandi og verða hér eftir kallaðir Rannsakendur. </p>
          <p></p>
          <p className={styles.text}><b>Verkefnastjóri er:</b></p> 
            <p className={styles.text}>Anna Sigríður Islind, <i>lektor í tölvunarfræði, Háskólinn í Reykjavík</i> (868-8981).</p>
            <p></p>
            <p className={styles.text}><b>Verkefnið samanstendur af eftirfarandi aðilum:</b></p>
            <p className={styles.text}>Anna Sigríður Islind, <i>lektor í tölvunarfræði, Háskólinn í Reykjavík</i>.</p>
            <p className={styles.text}>Hafrún Kristjánsdótttir, <i>lektor í tölvunarfræði, Háskólinn í Reykjavík</i>.</p>
            <p className={styles.text}>Gísli Hjálmtýrsson, <i>prófessor í tölvunarfræði, Háskólinn í Reykjavík</i>.</p>
            <p className={styles.text}>Hulda Lilja Hannesdótttir, <i>mastersnemi í tölvunarfræði, Háskólinn í Reykjavík</i>.</p>
            <p className={styles.text}>Steinunn Gróa Sigurðardóttir, <i>doktorsnemi í tölvunarfræði, Háskólinn í Reykjavík</i>.</p>
            <p className={styles.text}>Katrín Ýr Friðgeirsdóttir, <i>mastersnemi í íþróttafræði, Háskólinn í Reykjavík</i>.</p>
            <p className={styles.text}>Lára Hafliðadóttir, <i>mastersnemi í íþróttafræði, Háskólinn í Reykjavík</i>.</p>
            <p className={styles.text}>Grímur Gunnarsson, <i>mastersnemi í sálfræði, Háskólinn í Reykjavík</i>.</p>
            <p className={styles.text}>Hjalti Rúnar Oddsson, <i>háskólakennari í íþróttafræði , Háskólinn í Reykjavík</i>.</p>
            <p className={styles.text}>Sveinn Þorgeirsson, <i>háskólakennari í íþróttafræði, Háskólinn í Reykjavík</i>.</p>
            <p></p>
            <p className={styles.text}>
              Rannsakendur eru ábyrgðaraðilar þeirra upplýsinga sem unnið er með í DataWell kerfinu. Með ábyrgðaraðila er átt við þann er ákvarðar tilgang og aðferðir við vinnslu persónuupplýsinga og ber meginábyrgð á að öll vinnsla sé í samræmi við meginreglur um persónuvernd og að vinnslan sé byggð á lögmætum heimildum. Rannsakendur eru bundnir trúnaði og þagnarskyldu varðandi þær upplýsingar sem þáttakendur veita. Við meðferð og vinnslu persónuupplýsinga er farið að <i>lögum nr. 90/2018</i>, sem sér í lagi miðast að því að stuðla að því að með persónuupplýsingar sé farið í samræmi við grundvallarsjónarmið og reglur um persónuvernd og friðhelgi einkalífs og að tryggja áreiðanleika og gæði slíkra upplýsinga og frjálst flæði þeirra á innri markaði Evrópska efnahagssvæðisins.</p>
              <p className={styles.smallText}><br/>
              <i>Ef óskað er eftir að láta eyða aðgangi sínum er bent á að hafa samband í gegnum tölvupóstfangið: <u>datawell@ru.is </u></i>
            </p>
          </Card>
        </Grid>
      </div>
    );
};

export default Terms;