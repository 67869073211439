import React, { Component } from "react";

import { connect } from "react-redux";
import MeasurementsFilezone from "../../../components/MeasurementsFile/Filezone/Filezone";
import MeasurementsFiles from "../../../components/MeasurementsFile/MeasurementsFiles/MeasurementsFiles";

class PsychologicalMeasurements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateFiles: true,
    };
    this.childFilesUpdated = this.childFilesUpdated.bind(this);
    this.onChildNewFileAdded = this.onChildNewFileAdded.bind(this);
  }

  componentDidMount() {}

  onChildNewFileAdded() {
    this.setState({
      updateFiles: true,
    });
  }

  childFilesUpdated() {
    this.setState({
      updateFiles: false,
    });
  }

  render() {
    return (
      <div>
        <MeasurementsFilezone onNewFileAdded={this.onChildNewFileAdded} category="PSYCHOLOGICAL" title="Sálfræðimælingar"/>
        <MeasurementsFiles
          updateFiles={this.state.updateFiles}
          filesUpdated={this.childFilesUpdated}
          category="PSYCHOLOGICAL"
        />
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {};
};

export default connect(mapStateToProps, null)(PsychologicalMeasurements);
