const rules = `- Smellið á ratsjárritið til þess að sjá niðurstöður.

- Grafið sýnir eftirfarandi niðurstöður:
    \t •Þínar mælingar fyrir þá dagsetningu sem er valin (nýjasta mælingin er sjálfkrafa valin í upphafi), mismunandi tegundir mælinga eru staðsettar á hverjum ási fyrir sig. 
    \t •Meðaltal hópsins fyrir allar mælingar af sömu tegund.
    \t •Meðaltal mælingar, fyrir tiltekna tegund af mælingu á þeirri dagsetningu sem valin er.

- Grafið sýnir hlutfallslega frammistöðu leikmannsins miðað við aðra í hópnum, fyrir tiltekna dagsetningu og tegund mælingar.
    \t •Hæstu gildin eru yst í grafinu en lægstu gildin í miðju þess.

- Gott er að benda á að tölurnar sýna einungis stærð gildanna en ekki árangur þjónustuþegans, þ.e. í sumum tilvikum er betra að hafa hærri tölu en í öðrum er betra að hafa lægri tölu.

- Hægt er að breyta um dagsetningu með því að smella á mismunandi dagsetningar í haus töflunnar.

- Einnig er hægt að velja hvaða mælingar eru sýnilegar með því að haka við tegundir í töflunni.

`;

export default rules;
