import React, { useState, useEffect } from "react";
import CustomizedSnackbars from "../../../components/UI/CustomizedSnackbars";
import { connect } from "react-redux";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  Avatar,
  CircularProgress,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import styles from "./Researchers.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {noValue} from "../../../utils";
import AddUserButton from "../../../components/User/AddUserButton/AddUserButton";


const Researchers = (props) => {
    const [researchers, setResearchers] = useState([]);
    const [error, setError] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [pageCount, setPageCount] = useState(0);
    const [offset, setOffset] = useState(0); // for offset and limit
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("INITIAL");
    const [snackbarVariant, setSnackbarVariant] = useState("info");

    useEffect(() => {
        fetch(
            process.env.REACT_APP_API +
                "api/researchers/?limit=20&offset=" +
                offset +
                "&ordering=researcher__last_name&ordering=researcher__first_name",
            {
                method: "GET",
                headers: {
                Authorization: `JWT ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
                },
            }
        )
        .then(handleErrors)
        .then((res) => res.json())
        .then(
            (result) => {
            setResearchers(result.results);
            setLoaded(true);
            setPageCount(Math.ceil(result.count / 20));
            },
            (error) => {
                setError(true);
                setLoaded(true);
            }
        );
    }, [offset, loaded]);
        
    useEffect(() => {
        if (error===true) {
            setSnackbarOpen(true);
            setSnackbarMessage(
                "Villa kom upp við að sækja rannsakanda."
            );
            setSnackbarVariant("error");
            setError(false);
        };
    }, [error]);
    
    const closeSnackbar = () => {
        setSnackbarOpen(false);
    };

    function handleErrors(response) {
        if (!response.ok) {
          setError(true);
        }
        return response;
    }
    
    const handlePageChange = (value) => {
        let pageOffset = (value - 1) * 20;
        setOffset(pageOffset);
    };

    const handleResearcherCreated = () => {
        setOffset(0)
        setLoaded(false)
    };
    
    let snackbar = (
    <CustomizedSnackbars
        variant={snackbarVariant}
        message={snackbarMessage}
        open={snackbarOpen}
        closeSnackbar={closeSnackbar}
    />
    );
        
    let researchersList = <CircularProgress />;
    if (loaded) {
        if (researchers.length > 0) {
            researchersList = researchers
            .sort((a, b) => a.last_name.localeCompare(b.last_name))
            .sort((a, b) => a.first_name.localeCompare(b.first_name))
            .map((researcher, index) => {
                const { first_name, last_name, email, about } = researcher;
                let pic = researcher.profile_picture
                if (noValue(pic)) {
                    pic = first_name; // Make the profile pic be the first letter in the researchers name
                }
                let has_logged_in = researcher.user.has_logged_in;
                let mailto = "mailto: " + email
    
                return (
                    <>
                    <TableRow
                        key={"researchers" + index}
                    >
                        <TableCell component="td" scope="row">
                        <div className={styles.row}>
                            <div className={styles.avatar}>
                            <Avatar
                                src={pic}
                                alt={`${first_name} ${last_name}`}
                                className={styles.avatar}
                            />
                            </div>
                            {`${first_name} ${last_name}`}
                        </div>
                        </TableCell>
                        <TableCell> <a className="smallLink" href={mailto}>{email}</a></TableCell>
                        <TableCell> {about}</TableCell>
                        <TableCell>
                        {has_logged_in === true ? (
                            <FontAwesomeIcon
                            icon="check"
                            className={styles.primaryColor}
                            />
                        ) : (
                            <FontAwesomeIcon
                            icon="times"
                            style={{
                                color: "red",
                            }}
                            />
                        )}
                        </TableCell>
                    </TableRow>
                    </>
                );
            });
        }
        else {
            return (
                <TableRow>
                    <TableCell component="th" scope="row">
                    Enginn rannsakandi fannst.
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell className={styles.show}></TableCell>
                </TableRow>
            );
        }
    }        

    return (
        <div>
            {snackbar}
            <Paper>
            <h2 className={styles.title}>Rannsakendur</h2>
                <Table className={styles.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Nafn</TableCell>
                      <TableCell className={styles.show}>Netfang</TableCell>
                      <TableCell className={styles.show}>Um</TableCell>
                      <TableCell className={styles.show}>Virkur</TableCell>
                    </TableRow>
                  </TableHead>
                  {researchersList}
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell className={styles.show}></TableCell>
                    <TableCell className={styles.show}></TableCell>
                    {props.userType==="researcher" && (
                        <TableCell>
                            <AddUserButton handleCreated={handleResearcherCreated} text="Bæta við rannsakanda" createUserType="researcher" id={0}/>
                        </TableCell>
                    )}
                  </TableRow>
                </Table>
              <div className={styles.pagination}>
                <div>
                  <Pagination
                    count={pageCount}
                    variant="outlined"
                    shape="rounded"
                    onChange={(e, v) => handlePageChange(v)}
                  />
                </div>
              </div>
            </Paper>
        </div>
    )
}

const mapStateToProps = (store) => {
    return {
      userType: store.auth.userType,
    };
};

export default connect(mapStateToProps)(Researchers);

