import React, { useState } from "react";
import { PropTypes } from "prop-types";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import styles from "./EmailChangeForm.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomizedSnackbars from "../../UI/CustomizedSnackbars";
import { connect } from "react-redux";

const UserEmailChangeForm = (props) => {
  const [password, setPassword] = useState("");
  const [emailOld, setEmailOld] = useState("");
  const [email, setEmail] = useState("");
  const [emailRepeat, setEmailRepeat] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("INITIAL");
  const [snackbarVariant, setSnackbarVariant] = useState("info");
  const [error, setError] = useState(false);
  // const [successMessage, setSuccessMessage] = useState(<></>);
  // const [errorMessage, setErrorMessage] = useState(<></>);

  const { handleClose } = props;

  const onChangeEmailOld = (e) => {
    setEmailOld(e.target.value);
  };

  const onChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const onChangeEmailRepeat = (e) => {
    setEmailRepeat(e.target.value);
  };

  const onChangePassword = (e) => {
    setPassword(e.target.value);
  };

  // CHANGE PASSWORD HERE
  const onSubmit = (e) => {
    e.preventDefault();
    const emailChange = {
      password: password,
      email_old: emailOld.toLowerCase(),
      email: email.toLowerCase(),
      email_repeat: emailRepeat.toLowerCase(),
    };

    var formData = new FormData();
    for (var name in emailChange) {
      formData.append(name, emailChange[name]);
    }

    // Change the password here
    fetch(process.env.REACT_APP_API + "api/users/" + props.userID + "/", {
      method: "PATCH",
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
      body: formData,
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.status);
        }
      })
      .then(
        (result) => {
          setError(false);
          setSnackbarOpen(true);
          setSnackbarMessage("Netfangi hefur verið breytt í " + email);
          setSnackbarVariant("success");
          setTimeout(() => {
            handleClose(true);
          }, 2000);
        },
        (error) => {
          throw new Error(error);
        }
      )
      .catch((error) => {
        setError(true);
        setSnackbarOpen(true);
        setSnackbarVariant("error");
        if (email !== emailRepeat) {
          setSnackbarMessage("Netföngin þurfa að stemma.");
        } else if (error.message === "Error: 400") {
          setSnackbarMessage(
            "Ekki tókst að breyta netfangi. Athugið netfang og lykilorð."
          );
        } else {
          setSnackbarMessage("Það kom up villa hjá netþjóni, reynið aftur.");
        }
      });
  };

  const onClickPassword = () => {
    setShowPassword(!showPassword);
  };

  const onCancel = () => {
    handleClose(false);
  };

  const closeSnackbar = () => {
    setSnackbarOpen(false);
  };

  let snackbar = (
    <CustomizedSnackbars
      variant={snackbarVariant}
      message={snackbarMessage}
      open={snackbarOpen}
      closeSnackbar={closeSnackbar}
    />
  );

  return (
    <Grid container justify="center" alignItems="center">
      {snackbar}
      <Card style={{ width: "350px", height: "400px", padding: "20px" }}>
        <FontAwesomeIcon
          icon="times"
          style={{ color: "grey", cursor: "pointer" }}
          className={styles.theicons}
          onClick={() => onCancel()}
        />
        <form onSubmit={onSubmit} className={styles.formLogin}>
          <div className={styles.divider} />
          <p>
            Eftir að netfangi hefur verið breytt er notandi skráður út og nota
            skal nýja netfangið til að skrá sig aftur inn.
          </p>
          <FormControl>
            <InputLabel error={error}>Núverandi netfang</InputLabel>
            <Input
              className={styles.emailField}
              type={"email"}
              name="emailOld"
              value={emailOld}
              onChange={(e) => onChangeEmailOld(e)}
              error={error}
            />
          </FormControl>
          <FormControl>
            <InputLabel error={error}>Nýtt netfang</InputLabel>
            <Input
              className={styles.emailField}
              type={"email"}
              name="email"
              value={email}
              onChange={(e) => onChangeEmail(e)}
              error={error}
            />
          </FormControl>
          <FormControl>
            <InputLabel error={error}>Nýja netfang aftur</InputLabel>
            <Input
              className={styles.emailField}
              type={"email"}
              name="emailRepeat"
              value={emailRepeat}
              onChange={(e) => onChangeEmailRepeat(e)}
              error={error}
            />
          </FormControl>
          <FormControl>
            <InputLabel style={{ marginLeft: 27 }} error={error}>
              Lykilorð
            </InputLabel>
            <Input
              className={styles.passwordField}
              type={showPassword ? "text" : "password"}
              name="password"
              value={password}
              onChange={(e) => onChangePassword(e)}
              error={error}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Toggle password visibility"
                    onClick={() => onClickPassword()}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <div className={styles.divider} />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={email.length === 0}
          >
            Breyta netfangi
          </Button>
        </form>
      </Card>
    </Grid>
  );
};

const mapStateToProps = (store) => {
  return {
    userID: store.auth.id,
  };
};

export default connect(mapStateToProps)(UserEmailChangeForm);
