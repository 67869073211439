import React, { Component } from 'react';
import styles from './Connect.module.css';
import {Grid, Paper} from '@material-ui/core';
import { connect } from "react-redux";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { push } from "connected-react-router";
import CustomizedSnackbars from '../../components/UI/CustomizedSnackbars';
import UserWithingsService from '../../components/User/WithingsService/WithingsService';

class Connect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      snackbarOpen: false,
      snackbarMessage: "",
      snackbarVariant: "success",
      error: false,
      withingsServiceLoaded: false
    };
  }

  handleWithingsVerified = () => {
    this.setState({
      snackbarOpen: true,
      snackbarMessage:
        "Aðgangur tengdur við Withings.",
      snackbarVariant: "success",
    });
    this.props.push({
      pathname: './connect',
      search: ''
    }) 
    setTimeout(() => {
      this.setWithingsServiceLoaded(false)
    }, 3000);
  };

  handleError = () => {
    this.setState({
      error: false,
      snackbarOpen: true,
      snackbarMessage:
        "Það kom upp villa við að tengja aðgang við Withings.",
      snackbarVariant: "error",
    });
  }

  componentDidMount() {
    const queryString = require("query-string");
    const parsed = queryString.parse(this.props.location.search);
    if (parsed.state === "verifiedToken") {
      this.setState({
        snackbarOpen: true,
        snackbarMessage:
          "Vinsamlegast bíðið á meðan tengingu er komið á við Withings.",
        snackbarVariant: "info",
      });
      const data = {
        authentication_token: parsed.code
      };
      fetch(process.env.REACT_APP_API + "api/withingstokens/", {
        method: "POST",
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      })
      .then(handleErrors)
      .then(res => res.json())
      .then(
        (result) => {
          if (result.access_token!==undefined) {
            this.handleWithingsVerified();
          }
          else {
            this.setState({
              error: true
            });
          }
        },
        (error) => {
          this.setState({
            error: true
          });
        }
      )
    }
  }

  componentDidUpdate() {
    if (this.state.error) {
      this.handleError();
    }
  }

  closeSnackbar = () => {
    this.setState({
      snackbarOpen: false
    })
  };

  setWithingsServiceLoaded = (done) => {
    this.setState({
      withingsServiceLoaded: done
    })
  };

  render() {
    return (
      <Paper>
         <div className={styles.serviceContainer}>
          <Grid container justify="center" spacing={3}>
            <Grid item xs={12}>
              <h2 style={{textAlign: "center"}}>
                Tengja þjónustur við DataWell
              </h2>
            </Grid>
            <p>Withings er tengt ár í senn. Eftir tengingu eru gögn sótt mest ár aftur í tímann. Athugið að eftir tengingu getur tekið tíma að sækja gögnin til Withings.</p>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell> Þjónusta </TableCell>
                  <TableCell> Staða </TableCell>
                  <TableCell> Aðgerð </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <UserWithingsService loaded={this.state.withingsServiceLoaded} setLoaded={this.setWithingsServiceLoaded}/>
              </TableBody>
            </Table>
          </Grid>
          <CustomizedSnackbars
            variant={this.state.snackbarVariant}
            message={this.state.snackbarMessage}
            open={this.state.snackbarOpen}
            closeSnackbar={this.closeSnackbar}
          />
        </div>
      </Paper>
     
    );
  }
}

function handleErrors(response) {
  if (!response.ok) {
    this.setState({       error: true     });
  }
  return response;
}

const mapStateToProps = state => ({
  
});

export default connect(mapStateToProps, {
  push,
})(Connect);

