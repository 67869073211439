import React, { useState, useEffect } from "react";
import CustomizedSnackbars from "../../UI/CustomizedSnackbars";
import { connect } from "react-redux";
import { Typography, Button, Modal, Backdrop, Fade } from "@material-ui/core";
import AddUserForm from "../AddUserForm/AddUserForm";
import styles from "./AddUserButton.module.css";
import AddIcon from '@material-ui/icons/Add';

const AddUserButton = (props) => {
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("INITIAL");
    const [snackbarVariant, setSnackbarVariant] = useState("info");
    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        
    }, []);

    const closeSnackbar = () => {
        setSnackbarOpen(false);
    };

    let snackbar = (
        <CustomizedSnackbars
            variant={snackbarVariant}
            message={snackbarMessage}
            open={snackbarOpen}
            closeSnackbar={closeSnackbar}
        />
        );

    const handleUserAdd = () => {
        setOpenModal(true);
    };
    
    const handleClose = () => {
        setOpenModal(false);
    };

    const handleCreated = (text) => {
      setSnackbarOpen(true);
      setSnackbarMessage(text);
      setSnackbarVariant("success");
      setOpenModal(false);
      if (props.handleCreated!==undefined) 
      { 
        props.handleCreated()
      }
    };

    return (
        <div className={styles.addUser}>
          {snackbar}
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleUserAdd()}
            startIcon={<AddIcon/>}
          >
            {props.text}
          </Button>
          <div className={styles.modal}>
            <Modal
              open={openModal}
              onClose={() => handleClose()}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={openModal}>
                <div className={styles.modalContent}>
                  <h2 id="simple-modal-title">{props.text}</h2>
                  <AddUserForm createUserType={props.createUserType} handleCreated={handleCreated} id={props.id}/>
                </div>
              </Fade>
            </Modal>
          </div>
        </div>
    )
}

const mapStateToProps = (store) => {
    return {
      userType: store.auth.userType,
    };
};

export default connect(mapStateToProps)(AddUserButton);

