import React from "react";

import styles from "./Collaboration.module.css";
import HR_logo from "../../../assets/hr_logo.png"
import KSÍ_logo from "../../../assets/ksi_logo.png"
import DataWell_logo from "../../../assets/logo_green.svg"

const Collaboration = () => {
  return (
    <div className={styles.container}>
      <div className={styles.logos}>
        <div className={styles.hrLogo} />
        <img
          src={
            DataWell_logo
          }
          alt="DataWell"
          className={styles.logo}
        />
        <img
          src={
            HR_logo
          }
          alt="HR"
          className={styles.logo}
        />
      </div>
      <p>
        <a href="/about" className={styles.link}>DataWell 2021</a>  |  <a href = "mailto: datawell@ru.is" className={styles.link}>datawell@ru.is</a>  |  <a href="/terms" className={styles.link}>Persónuverndarstefna verkefnisins</a><br/>
        Unnið á vegum Tölvunarfræðideildar Háskóla Reykjavíkur.
      </p>
    </div>
  );
};

export default Collaboration;
