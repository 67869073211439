import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as moment from "moment";
import CustomizedSnackbars from "../../../components/UI/CustomizedSnackbars";
import { noValue } from "../../../utils";

import {
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  CircularProgress,
} from "@material-ui/core";

import Teams from "../../../components/Team/Teams/Teams";
import styles from "./Overview.module.css";
import MentalOverviewTable from "../../../components/MentalOverviewTable/MentalOverviewTable";

const ResearcherOverview = (props) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("INITIAL");
  const [snackbarVariant, setSnackbarVariant] = useState("info");
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [measurementsEvents, setMeasurementsEvents] = useState([]);

  useEffect(() => {
    fetch(
      process.env.REACT_APP_API +
        "api/measurementsfiles/?file_parsing_confirmed=true&ordering=-date_of_measurement&limit=10",
      {
        method: "GET",
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then(handleErrors)
      .then((res) => res.json())
      .then(
        (result) => {
          if (!error) {
            var data = [];
            result.results.forEach(function (item, index) {
              if (item !== null) {
                let uploaded_by = "";
                if (!noValue(item.uploaded_by)) {
                  uploaded_by = item.uploaded_by.email;
                } else {
                  uploaded_by = "Notandi ekki til";
                }
                if (item.category === "PHYSICAL") {
                  item.category = "Íþróttamæling";
                } else if (item.category === "PSYCHOLOGICAL") {
                  item.category = "Sálfræðimæling";
                }
                let teamName = "";
                if (item.team) {
                  teamName = item.team.name;
                }
                data.push({
                  id: item.id,
                  date_of_measurement: item.date_of_measurement,
                  uploaded_by: uploaded_by,
                  category: item.category,
                  teamName: teamName,
                  timestamp: item.timestamp,
                });
              }
            });
            setLoaded(true);
            setMeasurementsEvents(data);
          }
        },
        (error) => {
          setError(true);
          setSnackbarOpen(true);
          setSnackbarMessage("Villa kom upp við að sækja mælingar.");
          setSnackbarVariant("error");
        }
      );
  }, [error]);

  const closeSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleErrors = (response) => {
    if (!response.ok) {
      setError(true);
    }
    return response;
  };

  let newestMeasurements = <CircularProgress />;
  if (loaded) {
    let tableHead = (
      <TableHead>
        <TableRow key={0}>
          <TableCell>Hvenær sett inn</TableCell>
          <TableCell>Hópur</TableCell>
          <TableCell>Sett inn af</TableCell>
          <TableCell>Tegund</TableCell>
          <TableCell>Dagsetning mælinga</TableCell>
        </TableRow>
      </TableHead>
    );
    if (measurementsEvents.length === 0) {
      newestMeasurements = (
        <Table>
          {tableHead}
          <TableBody>
            <TableRow key={1}>
              <TableCell>
                <Typography variant="body2" gutterBottom>
                  Það eru engar mælingar í kerfinu.
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      );
    } else {
      newestMeasurements = (
        <Table>
          {tableHead}
          <TableBody>
            {measurementsEvents.map((row) => {
              let date = new Date(row.date_of_measurement);
              const year = date.getFullYear();
              const month = date.getMonth() + 1;
              const day = date.getDate();
              return (
                <TableRow key={"row" + row.id}>
                  <TableCell component="td" scope="row">
                    {moment(row.timestamp).format("DD.MM.YYYY, HH:mm:ss")}
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" gutterBottom>
                      {row.teamName}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" gutterBottom>
                      {row.uploaded_by}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" gutterBottom>
                      {row.category}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" gutterBottom>
                      {`${day}.${month}.${year}`}
                    </Typography>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      );
    }
  }

  return (
    <div>
      <Paper
        className="fullWindowPaper"
        style={{ padding: "10px", marginBottom: "10px" }}
      >
        <h2 className={styles.title}>Nýjustu mælingar</h2>
        {newestMeasurements}
      </Paper>
      <Paper className="fullWindowPaper">
        <h2 className={styles.title}>Hóparnir í kerfinu</h2>
        <Teams />
      </Paper>
      <Paper className="fullWindowPaper">
        <MentalOverviewTable topOnly={true} limit={5} />
      </Paper>
      {/* <Paper>
            Notendur í kerfinu?
        </Paper> */}
      <CustomizedSnackbars
        variant={snackbarVariant}
        message={snackbarMessage}
        open={snackbarOpen}
        closeSnackbar={closeSnackbar}
      />
    </div>
  );
};

const mapStateToProps = ({ measurements, auth }) => ({
  userType: auth.userType,
});

export default connect(mapStateToProps)(ResearcherOverview);
