import React, { Component } from "react";
import moment from "moment";
import DayPickerInput from "react-day-picker/DayPickerInput";
import 'react-day-picker/lib/style.css';
import MomentLocaleUtils, {
    formatDate,
    parseDate,
  } from "react-day-picker/moment";

const IcelandicDayPicker = (props) => {
  const today = new Date();
  return (
    <DayPickerInput
        onDayChange={(day) => {
          if (!isNaN(Date.parse(day)) == true) {
            props.setDay(day.toISOString().substring(0,10));
          }
        }}
        formatDate={formatDate} parseDate={parseDate} format="LL"
        placeholder={`${formatDate( moment(), "LL", "is" )}`}
        
        dayPickerProps={{
        locale: "is", localeUtils: MomentLocaleUtils,
        disabledDays: { after: today }
        }}
    />
  );
};

export default IcelandicDayPicker;