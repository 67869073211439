import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import CustomizedSnackbars from "../../../components/UI/CustomizedSnackbars";
import {
  Paper,
  Button,
  Select,
  MenuItem,
  FormControl,
} from "@material-ui/core";
import styles from "./Overview.module.css";
import TeamTopMeasurements from "../../../components/Team/TopMeasurements/TopMeasurements";
import TeamStatisticsGraph from "../../../components/Team/StatisticsGraph/StatisticsGraph";
import TypeSelect from "../../../components/UI/TypeSelect";
import SettingsIcon from "@material-ui/icons/Settings";
import StraightenIcon from "@material-ui/icons/Straighten";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import WatchIcon from "@material-ui/icons/Watch";
import Players from "../../../components/Team/Players/Players";

const TeamOverview = (props) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("INITIAL");
  const [snackbarVariant, setSnackbarVariant] = useState("info");
  const [error, setError] = useState(false);
  const [team, setTeam] = useState([]);
  const [teamPlayers, setTeamPlayers] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [id, setId] = useState(props.match.params.id); // team's id, depending on wether the user came from /teams page or Team Settings
  const [offset, setOffset] = useState(0); // for offset and limit
  const [pageCount, setPageCount] = useState(1); // total page count for athlete list
  const [type, setType] = useState(" ");
  const [valueName, setValueName] = useState("event_average");

  const closeSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleChange = (event) => {
    setValueName(event.target.value);
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.center}>
          <div className={styles.teamsList}>
            <div className={styles.settingsLink}>
              {/* <div>
                <Button
                  component={Link}
                  to={{
                    pathname: `/group/${id}/comparison`,
                  }}
                  variant="contained"
                  color="primary"
                  className={styles.button}
                  startIcon={<EqualizerIcon />}
                >
                  Bera saman leikmenn
                </Button>
              </div> */}
              <div>
                <Button
                  component={Link}
                  to={{
                    pathname: `/group/${id}/export`,
                  }}
                  variant="contained"
                  color="primary"
                  className={styles.button}
                  startIcon={<StraightenIcon />}
                >
                  Sækja mælingar
                </Button>
              </div>
              <div>
                <Button
                  component={Link}
                  to={{
                    pathname: `/group/${id}/exportactivity`,
                  }}
                  variant="contained"
                  color="primary"
                  className={styles.button}
                  startIcon={<WatchIcon />}
                >
                  Sækja snjallgögn
                </Button>
              </div>
              <div>
                <Link
                  to={{
                    pathname: `/group/${id}/settings`,
                  }}
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    className={styles.button}
                    startIcon={<SettingsIcon />}
                  >
                    Stillingar hóps
                  </Button>
                </Link>
              </div>
            </div>
            <Players id={id} />
          </div>
          {/* <div className={styles.imageContainer}>
            <Paper className={styles.chart}>
              <TypeSelect category="PHYSICAL" setType={setType} />
              <h3>Topp 10 í hverri mælingu</h3>
              <TeamTopMeasurements teamId={id} type={type} limit={10} />
            </Paper>
            <Paper className={styles.statistics}>
              <FormControl variant="outlined">
                <Select
                  id="statisticsSelect"
                  value={valueName}
                  onChange={handleChange}
                >
                  <MenuItem value={"event_average"}>Meðaltöl mælinga</MenuItem>
                  <MenuItem value={"event_female_average"}>
                    Meðaltöl mælinga - kvk
                  </MenuItem>
                  <MenuItem value={"event_male_average"}>
                    Meðaltöl mælinga - kk
                  </MenuItem>
                  <MenuItem value={"event_std_dev"}>
                    Staðalfrávik mælinga
                  </MenuItem>
                  <MenuItem value={"event_max"}>Hæstu gildi mælinga</MenuItem>
                  <MenuItem value={"event_female_max"}>
                    Hæstu gildi mælinga - kvk
                  </MenuItem>
                  <MenuItem value={"event_male_max"}>
                    Hæstu gildi mælinga - kk
                  </MenuItem>
                  <MenuItem value={"event_min"}>Lægstu gildi mælinga</MenuItem>
                  <MenuItem value={"event_female_min"}>
                    Lægstu gildi mælinga - kvk
                  </MenuItem>
                  <MenuItem value={"event_male_min"}>
                    Lægstu gildi mælinga - kk
                  </MenuItem>
                </Select>
              </FormControl>
              <TeamStatisticsGraph
                teamId={id}
                type={type}
                valueName={valueName}
              />
            </Paper>
          </div>
        </div>
        <CustomizedSnackbars
          variant={snackbarVariant}
          message={snackbarMessage}
          open={snackbarOpen}
          closeSnackbar={() => closeSnackbar()}
        /> */}
        </div>
      </div>
    </>
  );
};

export default TeamOverview;
