import React from "react";
import { TableCell, TableRow, Typography } from "@material-ui/core";

const CellPair = ({ left, right }) => {
  return (
    <TableRow>
      <TableCell>
        <Typography variant="body2">{left}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{right}</Typography>
      </TableCell>
    </TableRow>
  );
};

export default CellPair;
