import React, { Component } from "react";
import styles from "./DataTab.module.css";
import {
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    Typography
  } from "@material-ui/core";
import { ExpandMore as ExpandMoreIcon, CenterFocusWeak } from "@material-ui/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const DataTab = (props) => {
  return (
    <ExpansionPanel defaultExpanded>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
          <Typography variant="h5" style={{color: props.color}}>
            <FontAwesomeIcon icon={props.icon} style={{marginRight: "10px"}}/>
            {props.text}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div className="flexContainerCenter">
            {props.children}
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
  );
};

export default DataTab;
