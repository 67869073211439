import React from "react";
import { Link } from "react-router-dom";

import {
  Grid,
  Card,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Avatar,
  Button,
  CircularProgress,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import Favorite from "@material-ui/icons/Favorite";
import FavoriteBorder from "@material-ui/icons/FavoriteBorder";
import styles from "./MentalOverviewTable.module.css";
import { useEffect } from "react";
import { useState } from "react";
import CustomizedSnackbars from "../UI/CustomizedSnackbars";

const MentalOverviewTable = (props) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("info");
  const [error, setError] = useState(false);

  const [measurementsLoaded, setMeasurementsLoaded] = useState(false);
  const [measurements, setMeasurements] = useState([]);
  const [persons, setPersons] = useState([]);
  const [personsLoaded, setPersonsLoaded] = useState(false);
  const [sleep, setSleep] = useState([]);
  const [sleepLoaded, setSleepLoaded] = useState(false);
  const [steps, setSteps] = useState([]);
  const [stepsLoaded, setStepsLoaded] = useState(false);
  const [mindPoints, setMindPoints] = useState([]);
  const [mindPointsLoaded, setMindPointsLoaded] = useState(false);
  const [update, setUpdate] = useState(false);
  // 100 for dev, 500 for production
  const pageLimit = process.env.REACT_APP_PAGELIMIT; // The number of measurements to fetch from each page (prevents the server from crashing when there is too much data)

  // Get dates for every day of the passing week and format as in the backend
  const getFormattedDay = (subtractDays) => {
    let day = new Date(new Date().setDate(new Date().getDate() - subtractDays));
    day =
      day.getFullYear() +
      "-" +
      ("0" + (day.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + day.getDate()).slice(-2);
    return day;
  };

  var yesterday = getFormattedDay(1);
  var day2 = getFormattedDay(2);
  var day3 = getFormattedDay(3);
  var day4 = getFormattedDay(4);
  var day5 = getFormattedDay(5);
  var day6 = getFormattedDay(6);
  var day7 = getFormattedDay(7);

  const startDay = day7;
  const endDay = yesterday;

  const okBlue = "#a4cae0";
  const dangerRed = "#e0a4a4";
  const goodGreen = "#a4e0ae";
  const emptyGrey = "#dcddde";

  useEffect(() => {
    updateMeasurements();
  }, []); // Only render when updateTeams changes

  //IF(SLEEP<8): +(8 - SLEEP)
  //IF(SLEEP>9): +(SLEEP - 9)
  const calculateSleepScore = (value) => {
    if (value < 8) {
      return 8 - value;
    }
    if (value > 9) {
      return value - 9;
    } else {
      return 0;
    }
  };
  // IF(STEPS<8): +(8 - STEPS)
  const calculateStepScore = (value) => {
    if (value < 8) {
      return 8 - value;
    } else {
      return 0;
    }
  };
  // IF(MINDPOINT<20) + (20 - MINDPOINT)
  const calculateMindScore = (value) => {
    if (value < 25) {
      return 25 - value;
    } else {
      return 0;
    }
  };

  // Calculate a score to display the highest risk patients first
  const calculateCritical = (allMeasurements) => {
    for (let i = 0; i < allMeasurements.length; i++) {
      let sleepScore = 0;
      let stepScore = 0;
      let mindScore = 0;
      let markedCritical = 0;
      if (allMeasurements[i].critical === true) {
        markedCritical = 1000;
      }
      for (const [key, value] of Object.entries(allMeasurements[i].sleep)) {
        sleepScore = sleepScore + calculateSleepScore(Number(value));
      }
      for (const [key, value] of Object.entries(allMeasurements[i].steps)) {
        stepScore = stepScore + calculateStepScore(Number(value));
      }
      for (const [key, value] of Object.entries(
        allMeasurements[i].mindPoints
      )) {
        mindScore = mindScore + calculateMindScore(Number(value));
      }
      let addMeasurement = {
        score: sleepScore + stepScore + mindScore + markedCritical,
      };
      Object.assign(allMeasurements[i], addMeasurement);
    }
    setMeasurements(
      allMeasurements.sort((a, b) => parseFloat(b.score) - parseFloat(a.score))
    );
  };

  // Merge all the different measurements into an array of objects, each object containing 1 person's measurements
  useEffect(() => {
    if (sleepLoaded && stepsLoaded && mindPointsLoaded && personsLoaded) {
      let tempMeasurements = [];

      // Add all available persons
      if (persons.length > 0) {
        for (let i = 0; i < persons.length; i++) {
          tempMeasurements.push(persons[i]);
        }
      }

      // Add sleep data
      if (sleep.length > 0) {
        for (let i = 0; i < sleep.length; i++) {
          for (let j = 0; j < tempMeasurements.length; j++) {
            if (tempMeasurements[j].id === sleep[i].id) {
              let addMeasurement = {
                sleep: sleep[i].sleep,
              };
              Object.assign(tempMeasurements[j], addMeasurement);
            }
          }
        }
      }

      // Add steps data
      if (steps.length > 0) {
        for (let i = 0; i < steps.length; i++) {
          for (let j = 0; j < tempMeasurements.length; j++) {
            if (tempMeasurements[j].id === steps[i].id) {
              let addMeasurement = {
                steps: steps[i].steps,
              };
              Object.assign(tempMeasurements[j], addMeasurement);
            }
          }
        }
      }

      if (mindPoints.length > 0) {
        for (let i = 0; i < mindPoints.length; i++) {
          for (let j = 0; j < tempMeasurements.length; j++) {
            if (tempMeasurements[j].id === mindPoints[i].id) {
              let addMeasurement = {
                mindPoints: mindPoints[i].mindPoints,
              };
              Object.assign(tempMeasurements[j], addMeasurement);
            }
          }
        }
      }

      calculateCritical(tempMeasurements);

      setMeasurementsLoaded(true);
    }
  }, [sleepLoaded, stepsLoaded, mindPointsLoaded, personsLoaded, update]);

  const updateMeasurements = async () => {
    // Fetch people in loop
    fetch(process.env.REACT_APP_API + "api/athletes/", {
      method: "GET",
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    })
      .then(handleErrors)
      .then((res) => res.json())
      .then(
        (result) => {
          let limit = result.count;
          let offset = 0;
          let resultsArr = [];
          if (result.count > 0) {
            fetchPersons(resultsArr, result.count, limit, offset);
          } else {
            setPersonsLoaded(true);
          }
        },
        (error) => {
          setError(true);
          setSnackbarOpen(true);
          setSnackbarMessage("Villa kom upp við að sækja þjónustuþega.");
          setSnackbarVariant("error");
        }
      );

    // Fetch sleep in loop
    fetch(
      process.env.REACT_APP_API +
        "api/measurements/?type__name=WithingsSleepSummary" +
        "&date_of_measurement__gte=" +
        startDay +
        "&date_of_measurement__lte=" +
        endDay,
      {
        method: "GET",
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then(handleErrors)
      .then((res) => res.json())
      .then(
        (result) => {
          let limit = result.count;
          let offset = 0;
          let resultsArr = [];
          if (result.count > 0) {
            fetchSleep(resultsArr, result.count, limit, offset);
          } else {
            setSleepLoaded(true);
          }
        },
        (error) => {
          setError(true);
          setSnackbarOpen(true);
          setSnackbarMessage("Villa kom upp við að sækja svefn.");
          setSnackbarVariant("error");
        }
      );

    // Fetch steps in loop
    fetch(
      process.env.REACT_APP_API +
        "api/measurements/?type__name=WithingsActivitySummary" +
        "&date_of_measurement__gte=" +
        startDay +
        "&date_of_measurement__lte=" +
        endDay,
      {
        method: "GET",
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then(handleErrors)
      .then((res) => res.json())
      .then(
        (result) => {
          let limit = result.count;
          let offset = 0;
          let resultsArr = [];
          if (result.count > 0) {
            fetchSteps(resultsArr, result.count, limit, offset);
          } else {
            setStepsLoaded(true);
          }
        },
        (error) => {
          setError(true);
          setSnackbarOpen(true);
          setSnackbarMessage("Villa kom upp við að sækja skref.");
          setSnackbarVariant("error");
        }
      );

    // Fetch mindpoints in loop
    fetch(
      process.env.REACT_APP_API +
        "api/measurements/?origin__name=DatawellApp&type__name=MindPoint" +
        "&date_of_measurement__gte=" +
        startDay +
        "&date_of_measurement__lte=" +
        endDay,
      {
        method: "GET",
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then(handleErrors)
      .then((res) => res.json())
      .then(
        (result) => {
          let limit = result.count;
          let offset = 0;
          let resultsArr = [];
          if (result.count > 0) {
            fetchMindPoints(resultsArr, result.count, limit, offset);
          } else {
            setMindPointsLoaded(true);
          }
        },
        (error) => {
          setError(true);
          setSnackbarOpen(true);
          setSnackbarMessage("Villa kom upp við að sækja MindPoint.");
          setSnackbarVariant("error");
        }
      );
  };

  // Fetch all people (athletes) the user has access to
  const fetchPersons = async (resultsArr, fullLimit, limit, offset) => {
    fetch(
      process.env.REACT_APP_API +
        "api/athletes/" +
        "?limit=" +
        pageLimit +
        "&offset=" +
        offset,
      {
        method: "GET",
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.results.length > 0) {
            resultsArr.push(result.results);
            let tempArr = resultsArr;

            limit = Number(limit) - Number(pageLimit);
            offset = Number(offset) + Number(pageLimit);

            if (limit > 0) {
              setTimeout(() => {
                fetchPersons(tempArr, fullLimit, limit, offset);
              }, 1);
            } else {
              sortPersons(tempArr);
            }
          }
        },
        (error) => {
          setError(true);
          setSnackbarOpen(true);
          setSnackbarMessage("Villa kom upp við að sækja þjónustuþega.");
          setSnackbarVariant("error");
        }
      );
  };

  const sortPersons = (personArr) => {
    let tempMeasurements = [];
    for (let i = 0; i < personArr.length; i++) {
      for (let j = 0; j < personArr[i].length; j++) {
        if (!tempMeasurements.some((e) => e.id === personArr[i][j].id)) {
          tempMeasurements.push({
            id: personArr[i][j].id,
            name: personArr[i][j].first_name,
            critical: personArr[i][j].critical,
            sleep: {},
            steps: {},
            mindPoints: {},
          });
        }
      }
    }
    setPersons(tempMeasurements);
    setPersonsLoaded(true);
  };

  // Fetch Sleep
  const fetchSleep = async (resultsArr, fullLimit, limit, offset) => {
    fetch(
      process.env.REACT_APP_API +
        "api/measurements/?type__name=WithingsSleepSummary&ordering=-date_of_measurement" +
        "&date_of_measurement__gte=" +
        startDay +
        "&date_of_measurement__lte=" +
        endDay +
        "&limit=" +
        pageLimit +
        "&offset=" +
        offset,
      {
        method: "GET",
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.results.length > 0) {
            resultsArr.push(result.results);
            let tempArr = resultsArr;

            limit = Number(limit) - Number(pageLimit);
            offset = Number(offset) + Number(pageLimit);

            if (limit > 0) {
              setTimeout(() => {
                fetchSleep(tempArr, fullLimit, limit, offset);
              }, 1);
            } else {
              sortSleep(tempArr);
            }
          }
        },
        (error) => {
          setError(true);
          setSnackbarOpen(true);
          setSnackbarMessage("Villa kom upp við að sækja valdar mælingar.");
          setSnackbarVariant("error");
        }
      );
  };

  const sortSleep = (sleepArr) => {
    let tempMeasurements = [];
    for (let i = 0; i < sleepArr.length; i++) {
      for (const [key, value] of Object.entries(sleepArr[i])) {
        if (
          typeof value.data === "object" &&
          value.data.enddate &&
          value.data.startdate
        ) {
          if (!tempMeasurements.some((e) => e.id === value.athlete.id)) {
            tempMeasurements.push({
              id: value.athlete.id,
              name: value.athlete.first_name,
              sleep: {
                [value.date_of_measurement]:
                  (Number(value.data.enddate) - Number(value.data.startdate)) /
                  3600,
              },
            });
          } else {
            for (const [subkey, subvalue] of Object.entries(tempMeasurements)) {
              if (value.athlete.id === subvalue.id) {
                let addMeasurement = {
                  [value.date_of_measurement]:
                    (Number(value.data.enddate) -
                      Number(value.data.startdate)) /
                    3600,
                };

                Object.assign(subvalue.sleep, addMeasurement);
              }
            }
          }
        }
      }
    }
    setSleep(tempMeasurements);
    setSleepLoaded(true);
  };

  // Fetch Steps
  const fetchSteps = async (resultsArr, fullLimit, limit, offset) => {
    fetch(
      process.env.REACT_APP_API +
        "api/measurements/?type__name=WithingsActivitySummary&ordering=-date_of_measurement" +
        "&date_of_measurement__gte=" +
        startDay +
        "&date_of_measurement__lte=" +
        endDay +
        "&limit=" +
        pageLimit +
        "&offset=" +
        offset,
      {
        method: "GET",
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.results.length > 0) {
            resultsArr.push(result.results);
            let tempArr = resultsArr;

            limit = Number(limit) - Number(pageLimit);
            offset = Number(offset) + Number(pageLimit);

            if (limit > 0) {
              setTimeout(() => {
                fetchSteps(tempArr, fullLimit, limit, offset);
              }, 1);
            } else {
              sortSteps(tempArr);
            }
          }
        },
        (error) => {
          setError(true);
          setSnackbarOpen(true);
          setSnackbarMessage("Villa kom upp við að sækja valdar mælingar.");
          setSnackbarVariant("error");
        }
      );
  };

  const sortSteps = (stepsArr) => {
    let tempMeasurements = [];
    for (let i = 0; i < stepsArr.length; i++) {
      for (const [key, value] of Object.entries(stepsArr[i])) {
        if (typeof value.data === "object" && value.data.steps) {
          if (!tempMeasurements.some((e) => e.id === value.athlete.id)) {
            tempMeasurements.push({
              id: value.athlete.id,
              name: value.athlete.first_name,
              steps: {
                [value.date_of_measurement]: value.data.steps / 1000,
              },
            });
          } else {
            for (const [subkey, subvalue] of Object.entries(tempMeasurements)) {
              if (value.athlete.id === subvalue.id) {
                let addMeasurement = {
                  [value.date_of_measurement]: value.data.steps / 1000,
                };

                Object.assign(subvalue.steps, addMeasurement);
              }
            }
          }
        }
      }
    }
    setSteps(tempMeasurements);
    setStepsLoaded(true);
  };

  // Fetch MindPoints
  const fetchMindPoints = async (resultsArr, fullLimit, limit, offset) => {
    fetch(
      process.env.REACT_APP_API +
        "api/measurements/?origin__name=DatawellApp&type__name=MindPoint&ordering=-date_of_measurement" +
        "&date_of_measurement__gte=" +
        startDay +
        "&date_of_measurement__lte=" +
        endDay +
        "&limit=" +
        pageLimit +
        "&offset=" +
        offset,
      {
        method: "GET",
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.results.length > 0) {
            resultsArr.push(result.results);
            let tempArr = resultsArr;

            limit = Number(limit) - Number(pageLimit);
            offset = Number(offset) + Number(pageLimit);

            if (limit > 0) {
              setTimeout(() => {
                fetchSteps(tempArr, fullLimit, limit, offset);
              }, 1);
            } else {
              sortMindPoints(tempArr);
            }
          }
        },
        (error) => {
          setError(true);
          setSnackbarOpen(true);
          setSnackbarMessage("Villa kom upp við að sækja valdar mælingar.");
          setSnackbarVariant("error");
        }
      );
  };

  const sortMindPoints = (mindPointsArr) => {
    let tempMeasurements = [];
    for (let i = 0; i < mindPointsArr.length; i++) {
      for (const [key, value] of Object.entries(mindPointsArr[i])) {
        if (value.data_value) {
          if (!tempMeasurements.some((e) => e.id === value.athlete.id)) {
            tempMeasurements.push({
              id: value.athlete.id,
              name: value.athlete.first_name,
              mindPoints: {
                [value.date_of_measurement]: value.data_value,
              },
            });
          } else {
            for (const [subkey, subvalue] of Object.entries(tempMeasurements)) {
              if (value.athlete.id === subvalue.id) {
                let addMeasurement = {
                  [value.date_of_measurement]: value.data_value,
                };

                Object.assign(subvalue.mindPoints, addMeasurement);
              }
            }
          }
        }
      }
    }
    setMindPoints(tempMeasurements);
    setMindPointsLoaded(true);
  };

  // Update the critical status of the person in the backend
  const onClickFavorite = (person) => {
    const data = {
      critical: !person.critical,
    };
    fetch(process.env.REACT_APP_API + "api/athletes/" + person.id + "/", {
      method: "PATCH",
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          person["critical"] = result.critical;
          setUpdate(!update);
        },
        (error) => {
          setError(true);
          setSnackbarOpen(true);
          setSnackbarMessage(
            "Ekki tókst að breyta upplýsingum fyrir þjónustuþega: " +
              person.name +
              "."
          );
          setSnackbarVariant("error");
        }
      );
  };

  const closeSnackbar = () => {
    setSnackbarOpen(false);
  };

  function handleErrors(response) {
    if (!response.ok) {
      setError(true);
    }
    return response;
  }

  let overviewTable = (
    <TableRow>
      <TableCell component="th" scope="row">
        Enginn einstaklingur fannst í þessum hóp.
      </TableCell>
    </TableRow>
  );

  // Define the color of each cell
  const sleepColor = (value) => {
    if (value >= 7.5 && value <= 8.5) {
      return goodGreen;
    } else if ((value >= 7 && value < 7.5) || (value > 8.5 && value <= 9)) {
      return okBlue;
    } else if (value < 7 || value > 9) {
      return dangerRed;
    } else {
      return emptyGrey;
    }
  };

  const sleepPerPerson = (id, data) => {
    if (measurementsLoaded) {
      return (
        <>
          <TableRow key={"row" + id}>
            <TableCell
              component="td"
              scope="row"
              className={styles.subTableCell}
              style={{ backgroundColor: sleepColor(Number(data[yesterday])) }}
              key={yesterday}
            >
              {data[yesterday] ? data[yesterday].toFixed(1) : "-"}
            </TableCell>
            <TableCell
              className={styles.show2 + " " + styles.subTableCell}
              key={day2}
              style={{ backgroundColor: sleepColor(Number(data[day2])) }}
            >
              {data[day2] ? data[day2].toFixed(1) : "-"}
            </TableCell>
            <TableCell
              className={styles.show3 + " " + styles.subTableCell}
              key={day3}
              style={{ backgroundColor: sleepColor(Number(data[day3])) }}
            >
              {data[day3] ? data[day3].toFixed(1) : "-"}
            </TableCell>
            <TableCell
              className={styles.show4 + " " + styles.subTableCell}
              key={day4}
              style={{ backgroundColor: sleepColor(Number(data[day4])) }}
            >
              {data[day4] ? data[day4].toFixed(1) : "-"}
            </TableCell>
            <TableCell
              className={styles.show5 + " " + styles.subTableCell}
              key={day5}
              style={{ backgroundColor: sleepColor(Number(data[day5])) }}
            >
              {data[day5] ? data[day5].toFixed(1) : "-"}
            </TableCell>
            <TableCell
              className={styles.show6 + " " + styles.subTableCell}
              key={day6}
              style={{ backgroundColor: sleepColor(Number(data[day6])) }}
            >
              {data[day6] ? data[day6].toFixed(1) : "-"}
            </TableCell>
            <TableCell
              className={styles.show7 + " " + styles.subTableCell}
              key={day7}
              style={{ backgroundColor: sleepColor(Number(data[day7])) }}
            >
              {data[day7] ? data[day7].toFixed(1) : "-"}
            </TableCell>
          </TableRow>
        </>
      );
    }
  };

  // Define the color of each cell
  const stepColor = (value) => {
    if (value >= 8) {
      return goodGreen;
    } else if (value >= 4 && value < 8) {
      return okBlue;
    } else if (value < 4) {
      return dangerRed;
    } else {
      return emptyGrey;
    }
  };

  const stepsPerPerson = (id, data) => {
    if (measurementsLoaded) {
      return (
        <>
          <TableRow key={"row" + id}>
            <TableCell
              component="td"
              scope="row"
              className={styles.subTableCell}
              style={{ backgroundColor: stepColor(Number(data[yesterday])) }}
              key={yesterday}
            >
              {data[yesterday] ? data[yesterday].toFixed(1) : "-"}
            </TableCell>
            <TableCell
              className={styles.show2 + " " + styles.subTableCell}
              key={day2}
              style={{ backgroundColor: stepColor(Number(data[day2])) }}
            >
              {data[day2] ? data[day2].toFixed(1) : "-"}
            </TableCell>
            <TableCell
              className={styles.show3 + " " + styles.subTableCell}
              key={day3}
              style={{ backgroundColor: stepColor(Number(data[day3])) }}
            >
              {data[day3] ? data[day3].toFixed(1) : "-"}
            </TableCell>
            <TableCell
              className={styles.show4 + " " + styles.subTableCell}
              key={day4}
              style={{ backgroundColor: stepColor(Number(data[day4])) }}
            >
              {data[day4] ? data[day4].toFixed(1) : "-"}
            </TableCell>
            <TableCell
              className={styles.show5 + " " + styles.subTableCell}
              key={day5}
              style={{ backgroundColor: stepColor(Number(data[day5])) }}
            >
              {data[day5] ? data[day5].toFixed(1) : "-"}
            </TableCell>
            <TableCell
              className={styles.show6 + " " + styles.subTableCell}
              key={day6}
              style={{ backgroundColor: stepColor(Number(data[day6])) }}
            >
              {data[day6] ? data[day6].toFixed(1) : "-"}
            </TableCell>
            <TableCell
              className={styles.show7 + " " + styles.subTableCell}
              key={day7}
              style={{ backgroundColor: stepColor(Number(data[day7])) }}
            >
              {data[day7] ? data[day7].toFixed(1) : "-"}
            </TableCell>
          </TableRow>
        </>
      );
    }
  };

  // Define the color of each cell
  const mindPointsColor = (value) => {
    if (value >= 25) {
      return goodGreen;
    } else if (value >= 15 && value < 25) {
      return okBlue;
    } else if (value < 15) {
      return dangerRed;
    } else {
      return emptyGrey;
    }
  };

  const mindPointsPerPerson = (id, data) => {
    if (measurementsLoaded) {
      return (
        <>
          <TableRow key={"row" + id}>
            <TableCell
              component="td"
              scope="row"
              className={styles.subTableCell}
              style={{
                backgroundColor: mindPointsColor(Number(data[yesterday])),
              }}
              key={yesterday}
            >
              {data[yesterday] ? data[yesterday] : "-"}
            </TableCell>
            <TableCell
              className={styles.show2 + " " + styles.subTableCell}
              key={day2}
              style={{ backgroundColor: mindPointsColor(Number(data[day2])) }}
            >
              {data[day2] ? data[day2] : "-"}
            </TableCell>
            <TableCell
              className={styles.show3 + " " + styles.subTableCell}
              key={day3}
              style={{ backgroundColor: mindPointsColor(Number(data[day3])) }}
            >
              {data[day3] ? data[day3] : "-"}
            </TableCell>
            <TableCell
              className={styles.show4 + " " + styles.subTableCell}
              key={day4}
              style={{ backgroundColor: mindPointsColor(Number(data[day4])) }}
            >
              {data[day4] ? data[day4] : "-"}
            </TableCell>
            <TableCell
              className={styles.show5 + " " + styles.subTableCell}
              key={day5}
              style={{ backgroundColor: mindPointsColor(Number(data[day5])) }}
            >
              {data[day5] ? data[day5] : "-"}
            </TableCell>
            <TableCell
              className={styles.show6 + " " + styles.subTableCell}
              key={day6}
              style={{ backgroundColor: mindPointsColor(Number(data[day6])) }}
            >
              {data[day6] ? data[day6] : "-"}
            </TableCell>
            <TableCell
              className={styles.show7 + " " + styles.subTableCell}
              key={day7}
              style={{ backgroundColor: mindPointsColor(Number(data[day7])) }}
            >
              {data[day7] ? data[day7] : "-"}
            </TableCell>
          </TableRow>
        </>
      );
    }
  };

  if (measurementsLoaded) {
    if (measurements.length > 0) {
      overviewTable = (
        <>
          {measurements.slice(0, props.limit).map((row) => {
            return (
              <TableRow id={"player_" + row.id} key={"player" + row.id}>
                <TableCell
                  component="td"
                  scope="row"
                  className={styles.personCell + " " + styles.largeScreen}
                >
                  <Link
                    className={styles.personLink}
                    to={{
                      pathname: `/user/${row.id}/`,
                    }}
                  >
                    {row.name}
                  </Link>
                </TableCell>
                {/* <TableCell
                  component="td"
                  scope="row"
                  className={styles.personCell + " " + styles.mediumScreen}
                >
                  <Link
                    className={styles.personLink}
                    to={{
                      pathname: `/user/${row.id}/`,
                    }}
                  >
                    {row.name.split(" ")[0] +
                      " " +
                      row.name.split(" ").substring(0, 1) + "."}
                  </Link>
                </TableCell> */}
                <TableCell
                  component="td"
                  scope="row"
                  className={styles.personCell + " " + styles.smallScreen}
                >
                  <Link
                    className={styles.personLink}
                    to={{
                      pathname: `/user/${row.id}/`,
                    }}
                  >
                    {row.name.split(" ")[0]}
                  </Link>
                </TableCell>

                <TableCell className={styles.personCell}>
                  <div>
                    <Table>
                      <Link
                        className={styles.personLink}
                        to={{
                          pathname: `/user/${row.id}/sleep`,
                        }}
                      >
                        <TableBody>
                          {sleepPerPerson(row.id, row.sleep)}
                        </TableBody>
                      </Link>
                    </Table>
                  </div>
                </TableCell>
                <TableCell className={styles.personCell}>
                  <Link
                    className={styles.personLink}
                    to={{
                      pathname: `/user/${row.id}/activity`,
                    }}
                  >
                    <TableBody>{stepsPerPerson(row.id, row.steps)}</TableBody>
                  </Link>
                </TableCell>
                <TableCell className={styles.personCell}>
                  <Link
                    className={styles.personLink}
                    to={{
                      pathname: `/user/${row.id}/psychology`,
                    }}
                  >
                    <TableBody>
                      {mindPointsPerPerson(row.id, row.mindPoints)}
                    </TableBody>
                  </Link>
                </TableCell>
                {props.withOptions ? (
                  <TableCell className={styles.largeScreen}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          icon={<FavoriteBorder />}
                          checkedIcon={<Favorite />}
                          color="primary"
                          value="follow"
                          checked={row.critical}
                        />
                      }
                      onClick={() => onClickFavorite(row)}
                    />
                  </TableCell>
                ) : (
                  <></>
                )}
              </TableRow>
            );
          })}
        </>
      );
    } else {
      overviewTable = (
        <>
          <TableRow>
            <TableCell component="td" scope="row"></TableCell>
            <TableCell></TableCell>
            <TableCell>Engar mælingar fundust fyrir þetta tímabil.</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </>
      );
    }
  } else {
    overviewTable = (
      <>
        <TableRow>
          <TableCell component="td" scope="row"></TableCell>
          <TableCell></TableCell>
          <TableCell style={{ textAlign: "center" }}>
            <CircularProgress />
          </TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
        </TableRow>
      </>
    );
  }

  let weekDates = (
    <TableHead>
      <TableCell className={styles.subTableCell} key={yesterday}>
        {parseInt(yesterday.split("-")[2]) +
          "/" +
          parseInt(yesterday.split("-")[1])}
      </TableCell>
      <TableCell
        className={styles.show2 + " " + styles.subTableCell}
        key={day2}
      >
        {parseInt(day2.split("-")[2]) + "/" + parseInt(day2.split("-")[1])}
      </TableCell>
      <TableCell
        className={styles.show3 + " " + styles.subTableCell}
        key={day3}
      >
        {parseInt(day3.split("-")[2]) + "/" + parseInt(day3.split("-")[1])}
      </TableCell>
      <TableCell
        className={styles.show4 + " " + styles.subTableCell}
        key={day4}
      >
        {parseInt(day4.split("-")[2]) + "/" + parseInt(day4.split("-")[1])}
      </TableCell>
      <TableCell
        className={styles.show5 + " " + styles.subTableCell}
        key={day5}
      >
        {parseInt(day5.split("-")[2]) + "/" + parseInt(day5.split("-")[1])}
      </TableCell>
      <TableCell
        className={styles.show6 + " " + styles.subTableCell}
        key={day6}
      >
        {parseInt(day6.split("-")[2]) + "/" + parseInt(day6.split("-")[1])}
      </TableCell>
      <TableCell
        className={styles.show7 + " " + styles.subTableCell}
        key={day7}
      >
        {parseInt(day7.split("-")[2]) + "/" + parseInt(day7.split("-")[1])}
      </TableCell>
    </TableHead>
  );

  return (
    <div>
      <Grid item>
        <Card className={styles.table}>
          <div className={styles.tableContainer}>
            <Table>
              <TableHead>
                <TableRow className={styles.largeScreen}>
                  <TableCell>Nafn</TableCell>
                  <TableCell>Svefn (klst)</TableCell>
                  <TableCell>Skref (þúsund)</TableCell>
                  <TableCell>MindPoints (stig)</TableCell>
                  {props.withOptions ? <TableCell>Vakta</TableCell> : <></>}
                </TableRow>
                <TableRow className={styles.smallScreen}>
                  <TableCell>Nafn</TableCell>
                  <TableCell>Svefn</TableCell>
                  <TableCell>Skref</TableCell>
                  <TableCell>MindPoints</TableCell>
                </TableRow>
              </TableHead>
              <TableCell className={styles.weekdates}></TableCell>
              <TableCell className={styles.weekdates}>{weekDates}</TableCell>
              <TableCell className={styles.weekdates}>{weekDates}</TableCell>
              <TableCell className={styles.weekdates}>{weekDates}</TableCell>
              {props.withOptions ? (
                <TableCell className={styles.largeScreen}></TableCell>
              ) : (
                <></>
              )}
              <TableBody>{overviewTable}</TableBody>
            </Table>
          </div>
          <div className={styles.seeMore}>
            {props.topOnly ? (
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to={{ pathname: "/mentalOverview" }}
              >
                Sjá alla þjónustuþega
              </Button>
            ) : (
              <></>
            )}
          </div>
        </Card>
      </Grid>

      <CustomizedSnackbars
        variant={snackbarVariant}
        message={snackbarMessage}
        open={snackbarOpen}
        closeSnackbar={() => closeSnackbar()}
      />
    </div>
  );
};

export default MentalOverviewTable;
