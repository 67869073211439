import React from "react";
import { Card, CardContent, Typography } from "@material-ui/core";
import styles from "./CardTitleValue.module.css"

const CardTitleValue= ({ title, value }) => {
  return (
    <div className={styles.CardContent}>
        <Card className={styles.cardWidth}>
        <CardContent>
            <Typography
            align="center"
            variant="subtitle2"
            color="textSecondary"
            gutterBottom
            >
            {title}
            </Typography>
            <Typography align="center" variant="subtitle2" component="h2">
            {value}
            </Typography>
        </CardContent>
        </Card>
    </div>
  );
};

export default CardTitleValue;
