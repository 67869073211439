import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import CustomizedSnackbars from "../../../components/UI/CustomizedSnackbars";
import defaultUser from "../../../assets/default_user.png"; // Tell Webpack this JS file uses this image
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Avatar,
  CircularProgress,
  Button,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import Pagination from "@material-ui/lab/Pagination";
import styles from "./Comparison.module.css";
import { ResponsiveRadar } from "@nivo/radar";
import { noValue, getStatisticsPercentage } from "../../../utils";

var allMeasRadar = [];
var allMeasMap = [];

var radarChart = (
  <p>Smellið á þjónustuþega úr listanum til þess að bera þá saman.</p>
);
let keyValues = [];
let counter = 0;

const TeamComparison = (props) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("INITIAL");
  const [snackbarVariant, setSnackbarVariant] = useState("info");
  const [error, setError] = useState(false);
  const [team, setTeam] = useState([]);
  const [name, setName] = useState("");
  const [teamLogo, setTeamLogo] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [id, setId] = useState(props.match.params.id); // team's id
  const [offset, setOffset] = useState(0); // for offset and limit
  const [pageCount, setPageCount] = useState(1); // total page count for athlete list
  const [data, setData] = useState(0);
  const [headers, setHeaders] = useState([]);
  let selectedTypes = new Map();
  let selectedNum = 0;

  let radarData = [];

  const teamPath = "/group/" + id;

  useEffect(() => {
    fetch(process.env.REACT_APP_API + "api/teams/" + id + "/", {
      method: "GET",
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    })
      .then(handleErrors)
      .then((res) => res.json())
      .then(
        (result) => {
          setName(result.name);
          setTeamLogo(result.team_logo);
        },
        (error) => {
          setError(true);
          setSnackbarOpen(true);
          setSnackbarMessage("Villa kom upp við að sækja hóp.");
          setSnackbarVariant("error");
        }
      );
    fetch(
      process.env.REACT_APP_API +
        "api/athleteinteams/?limit=20&offset=" +
        offset +
        "&ordering=athlete__first_name&team=" +
        id,
      {
        method: "GET",
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then(handleErrors)
      .then((res) => res.json())
      .then(
        (result) => {
          setTeam(result.results);
          setLoaded(true);
          setPageCount(Math.ceil(result.count / 20));
        },
        (error) => {
          setError(true);
          setSnackbarOpen(true);
          setSnackbarMessage(
            "Villa kom upp við að sækja þjónustuþega fyrir hóp."
          );
          setSnackbarVariant("error");
        }
      );
  }, [id, offset]);

  const handleClick = (player) => {
    var playerID = "player" + player.id;
    var element = document.getElementById(playerID).className;
    if (element.includes(styles.tableColor1)) {
      document.getElementById(playerID).className = styles.tableColor2;
    } else {
      document.getElementById(playerID).className = styles.tableColor1;
    }

    var coun1 = -1;
    var coun2 = 0;
    const player_id = player.id;
    if (player_id !== 0) {
      selectedNum = 0;
      var selNum = selectedNum;
      const queryString = "?athlete=" + player.id;
      fetch(
        process.env.REACT_APP_API +
          "api/measurementswitheventsstatistics/" +
          queryString +
          "&ordering=type__name,-date_of_measurement&event__origin__name=MeasurementsFile&type__category=PHYSICAL&data_value__isnull=false&data_value__gt=0",
        {
          method: "GET",
          headers: {
            Authorization: `JWT ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      )
        .then(handleErrors)
        .then((res) => res.json())
        .then(
          (result) => {
            let dates = [];
            let measurementsByTypes = new Map();
            let selectedMap = selectedTypes;

            let tempRadarData = [];
            let tempMapData = new Map();
            var key = player.first_name + " " + player.last_name;
            var keyExists = checkKeyValues(key);

            if (keyExists != true) {
              keyValues.push(key);
              counter += 1;

              result.results.forEach(function (item, index) {
                let teamName = "";
                if (item.event.team !== undefined) {
                  teamName = item.event.team.name + "\n";
                }
                dates.push(teamName + item.date_of_measurement);
                let dataPercentage = getStatisticsPercentage(
                  "data_value",
                  item,
                  false
                );

                if (!measurementsByTypes.has(item.type.name)) {
                  var measurementByDate = new Map();
                  measurementByDate.set(
                    teamName + item.date_of_measurement,
                    Math.round(item.data_value * 1000) / 1000
                  );
                  measurementsByTypes.set(item.type.name, measurementByDate);

                  if (selNum < 6) {
                    selectedMap.set(item.type.name, true);
                    /* This is the newest values because from API ordered desc by measurement_date */

                    tempRadarData.push({
                      type: item.type.name,
                      [keyValues[counter - 1]]: dataPercentage,
                    });
                  } else {
                    selectedMap.set(item.type.name, false);
                  }
                  tempMapData.set(item.type.name, [
                    dataPercentage,
                    item.data_value,
                    item.team_events_average,
                  ]);
                } else {
                  if (
                    item.type.name !== "Height" &&
                    item.type.name !== "Weight" &&
                    item.type.name !== "BMI"
                  ) {
                    measurementsByTypes.set(
                      item.type.name,
                      measurementsByTypes
                        .get(item.type.name)
                        .set(
                          teamName + item.date_of_measurement,
                          Math.round(item.data_value * 1000) / 1000
                        )
                    );
                  }
                }
              });
              for (let i = 0; i < tempRadarData.length; i++) {
                var typeExists = false;
                for (let j = 0; j < allMeasRadar.length; j++) {
                  if (tempRadarData[i].type === allMeasRadar[j].type) {
                    typeExists = true;
                  }
                }
                if (typeExists === false) {
                  allMeasRadar.push({
                    type: tempRadarData[i].type,
                  });
                }
              }

              for (let i = 0; i < allMeasRadar.length; i++) {
                var dataExists = false;
                for (let j = 0; j < tempRadarData.length; j++) {
                  if (tempRadarData[j].type === allMeasRadar[i].type) {
                    allMeasRadar[i][keyValues[counter - 1]] =
                      tempRadarData[j][keyValues[counter - 1]];
                    dataExists = true;
                  }
                }
                if (dataExists === false) {
                  allMeasRadar[i][keyValues[counter - 1]] = 0;
                }
              }

              allMeasMap[counter - 1] = tempMapData;

              selectedNum = selNum;
              selectedTypes = selectedMap;
            }

            radarData = allMeasRadar;
            radarChart = (
              <ResponsiveRadar
                key={counter}
                data={radarData}
                keys={keyValues}
                indexBy="type"
                maxValue={100}
                margin={{ top: 70, right: 80, bottom: 40, left: 80 }}
                borderColor={{ from: "color" }}
                gridLevels={5}
                gridShape="linear"
                // gridLabel={LabelComponent}
                gridLabelOffset={14}
                enableDots={true}
                dotSize={10}
                dotColor={{ from: "color" }}
                dotBorderWidth={2}
                dotBorderColor={{ from: "color" }}
                enableDotLabel={false}
                dotLabel="value"
                dotLabelYOffset={-12}
                colors={[
                  "rgba(69, 88, 173)",
                  "rgba(149, 0, 255)",
                  "rgba(149,20,20)",
                ]}
                fillOpacity={0.35}
                blendMode="multiply"
                animate={true}
                motionStiffness={90}
                motionDamping={15}
                isInteractive={true}
                tooltipFormat={(value, key) => {
                  coun1 += 1;
                  if (coun1 % keyValues.length === 0 && coun1 != 0) {
                    coun2 += 1;
                  }
                  coun2 = coun2 % allMeasRadar.length;
                  for (let i = 0; i < keyValues.length; i++) {
                    if (key === keyValues[i]) {
                      if (
                        allMeasMap[i].get(allMeasRadar[coun2].type) ===
                        undefined
                      ) {
                        return 0;
                      } else {
                        let value = allMeasMap[i].get(
                          allMeasRadar[coun2].type
                        )[1];
                        return Math.round(value * 1000) / 1000;
                      }
                    }
                  }
                }}
                legends={[
                  {
                    anchor: "top-left",
                    direction: "column",
                    translateX: -50,
                    translateY: -40,
                    itemWidth: 80,
                    itemHeight: 20,
                    itemTextColor: "#999",
                    symbolSize: 12,
                    symbolShape: "circle",
                    effects: [
                      {
                        on: "hover",
                        style: {
                          itemTextColor: "#000",
                        },
                      },
                    ],
                  },
                ]}
              />
            );

            let headerNames = [...new Set(dates)];
            headerNames = headerNames.reverse();
            headerNames = ["Tegund"].concat(headerNames);
            setHeaders(headerNames);
          },
          (error) => {
            setError(true);
            setSnackbarOpen(true);
            setSnackbarMessage("Það kom upp villa við að sækja mælingar");
            setSnackbarVariant("error");
          }
        );
    }
  };

  const handlePageChange = (value) => {
    let pageOffset = (value - 1) * 20;
    setOffset(pageOffset);
  };

  const closeSnackbar = () => {
    setSnackbarOpen(false);
  };

  function handleErrors(response) {
    if (!response.ok) {
      setError(true);
    }
    return response;
  }

  let club = (
    <TableRow>
      <TableCell component="th" scope="row">
        Enginn þjónustuþegi fannst í þessum hóp.
      </TableCell>
    </TableRow>
  );
  if (loaded) {
    if (team[0] && team[0].athlete) {
      club = team.map((player, index) => {
        let profile_picture = null;
        profile_picture = player.athlete.profile_picture;
        const { first_name, last_name } = player.athlete;
        const { date_of_birth } = player.athlete;
        let year_of_birth = "";
        if (date_of_birth !== null) {
          year_of_birth = date_of_birth.substr(0, date_of_birth.indexOf("-"));
        }

        let pic = profile_picture;
        if (noValue(pic)) {
          pic = defaultUser;
        }

        var playerID = "player" + player.athlete.id;
        return (
          <>
            <TableRow
              id={playerID}
              className={styles.tableColor1}
              key={"team" + index}
            >
              <TableCell
                component="td"
                scope="row"
                onClick={() => handleClick(player.athlete)}
              >
                <div className={styles.row}>
                  <div>
                    <div className={styles.avatar}>
                      <Avatar
                        src={pic}
                        alt={`${first_name} ${last_name}`}
                        className={styles.avatar}
                      />
                    </div>
                    {`${first_name} ${last_name}`}
                  </div>
                </div>
              </TableCell>
              <TableCell>{year_of_birth}</TableCell>
            </TableRow>
          </>
        );
      });
    }
  } else {
    club = <CircularProgress />;
  }
  return (
    <div className={styles.container}>
      <div className={styles.center}>
        <div className={styles.teamsList}>
          <div id="chart" className={styles.radarChart}>
            {radarChart}
          </div>
          <Paper className={styles.team}>
            <div className={styles.header}>
              {name}
              <img src={teamLogo} alt="" className={styles.team_logo} />
            </div>

            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Nafn</TableCell>
                  <TableCell>Fæðingarár</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{club}</TableBody>
            </Table>
          </Paper>
          <div className={styles.pagination}>
            <Pagination
              count={pageCount}
              variant="outlined"
              shape="rounded"
              onChange={(e, v) => handlePageChange(v)}
            />
          </div>
        </div>
        <div className={styles.button}>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            component={Link}
            to={{ pathname: teamPath }}
          >
            Til baka
          </Button>
        </div>
        <div className={styles.imageContainer}></div>
      </div>

      <CustomizedSnackbars
        variant={snackbarVariant}
        message={snackbarMessage}
        open={snackbarOpen}
        closeSnackbar={() => closeSnackbar()}
      />
    </div>
  );
};

function handleErrors(response) {
  if (!response.ok) {
    this.setState({
      error: true,
    });
  }
  return response;
}

function checkKeyValues(key) {
  for (let i = 0; i < keyValues.length; i++) {
    if (keyValues[i] === key) {
      delete keyValues[i];

      for (let j = i; j < keyValues.length; j++) {
        keyValues[j] = keyValues[j + 1];
      }
      for (let j = 0; j < allMeasRadar.length; j++) {
        delete allMeasRadar[j][key];
      }
      keyValues.pop();
      counter -= 1;
      return true;
    }
  }
  return false;
}

const mapStateToProps = (store) => {
  return {
    userType: store.auth.userType,
    userId: store.auth.id,
    name: store.auth.fullName,
    profilePicture: store.auth.profilePicture,
    athlete: store.auth.athlete,
  };
};
// Is redux necessary?
export default connect(mapStateToProps)(TeamComparison);
