import {
  LOG_IN_SUCCESS,
  LOG_IN_FAILURE,
  SERVER_ERROR,
  LOG_OUT,
  RESET,
  UPDATE_USER,
} from "../actions/authTypes";
import {noValue} from "../utils"

const initialState = {
  email: "",
  fullName: "",
  profilePicture: "",
  userType: "",
  loggedIn: false,
  error: false,
  checkingToken: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOG_IN_SUCCESS:
      const {
        email,
        first_name,
        last_name,
        groups,
        profile_picture,
        id,
      } = action.payload.user;
      let profilePicture = profile_picture
      if (noValue(profile_picture)) {
        profilePicture = ""
      }
      if (action.payload.athlete!==undefined) {
        return {
          ...state,
          email: email,
          fullName: `${action.payload.athlete.first_name} ${action.payload.athlete.last_name}`,
          userType: groups[0]["name"],
          profilePicture: action.payload.athlete.profile_picture,
          id: id,
          loggedIn: true,
          error: false,
          athlete: action.payload.athlete,
          serverError: false,
          checkingToken: false,
        };
      } else if (action.payload.coach!==undefined) {
        return {
          ...state,
          email: email,
          fullName: `${action.payload.coach.first_name} ${action.payload.coach.last_name}`,
          userType: groups[0]["name"],
          profilePicture: action.payload.coach.profile_picture,
          id: id,
          loggedIn: true,
          error: false,
          coach: action.payload.coach,
          serverError: false,
          checkingToken: false,
        };
      } else if (action.payload.researcher!==undefined) {
        return {
          ...state,
          email: email,
          fullName: `${action.payload.researcher.first_name} ${action.payload.researcher.last_name}`,
          userType: groups[0]["name"],
          profilePicture: action.payload.researcher.profile_picture,
          id: id,
          loggedIn: true,
          error: false,
          researcher: action.payload.researcher,
          serverError: false,
          checkingToken: false,
        };
      }
      return {
        ...state,
        email: "",
        id: "",
        loggedIn: false,
        error: false,
        serverError: false,
        checkingToken: false,
      };
    case LOG_IN_FAILURE:
      return {
        email: "",
        userType: "",
        loggedIn: false,
        error: true,
        serverError: false,
        checkingToken: false,
      };
    case LOG_OUT:
      return {
        email: "",
        userType: "",
        loggedIn: false,
        profilePicture: "",
        id: "",
        fullName:"",
        researcher: null,
        athlete: null,
        coach: null,
        checkingToken: false,
      };
    case RESET:
      return {
        ...state,
        error: false,
        serverError: false,
        checkingToken: false,
      };
    case SERVER_ERROR:
      return {
        ...state,
        email: "",
        loggedIn: false,
        error: true,
        serverError: true,
        checkingToken: false,
      };
    default:
      return state;
  }
}
