import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import CustomizedSnackbars from "../../../../components/UI/CustomizedSnackbars";
import defaultUser from "../../../../assets/default_user.png"; // Tell Webpack this JS file uses this image
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Avatar,
  CircularProgress,
  LinearProgress,
  Button,
  InputLabel,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import FileDownloadIcon from "@material-ui/icons/FileDownload";
import styles from "../Export.module.css";
import { noValue } from "../../../../utils";
import IcelandicDayPicker from "../../../../components/UI/IcelandicDayPicker";
import { CSVLink } from "react-csv";

// Export data uploaded via Excel sheets, i.e. of origin name MeasurementsFile
const ExportData = (props) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("INITIAL");
  const [snackbarVariant, setSnackbarVariant] = useState("info");
  const [error, setError] = useState(false);
  const [team, setTeam] = useState([]);
  const [name, setName] = useState("");
  const [teamLoaded, setTeamLoaded] = useState(false);
  const [typesLoaded, setTypesLoaded] = useState(false);
  const [downloadFetching, setDownloadFetching] = useState(false);
  const [id, setId] = useState(props.match.params.id); // team's id
  const [measurementTypes, setMeasurementTypes] = useState([]);
  const [selectAllTypes, setSelectAllTypes] = useState(false);
  const [selectedMeasurements, setSelectedMeasurements] = useState([]);
  const [selectAllIndividuals, setSelectAllIndividuals] = useState(false);
  const [selectedIndividuals, setSelectedIndividuals] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [progress, setProgress] = useState(0);
  const [fetchProgress, setFetchProgress] = useState(0);
  const [downloadProgress, setDownloadProgress] = useState(<div></div>);
  const pageLimit = 500; // The number of measurements to fetch from each page (prevents the server from crashing when there is too much data)

  // In order to set the start day as one year ago, need to edit IcelandicDayPicker to accomodate this
  var d = new Date();
  var year = d.getFullYear(); // -1 to get last year
  var month = d.getMonth();
  var day = d.getDate();
  const [startDay, setStartDay] = useState(
    new Date(year, month, day).toISOString().substring(0, 10)
  );
  const [endDay, setEndDay] = useState(
    new Date().toISOString().substring(0, 10)
  );

  let csvLink = React.createRef(); // Create the hidden link to download the csv data

  const teamPath = "/group/" + id;

  useEffect(() => {
    fetch(process.env.REACT_APP_API + "api/teams/" + id + "/", {
      method: "GET",
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setName(result.name);
        },
        (error) => {
          setError(true);
          setSnackbarOpen(true);
          setSnackbarMessage("Villa kom upp við að sækja hóp.");
          setSnackbarVariant("error");
        }
      );
    fetch(
      process.env.REACT_APP_API +
        "api/athleteinteams/?limit=" +
        pageLimit +
        "&ordering=athlete__first_name&team=" +
        id,
      {
        method: "GET",
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          setTeam(result.results);
          setTeamLoaded(true);
        },
        (error) => {
          setError(true);
          setSnackbarOpen(true);
          setSnackbarMessage(
            "Villa kom upp við að sækja einstaklinga fyrir hóp."
          );
          setSnackbarVariant("error");
        }
      );
    getMeasurementTypes(id);
  }, [id]);

  const getMeasurementTypes = () => {
    // We first need to get the number of total measurements to be able to get them all
    fetch(process.env.REACT_APP_API + "api/typesused/", {
      method: "GET",
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        fetch(
          process.env.REACT_APP_API +
            "api/typesused/" +
            "?limit=" +
            result.count,
          {
            method: "GET",
            headers: {
              Authorization: `JWT ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
            },
          }
        )
          .then((res) => res.json())
          .then(
            (result) => {
              let tempMeasurements = [];
              for (const [key, value] of Object.entries(result.results)) {
                if (value.name !== "" && !value.name.startsWith("Withings")) {
                  tempMeasurements.push(value);
                }
              }
              setMeasurementTypes(
                tempMeasurements.sort((a, b) => a.name.localeCompare(b.name))
              );
              setTypesLoaded(true);
            },
            (error) => {
              setError(true);
              setSnackbarOpen(true);
              setSnackbarMessage(
                "Villa kom upp við að sækja tegundir mælinga."
              );
              setSnackbarVariant("error");
            }
          );
      });
  };

  const handlePersonClick = (individual) => {
    let selectedPersons = selectedIndividuals;
    let individualID = "individual" + individual.id;
    let element = document.getElementById(individualID).className;
    if (element.includes(styles.tableColor1)) {
      document.getElementById(individualID).className = styles.tableColor2;
    } else {
      document.getElementById(individualID).className = styles.tableColor1;
    }
    if (selectedPersons.includes(individual.id)) {
      // If clicked type exists in the list, remove it
      let removedIndividual = selectedPersons.filter(
        (item) => item !== individual.id
      ); // Remove the individual from the list
      selectedPersons = removedIndividual;
    } else {
      // Else add it
      selectedPersons.push(individual.id);
    }

    setSelectedIndividuals(selectedPersons);
  };

  const handleTypeClick = (clickedType) => {
    let selectedTypes = selectedMeasurements;
    let typeID = "type" + clickedType.id;
    let element = document.getElementById(typeID).className;
    if (element.includes(styles.tableColor1)) {
      document.getElementById(typeID).className = styles.tableColor2;
    } else {
      document.getElementById(typeID).className = styles.tableColor1;
    }

    if (selectedTypes.includes(clickedType.id)) {
      // If clicked type exists in the list, remove it
      let removedType = selectedTypes.filter((item) => item !== clickedType.id); // Remove the type from the list
      selectedTypes = removedType;
    } else {
      // Else add it
      selectedTypes.push(clickedType.id);
    }

    setSelectedMeasurements(selectedTypes);
  };

  const closeSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleSelectAllIndividuals = () => {
    if (teamLoaded) {
      let selectedPersons = [];
      let allIndividualsSelected = !selectAllIndividuals;
      setSelectAllIndividuals(allIndividualsSelected); // Toggle the select
      // Highlight all the individuals in the table
      if (allIndividualsSelected) {
        for (let i = 0; i < team.length; i++) {
          selectedPersons.push(team[i].athlete.id);
          let individualID = "individual" + team[i].athlete.id;
          document.getElementById(individualID).className = styles.tableColor2;
        }
      } else {
        // If select all is being reset
        for (let i = 0; i < team.length; i++) {
          let individualID = "individual" + team[i].athlete.id;
          document.getElementById(individualID).className = styles.tableColor1;
        }
      }
      setSelectedIndividuals(selectedPersons);
    }
  };

  const handleSelectAllTypes = () => {
    if (typesLoaded) {
      let selectedTypes = [];
      let allTypesSelected = !selectAllTypes;
      setSelectAllTypes(allTypesSelected); // Toggle the select
      // Highlight all the types in the table
      if (allTypesSelected) {
        for (var i = 0; i < measurementTypes.length; i++) {
          selectedTypes.push(measurementTypes[i].id);
          var typeID = "type" + measurementTypes[i].id;
          document.getElementById(typeID).className = styles.tableColor2;
        }
      } else {
        // If select all is being reset
        for (var i = 0; i < measurementTypes.length; i++) {
          var typeID = "type" + measurementTypes[i].id;
          document.getElementById(typeID).className = styles.tableColor1;
        }
      }
      setSelectedMeasurements(selectedTypes);
    }
  };

  useEffect(() => {
    if (headers.length > 0 && csvData.length > 0 && progress === 100) {
      setTimeout(() => {
        if (csvLink.current) {
          csvLink.current.link.click(); // Click the invisible (hidden) CSVLink component (button)
          setProgress(0);
          setHeaders([]);
          setCsvData([]);
        }
      }, 10); // Short timeout to allow csvData to truly update before download
    }
  }, [progress, headers, csvData, csvLink]);

  useEffect(() => {
    // Display the spinner while data is fetching from backend
    if (downloadFetching && progress === 0) {
      setDownloadProgress(
        <div className={styles.progressBar}>
          <p>Sæki gögn frá vefþjóni...</p>
          <LinearProgress
            variant="determinate"
            value={fetchProgress.toFixed(0)}
          />
          {fetchProgress.toFixed(0)}%
        </div>
      );
    }
    // Show progress bar while the data is being filtered
    else if (progress > 0) {
      setDownloadProgress(
        <div className={styles.progressBar}>
          <p>Undirbý skrá...</p>
          <LinearProgress variant="determinate" value={progress.toFixed(0)} />
          {progress.toFixed(0)}%
        </div>
      );
    }
  }, [fetchProgress, progress, downloadFetching]);

  // Async timer for the progress bar to update accordingly to the for-loop in formatCSV()
  const timer = (delay) => {
    return new Promise((resolve) => setTimeout(resolve, delay));
  };

  const formatCSV = async (measurementData, delay) => {
    let tempHeaders = [
      { label: "Id", key: "Id" },
      { label: "Name", key: "Name" },
      { label: "Date", key: "Date" },
    ];
    let tempCsvData = [];
    var counter = 0;
    var objSize = Object.keys(measurementData).length;
    setDownloadFetching(false);
    setFetchProgress(0);
    setProgress(1);
    for (const [key, value] of Object.entries(measurementData)) {
      let percentage = counter / objSize;
      setProgress(percentage * 100);

      counter++;
      // Add each header only once
      if (!tempHeaders.some((e) => e.key === value.type.name)) {
        tempHeaders.push({ label: value.type.name, key: value.type.name });
      }
      // Add the data values to the csv object
      if (
        !tempCsvData.some(
          (e) =>
            e.Id === value.athlete.id && e.Date === value.date_of_measurement
        )
      ) {
        tempCsvData.push({
          Id: value.athlete.id,
          Name: value.athlete.first_name + " " + value.athlete.last_name,
          Date: value.date_of_measurement,
          [value.type.name]: value.data_value,
        });
      } else {
        let updatedData = tempCsvData.map((x) =>
          x.Id === value.athlete.id
            ? { ...x, [value.type.name]: value.data_value }
            : x
        );
        tempCsvData = updatedData;
      }
      // Wait delay amount in ms before continuing, give browser time to render last update
      await timer(delay);
    }
    if (counter === objSize) {
      setProgress(100);
    }

    tempCsvData.sort((a, b) => sortF(a, b));
    setHeaders(tempHeaders);
    setCsvData(tempCsvData);
  };

  function sortF(a, b) {
    // Sort by name
    if (a.Name.localeCompare(b.Name) < 0) {
      return -1;
    } else if (a.Name.localeCompare(b.Name) > 0) {
      return 1;
    }

    // Else sort by date
    if (new Date(a.Date) - new Date(b.Date)) {
      return 1;
    } else if (new Date(b.Date) - new Date(a.Date)) {
      return -1;
    } else {
      return 0;
    }
  }

  const filterDownload = (measurementData) => {
    setFetchProgress(100);
    let tempMeasurements = [];
    for (let i = 0; i < measurementData.length; i++) {
      for (const [key, value] of Object.entries(measurementData[i])) {
        if (
          selectedIndividuals.includes(value.athlete.id) &&
          selectedMeasurements.includes(value.type.id)
        ) {
          tempMeasurements.push(value);
        }
      }
    }
    formatCSV(tempMeasurements);
  };

  const fetchMeasurements = async (
    resultsArr,
    fullLimit,
    limit,
    offset,
    oneSelectedString
  ) => {
    fetch(
      process.env.REACT_APP_API +
        "api/measurements/?event__team=" +
        +id +
        "&" +
        oneSelectedString +
        "date_of_measurement__gte=" +
        startDay +
        "&date_of_measurement__lte=" +
        endDay +
        "&ordering=date_of_measurement" +
        "&limit=" +
        pageLimit +
        "&offset=" +
        offset +
        // Here we only want data from the measurements files (Excel)
        "&data_value__gt=0&event__origin__name=MeasurementsFile",
      {
        method: "GET",
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.results.length > 0) {
            let percentage = offset / fullLimit;
            setFetchProgress(percentage * 100);

            resultsArr.push(result.results);
            let tempArr = resultsArr;

            limit = Number(limit) - Number(pageLimit);
            offset = Number(offset) + Number(pageLimit);

            if (limit > 0) {
              setTimeout(() => {
                fetchMeasurements(
                  tempArr,
                  fullLimit,
                  limit,
                  offset,
                  oneSelectedString
                );
              }, 1);
            } else {
              // Recursively fetch the rest of the data
              filterDownload(tempArr);
            }
          }
        },
        (error) => {
          setError(true);
          setSnackbarOpen(true);
          setSnackbarMessage("Villa kom upp við að sækja valdar mælingar.");
          setSnackbarVariant("error");
          setDownloadFetching(false);
          setProgress(0);
          setDownloadProgress(<div></div>);
        }
      );
  };

  const handleDownload = () => {
    // Only fetch for the selected individual if only one individual is selected
    var oneSelectedString = "";
    if (selectedIndividuals.length === 1) {
      oneSelectedString = "athlete=" + selectedIndividuals[0] + "&";
    }

    setDownloadFetching(true);
    // FETCH the measurements for the team to get the count
    fetch(
      process.env.REACT_APP_API +
        "api/measurements/?event__team=" +
        +id +
        "&" +
        oneSelectedString +
        "date_of_measurement__gte=" +
        startDay +
        "&date_of_measurement__lte=" +
        endDay +
        "&data_value__gt=0&event__origin__name=MeasurementsFile",
      {
        method: "GET",
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          // Send a new request to get ALL measurements after getting the total number (count)
          // Loop through all the measurements and types and add the corresponding ones to a map
          // Add for the selected athletes and types

          var limit = result.count;
          var offset = 0;
          var resultsArr = [];

          // If there are measurements found, send a new request (recursive function) to get all the data
          if (result.count > 0) {
            fetchMeasurements(
              resultsArr,
              result.count,
              limit,
              offset,
              oneSelectedString
            );
          }
          // If the count is 0 then there are no measurements found from the query
          else {
            setError(true);
            setSnackbarOpen(true);
            setSnackbarMessage("Engar mælingar fundust fyrir valin atriði.");
            setSnackbarVariant("error");
            setDownloadFetching(false);
            setProgress(0);
            setDownloadProgress(<div></div>);
          }
        },
        (error) => {
          setError(true);
          setSnackbarOpen(true);
          setSnackbarMessage("Villa kom upp við að sækja mælingar liðs.");
          setSnackbarVariant("error");
          setDownloadFetching(false);
          setProgress(0);
          setDownloadProgress(<div></div>);
        }
      );
  };

  let club = (
    <TableRow>
      <TableCell component="th" scope="row">
        Enginn einstaklingur fannst í þessum hóp.
      </TableCell>
    </TableRow>
  );
  if (teamLoaded) {
    if (team && team[0] && team[0].athlete) {
      club = team.map((player, index) => {
        let profile_picture = null;
        profile_picture = player.athlete.profile_picture;
        const { first_name, last_name } = player.athlete;
        const { date_of_birth } = player.athlete;
        let year_of_birth = "";
        if (date_of_birth !== null) {
          year_of_birth = date_of_birth.substr(0, date_of_birth.indexOf("-"));
        }

        let pic = profile_picture;
        if (noValue(pic)) {
          pic = defaultUser;
        }

        var playerID = "individual" + player.athlete.id;
        return (
          <>
            <TableRow
              id={playerID}
              className={styles.tableColor1}
              key={"team" + index}
              onClick={() => handlePersonClick(player.athlete)}
            >
              <TableCell component="td" scope="row">
                <div>
                  <div className={styles.row}>
                    <div className={styles.athleteWrapper}>
                      <div className={styles.avatar}>
                        <Avatar
                          src={pic}
                          alt={`${first_name} ${last_name}`}
                          className={styles.avatar}
                        />
                      </div>
                      {`${first_name} ${last_name}`}
                    </div>
                  </div>
                </div>
              </TableCell>
              <TableCell>{year_of_birth}</TableCell>
            </TableRow>
          </>
        );
      });
    }
  } else {
    club = <CircularProgress />;
  }

  let measurements = (
    <TableRow>
      <TableCell component="th" scope="row">
        Engar mælingar fundust fyrir {name}.
      </TableCell>
    </TableRow>
  );
  if (typesLoaded) {
    if (measurementTypes && measurementTypes[0] && measurementTypes[0].id) {
      measurements = measurementTypes.map((type, index) => {
        return (
          <>
            <TableRow
              id={"type" + type.id}
              className={styles.tableColor1}
              key={"type" + index}
            >
              <TableCell
                component="td"
                scope="row"
                onClick={() => handleTypeClick(type)}
              >
                <div>
                  <div className={styles.row}>
                    <div
                      className={styles.athleteWrapper}
                    >{`${type.name} `}</div>
                  </div>
                </div>
              </TableCell>
            </TableRow>
          </>
        );
      });
    }
  } else {
    measurements = <CircularProgress />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.center}>
        <div className={styles.teamsList}>
          <div id="chart" className={styles.downloadData}>
            <div>
              {downloadFetching | (progress > 0) ? (
                downloadProgress
              ) : (
                <p>
                  Smellið á einstaklinga úr listanum til þess að bæta þeim við.
                </p>
              )}
            </div>
            <div>
              <Button
                variant="contained"
                color="primary"
                className={styles.button}
                startIcon={<FileDownloadIcon />}
                onClick={() => handleDownload()}
              >
                Sækja gögn
              </Button>
              <CSVLink
                filename={name + "_" + startDay + "_" + endDay + ".csv"}
                headers={headers}
                className={styles.hidden}
                data={csvData}
                ref={csvLink}
                target="_blank"
              >
                {" "}
                Download Me
              </CSVLink>
            </div>
          </div>
          <Paper className={styles.team}>
            <div className={styles.header}>
              {name}
              <div className={styles.button}>
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  component={Link}
                  onClick={() => handleSelectAllIndividuals()}
                >
                  {selectAllIndividuals ? "Endurstilla" : "Velja alla"}
                </Button>
              </div>
            </div>

            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Nafn</TableCell>
                  <TableCell>Fæðingarár</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{club}</TableBody>
            </Table>
          </Paper>
        </div>
        <div className={styles.button}>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            component={Link}
            to={{ pathname: teamPath }}
          >
            Til baka
          </Button>
        </div>
        <div className={styles.imageContainer}>
          <Paper>
            <div className={styles.subheader}>Veldu tímabil</div>
            <div className={styles.datePickerContainer}>
              <div className={styles.datePicker}>
                <InputLabel shrink>Upphafsdagsetning</InputLabel>
                <IcelandicDayPicker setDay={setStartDay} />
              </div>
              <div className={styles.datePicker}>
                <InputLabel shrink>Lokadagsetning</InputLabel>
                <IcelandicDayPicker setDay={setEndDay} />
              </div>
            </div>
          </Paper>
          <Paper>
            <div className={styles.measurementHeader}>
              <div className={styles.subheader}>Veldu mælingar</div>
              <div className={styles.button}>
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  component={Link}
                  onClick={() => handleSelectAllTypes()}
                >
                  {selectAllTypes ? "Endurstilla" : "Velja allt"}
                </Button>
              </div>
            </div>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Tegund</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{measurements}</TableBody>
            </Table>
          </Paper>
        </div>
      </div>

      <CustomizedSnackbars
        variant={snackbarVariant}
        message={snackbarMessage}
        open={snackbarOpen}
        closeSnackbar={() => closeSnackbar()}
      />
    </div>
  );
};

const mapStateToProps = (store) => {
  return {
    userType: store.auth.userType,
    userId: store.auth.id,
    name: store.auth.fullName,
    profilePicture: store.auth.profilePicture,
    athlete: store.auth.athlete,
  };
};

export default connect(mapStateToProps)(ExportData);
