import React, { Component } from "react";
import { connect } from "react-redux";

import ReactApexChart from "react-apexcharts";

// This component is a good example of dynamic rendering of value from props with Apex chart!
class PercentageBar extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        series: [this.props.percentage],
        options: {
          chart: {
            id: 'PercentageBar',
            type: 'radialBar',
            toolbar: {
              show: true
            }
          },
          plotOptions: {
            radialBar: {
              startAngle: -135,
              endAngle: 225,
               hollow: {
                margin: 0,
                size: '90%',
                background: '#fff',
                image: undefined,
                imageOffsetX: 0,
                imageOffsetY: 0,
                position: 'front',
                dropShadow: {
                  enabled: true,
                  top: 3,
                  left: 0,
                  blur: 4,
                  opacity: 0.24
                }
              },
              track: {
                background: '#fff',
                strokeWidth: '20%',
                margin: 0, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: -3,
                  left: 0,
                  blur: 4,
                  opacity: 0.35
                }
              },
          
              dataLabels: {
                show: true,
                name: {
                  offsetY: -10,
                  show: true,
                  color: '#888',
                  fontSize: '14px'
                },
                value: {
                  formatter: function(val) {
                    return parseInt(val);
                  },
                  color: '#111',
                  fontSize: '25px',
                  show: true,
                }
              }
            }
          },
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'horizontal',
              shadeIntensity: 0.5,
              gradientToColors: ['#629e82'],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100]
            }
          },
          stroke: {
            lineCap: 'round'
          },
          labels: [this.props.title],
        },
      
      
      };
    }

    componentDidUpdate(prevProps) {
      if (this.props.percentage != prevProps.percentage) {
        window.ApexCharts.exec('PercentageBar', 'updateSeries', [this.props.percentage])
      }
    }

    render() {
      return (
            <div id="card">
                <div id="chart">
                    <ReactApexChart options={this.state.options} series={this.state.series} type="radialBar" height={150} width={200}/>
                </div>
            </div> 
        )
    }
}

const mapStateToProps = (store) => {
  if (store.auth.athlete) {
    var athlete = store.auth.athlete;
  } else if (store.router.location.state) {
    var athlete = store.router.location.state;
  }
  return {
    userType: store.auth.userType,
    userId: store.auth.id,
    name: store.auth.fullName,
    profilePicture: store.auth.profilePicture,
    athlete: athlete,
  };
};

export default connect(mapStateToProps, null)(PercentageBar);