import React, { Component } from "react";
import { connect } from "react-redux";
import CustomizedSnackbars from "../../../UI/CustomizedSnackbars";
import PercentageBar from "../../../UI/PercentageBar"
import moment from "moment";
import styles from "./SleepDetails.module.css"
import CardTitleValue from "../../../UI/CardTitleValue/CardTitleValue";
import CardTitleValueLarge from "../../../UI/CardTitleValueLarger/CardTitleValue";
import Circle from "react-circle";
import {
  Grid,
  Card,
} from "@material-ui/core";
import { text } from "@fortawesome/fontawesome-svg-core";

class AthleteChartSleepDetails extends Component {
  constructor(props) {
    super(props);

    let today = new Date();

    let week = [
      today.toDateString(),
    ];

    this.state = {
      snackbarOpen: false,
      snackbarMessage: "INITAL",
      snackbarVariant: "info",
      error: false,
      loaded: false,
      week: week,

      wakeUpCount: "", //Number of wake-ups
      sleepScore: 0, //Sleep score
      lightSleepDuration: "", //Duration of light sleep
      wakeUpDuration: "", //Duration of wake up
      durationToSleep: "", //Time it took to fall asleep
      durationToWakeup: "", //Time it took to wake up
      totalTimeAsleep: "",
      startSleepTime: "",
      endSleepTime: "",
      snoring: "", //snor
      snoringepisodecount: "",
      breathing_disturbances_intensity: 0,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.day!==prevProps.day) {
      this.fetchData();
    }
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    const id = this.props.athleteId;
    var day = this.props.day;

    if (id!==0 && id!==undefined) {
      const queryString = "?athlete=" + this.props.athleteId;

      fetch(
        process.env.REACT_APP_API +
          "api/measurements/" +
          queryString +
          "&type__name=WithingsSleepSummary&date_of_measurement__gte=" +
          day +
          "&date_of_measurement__lte=" +
          day,
        {
          method: "GET",
          headers: {
            Authorization: `JWT ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      )
        .then(handleErrors)
        .then((res) => res.json())
        .then(
          (result) => {
            if (result.results.length!==0) {
              this.setState({ loaded: true });
              var yesterday = moment().format("YYYY-MM-DD");

              let dataArray = [];
              let infoArray = [];
              var i;

              for (i = 0; i < result.results.length; i++) {
                let date = new Date(result.results[i].date_of_measurement);
                // if (this.state.week.includes(date.toDateString())) {
                let sleepData = result.results[i].data;
                /* Multiplying to get milliseconds instead of seconds */
                let startDate = new Date(sleepData.startdate * 1000);
                let endDate = new Date(sleepData.enddate * 1000);
                let millisecondsInDay = 1000 * 60 * 60 * 24;
                let startTime = startDate.getTime() % millisecondsInDay;
                let endTime = endDate.getTime() % millisecondsInDay;
                let millisecondsInHour = 1000 * 60 * 60;

                startTime /= millisecondsInHour;
                endTime /= millisecondsInHour;

                if (startTime > endTime) {
                  startTime -= 24;
                }

                startTime = Math.round(startTime * 1000) / 1000;
                endTime = Math.round(endTime * 1000) / 1000;

                var timeSlept = totalSleepDuration(startTime, endTime);
                if (result.results[i].date_of_measurement===day) {
                  dataArray.push(result.results[i].data.data);
                  break;
                }
              }
              //}
              infoArray = dataArray[Object.keys(dataArray)[0]];

              let hours = Math.floor(timeSlept);
              let minutes = Math.round((timeSlept - hours) * 60);
              timeSlept = hours + " klst og " + minutes + " mín";
              let sleepScore = infoArray.sleep_score;
              if (sleepScore == undefined) {
                sleepScore = 0
              }

              let breathing_disturbances_intensity = infoArray.breathing_disturbances_intensity;
              if (breathing_disturbances_intensity == undefined) {
                breathing_disturbances_intensity = 0
              }
              let snoring = infoArray.snoring
              if (snoring == undefined){
                snoring = 0
              }
              let snoringepisodecount = infoArray.snoringepisodecount
              if (snoringepisodecount == undefined){
                snoringepisodecount = 0
              }

              this.setState({
                data: infoArray,
                wakeUpCount: infoArray.wakeupcount,
                sleepScore: sleepScore,
                lightSleepDuration: infoArray.lightsleepduration,
                wakeUpDuration: infoArray.wakeupduration,
                durationToSleep: infoArray.durationtosleep,
                durationToWakeup: infoArray.durationtowakeup,
                totalTimeAsleep: timeSlept,
                snoring: snoring,
                snoringepisodecount: snoringepisodecount,
                breathing_disturbances_intensity: breathing_disturbances_intensity,

                loaded: true,
              });
            }
          },
          (error) => {
            this.setState({
              error: true,
              snackbarOpen: true,
              snackbarMessage:
                "Það kom upp villa við að sækja svefnupplýsingar.",
              snackbarVariant: "error",
            });
          }
        );
    }
  }

  closeSnackbar = () => {
    this.setState({
      snackbarOpen: false,
    });
  };

  render() {
    let {
      durationToSleep,
      durationToWakeup,
      wakeUpCount,
      sleepScore,
      totalTimeAsleep,
      wakeUpDuration,
      breathing_disturbances_intensity,
      snoring,
      snoringepisodecount,
    } = this.state;

    var yesterday = moment().subtract(1, "d").format("DD/MM/YYYY");

    if (wakeUpCount===1) {
      wakeUpCount = wakeUpCount + " sinni";
    } else {
      wakeUpCount = wakeUpCount + " sinnum";
    }

    var color = ""
    if (breathing_disturbances_intensity < 30) {
      color = "green"
    } else if (breathing_disturbances_intensity < 60) {
      color = "orange"
    } else if (60 <= breathing_disturbances_intensity){
      color = "red"
    }

    wakeUpDuration = wakeUpDuration / 60;
    durationToSleep = durationToSleep / 60;
    const sleepPercentageBar = (<PercentageBar title="Svefnstig" percentage={sleepScore}/>)
    const breathingDisturbancePercentageBar = (<Circle 
      responsive={false} // Boolean: Make SVG adapt to parent size
      size={110} // Number: Defines the size of the circle.
      lineWidth={14} // Number: Defines the thickness of the circle's stroke. 
      progress={breathing_disturbances_intensity} // Number: Update to change the progress and percentage.
      progressColor={color}  // String: Color of "progress" portion of circle.
      bgColor="whitesmoke" // String: Color of "empty" portion of circle.
      textColor= {color}// String: Color of percentage text color.
      textStyle={{ 
        font: 'bold 5rem Helvetica, Arial, sans-serif', // CSSProperties: Custom styling for percentage.
      }}
      percentSpacing={10} // Number: Adjust spacing of "%" symbol and number.
      roundedStroke={true} // Boolean: Rounded/Flat line ends
      showPercentage={true} // Boolean: Show/hide percentage.
      showPercentageSymbol={false} // Boolean: Show/hide only the "%" symbol.
      />)

    return (
      <>
        {totalTimeAsleep != "" && (
          <div className={styles.activities}>
            {/*<Grid>
              <div className={styles.title}>{this.props.day}</div>
            </Grid>*/}

            <div className={styles.align}>
              <div className={styles.cardContainer}>
                <CardTitleValue title="Heildarsvefn:" value={totalTimeAsleep}/>
                {sleepPercentageBar}
                <CardTitleValueLarge title="Öndunartruflanir:" value={breathingDisturbancePercentageBar}/>  
              </div>
              
              <div className={styles.cardContainer}>
                <CardTitleValue title="Tíminn sem tók að sofna:" value={durationToSleep + " mín"}/>
                <CardTitleValue title="Vaknaði:" value={wakeUpCount}/>
                <CardTitleValue title="Tími vakandi:" value={wakeUpDuration + " mín"}/>  
              </div>
              <div className={styles.cardContainer}>
                <CardTitleValue title= "Tíminn sem tók að vakna:" value={durationToWakeup}/>  
                <CardTitleValue title= "Hrotutími:" value={snoring}/> 
                <CardTitleValue title= "Tíðni hrotu lota:" value={snoringepisodecount}/>     
                
              </div>
            </div>

            <CustomizedSnackbars
              variant={this.state.snackbarVariant}
              message={this.state.snackbarMessage}
              open={this.state.snackbarOpen}
              closeSnackbar={this.closeSnackbar}
            />
          </div>
        )}
        {totalTimeAsleep == "" && (
          <p>Engin gögn til staðar.</p>
        )}
      </>
    );
  }
}

function totalSleepDuration(startTime, endTime) {
  let timeSlept = 0;
  if (startTime < 0) {
    timeSlept = startTime * -1 + endTime;
  } else {
    timeSlept = endTime - startTime;
  }

  return timeSlept;
}

function handleErrors(response) {
  if (!response.ok) {
    this.setState({ error: true });
  }
  return response;
}

const mapStateToProps = (store) => {
  if (store.auth.athlete) {
    var athlete = store.auth.athlete;
  } else {
    var athlete = store.router.location.state;
  }
  return {
    userType: store.auth.userType,
    athlete: athlete,
  };
};

export default connect(mapStateToProps, null)(AthleteChartSleepDetails);
