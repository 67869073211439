import React from "react";
import { Link } from "react-router-dom";

import { MenuItem } from "@material-ui/core";

const LinkMenuItem = ({ link, title, close }) => {
  return (
    <MenuItem component={Link} to={link} onClick={() => close()}>
      {" "}
      {title}{" "}
    </MenuItem>
  );
};

export default LinkMenuItem;
