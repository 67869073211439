import React, { Component } from "react";
import { connect } from "react-redux";
import { ResponsiveBar } from "@nivo/bar";
import { CircularProgress} from "@material-ui/core";
import CustomizedSnackbars from "../../../UI/CustomizedSnackbars";
import styles from "./WorkoutBars.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {dayNameFromNumber, dateToIcelandicString, subtractDays} from "../../../../utils";

import moment from "moment";

class AthleteChartWorkoutBars extends Component {
  constructor(props) {
    super(props);
    let day = this.props.date;
    let week = [
      dateToIcelandicString(subtractDays(day, 6)),
      dateToIcelandicString(subtractDays(day, 5)),
      dateToIcelandicString(subtractDays(day, 4)),
      dateToIcelandicString(subtractDays(day, 3)),
      dateToIcelandicString(subtractDays(day, 2)),
      dateToIcelandicString(subtractDays(day, 1)),
      dateToIcelandicString(day)
    ];
    let weekdays = [
      dayNameFromNumber(subtractDays(day, 6).getDay()),
      dayNameFromNumber(subtractDays(day, 5).getDay()),
      dayNameFromNumber(subtractDays(day, 4).getDay()),
      dayNameFromNumber(subtractDays(day, 3).getDay()),
      dayNameFromNumber(subtractDays(day, 2).getDay()),
      dayNameFromNumber(subtractDays(day, 1).getDay()),
      dayNameFromNumber(day.getDay()),
    ];

    this.state = {
      snackbarOpen: false,
      snackbarMessage: "INITAL",
      snackbarVariant: "info",
      error: false,
      loaded: false,
      week: week,
      weekdays: weekdays,
      data: [],
    };
    this.updateData()
  }

  componentDidUpdate(prevProps) {
    if (this.props.date != prevProps.date) {
      this.setState({
        loaded: false,
        error: false,
        data: [],
      });
      this.updateData()
    }
  }

  updateData = () => {
    this.counter = 0;
    let day = this.props.date;
    var sixBefore = subtractDays(day, 6).toISOString().substring(0, 10);
    let week = [
      dateToIcelandicString(subtractDays(day, 6)),
      dateToIcelandicString(subtractDays(day, 5)),
      dateToIcelandicString(subtractDays(day, 4)),
      dateToIcelandicString(subtractDays(day, 3)),
      dateToIcelandicString(subtractDays(day, 2)),
      dateToIcelandicString(subtractDays(day, 1)),
      dateToIcelandicString(day)
    ];
    let weekdays = [
      dayNameFromNumber(subtractDays(day, 6).getDay()),
      dayNameFromNumber(subtractDays(day, 5).getDay()),
      dayNameFromNumber(subtractDays(day, 4).getDay()),
      dayNameFromNumber(subtractDays(day, 3).getDay()),
      dayNameFromNumber(subtractDays(day, 2).getDay()),
      dayNameFromNumber(subtractDays(day, 1).getDay()),
      dayNameFromNumber(day.getDay()),
    ];
    this.setState({
      week: week,
      weekdays: weekdays
    });

    const id = this.props.athleteId;
    if (id!==0 && id!==undefined) {
      const queryString = "?athlete=" + this.props.athleteId;
      fetch(
        process.env.REACT_APP_API +
          "api/measurements/" +
          queryString +
          "&type__name=WithingsWorkout&date_of_measurement__gte=" + sixBefore +"&date_of_measurement__lte=" + day.toISOString().substring(0, 10),
        {
          method: "GET",
          headers: {
            Authorization: `JWT ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      )
        .then(handleErrors)
        .then((res) => res.json())
        .then(
          (result) => {
            var weekdaysWithData = new Map();
            var i;
            for (i = 0; i < this.state.weekdays.length; i++) {
              weekdaysWithData.set(this.state.weekdays[i], {
                weekday: this.state.weekdays[i],
                [this.counter]: 0,
              });
              this.counter++;
            }
            for (i = 0; i < result.results.length; i++) {
              let date = new Date(result.results[i].date_of_measurement);
              let workout = result.results[i].data;
              var lengthInHours = (workout.enddate - workout.startdate) / 60;
              var predefined = weekdaysWithData.get(
                dayNameFromNumber(date.getDay())
              );
              var combined = {
                ...predefined,
                ...{ [this.counter]: lengthInHours },
              };
              this.counter++;
              weekdaysWithData.set(
                dayNameFromNumber(date.getDay()),
                combined
              );
            }
            let data = []
            if (result.results.length > 0) {
              data = Array.from(weekdaysWithData.values())
            }
            this.setState({
              loaded: true,
              data: data,
            });
          },
          (error) => {
            this.setState({
              error: true,
              snackbarOpen: true,
              snackbarMessage:
                "Það kom upp villa við að sækja upplýsingar um æfingar.",
              snackbarVariant: "error",
            });
          }
        );
    }
  }

  closeSnackbar = () => {
    this.setState({
      snackbarOpen: false,
    });
  };

  render() {
    const data = this.state.data;
    var bar = <CircularProgress />;
    if (this.state.data.length!==0) {
      bar = (
        <div className={styles.bars}>
            <ResponsiveBar 
            innerPadding={5}
            barComponent={CustomBarComponent}
            data={data}
            keys={[
              1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29
            ]}
            indexBy="weekday"
            margin={{ top: 50, right: 60, bottom: 50, left: 60 }}
            padding={0.3}
            enableGridY={true}
            gridYValues={[
              15,
              30,
              45,
              60,
              75,
              90,
              105,
              120,
              135,
              150,
              165,
              180,
              195,
              210,
              225,
              240,
              255,
              270,
              285,
              300,
              315,
              330,
              345,
              360,
              375,
              390,
              405,
              420,
              435,
              450,
              465,
              480,
              495,
              510,
            ]}
            borderColor={{ from: "color" }}
            axisBottom={{
              tickSize: 0.0001,
              tickPadding: 5,
              tickRotation: 0,
              legendPosition: "middle",
              legendOffset: 32,
            }}
            axisLeft={{
              tickSize: 0.0001,
              tickPadding: 5,
              tickRotation: 0,
              legend: "mín",
              legendPosition: "middle",
              legendOffset: -40,
            }}
            labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
            animate={true}
            motionStiffness={90}
            motionDamping={15}
            isInteractive={true}
          />
        </div>
        
      );
    }
    if (this.state.loaded && this.state.data.length==0){
      bar = (<p>Engin gögn til staðar</p>);
    }
    return (
      <div>
        {this.state.week !== undefined && (
          <div>
            Vikan: {this.state.week[0]} - {this.state.week[6]}
          </div>
        )}
        {bar}
        <CustomizedSnackbars
          variant={this.state.snackbarVariant}
          message={this.state.snackbarMessage}
          open={this.state.snackbarOpen}
          closeSnackbar={this.closeSnackbar}
        />
      </div>
    );
  }
}

const CustomBarComponent = ({ x, y, width, height, color }) => (
  <svg>
    <rect
      x={x}
      y={y}
      rx={width / 4}
      ry={width / 4}
      width={width}
      height={height}
      fill={color}
    />
    <FontAwesomeIcon
      icon="running"
      x={x + width / 6}
      y={y + height / 6}
      width={(width * 2) / 3}
      height={(height * 2) / 3}
      color="white"
    />
  </svg>
);

function handleErrors(response) {
  if (!response.ok) {
    this.setState({ error: true });
  }
  return response;
}

const mapStateToProps = (store) => {
  if (store.auth.athlete) {
    var athlete = store.auth.athlete;
  } else {
    var athlete = store.router.location.state;
  }
  return {
    userType: store.auth.userType,
    userId: store.auth.id,
    athlete: athlete,
  };
};

export default connect(mapStateToProps, null)(AthleteChartWorkoutBars);
