import React, { Component } from "react";
import { Paper } from "@material-ui/core";
import styles from "./MeasurementsFiles.module.css";
import { connect } from "react-redux";
import MeasurementsFileItem from '../Item/Item'
import CustomizedSnackbars from '../../UI/CustomizedSnackbars'

class MeasurementsFiles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      snackbarOpen: false,
      snackbarMessage: "INITAL",
      snackbarVariant: "info",
      error: false,
      loaded: false,
      measurementsFiles: []
    };
  }

  componentDidMount() {
    if(!this.state.loaded) {
      this.updateFiles();
    }
  }

  componentDidUpdate() {
    if(this.props.updateFiles) {
      this.updateFiles();
      this.props.filesUpdated()
    }
  }

  closeSnackbar = () => {
    this.setState({
      snackbarOpen: false
    });
  }

  updateFiles = () => {
    fetch(process.env.REACT_APP_API + `api/measurementsfiles/?category=${this.props.category}&ordering=-timestamp`, {
      method: "GET",
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`,
        "Content-Type": "application/json"
      },
    })
    .then(handleErrors)
    .then(res => res.json())
    .then(
      (result) => {
        this.setState({
          loaded: true,
          measurementsFiles: result.results
        });
      },
      (error) => {
        this.setState({
          error: true,
          snackbarOpen: true,
          snackbarMessage: 'Það kom upp villa við að sækja skjöl.',
          snackbarVariant: 'error'
        });
      }
    )
  }

  render() {
    const files = this.state.measurementsFiles 

    return (
        <Paper className={styles.box}>
          <div className={styles.files}>
            { files.map(file => 
              <MeasurementsFileItem key={"rendFile" + file.id} measurementsFile= {file} updateFiles={this.updateFiles}/>
            ) }
          </div>
          <CustomizedSnackbars variant={this.state.snackbarVariant} message={this.state.snackbarMessage} open={this.state.snackbarOpen} closeSnackbar={this.closeSnackbar}/>
        </Paper>
    );
  }

}

function handleErrors(response) {
  if (!response.ok) {
    this.setState({       error: true     });
  }
  return response;
}

const mapStateToProps = store => {
  return {
    
  };
};

export default connect(
  mapStateToProps,
  null
)(MeasurementsFiles);
