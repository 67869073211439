import React, { useState, useEffect } from "react";
import CustomizedSnackbars from "../../../components/UI/CustomizedSnackbars";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  Avatar,
  CircularProgress,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import styles from "./Coaches.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { noValue } from "../../../utils";

const ResearcherCoaches = (props) => {
  const [Coaches, setCoaches] = useState([]);
  const [error, setError] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [offset, setOffset] = useState(0); // for offset and limit
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("INITIAL");
  const [snackbarVariant, setSnackbarVariant] = useState("info");

  useEffect(() => {
    fetch(
      process.env.REACT_APP_API +
        "api/coacheswithteams/?limit=20&offset=" +
        offset +
        "&ordering=last_name&ordering=first_name",
      {
        method: "GET",
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then(handleErrors)
      .then((res) => res.json())
      .then(
        (result) => {
          setCoaches(result.results);
          setLoaded(true);
          setPageCount(Math.ceil(result.count / 20));
        },
        (error) => {
          setError(true);
          setLoaded(true);
        }
      );
  }, [offset, loaded]);

  useEffect(() => {
    if (error === true) {
      setSnackbarOpen(true);
      setSnackbarMessage("Villa kom upp við að sækja leiðbeinanda.");
      setSnackbarVariant("error");
      setError(false);
    }
  }, [error]);

  const closeSnackbar = () => {
    setSnackbarOpen(false);
  };

  function handleErrors(response) {
    if (!response.ok) {
      setError(true);
    }
    return response;
  }

  const handlePageChange = (value) => {
    let pageOffset = (value - 1) * 20;
    setOffset(pageOffset);
  };

  let snackbar = (
    <CustomizedSnackbars
      variant={snackbarVariant}
      message={snackbarMessage}
      open={snackbarOpen}
      closeSnackbar={closeSnackbar}
    />
  );

  let CoachesList = <CircularProgress />;
  if (loaded) {
    if (Coaches.length > 0) {
      CoachesList = Coaches.sort((a, b) =>
        a.last_name.localeCompare(b.last_name)
      )
        .sort((a, b) => a.first_name.localeCompare(b.first_name))
        .map((coach, index) => {
          let { first_name, last_name, email, about, teams } = coach;
          let teamsList = teams.map((x) => x.team__name);
          teams = teamsList.join(", ");
          let pic = coach.profile_picture;
          if (noValue(pic)) {
            pic = first_name; // Make the profile pic be the first letter in the Coaches name
          }
          let has_logged_in = coach.user.has_logged_in;
          let mailto = "mailto: " + email;

          return (
            <>
              <TableRow key={"Coaches" + index}>
                <TableCell component="td" scope="row">
                  <div className={styles.row}>
                    <Link
                      to={{
                        pathname: `/adviser/${coach.id}/`,
                      }}
                    >
                      <div className={styles.avatar}>
                        <Avatar
                          src={pic}
                          alt={`${first_name} ${last_name}`}
                          className={styles.avatar}
                        />
                      </div>
                      {`${first_name} ${last_name}`}
                    </Link>
                  </div>
                </TableCell>
                <TableCell>
                  {" "}
                  <a className="smallLink" href={mailto}>
                    {email}
                  </a>
                </TableCell>
                <TableCell> {about}</TableCell>
                <TableCell> {teams}</TableCell>
                <TableCell>
                  {has_logged_in === true ? (
                    <FontAwesomeIcon
                      icon="check"
                      className={styles.primaryColor}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon="times"
                      style={{
                        color: "red",
                      }}
                    />
                  )}
                </TableCell>
              </TableRow>
            </>
          );
        });
    } else {
      return (
        <Paper>
          <TableRow>
            <TableCell component="th" scope="row">
              Enginn meðferðaraðili fannst.
            </TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell className={styles.show}></TableCell>
          </TableRow>
        </Paper>
      );
    }
  }

  return (
    <div>
      {snackbar}
      <Paper>
        <h2 className={styles.title}>Meðferðaraðilar</h2>
        <Table className={styles.table}>
          <TableHead>
            <TableRow>
              <TableCell>Nafn</TableCell>
              <TableCell className={styles.show}>Netfang</TableCell>
              <TableCell className={styles.show}>Um</TableCell>
              <TableCell className={styles.show}>Hópar</TableCell>
              <TableCell className={styles.show}>Virkur</TableCell>
            </TableRow>
          </TableHead>
          {CoachesList}
        </Table>
        <div className={styles.pagination}>
          <div>
            <Pagination
              count={pageCount}
              variant="outlined"
              shape="rounded"
              onChange={(e, v) => handlePageChange(v)}
            />
          </div>
        </div>
      </Paper>
    </div>
  );
};

const mapStateToProps = (store) => {
  return {
    userType: store.auth.userType,
  };
};

export default connect(mapStateToProps)(ResearcherCoaches);
