// import React from "react";
// import { Link } from "react-router-dom";

// import { Grid, Card } from "@material-ui/core";
// import styles from "./Players.module.css";

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import CustomizedSnackbars from "../../../components/UI/CustomizedSnackbars";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Avatar,
  CircularProgress,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import styles from "./Players.module.css";
import { noValue } from "../../../utils";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";

const Players = (props) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("INITIAL");
  const [snackbarVariant, setSnackbarVariant] = useState("info");
  const [error, setError] = useState(false);
  const [team, setTeam] = useState([]);
  const [teamPlayers, setTeamPlayers] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [id, setId] = useState(props.id); // team's id, depending on wether the user came from /teams page or Team Settings
  const [offset, setOffset] = useState(0); // for offset and limit
  const [pageCount, setPageCount] = useState(1); // total page count for athlete list
  const [type, setType] = useState(" ");

  useEffect(() => {
    getTeamPlayers(offset);
    getTeamFromUrl().then((result) => {
      setId(result.id);
      setTeam(result);
      getTeamPlayers(offset);
    });
  }, [id, offset, type]);

  const handlePageChange = (value) => {
    let pageOffset = (value - 1) * 20;
    setOffset(pageOffset);
  };

  //   const closeSnackbar = () => {
  //     setSnackbarOpen(false);
  //   };

  function handleErrors(response) {
    if (!response.ok) {
      setError(true);
    }
    return response;
  }

  //   const handleChange = (event) => {
  //     setValueName(event.target.value);
  //   };

  const onKeyPress = (event) => {
    if (event.key === "Enter") {
      searchForTeamPlayers(event.target.value);
    }
  };

  function searchForTeamPlayers(letter) {
    if ((letter.length > 0) & (id > 0)) {
      fetch(
        process.env.REACT_APP_API +
          "api/athleteinteams/?limit=20&search=" +
          letter +
          "&ordering=athlete__last_name&ordering=athlete__first_name&team=" +
          id,
        {
          method: "GET",
          headers: {
            Authorization: `JWT ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      )
        .then(handleErrors)
        .then((res) => res.json())
        .then(
          (result) => {
            setTeamPlayers(result.results);
            setLoaded(true);
            setPageCount(Math.ceil(result.count / 20));
          },
          (error) => {
            setError(true);
            setSnackbarOpen(true);
            setSnackbarMessage("Villa kom upp við að sækja einstaklinga");
            setSnackbarVariant("error");
          }
        );
    } else {
      getTeamPlayers(offset);
    }
  }

  function getTeamPlayers(offset) {
    if (id > 0) {
      fetch(
        process.env.REACT_APP_API +
          "api/athleteinteams/?limit=20&offset=" +
          offset +
          "&ordering=athlete__last_name&ordering=athlete__first_name&team=" +
          id,
        {
          method: "GET",
          headers: {
            Authorization: `JWT ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      )
        .then(handleErrors)
        .then((res) => res.json())
        .then(
          (result) => {
            setTeamPlayers(result.results);
            setLoaded(true);
            setPageCount(Math.ceil(result.count / 20));
          },
          (error) => {
            setError(true);
            setSnackbarOpen(true);
            setSnackbarMessage(
              "Villa kom upp við að sækja einstaklinga fyrir hóp."
            );
            setSnackbarVariant("error");
          }
        );
    }
  }

  function handleErrors(response) {
    if (!response.ok) {
      setError(true);
    }
    return response;
  }

  function getTeamFromUrl() {
    var parts = window.location.href.split("/");
    let teamId = parts.pop() || parts.pop(); // handle potential trailing slash
    return fetch(process.env.REACT_APP_API + "api/teams/" + teamId + "/", {
      method: "GET",
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          return result;
        },
        (error) => {}
      );
  }

  const { name, team_logo } = team;
  let club = (
    <TableRow>
      <TableCell component="th" scope="row">
        Enginn einstaklingur fannst í þessum hóp.
      </TableCell>
    </TableRow>
  );
  if (loaded) {
    if (teamPlayers[0] && teamPlayers[0].athlete) {
      club = teamPlayers
        .sort((a, b) => a.athlete.last_name.localeCompare(b.athlete.last_name))
        .sort((a, b) =>
          a.athlete.first_name.localeCompare(b.athlete.first_name)
        )
        .map((player, index) => {
          let profile_picture = null;
          profile_picture = player.athlete.profile_picture;
          const { first_name, last_name } = player.athlete;
          const { date_of_birth } = player.athlete;
          let year_of_birth = "";
          if (date_of_birth !== null) {
            year_of_birth = date_of_birth.substr(0, date_of_birth.indexOf("-"));
          }

          let pic = profile_picture;
          if (noValue(pic)) {
            pic = first_name;
          }
          return (
            <>
              <TableRow key={"team" + index}>
                <TableCell component="td" scope="row">
                  <div className={styles.row}>
                    <Link
                      to={{
                        pathname: `/user/${player.athlete.id}/`,
                      }}
                    >
                      <div className={styles.avatar}>
                        <Avatar
                          src={pic}
                          alt={`${first_name} ${last_name}`}
                          className={styles.avatar}
                        />
                      </div>
                      {`${first_name} ${last_name}`}
                    </Link>
                  </div>
                </TableCell>
                <TableCell className={styles.show}>{year_of_birth}</TableCell>
              </TableRow>
            </>
          );
        });
    }
  } else {
    club = <CircularProgress />;
  }

  return (
    <>
      <Paper className={styles.team}>
        <div className={styles.header}>
          {name}
          <img src={team_logo} alt="" className={styles.team_logo} />
        </div>
        <div className={styles.search_box}>
          <TextField
            id="outlined-search_input"
            aria-label="Search"
            label={
              <div>
                <SearchIcon className={styles.searchIcon} />
                <p>Leita</p>
              </div>
            }
            type="search"
            name="search"
            //className="form-control form-control-sm ml-3 w-75"
            autoComplete="search"
            maxLength="100"
            variant="outlined"
            fullWidth="true"
            size="small"
            className={styles.textField}
            onKeyPress={onKeyPress}
          />
        </div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nafn</TableCell>
              <TableCell className={styles.show}>Fæðingarár</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{club}</TableBody>
        </Table>
      </Paper>
      <div className={styles.pagination}>
        <Pagination
          count={pageCount}
          variant="outlined"
          shape="rounded"
          onChange={(e, v) => handlePageChange(v)}
        />
      </div>
    </>
  );
};

export default Players;
