import React, { Component } from "react";
import { connect } from "react-redux";

import ReactApexChart from "react-apexcharts";

import styles from "./MeasurementGraph.module.css";
import CustomizedSnackbars from "../../../UI/CustomizedSnackbars";
import { noValue } from "../../../../utils";
import { CircularProgress } from "@material-ui/core";

/* Bad coding style, might need to changa later. Saw it done this way for an example with another graph */
let globalOriginArray = [];

class AthleteChartMeasurementGraph extends Component {
  constructor(props) {
    super(props);
    let date = new Date();
    let yearAgo = new Date();

    yearAgo.setFullYear(date.getFullYear() - 1);
    date = date.getTime();
    yearAgo = yearAgo.getTime();
    this.state = {
      snackbarOpen: false,
      snackbarMessage: "INITAL",
      snackbarVariant: "info",
      error: false,
      loaded: false,
      options: {
        tooltip: {
          y: {
            formatter: function (value, { seriesIndex, dataPointIndex, w }) {
              return (
                Math.round(value * 100) / 100 +
                " (" +
                globalOriginArray[dataPointIndex] +
                ")"
              );
            },
          },
        },
        chart: {
          type: "line",
          shadow: {
            enabled: false,
            color: "#bbb",
            top: 3,
            left: 2,
            blur: 3,
            opacity: 1,
          },
        },
        stroke: {
          width: 7,
          curve: "smooth",
        },

        xaxis: {
          type: "datetime",
          min: yearAgo,
          max: date,
        },
        title: {
          text: this.props.typeTitle,
          align: "left",
          style: {
            fontSize: "16px",
            color: "#666",
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            gradientToColors: [this.props.gradientColor],
            shadeIntensity: 1,
            type: "horizontal",
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100, 100, 100],
          },
        },
        markers: {
          size: 4,
          opacity: 0.9,
          colors: [this.props.markerColor],
          strokeColor: "#fff",
          strokeWidth: 2,

          hover: {
            size: 7,
          },
        },
        yaxis: {
          decimalsInFloat: 2,
          title: {
            text: this.props.typeTitle,
          },
        },
      },
      series: [
        {
          name: this.props.typeTitle,
          data: [],
        },
      ],
    };
  }

  closeSnackbar = () => {
    this.setState({
      snackbarOpen: false,
    });
  };

  updateMeas = () => {
    let typeName = this.props.type;
    if (typeName === " ") {
      typeName = null;
    }
    const queryString = "?athlete=" + this.props.athleteId;
    fetch(
      process.env.REACT_APP_API +
        "api/measurements/" +
        queryString +
        "&type__name=" +
        typeName +
        "&ordering=-date_of_measurement",
      {
        method: "GET",
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          let data = [];
          var i;
          for (i = 0; i < result.results.length; i++) {
            if (!noValue(result.results[i].origin)) {
              origin = result.results[i].origin.name;
            } else {
              origin = "";
            }
            if (origin === "MeasurementsFile") {
              origin = "Íþróttafræðideild HR";
            }
            globalOriginArray.push(origin);
            // Use data_value if possible
            if (result.results[i].data_value !== null) {
              data.push({
                x: new Date(result.results[i].date_of_measurement).getTime(),
                y: result.results[i].data_value,
              });
            } else {
              data.push({
                x: new Date(result.results[i].date_of_measurement).getTime(),
                y: result.results[i].data,
              });
            }
          }
          this.setState({ loaded: true });
          let series1 = { name: this.props.typeTitle, data: data };
          let seriesAll = [series1];
          this.setState({ series: seriesAll });
        },
        (error) => {
          this.setState({
            error: true,
            snackbarOpen: true,
            snackbarMessage: "Villa kom upp við að sækja mælingar.",
            snackbarVariant: "error",
          });
        }
      );
  };

  componentDidMount() {
    if (this.props.type !== " ") {
      this.updateMeas();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.type !== prevProps.type) {
      this.updateMeas();
    }
  }
  render() {
    return (
      <div id="chart">
        {this.state.loaded && this.state.series[0].data.length > 0 && (
          <ReactApexChart
            options={this.state.options}
            series={this.state.series}
            type="line"
            className={styles.chart}
          />
        )}
        {this.state.loaded && this.state.series[0].data.length === 0 && (
          <p>Engin gögn til staðar.</p>
        )}
        {this.props.type === " " && <p>Engin tegund valin.</p>}
        {!this.state.loaded && this.props.type !== " " && <CircularProgress />}
        <CustomizedSnackbars
          variant={this.state.snackbarVariant}
          message={this.state.snackbarMessage}
          open={this.state.snackbarOpen}
          closeSnackbar={this.closeSnackbar}
        />
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  if (store.auth.athlete) {
    var athlete = store.auth.athlete;
  } else if (store.router.location.state) {
    var athlete = store.router.location.state;
  }
  return {
    userType: store.auth.userType,
    userId: store.auth.id,
    athlete: athlete,
  };
};

export default connect(mapStateToProps, null)(AthleteChartMeasurementGraph);
