import React from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { isLoggedIn, login, resetError } from "../../actions/authActions";
import { Link } from "react-router-dom";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Typography from "@material-ui/core/Typography";
import styles from "./Login.module.css";
import logoGreen from "../../assets/logo_green.svg"; // Tell Webpack this JS file uses this image
import { push } from "connected-react-router";
import MuiAlert from "@material-ui/lab/Alert";
import FrontPageImg from "../../assets/dataWellOverview.jpg";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      userType: "",
      showPassword: false,
      redirect: false,
    };

    this.onClick = this.onClick.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    if (window.location.host.includes("datawell.ru.is")) {
      this.setState({ redirect: true });
    }
    if (this.props.pathname !== "/") {
      this.props.push("/");
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    this.props.resetError();
  }

  onSubmit(e) {
    e.preventDefault();

    const credentials = {
      email: this.state.email.toLowerCase(),
      password: this.state.password,
    };

    this.props.login(credentials);
  }

  onClick() {
    this.setState((state) => ({
      showPassword: !state.showPassword,
    }));
  }

  render() {
    if (this.state.redirect) window.location.replace("https://datawell.is/");
    let errorMessage = this.props.error ? (
      <p
        style={{
          color: "#f44336",
          fontSize: "12px",
          marginLeft: "0",
          minWidth: "100%",
        }}
      >
        Rangt netfang eða lykilorð!
      </p>
    ) : null;

    errorMessage = this.props.serverError ? (
      <p style={{ color: "#f44336" }}>Það kom upp villa hjá netþjóni.</p>
    ) : (
      errorMessage
    );

    if (this.props.loggedIn) {
      return null;
    } else {
      return (
        <div className={styles.backgroundColor}>
          <Grid
            // margintop="20%"
            container
            justify="center"
            marginTop="30%"
            align="center"
            style={{ minHeight: "80vh" }}
          >
            <Grid className={styles.gridIMG}>
              <img
                src={FrontPageImg}
                alt="DataWell"
                className={styles.img}
              ></img>
            </Grid>
            <Grid className={styles.gridLogin}>
              <Typography
                variant="h3"
                style={{ marginBottom: "2%", marginTop: "2%", color: "white" }}
                className="nameTitle"
              >
                DataWell
              </Typography>
              <div className={styles.loginContainer}>
                <Card className={styles.card}>
                  <form onSubmit={this.onSubmit} className={styles.formLogin}>
                    <Typography variant="h5" color="inherit">
                      Innskráning
                    </Typography>
                    <div className={styles.divider} />
                    <FormControl>
                      <TextField
                        name="email"
                        label="Netfang"
                        value={this.state.email}
                        onChange={this.onChange}
                        error={this.props.error}
                      />
                    </FormControl>
                    <div className={styles.divider} />
                    <FormControl>
                      <InputLabel
                        style={{ marginLeft: 20 }}
                        error={this.props.error}
                      >
                        Lykilorð
                      </InputLabel>
                      <Input
                        className={styles.passwordField}
                        type={this.state.showPassword ? "text" : "password"}
                        name="password"
                        value={this.state.password}
                        onChange={this.onChange}
                        error={this.props.error}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="Toggle password visibility"
                              onClick={this.onClick}
                            >
                              {this.state.showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                    <h5>
                      <Link
                        color="primary"
                        to="/forgotpassword"
                        className="smallLink"
                      >
                        Gleymt lykilorð
                      </Link>
                    </h5>
                    {errorMessage}
                    <Button type="submit" variant="contained" color="primary">
                      Skrá inn
                    </Button>
                  </form>
                </Card>
                <Card className={styles.card}>
                  <div>
                    <Typography variant="h5" color="inherit">
                      Nýskráning
                    </Typography>
                    <Link to="/signup">
                      <img src={logoGreen} alt="DataWell" height={100} />
                    </Link>
                    <h5>
                      Smelltu{" "}
                      <Link color="primary" to="/signup" className="smallLink">
                        hér
                      </Link>{" "}
                      eða á logo-ið til þess að stofna nýjan aðgang
                    </h5>
                  </div>
                </Card>
              </div>
            </Grid>
          </Grid>
        </div>
      );
    }
  }
}

Login.propTypes = {
  login: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  loggedIn: state.auth.loggedIn,
  error: state.auth.error,
  serverError: state.auth.serverError,
  pathname: state.router.location.pathname,
  url: state.router.location,
});

export default connect(mapStateToProps, {
  isLoggedIn,
  login,
  resetError,
  push,
})(Login);
