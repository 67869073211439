import React, { useState, useEffect } from "react";
import styles from "./AddEmailForm.module.css";
import Button from "@material-ui/core/Button";
import CustomizedSnackbars from "../../UI/CustomizedSnackbars";

const AthleteAddEmailForm = (props) => {
  const { changeUserType, id } = props;
  const [email, setEmail] = useState("");
  const [emailRepeat, setEmailRepeat] = useState("");

  const [error, setError] = useState(false);

  // Messages
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("INITIAL");
  const [snackbarVariant, setSnackbarVariant] = useState("info");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handleEmailRepeatChange = (e) => {
    setEmailRepeat(e.target.value);
  };

  const closeSnackbar = () => {
    setSnackbarOpen(false);
  };

  const createUser = () => {
    const data = {
      email: email.toLowerCase(),
      email_repeat: emailRepeat.toLowerCase(),
    };

    var formData = new FormData();
    for (let name in data) {
      formData.append(name, data[name]);
    }

    fetch(
      process.env.REACT_APP_API + "api/" + changeUserType + "s/" + id + "/",
      {
        method: "PATCH",
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          if (this.props.handleAdd !== undefined) {
            this.props.handleAdd();
          }
        },
        (error) => {
          setError(true);
          setSnackbarOpen(true);
          setSnackbarMessage("Ekki tókst að breyta netfangi!");
          setSnackbarVariant("error");
        }
      );
  };

  let snackbar = (
    <CustomizedSnackbars
      variant={snackbarVariant}
      message={snackbarMessage}
      open={snackbarOpen}
      closeSnackbar={closeSnackbar}
    />
  );

  return (
    <>
      <div className={styles.container}>
        {snackbar}
        <form
          id="signUpForm"
          className={styles.signInForm}
          onSubmit={(e) => createUser(e)}
        >
          <div className={styles.inputField}>
            <label className="label" htmlFor="email">
              Netfang
            </label>
            <input
              type="email"
              id="email"
              onChange={(e) => handleEmailChange(e)}
              required
            />
          </div>
          <div className={styles.inputField}>
            <label className="label" htmlFor="emailRepeat">
              Staðfestu netfang
            </label>
            <input
              type="email"
              id="emailRepeat"
              onChange={(e) => handleEmailRepeatChange(e)}
              required
            />
          </div>

          <Button type="submit" variant="contained" color="primary">
            Breyta{" "}
          </Button>
        </form>
      </div>
    </>
  );
};

export default AthleteAddEmailForm;
