import React, { useState } from "react";

import { Button, Paper } from "@material-ui/core";
import styles from "./CreateTeam.module.css";
import { connect } from "react-redux";
import { DropzoneArea } from "material-ui-dropzone";
import axios from "axios";
import CustomizedSnackbars from "../../UI/CustomizedSnackbars";

import { TextField } from "@material-ui/core";

var dropzoneKey = 0;

const CreateTeam = (props) => {
  const [nameEmpty, setNameEmpty] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnakcbarMessage] = useState("INITAL");
  const [snackbarVariant, setSnackbarVariant] = useState("info");
  const [accept, setAccept] = useState(".csv, .xlsx, .xls"); // unused
  const [logo, setLogo] = useState([]);
  const [name, setName] = useState("");
  const [teams, setTeams] = useState([]); // unused
  const [isNationalTeam, setIsNationalTeam] = useState(false);
  const [isYouthTeam, setIsYouthTeam] = useState(false);

  const handleCheckboxChange = (event) => {
    const target = event.target;
    const checked = target.checked;
    const name = target.name;

    if (name === "isNationalTeam") {
      setIsNationalTeam(checked);
    } else if (name === "isYouthTeam") {
      setIsYouthTeam(checked);
    }
  };

  const handleSubmit = () => {
    let form_data = new FormData();
    form_data.append("name", name);
    if (logo.length === 1) {
      form_data.append("team_logo", logo[0]);
    }
    form_data.append("national_team", isNationalTeam);
    form_data.append("youth_team", isYouthTeam);

    let config = {
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    };

    axios
      .post(process.env.REACT_APP_API + "api/teams/", form_data, config)
      .then((response) => {
        dropzoneKey++;
        props.onNewTeamAdded();
        setLogo([]);
        setName("");
        setSnackbarOpen(true);
        setSnackbarVariant("success");
        setSnakcbarMessage("Hópur var stofnaður");
      })
      .catch((error) => {
        setSnackbarOpen(true);
        setSnakcbarMessage(
          "Villa kom upp við að stofna hóp, vinsamlegast reynið aftur."
        );
        setSnackbarVariant("error");
      });
  };

  const handleFileChange = (files) => {
    setLogo(files);
  };

  const handleNameChange = (event) => {
    if (event.target.value!=="") {
      setName(event.target.value);
      setNameEmpty(false);
    } else {
      setName(event.target.value);
      setNameEmpty(true);
    }
  };

  const closeSnackbar = () => {
    setSnackbarOpen(false);
  };

  const Dropzone = (
    <DropzoneArea
      key={dropzoneKey}
      onChange={(f) => handleFileChange(f)}
      filesLimit={1}
      acceptedFiles={["image/jpeg", "image/bmp", "image/png"]}
      dropzoneText={"Dragið lógo hóps hingað eða smellið á kassann."}
      showPreviewsInDropzone={true}
      showAlerts={false}
      showFileNames={true}
    />
  );

  return (
    <div>
      <Paper className={styles.box}>
        <div className={styles.allContent}>
          <h2 className={styles.title}>Stofna hóp</h2>
          <div className={styles.inputs}>{Dropzone}</div>
          <div className={styles.inputs}>
            <div className={styles.input}>
              <TextField
                id="name_input"
                label="Nafn á hóp"
                onChange={(e) => handleNameChange(e)}
                value={name}
                maxLength="100"
              />
            </div>
            <div className={styles.input}>
              <label shrink>Landslið</label>
              <input
                name="isNationalTeam"
                type="checkbox"
                checked={isNationalTeam}
                onChange={(e) => handleCheckboxChange(e)}
              />
            </div>
            <div className={styles.inputs}>
              <label shrink>Ungmenna flokkur</label>
              <input
                name="isYouthTeam"
                type="checkbox"
                checked={isYouthTeam}
                onChange={(e) => handleCheckboxChange(e)}
              />
            </div>
            <div className={styles.button}>
              <Button
                onClick={() => handleSubmit()}
                variant="contained"
                color="primary"
                disabled={nameEmpty}
              >
                Stofna
              </Button>
              <CustomizedSnackbars
                variant={snackbarVariant}
                message={snackbarMessage}
                open={snackbarOpen}
                closeSnackbar={() => closeSnackbar()}
              />
            </div>
          </div>
        </div>
      </Paper>
    </div>
  );
};

const mapStateToProps = (store) => {
  return {};
};

export default connect(mapStateToProps)(CreateTeam);
