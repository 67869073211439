import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Typography,
} from "@material-ui/core";
import AthleteChartMeasurementsTable from "../../../components/Athlete/Chart/MeasurementsTable/MeasurementsTable";
import styles from "./Psychology.module.css";
import AthleteChartMeasurementGraph from "../../../components/Athlete/Chart/MeasurementGraph/MeasurementGraph"
import TypeSelect from "../../../components/UI/TypeSelect";
import AthleteInfo from "../../../components/Athlete/Info/Info"
import AthleteDataCategories from "../../../components/Athlete/DataCategories/DataCategories";
import DataTab from "../../../components/UI/DataTab/DataTab";

const AthletePsychology = (props) => {
  let athleteId = null;
  if (props.userType!=="athlete") {
    athleteId = props.match.params.id;
  } else {
    athleteId = props.athlete.id;
  }
  const [type, setType] = useState(" ");
  
  return (
    <Typography component="div">
      <AthleteInfo/>
      <AthleteDataCategories/>
      <DataTab icon="seedling" color="#6DA58A" text="Sálfræðipróf">
          <AthleteChartMeasurementsTable athleteId={athleteId} category="PSYCHOLOGICAL" color="#9ab5a8" colors={["rgba(72, 74, 72)", "rgba(2, 201, 148)", "rgba(60, 98, 105)"]}  />
      </DataTab>
      <DataTab text="Stakar niðurstöður" color="#4d7d7b" icon="chart-line">
        <div className="fullBases">
          <TypeSelect category="PSYCHOLOGICAL" setType={setType}/>
        </div>
        <AthleteChartMeasurementGraph athleteId={athleteId} type={type} typeTitle={type} gradientColor="#6DA58A" markerColor="#4d7d7b"/>
      </DataTab>
      {/* Waiting for data from app, add here once ready
      <DataTab text="Hvernig líður mér í dag?" icon="" color="#4d7d7b" icon="tasks">
        ADD HERE
      </DataTab>*/}

    </Typography>
  );
};

const mapStateToProps = (store) => {
  if (store.auth.athlete) {
    var athlete = store.auth.athlete;
  }
  return {
    userType: store.auth.userType,
    athlete: athlete,
  };
};

export default connect(mapStateToProps)(AthletePsychology);
