import React, { Component, Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import ReactTableContainer from "react-table-container";
import Checkbox from "@material-ui/core/Checkbox";
import { ResponsiveRadar } from "@nivo/radar";
import CustomizedSnackbars from "../../../UI/CustomizedSnackbars";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import { withTheme } from "@material-ui/core/styles";
import styles from "./MeasurementsTable.module.css";
import Skeleton from '@material-ui/lab/Skeleton';
import DialogWithButton from "../../../UI/DialogWithButton/DialogWithButton";
import rules from "./SpiderChartRules";
import { noValue, getStatisticsPercentage } from "../../../../utils";

class AthleteChartMeasurementsTable extends Component {
  eventAverage = "Meðaltal mælingar";
  myValue = "Mín gögn";
  teamAverage = "Meðaltal hópsins";
  counter = 0;

  state = {
    mapDataForRadarChart: new Map(),
    allMeasurements: new Map(),
    myValueKey: this.myValue,
    eventAverageKey: this.eventAverage,
    teamAverageKey: this.teamAverage,
    selectedNum: 0,
    selectedTypes: new Map(),
    currEvent: "Nýjustu mælingar",
    snackbarOpen: false,
    snackbarMessage: "INITAL",
    snackbarVariant: "info",
    error: false,
    loaded: false,
    data: [],
    headers: [],
    radarData: [],
  };

  constructor(props) {
    super(props);
    this.getData = this.getData.bind(this);

    this.palette = props.theme.palette;
  }

  componentDidMount() {
    this.getData();
  }

  closeSnackbar = () => {
    this.setState({
      snackbarOpen: false,
    });
  };

  handleClick = (event, name) => {
    let seltype = this.state.selectedTypes;
    if (seltype.get(name)) {
      seltype.set(name, false);
      /* DESELECTING type */
      let radarData = this.state.radarData;
      radarData.forEach(function (item, index) {
        if (item.type === name) {
          radarData.splice(index, 1);
        }
      });
      let newselnum = this.state.selectedNum -1;
      this.setState({
        selectedNum: newselnum,
        radarData: radarData,
        selectedTypes: seltype
      });
    } else {
      /* SELECTING type */
      if (this.state.selectedNum < 6) {
        seltype.set(name, true);
        let radarData = this.state.radarData;
        let data = this.state.mapDataForRadarChart.get(name);
        radarData.push({
          type: name,
          [this.state.teamAverageKey]: data[1],
          [this.state.myValueKey]: data[0],
          [this.state.eventAverageKey]: data[7],
        });
        let newselnum = this.state.selectedNum +1;
        this.setState({
          selectedNum: newselnum,
          selectedTypes: seltype,
          radarData: radarData,
        });
      }
      else {
        this.setState({
          snackbarOpen: true,
          snackbarMessage: "Það er mest hægt að velja 6 mælingar í einu",
          snackbarVariant: "info",
        });
      }
    }
  };

  headerClicked = (event) => {
    let date = event.n.split("\n")[1];
    let dates = [];
    let mapData = new Map();
    let measurementsByTypes = new Map();
    //this.selectedTypes = new Map();
    let radarData = [];
    for (let item of this.state.allMeasurements) {
      let teamName = "";
      if (!noValue(item.event) && !noValue(item.event.team)) {
        teamName = item.event.team.name + "\n";
      }
      dates.push(teamName + item.date_of_measurement);
      if (item.date_of_measurement===date) {
        let dataPercentage = getStatisticsPercentage("data_value", item)
        let averagePercentage = getStatisticsPercentage("team_events_gender_average", item)
        let eventAveragePercentage = getStatisticsPercentage("event_gender_average", item)

        if (!measurementsByTypes.has(item.type.name)) {
          var measurementByDate = new Map();
          measurementByDate.set(
            teamName + item.date_of_measurement,
            Math.round(item.data_value * 1000) / 1000
          );
          measurementsByTypes.set(item.type.name, measurementByDate);
          if (this.state.selectedTypes.get(item.type.name)) {
            let avgkey = this.state.teamAverageKey;
            radarData.push({
              type: item.type.name,
              [avgkey]: averagePercentage,
              [this.state.myValueKey]: dataPercentage,
              [this.state.eventAverageKey]: eventAveragePercentage,
            });
          }
          mapData.set(item.type.name, [
            dataPercentage,
            averagePercentage,
            item.data_value,
            item.team_events_gender_average,
            teamName,
            item.date_of_measurement,
            item.event_gender_average,
            eventAveragePercentage,
            item.event_max,
          ]);
        } else {
            measurementsByTypes.set(
              item.type.name,
              measurementsByTypes
                .get(item.type.name)
                .set(
                  teamName + item.date_of_measurement,
                  Math.round(item.data_value * 1000) / 1000
                )
            );
        }
      }
      for (let key of measurementsByTypes.keys()) {
        measurementsByTypes.set(
          key,
          measurementsByTypes.get(key).set("Tegund", key)
        );
      }

      //let values = Array.from(measurementsByTypes.values());
      this.setState({
        mapDataForRadarChart: mapData,
        radarData: radarData,
        currEvent: event.n
      });
    }
  };

  isItemSelected(type) {
    return this.state.selectedTypes.get(type);
  }

  render() {
    const data = this.state.data;
    const headers = this.state.headers;
    let chartIndex = -1;
    this.counter += 1;
    var radarChart = <Skeleton variant="circle" width={210} height={210}/>
    var table = (<div>
      <Skeleton variant="text" animation="wave" height={70} />
      <Skeleton variant="rect" width={600} height={250} animation="wave" />
    </div>)
    if(this.state.loaded && data.length == 0) {
      radarChart = null;
    }
    if(this.state.loaded && data.length > 0) {
      radarChart = (
        <div id="chart" className={styles.radarChart}>
            <p><b>{this.state.currEvent}</b> <br/> Smellið á dagsetingu/dálk í töflu til að skipta.</p>
            <ResponsiveRadar
            key={this.counter}
            data={this.state.radarData}
            keys={[this.state.teamAverageKey, this.state.myValueKey, this.state.eventAverageKey]}
            indexBy="type"
            maxValue={100}
            margin={{ top: 70, right: 80, bottom: 40, left: 80 }}
            borderColor={{ from: "color" }}
            gridLevels={5}
            gridShape="linear"
            gridLabel={LabelComponent}
            gridLabelOffset={14}
            enableDots={true}
            dotSize={10}
            dotColor={{ from: "color" }}
            dotBorderWidth={2}
            dotBorderColor={{ from: "color" }}
            enableDotLabel={false}
            dotLabel="value"
            dotLabelYOffset={-12}
            colors={this.props.colors}
            fillOpacity={0.35}
            blendMode="multiply"
            animate={true}
            motionStiffness={90}
            motionDamping={15}
            isInteractive={true}
            tooltipFormat={(value, key) => {
              /* Custom tooltip value for radar chart since want to show real values not the percentages used to render.
                 Have to know the index to be able to access the name of the type and thus the values. */
              if(this.state.teamAverageKey == key) chartIndex++;
              if (this.state.radarData[chartIndex] != undefined) {
                if (key === this.state.myValueKey) {
                  let value = this.state.mapDataForRadarChart.get(
                    this.state.radarData[chartIndex].type
                  )[2];
                  return (Math.round(value * 1000) / 1000);
                } else if (key === this.state.teamAverageKey) {
                  if (
                    this.state.mapDataForRadarChart.get(
                      this.state.radarData[chartIndex].type
                    )!==null &&
                    this.state.mapDataForRadarChart.get(
                      this.state.radarData[chartIndex].type
                    )!==undefined
                  ) {
                    let value = this.state.mapDataForRadarChart.get(
                      this.state.radarData[chartIndex].type
                    )[3];
                    return Math.round(value * 1000) / 1000;
                  }
                  return 0;
                } else if (key === this.state.eventAverageKey) {
                  if (
                    this.state.mapDataForRadarChart.get(
                      this.state.radarData[chartIndex].type
                    )!==null &&
                    this.state.mapDataForRadarChart.get(
                      this.state.radarData[chartIndex].type
                    )!==undefined
                  ) {
                    let value = this.state.mapDataForRadarChart.get(
                      this.state.radarData[chartIndex].type
                    )[6];
                    return Math.round(value * 1000) / 1000;
                  }
                }
              }
            }}
            legends={[
              {
                anchor: "top-left",
                direction: "column",
                translateX: -50,
                translateY: -40,
                itemWidth: 80,
                itemHeight: 20,
                itemTextColor: "#999",
                symbolSize: 12,
                symbolShape: "circle",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemTextColor: "#000",
                    },
                  },
                ],
              },
            ]}
          />
          {"  "} Leiðbeiningar: 
          <DialogWithButton
            title="Upplýsingar um mælingar fyrir ratsjáarrit"
            iconName="info-circle"
            content={rules}
          />
        </div>
      );
    }
    var tableWidth = window.innerWidth * 0.6 + "px";
    if (window.innerWidth < 1050) {
      tableWidth = window.innerWidth * 0.93 + "px";
    }
    if(this.state.loaded && data.length == 0) {
      table = <p>Engin gögn til staðar.</p>
    }
    if(this.state.loaded && data.length > 0) {
      table = (
        <div>
            <ReactTableContainer
            customHeader={[TableHead]}
            className={styles.table}
            >
            <Table>
              <TableHead style={{ backgroundColor: this.props.color }}>
                <TableRow>
                  <TableCell className={styles.checkbox}> </TableCell>
                  {headers.map((n) => (
                    <Fragment key={n}>
                      {n != "Tegund" && (
                        <TableCell
                          className={styles.headerTitle}
                          hover
                          onClick={(event) => this.headerClicked({ n })}
                        >
                            {n}
                        </TableCell>
                      )}
                      {n==="Tegund" && (
                        <TableCell
                        >
                            {n}
                        </TableCell>
                      )}
                      
                    </Fragment>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((dataItem) => (
                  <TableRow
                    hover
                    onClick={(event) =>
                      this.handleClick(event, dataItem.get("Tegund"))
                    }
                    role="checkbox"
                    aria-checked={this.isItemSelected(dataItem.get("Tegund"))}
                    tabIndex={-1}
                    selected={this.isItemSelected(dataItem.get("Tegund"))}
                    key={dataItem.get("Tegund")}
                  >
                    <TableCell className={styles.checkbox}>
                      <Checkbox
                        checked={this.isItemSelected(dataItem.get("Tegund"))}
                        inputProps={{ "aria-labelledby": dataItem.get("Tegund") }}
                      />
                    </TableCell>
                    {headers.map((headerItem) => (
                      <Fragment key={dataItem.get("Tegund") + headerItem}>
                        <TableCell>{dataItem.get(headerItem)}</TableCell>
                      </Fragment>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </ReactTableContainer>
        </div>
      );
    }

    return (
      <div className={styles.container}>
        <div className={styles.cont}>
          {/* For all categories except PSYCHOLOGICAL have the table on the left side */}
          {this.props.category != "PSYCHOLOGICAL" && (
            <div>{table}</div>
          )}
          {radarChart}
          {this.props.category==="PSYCHOLOGICAL" && (
            <div>{table}</div>
          )}
        </div>
        <CustomizedSnackbars
          variant={this.state.snackbarVariant}
          message={this.state.snackbarMessage}
          open={this.state.snackbarOpen}
          closeSnackbar={this.closeSnackbar}
        />
      </div>
    );
  }

  setGender(genderRaw) {
    let gender;
    if (genderRaw==="UNKNOWN") {
      gender = ""
    }
    else if (genderRaw==="OTHER") {
      gender = ""
    }
    else if (genderRaw==="MALE") {
      gender = "kk"
    }
    else if (genderRaw==="FEMALE") {
      gender = "kvk"
    }   
    this.setState({
      eventAverageKey: this.eventAverage + " (" + gender + ")",
      teamAverageKey: this.teamAverage + " (" + gender + ")"
    });
  }

  getData() {
    const id = this.props.athleteId;
    if (id!==0) {
      var selNum = 0;
      const queryString = "?athlete=" + this.props.athleteId + "&type__category=" + this.props.category
      fetch(
        process.env.REACT_APP_API +
          "api/measurementswitheventsstatistics/" + queryString + "&origin__name=MeasurementsFile&ordering=type__name,-date_of_measurement&data_value__gt=0",
        {
          method: "GET",
          headers: {
            Authorization: `JWT ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      )
        .then(handleErrors)
        .then((res) => res.json())
        .then(
          (result) => {

            let allMeas = result.results;
            if(allMeas.length > 0) {
              this.setGender(allMeas[0].athlete.gender)
            }
            if (!this.state.error) {
              let dates = [];
              let measurementsByTypes = new Map();
              let mapData = new Map();
              let selectedMap = this.state.selectedTypes;
              let radarData = [];

              result.results.forEach((item, index) => {
                let teamName = "";
                if (!noValue(item.event) && !noValue(item.event.team)) {
                  teamName = item.event.team.name + "\n";
                }
                dates.push(teamName + item.date_of_measurement);
                let dataPercentage = getStatisticsPercentage("data_value", item)
                let averagePercentage = getStatisticsPercentage("team_events_gender_average", item)
                let eventAveragePercentage = getStatisticsPercentage("event_gender_average", item)

                if (
                  !measurementsByTypes.has(item.type.name)
                ) {
                  var measurementByDate = new Map();
                  measurementByDate.set(
                    teamName + item.date_of_measurement,
                    Math.round(item.data_value * 1000) / 1000
                  );

                  measurementsByTypes.set(item.type.name, measurementByDate);
                  if (selNum < 6) {
                    selectedMap.set(item.type.name, true);
                    /* This is the newest values because from API ordered desc by measurement_date */
                    let avgkey = this.state.teamAverageKey;
                    radarData.push({
                      type: item.type.name,
                      [avgkey]: averagePercentage,
                      [this.state.myValueKey]: dataPercentage,
                      [this.state.eventAverageKey]: eventAveragePercentage,
                    });
                    selNum += 1;
                  } else {
                    selectedMap.set(item.type.name, false);
                  }
                  mapData.set(item.type.name, [
                    dataPercentage,
                    averagePercentage,
                    item.data_value,
                    item.team_events_gender_average,
                    teamName,
                    item.date_of_measurement,
                    item.event_gender_average,
                    eventAveragePercentage,
                  ]);
                } else {
                    measurementsByTypes.set(
                      item.type.name,
                      measurementsByTypes
                        .get(item.type.name)
                        .set(
                          teamName + item.date_of_measurement,
                          Math.round(item.data_value * 1000) / 1000
                        )
                    );
                }
              });
              let headerNames = [...new Set(dates)];
              headerNames = headerNames.reverse();
              headerNames = ["Tegund"].concat(headerNames);

              /* Adding the type to make it easier to render as a first column in table*/
              for (let key of measurementsByTypes.keys()) {
                measurementsByTypes.set(
                  key,
                  measurementsByTypes.get(key).set("Tegund", key)
                );
              }

              let values = Array.from(measurementsByTypes.values());
              this.setState({
                mapDataForRadarChart: mapData,
                allMeasurements: allMeas,
                selectedNum: selNum,
                selectedTypes: selectedMap,
                headers: headerNames,
                data: values,
                radarData: radarData,
                loaded: true,
              });
            }
          },
          (error) => {
            this.setState({
              error: true,
              snackbarOpen: true,
              snackbarMessage: "Það kom upp villa við að sækja mælingar",
              snackbarVariant: "error",
            });
          }
        );
    }
  }
}

function handleErrors(response) {
  if (!response.ok) {
    this.setState({
      error: true,
    });
  }
  return response;
}

const LabelComponent = ({ id, anchor }) => (
  <g
    transform={`translate(${
      anchor === "end" ? -65 : anchor === "middle" ? -40 : -15
    }, 0)`}
  >
    <text>{id.substring(0, 10) + "..."}</text>
  </g>
);

const mapStateToProps = (store) => {
  
};

export default withTheme(AthleteChartMeasurementsTable);