import React from 'react';
import styles from './About.module.css'
import {Card, Grid, Typography} from "@material-ui/core";

const About = () => {
    return (
      <div>
      <Grid container justify="center" alignItems="center" align="center">
        <Card className={styles.card}>
          <Typography variant="h5">
            <b>Saga DataWell</b>
          </Typography> 
          <p></p>
            <p className={styles.text}>
            Undanfarin ár hefur Íþróttafræðideildin safnað mælingum um hæfni og frammistöðu íþróttafólks.
              Deildin hafði þó ekki aðgang að sameiginlegum, miðlægum stað til að geyma og greina gögnin og því var Tölvunarfræðideildin fengin til að vinna að því.
              Útbúa þurfti gagnagrunn til þess að halda utan um mælingarnar og framenda til þess að birta mælingarnar. Verkefnið hefur verið í vinnslu í um það bil tvö ár,
              allt frá 2018. Í upphafi var hópur stelpna úr tölvunarfræðinni sem vann sjálfstætt verkefni sem nefndist TD Sport. Þar var aðallega unnið að viðmótshönnun að fyrstu útgáfu af kerfisins á formi heimasíðu.
              Seinna meir komu inn tveir strákar sem unnu að verkefninu um sumarið og á haustönn 2018. Á þeim tíma bættist Hulda Lilja við sem til lengri tíma vann að bakendanum í bland við framenda.
              Fyrstu Withings úrin voru fengin í verkefnið haustið 2018 og Hulda Lilja útfærði þá tenginu frá úrunum í gagnagrunn. Á sama tíma gerðu strákarnir fyrstu uppsetningu að bakenda og framenda.
              Hulda forritaði framenda svo hann næði næstum upp að bakenda virkni og var fyrsta demóið tilbúið í nóvember 2019. Eftir það tók við að styðja sérhæfða aðgangsstýringu eftir notendahópum og sú virkni útfærð að hægt væri að lesa inn Excel skjöl í gagnagrunninn. 
              Í byrjun árs 2020 kom inn BSc hópur sem vann lokaverkefni í tengslum við kerfið. Þau unnu að nánari viðmótshönnun auk þess að 
              notendaprófa hönnunina og rannsaka þarfir og óskir notenda. Það má lesa niðurstöður rannsóknarinnar á <b><a className={styles.link} href="https://skemman.is/handle/1946/35696">Skemmunni</a></b>. 
              Meðfram því vann Hulda Lilja að því að setja upp kerfið á vél frá skólanum til að undirbúa formlega útgáfu kerfisins.
              Sumarið 2020 hlaut verkefnið styrk frá Rannís fyrir áframhaldandi þróun og rannsóknir. Fyrir þann styrk var hægt að ráða þrjá sumarstarfsmenn í þrjá mánuði auk þess sem tveir til viðbótar voru ráðnir sem sumarstarfsmenn á vegum HR.
              Tveir nemendanna sáu um að halda áfram framendaforritun til þess að gera verkefnið tilbúið fyrir fyrstu notendur og aðrir þrír sáu um að útfæra app þar sem notandi getur svarað spurningum varðandi almenna líðan auk þess að 
              fá inn heilsugögn frá fleiri stöðum. Hulda Lilja hélt áfram að byggja upp bakendann, yfirsýn og koma að framenda fram að hausti 2020.
              Anna Sigríður Islind, sem leiðbeinir núverandi teymi, hefur stýrt verkefninu síðan byrjun árs 2019. Einnig koma að verkefninu tveir nemar og einn rannsakandi úr íþróttafræðinni, einn nemi úr sálfræðinni.
              Sálfræðideildin sameinaðist verkefninu í þeim tilgangi að skoða tenginguna á milli sálfræðilegra- og líkamlegra mælinga íþróttafólks.
            </p>
        </Card>
      </Grid>
      </div>
    );
};

export default About;