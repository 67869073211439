import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Typography,
  InputLabel,
} from "@material-ui/core";
import AthleteChartHeartRateLine from "../../../components/Athlete/Chart/HeartRateLine/HeartRateLine";
import styles from "./Activity.module.css";
import AthleteInfo from "../../../components/Athlete/Info/Info"
import AthleteDataCategories from "../../../components/Athlete/DataCategories/DataCategories";
import DataTab from "../../../components/UI/DataTab/DataTab";
import AthleteChartDayActivity from "../../../components/Athlete/Chart/DayActivity/DayActivity";
import AthleteChartStepsBars from "../../../components/Athlete/Chart/StepsBars/StepsBars";
import AthleteChartWorkoutBars from "../../../components/Athlete/Chart/WorkoutBars/WorkoutBars";
import IcelandicDayPicker from "../../../components/UI/IcelandicDayPicker"
import AthleteChartOxygenLevel from "../../../components/Athlete/Chart/OxygenLevelBars/OxygenLevelBar";

const AthleteActivity = (props) => {
  const [activityDay, setActivityDay] = useState(new Date().toISOString().substring(0,10));
  const [heartrateDay, setHeartRateDay] = useState(new Date().toISOString().substring(0,10));
  const [oxygenLevelDay, setOxygenLevelDay] = useState(new Date().toISOString().substring(0,10));
  const [workoutDay, setWorkoutDay] = useState(new Date().toISOString().substring(0,10));

  let athleteId = null;
  if (props.userType!=="athlete") {
    athleteId = props.match.params.id;
  } else {
    athleteId = props.athlete.id;
  }

  return (
    <Typography component="div">
      <AthleteInfo/>
      <AthleteDataCategories/>
      <DataTab icon="running" color="#999265" text="Hreyfing">
          <div className={styles.input}>
            <InputLabel shrink>Velja dagsetningu</InputLabel>
            <IcelandicDayPicker setDay={setActivityDay}/>
          </div>
          <AthleteChartDayActivity athleteId={athleteId} day={activityDay}/>
          <AthleteChartStepsBars
              athleteId={athleteId}
              date={new Date(activityDay)}
            />
      </DataTab>
      <DataTab icon="heartbeat" color="#a15d5d" text="Hjartsláttur">
          <div className={styles.input}>
            <InputLabel shrink>Velja dagsetningu</InputLabel>
            <IcelandicDayPicker setDay={setHeartRateDay}/>
          </div>
          <AthleteChartHeartRateLine
            emptyText="Engin gögn til staðar."
            athleteId={athleteId}
            date={new Date(heartrateDay)}
            dataType="mean"
            title="Meðalhjartsláttur viku aftur í tímann"
          />
          <AthleteChartHeartRateLine
              athleteId={athleteId}
              date={new Date(heartrateDay)}
              dataType="max"
              title="Hámarkshjartsláttur viku aftur í tímann"
            />
          <AthleteChartHeartRateLine
                  athleteId={athleteId}
                  date={new Date(heartrateDay)}
                  dataType="min"
                  title="Lágmarkshjartsláttur viku aftur í tímann"
                />
      </DataTab>

      <DataTab icon="tint" color="#d1a371" text="Súrefnismettun">
          <div className={styles.input}>
            <InputLabel shrink>Velja dagsetningu</InputLabel>
            <IcelandicDayPicker setDay={setOxygenLevelDay}/>
          </div>
          <AthleteChartOxygenLevel
            emptyText="Engin gögn til staðar."
            athleteId={athleteId}
            date={new Date(oxygenLevelDay)}
            dataType="SPO2"
            title="Súrefnismettun viku aftur í tímann"
          />

      </DataTab>
      <DataTab icon="dumbbell" color="#a86c3e" text="Æfingar">
          <div className={styles.input}>
            <InputLabel shrink>Velja dagsetningu</InputLabel>
            <IcelandicDayPicker setDay={setWorkoutDay}/>
          </div>
        <AthleteChartWorkoutBars
          athleteId={athleteId}
          date={new Date(workoutDay)}
        />
      </DataTab>
    </Typography>
  );
};

const mapStateToProps = (store) => {
  if (store.auth.athlete) {
    var athlete = store.auth.athlete;
  }
  return {
    userType: store.auth.userType,
    athlete: athlete,
  };
};

export default connect(mapStateToProps)(AthleteActivity);
