import React, { Component } from "react";
import { connect } from "react-redux";
import CustomizedSnackbars from "../../../UI/CustomizedSnackbars";
import { Grid } from "@material-ui/core";
import ReactApexChart from "react-apexcharts";
import styles from "./StepsBars.module.css";
import { noValue, dayNameFromNumber, subtractDays, dateToIcelandicString } from "../../../../utils";

var colors = ["rgba(191, 85, 236, 1)"];
var dataLabelColor = ["rgba(46, 49, 49, 1)"];
let globalOriginArray = [];

class AthleteChartStepsBars extends Component {
  constructor(props) {
    super(props);

    let day = this.props.date;
    let week = [
      dateToIcelandicString(subtractDays(day, 6)),
      dateToIcelandicString(subtractDays(day, 5)),
      dateToIcelandicString(subtractDays(day, 4)),
      dateToIcelandicString(subtractDays(day, 3)),
      dateToIcelandicString(subtractDays(day, 2)),
      dateToIcelandicString(subtractDays(day, 1)),
      dateToIcelandicString(day)
    ];
    let weekdays = [
      dayNameFromNumber(subtractDays(day, 6).getDay()),
      dayNameFromNumber(subtractDays(day, 5).getDay()),
      dayNameFromNumber(subtractDays(day, 4).getDay()),
      dayNameFromNumber(subtractDays(day, 3).getDay()),
      dayNameFromNumber(subtractDays(day, 2).getDay()),
      dayNameFromNumber(subtractDays(day, 1).getDay()),
      dayNameFromNumber(day.getDay()),
    ];

    this.state = {
      snackbarOpen: false,
      snackbarMessage: "INITAL",
      snackbarVariant: "info",
      error: false,
      loaded: false,
      week: week,
      weekdays: weekdays,
      originArray: [],
      options: {
        plotOptions: {
          bar: {
            endingShape: "rounded",
            distributed: false,
            horizontal: false,
            columnWidth: "40%",
            dataLabels: {
              position: "top",
            },
          },
        },
        tooltip: {
          y: {
            formatter: function (value, { seriesIndex, dataPointIndex, w }) {
              value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
              return (
                value +
                " skref (" +
                getGlobalOriginArray()[dataPointIndex] +
                ")"
              );
            },
          },
        },
        dataLabels: {
          enabled: false,
          formatter: function (val) {
            return val + " skref";
          },
          style: {
            textColor: dataLabelColor,
          },
          offsetY: 10,
        },
        colors: colors,
        fill: {
          colors: colors,
        },
        chart: {
          toolbar: {
            show: false,
          },
          type: "bar",
          dropShadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2,
          },
        },
        title: {
          align: "center",
          position: "top",
          style: {
            fontSize: 16,
          },
        },
        grid: {
          borderColor: "#e7e7e7",
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        xaxis: {
          color: ["black"],
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          tooltip: {
            enabled: true,
          },
          labels: {
            style: {
              fontSize: 14,
            },
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },

          labels: {
            formatter: (value) => {
              return (
                value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") +
                " skref"
              );
            },

            style: {
              fontSize: 14,
            },
          },
        },
        responsive: [
          {
            breakpoint: 10000,
            options: {
              plotOptions: {
                bar: {
                  horizontal: false,
                },
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      series: [
        {
          name: "Skrefafjöldi",
          data: [],
        },
      ],
    };
  }
  componentDidUpdate(prevProps) {
    //Typical usage, don't forget to compare the props
    if (this.props.date!==prevProps.date) {
      this.fetchData();
    }
  }
  componentDidMount() {
    this.fetchData();
  }
  fetchData() {
    let day = this.props.date;
    var sixBefore = subtractDays(day, 6).toISOString().substring(0, 10);
    let week = [
      dateToIcelandicString(subtractDays(day, 6)),
      dateToIcelandicString(subtractDays(day, 5)),
      dateToIcelandicString(subtractDays(day, 4)),
      dateToIcelandicString(subtractDays(day, 3)),
      dateToIcelandicString(subtractDays(day, 2)),
      dateToIcelandicString(subtractDays(day, 1)),
      dateToIcelandicString(day)
    ];
    let weekdays = [
      dayNameFromNumber(subtractDays(day, 6).getDay()),
      dayNameFromNumber(subtractDays(day, 5).getDay()),
      dayNameFromNumber(subtractDays(day, 4).getDay()),
      dayNameFromNumber(subtractDays(day, 3).getDay()),
      dayNameFromNumber(subtractDays(day, 2).getDay()),
      dayNameFromNumber(subtractDays(day, 1).getDay()),
      dayNameFromNumber(day.getDay()),
    ];
    this.setState({
      week: week,
      weekdays: weekdays
    });

    const id = this.props.athleteId;
    if (id!==0) {
      const queryString = "?athlete=" + this.props.athleteId;
      var date = fetch(
        process.env.REACT_APP_API +
          "api/measurements/" +
          queryString +
          "&type__name=WithingsActivitySummary&date_of_measurement__gte=" +
          sixBefore +
          "&date_of_measurement__lte=" +
          day.toISOString().substring(0, 10),

        {
          method: "GET",
          headers: {
            Authorization: `JWT ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      )
        .then(handleErrors)
        .then((res) => res.json())
        .then(
          (result) => {
            if (result.results.length!==0) {
              let data = [];
              var weekdaysWithData = new Map();
              var i;
              for (i = 0; i < 7; i++) {
                weekdaysWithData.set(this.state.weekdays[i], [0]);
              }

              let originArray = [];

              for (i = 0; i < 7; i++) {
                origin = "";
                if (!noValue(result.results[i]) && !noValue(result.results[i].origin)) {
                  origin = result.results[i].origin.name
                }
                originArray.push(origin);
              }

              for (i = 0; i < 7; i++) {
                if (!noValue(result.results[i])) {
                  let date = new Date(result.results[i].date_of_measurement);
                  let activityData = result.results[i].data;
                  let stepsOfTheWeek = activityData.steps;
                  weekdaysWithData.set(dayNameFromNumber(date.getDay()), [
                    stepsOfTheWeek,
                  ]);
                }
              }

              for (i = 0; i < 7; i++) {
                data.push({
                  x: this.state.weekdays[i],
                  y: weekdaysWithData.get(this.state.weekdays[i]),
                });
              }
              globalOriginArray = originArray;
              let series1 = { name: "Skrefafjöldi" ,data: data };
              let seriesAll = [series1];
              this.setState({ series: seriesAll, originArray: originArray, loaded: true});
            } 
            else {
              this.setState({ loaded: true});
            }
          },
          (error) => {
            this.setState({
              error: true,
              snackbarOpen: true,
              snackbarMessage:
                "Það kom upp villa við að sækja svefnupplýsingar.",
              snackbarVariant: "error",
            });
          }
        );
    }
    //}
  }

  closeSnackbar = () => {
    this.setState({
      snackbarOpen: false,
    });
  };
  
  render() {
    return (
      <div id="chart">
        {this.state.week !== undefined && (
          <div>
            Skrefafjöldi vikuna: {this.state.week[0]} - {this.state.week[6]}
          </div>
        )}
        {(this.state.loaded && this.state.series[0].data.length == 7) &&(
          <Grid>
            <ReactApexChart
              options={this.state.options}
              series={this.state.series}
              type="bar"
              className={styles.chart}
            />
          </Grid>
        )}
        {(this.state.loaded && this.state.series[0].data.length ==0) &&(
          <p>Engin gögn til staðar.</p>
        )}
        <CustomizedSnackbars
          variant={this.state.snackbarVariant}
          message={this.state.snackbarMessage}
          open={this.state.snackbarOpen}
          closeSnackbar={this.closeSnackbar}
        />
      </div>
    );
  }
}
function getGlobalOriginArray() {
  return globalOriginArray;
}

function handleErrors(response) {
  if (!response.ok) {
    this.setState({ error: true });
  }
  return response;
}

const mapStateToProps = (store) => {
  if (store.auth.athlete) {
    var athlete = store.auth.athlete;
  } else {
    var athlete = store.router.location.state;
  }
  return {
    userType: store.auth.userType,
    userId: store.auth.id,
    name: store.auth.fullName,
    athlete: athlete,
  };
};

export default connect(mapStateToProps, null)(AthleteChartStepsBars);
