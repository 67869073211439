import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Typography
} from "@material-ui/core";
import styles from "./Measurements.module.css";
import moment from "moment";
import DataTab from "../../../components/UI/DataTab/DataTab";
import AthleteInfo from "../../../components/Athlete/Info/Info"
import AthleteDataCategories from "../../../components/Athlete/DataCategories/DataCategories";
import AthleteChartMeasurementGraph from "../../../components/Athlete/Chart/MeasurementGraph/MeasurementGraph"
import AthleteChartMeasurementsTable from "../../../components/Athlete/Chart/MeasurementsTable/MeasurementsTable";
import DialogWithButton from "../../../components/UI/DialogWithButton/DialogWithButton";
import aboutPhysicalMeasurements from "../AboutPhysicalMeasurements.js";
import TypeSelect from "../../../components/UI/TypeSelect";

const AthleteMeasurements = (props) => {
  const [bodyType, setBodyType] = useState(" ");
  const [physicalType, setPhysicalType] = useState(" ");
  let athleteId = null;
  if (props.userType!=="athlete") {
    athleteId = props.match.params.id;
  } else {
    athleteId = props.athlete.id;
  }
  
  return (
    <Typography component="div">
      <AthleteInfo/>
      <AthleteDataCategories/>
      <DataTab text="Niðurstöður mælinga" color="#6e6280" icon="ruler">
        <AthleteChartMeasurementsTable athleteId={athleteId} category="PHYSICAL" color="#aba1c9" colors={["rgba(72, 74, 72)", "rgba(138, 0, 237)", "rgba(69, 88, 173)"]}/>
        <span>Nánari útskýring á mælingu:
          <DialogWithButton
            title="Nánar um hvað var prófað í mælingu"
            iconName="info-circle"
            content={aboutPhysicalMeasurements}
          />
        </span>
      </DataTab>
      <DataTab text="Þróun mælinga" color="#936c94" icon="chart-line">
        <div className="fullBases">
          <TypeSelect category="PHYSICAL" setType={setPhysicalType}/>
        </div>
        <AthleteChartMeasurementGraph athleteId={athleteId} type={physicalType} typeTitle={physicalType} gradientColor="#7a6cd4" markerColor="#d06cd4"/>
      </DataTab>
      <DataTab text="Þyngd og BMI" color="#7a6370" icon="weight">
        <AthleteChartMeasurementGraph athleteId={athleteId} type="Weight" typeTitle="Þyngd(kg)" gradientColor="#FDD835" markerColor="#FFA41B"/>
        <AthleteChartMeasurementGraph athleteId={athleteId} type="BMI" typeTitle="BMI" gradientColor="#8442f5" markerColor="#f54242"/>
      </DataTab>
      <DataTab text="Aðrar líkamsmælingar" color="#936c94" icon="balance-scale">
        <div className="fullBases">
          <TypeSelect category="BODY" setType={setBodyType}/>
        </div>
        <AthleteChartMeasurementGraph athleteId={athleteId} type={bodyType} typeTitle={bodyType} gradientColor="#ac69c7" markerColor="#64d9d9"/>
      </DataTab>
    </Typography>
  );
};

const mapStateToProps = (store) => {
  if (store.auth.athlete) {
    var athlete = store.auth.athlete;
  }
  return {
    userType: store.auth.userType,
    athlete: athlete,
  };
};
export default connect(mapStateToProps, null)(AthleteMeasurements);
