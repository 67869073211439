import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import CustomizedSnackbars from "../../../../components/UI/CustomizedSnackbars";
import defaultUser from "../../../../assets/default_user.png"; // Tell Webpack this JS file uses this image
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Avatar,
  CircularProgress,
  LinearProgress,
  Button,
  InputLabel,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import FileDownloadIcon from "@material-ui/icons/FileDownload";
import styles from "../Export.module.css";
import { noValue } from "../../../../utils";
import IcelandicDayPicker from "../../../../components/UI/IcelandicDayPicker";
import { CSVLink } from "react-csv";

// Export data uploaded via Excel sheets, i.e. of origin name MeasurementsFile
const ExportWithings = (props) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("INITIAL");
  const [snackbarVariant, setSnackbarVariant] = useState("info");
  const [error, setError] = useState(false);
  const [team, setTeam] = useState([]);
  const [name, setName] = useState("");
  const [teamLoaded, setTeamLoaded] = useState(false);
  const [typesLoaded, setTypesLoaded] = useState(false);
  const [fileNameSelection, setFileNameSelection] = useState("id");
  const [downloadFetching, setDownloadFetching] = useState(false);
  const [id, setId] = useState(props.match.params.id); // team's id
  const [measurementTypes, setMeasurementTypes] = useState([]); // Change this array to show more/different measurement types
  const [selectAllTypes, setSelectAllTypes] = useState(false);
  const [selectedMeasurements, setSelectedMeasurements] = useState([]);
  const [selectAllIndividuals, setSelectAllIndividuals] = useState(false);
  const [selectedIndividuals, setSelectedIndividuals] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [progress, setProgress] = useState(0);
  const [fetchProgress, setFetchProgress] = useState(0);
  const [downloadProgress, setDownloadProgress] = useState(<div></div>);
  const pageLimit = 500; // The number of measurements to fetch from each page (prevents the server from crashing when there is too much data)
  const [athleteId, setAthleteId] = useState("");

  // In order to set the start day as one year ago, need to edit IcelandicDayPicker to accomodate this
  var d = new Date();
  var year = d.getFullYear();
  var month = d.getMonth();
  var day = d.getDate();

  const [startDay, setStartDay] = useState(
    new Date(year, month, day).toISOString().substring(0, 10)
  );

  const [endDay, setEndDay] = useState(
    new Date().toISOString().substring(0, 10)
  );

  let csvLink = React.createRef();

  const teamPath = "/group/" + id;

  // Withings data looks different depending on if it's on Heroku or datawell.is
  var originName = "origin__name";
  if (process.env.REACT_APP_API === "http://datawellapi.herokuapp.com/") {
    originName = "event__origin__name";
  }

  useEffect(() => {
    fetch(process.env.REACT_APP_API + "api/teams/" + id + "/", {
      method: "GET",
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setName(result.name);
        },
        (error) => {
          setError(true);
          setSnackbarOpen(true);
          setSnackbarMessage("Villa kom upp við að sækja hóp.");
          setSnackbarVariant("error");
        }
      );
    fetch(
      process.env.REACT_APP_API +
        "api/athleteinteams/?limit=" +
        pageLimit +
        "&ordering=athlete__first_name&team=" +
        id,
      {
        method: "GET",
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          setTeam(result.results);
          setTeamLoaded(true);
        },
        (error) => {
          setError(true);
          setSnackbarOpen(true);
          setSnackbarMessage(
            "Villa kom upp við að sækja einstaklinga fyrir hóp."
          );
          setSnackbarVariant("error");
        }
      );
    getMeasurementTypes(id);
  }, [id]);

  const getMeasurementTypes = () => {
    // We first need to get the number of total measurements to be able to get them all
    fetch(process.env.REACT_APP_API + "api/typesused/", {
      method: "GET",
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        fetch(
          process.env.REACT_APP_API +
            "api/typesused/" +
            "?limit=" +
            result.count,
          {
            method: "GET",
            headers: {
              Authorization: `JWT ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
            },
          }
        )
          .then((res) => res.json())
          .then(
            (result) => {
              let tempMeasurements = [];
              // Need to update this to also fetch Health App data from DatawellApp
              for (const [key, value] of Object.entries(result.results)) {
                if (
                  (value.name !== "" &&
                    (value.name.startsWith("Withings") ||
                      value.category === "BODY")) ||
                  value.name.startsWith("MindPoint") ||
                  value.category === "PSYCHOLOGICAL"
                ) {
                  tempMeasurements.push(value);
                }
              }
              setMeasurementTypes(
                tempMeasurements.sort((a, b) => a.name.localeCompare(b.name))
              );
              setTypesLoaded(true);
            },
            (error) => {
              setError(true);
              setSnackbarOpen(true);
              setSnackbarMessage(
                "Villa kom upp við að sækja tegundir mælinga."
              );
              setSnackbarVariant("error");
            }
          );
      });
  };

  const handlePersonClick = (individual) => {
    let selectedPersons = selectedIndividuals;
    var individualID = "individual" + individual.id;
    var element = document.getElementById(individualID).className;
    if (element.includes(styles.tableColor1)) {
      document.getElementById(individualID).className = styles.tableColor2;
    } else {
      document.getElementById(individualID).className = styles.tableColor1;
    }
    if (selectedPersons.includes(individual.id)) {
      // If clicked type exists in the list, remove it
      let removedIndividual = selectedPersons.filter(
        (item) => item !== individual.id
      ); // Remove the individual from the list
      selectedPersons = removedIndividual;
    } else {
      // Else add it
      selectedPersons.push(individual.id);
    }

    setSelectedIndividuals(selectedPersons);
  };

  const handleTypeClick = (clickedType) => {
    let selectedTypes = selectedMeasurements;
    var typeID = "type" + clickedType.id;
    var element = document.getElementById(typeID).className;
    if (element.includes(styles.tableColor1)) {
      document.getElementById(typeID).className = styles.tableColor2;
    } else {
      document.getElementById(typeID).className = styles.tableColor1;
    }

    if (selectedTypes.includes(clickedType.id)) {
      // If clicked type exists in the list, remove it
      let removedType = selectedTypes.filter((item) => item !== clickedType.id); // Remove the type from the list
      selectedTypes = removedType;
    } else {
      // Else add it
      selectedTypes.push(clickedType.id);
    }

    setSelectedMeasurements(selectedTypes);
  };

  const closeSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleSelectAllIndividuals = () => {
    if (teamLoaded) {
      let selectedPersons = [];
      let allIndividualsSelected = !selectAllIndividuals;
      setSelectAllIndividuals(allIndividualsSelected); // Toggle the select
      // Highlight all the individuals in the table
      if (allIndividualsSelected) {
        for (let i = 0; i < team.length; i++) {
          selectedPersons.push(team[i].athlete.id);
          let individualID = "individual" + team[i].athlete.id;
          document.getElementById(individualID).className = styles.tableColor2;
        }
      } else {
        // If select all is being reset
        for (let i = 0; i < team.length; i++) {
          let individualID = "individual" + team[i].athlete.id;
          document.getElementById(individualID).className = styles.tableColor1;
        }
      }
      setSelectedIndividuals(selectedPersons);
    }
  };

  const handleSelectAllTypes = () => {
    let selectedTypes = [];
    let allTypesSelected = !selectAllTypes;
    setSelectAllTypes(allTypesSelected); // Toggle the select
    // Highlight all the types in the table
    if (allTypesSelected) {
      for (let i = 0; i < measurementTypes.length; i++) {
        selectedTypes.push(measurementTypes[i].id);
        let typeID = "type" + measurementTypes[i].id;
        document.getElementById(typeID).className = styles.tableColor2;
      }
    } else {
      // If select all is being reset
      for (let i = 0; i < measurementTypes.length; i++) {
        let typeID = "type" + measurementTypes[i].id;
        document.getElementById(typeID).className = styles.tableColor1;
      }
    }
    setSelectedMeasurements(selectedTypes);
  };

  useEffect(() => {
    if (headers.length > 0 && csvData.length > 0 && progress === 100) {
      setTimeout(() => {
        if (csvLink.current) {
          csvLink.current.link.click(); // Click the invisible (hidden) CSVDownload component (button)
          setProgress(0);
          setHeaders([]);
          setCsvData([]);
        }
      }, 10); // Short timeout to allow csvData to truly update before download
    }
  }, [progress, headers, csvData, athleteId, csvLink]);

  useEffect(() => {
    // Display the spinner while data is fetching from backend
    if (downloadFetching && progress === 0) {
      setDownloadProgress(
        <div className={styles.progressBar}>
          <p>Sæki gögn frá vefþjóni...</p>
          <LinearProgress
            variant="determinate"
            value={fetchProgress.toFixed(0)}
          />
          {fetchProgress.toFixed(0)}%
        </div>
      );
    }
    // Show progress bar while the data is being filtered
    else if (progress > 0) {
      setDownloadProgress(
        <div className={styles.progressBar}>
          <p>Undirbý skrá...</p>
          <LinearProgress variant="determinate" value={progress.toFixed(0)} />
          {progress.toFixed(0)}%
        </div>
      );
    }
  }, [fetchProgress, progress, downloadFetching]);

  // Async timer for the progress bar to update accordingly to the for-loop in formatCSV()
  const timer = (delay) => {
    return new Promise((resolve) => setTimeout(resolve, delay));
  };

  const pushToCsvData = (tempCsvData, date, time, type, name, value) => {
    if (
      !tempCsvData.some(
        (e) => e.date === date && e.time === time && e.type === type
      )
    ) {
      tempCsvData.push({
        date: date,
        time: time,
        type: type,
        [name]: value,
      });
    } else {
      let updatedData = tempCsvData.map((x) =>
        x.date === date && x.time === time ? { ...x, [name]: value } : x
      );
      return updatedData;
    }
    return tempCsvData;
  };

  const formatCSV = async (measurementData, delay) => {
    let tempHeaders = [
      { label: "date", key: "date" },
      { label: "time", key: "time" },
      { label: "type", key: "type" },
    ];
    let tempCsvData = [];
    var counter = 0;
    var objSize = measurementData.length;
    setDownloadFetching(false);
    setFetchProgress(0);
    setProgress(1);
    for (var i = 0; i < measurementData.length; i++) {
      let percentage = counter / objSize;
      setProgress(percentage * 100);

      counter++;

      // Withings data is displayed as an object with multiple values, while MeasurementsFile data only displays one value for one type
      for (const [key, value] of Object.entries(measurementData)) {
        let rawDate;
        // Check for all the different types of data in the backend
        // WithingsSleepSummary, WithingsWorkout
        if (
          typeof value.data === "object" &&
          value.data.startdate &&
          ((value.origin && value.origin.name === "Withings") ||
            (value.event &&
              value.event.origin &&
              value.event.origin.name === "Withings"))
        ) {
          rawDate = new Date(value.data.startdate * 1000);
        }
        // DatawellApp
        else if (typeof value.data === "object" && value.data.startdate) {
          rawDate = new Date(value.data.startdate);
        }
        // WithingsActivitySummary, number
        else if (value.event && value.event.timestamp) {
          rawDate = new Date(value.event.timestamp);
        }
        // Other
        else if (value.origin && value.origin.timestamp) {
          rawDate = new Date(value.origin.timestamp);
        } else {
          rawDate = new Date(value.timestamp);
        }

        let dateTime = new Date(Date.parse(rawDate));
        let time = dateTime.toTimeString().split(" ")[0];

        // If the data is a number -> a BODY measurement from Withings
        if (typeof value.data === "number") {
          if (!tempHeaders.some((e) => e.key === value.type.name)) {
            tempHeaders.push({ label: value.type.name, key: value.type.name });
          }

          tempCsvData = pushToCsvData(
            tempCsvData,
            value.date_of_measurement,
            time,
            value.type.name,
            value.type.name,
            value.data_value
          );
        }
        // If the data is a Withings object
        else if (
          (typeof value.data === "object" &&
            value.origin &&
            value.origin.name === "Withings") ||
          (value.event &&
            value.event.origin &&
            value.event.origin.name === "Withings")
        ) {
          let data = value.data;

          // WithingsWorkout contains a data.data object as well as heart_rate series
          if (value.data.data && typeof value.data.data === "object") {
            data = value.data.data;
            if (
              value.data &&
              value.data.heart_rate &&
              value.data.heart_rate.series
            ) {
              // Add the heart_rate value header to
              if (!tempHeaders.some((e) => e.key === "heart_rate")) {
                tempHeaders.push({
                  label: "heart_rate",
                  key: "heart_rate",
                });
              }

              // For Workout / Sleep data
              // Loop through the timestamps and add the corresponding heart rate
              for (const [heartKey, heartValue] of Object.entries(
                value.data.heart_rate.series
              )) {
                let heartTimeFull = new Date(heartKey * 1000); //new Date(heartKey);
                let heartTime = heartTimeFull.toTimeString().split(" ")[0];
                let heartDate =
                  heartTimeFull.getFullYear() +
                  "-" +
                  ("0" + (heartTimeFull.getMonth() + 1)).slice(-2) +
                  "-" +
                  ("0" + heartTimeFull.getDate()).slice(-2);

                tempCsvData = pushToCsvData(
                  tempCsvData,
                  heartDate,
                  heartTime,
                  value.type.name,
                  "heart_rate",
                  heartValue.heart_rate
                );
              }
            }
          }

          // For Summary data
          for (const [dataKey, dataValue] of Object.entries(data)) {
            if (dataKey !== "date") {
              if (!tempHeaders.some((e) => e.key === dataKey)) {
                tempHeaders.push({
                  label: dataKey,
                  key: dataKey,
                });
              }

              tempCsvData = pushToCsvData(
                tempCsvData,
                value.date_of_measurement,
                time,
                value.type.name,
                dataKey,
                dataValue
              );
            }
          }
        } // If the data is from DatawellApp
        else if (typeof value.data === "object" && value.data.DatawellAppID) {
          if (!tempHeaders.some((e) => e.key === value.type.name)) {
            tempHeaders.push({ label: value.type.name, key: value.type.name });
          }
          tempCsvData = pushToCsvData(
            tempCsvData,
            value.date_of_measurement,
            time,
            value.type.name,
            value.type.name,
            value.data_value
          );
        } else {
          // If no corresponding measurements were found based on the user's selection
          setProgress(0);
          setFetchProgress(0);
          setDownloadFetching(false);
          setCsvData([]);
          setHeaders([]);
          setError(true);
          setSnackbarOpen(true);
          setSnackbarMessage(
            "Ekki tókst að sækja mælingar fyrir viðkomandi val."
          );
        }
        // Wait delay amount in ms before continuing, give browser time to render last update
        await timer(delay);
      }
    }
    if (counter === objSize) {
      setProgress(100);
    }

    tempCsvData.sort((a, b) => sortF(a, b));
    if (fileNameSelection === "name") {
      setAthleteId(measurementData[0].athlete.first_name.replace(/ /g, "_")); // If we want to use the athlete's name to name the file
    } else {
      setAthleteId(measurementData[0].athlete.id); // If we want to use the athlete's id to name the file
    }
    setHeaders(tempHeaders);
    setCsvData(tempCsvData);
  };

  // Custom sort by date and time
  function sortF(a, b) {
    // Sort by date
    if (new Date(a.date) - new Date(b.date)) {
      return 1;
    } else if (new Date(b.date) - new Date(a.date)) {
      return -1;
    }

    // Else sort by time
    if (new Date(a.time) - new Date(b.time)) {
      return 1;
    } else if (new Date(b.time) - new Date(a.time)) {
      return -1;
    } else {
      return 0;
    }
  }

  // Change array of arrays of objects to an array of objects
  const filterDownload = (measurementData) => {
    setFetchProgress(100);
    let tempMeasurements = [];
    for (var i = 0; i < measurementData.length; i++) {
      for (const [key, value] of Object.entries(measurementData[i])) {
        if (value.type) {
          if (selectedMeasurements.includes(value.type.id)) {
            tempMeasurements.push(value);
          }
        }
      }
    }

    if (tempMeasurements.length > 0) {
      formatCSV(tempMeasurements);
    } else {
      // If no corresponding measurements were found based on the user's selection
      setProgress(0);
      setFetchProgress(0);
      setDownloadFetching(false);
      setCsvData([]);
      setHeaders([]);
      setError(true);
      setSnackbarOpen(true);
      setSnackbarMessage("Engar mælingar fundust fyrir viðkomandi val.");
    }
  };

  const fetchMeasurements = (
    resultsArr,
    fullLimit,
    limit,
    offset,
    individual,
    origin
  ) => {
    fetch(
      process.env.REACT_APP_API +
        "api/measurements/?" +
        "athlete=" +
        individual +
        "&date_of_measurement__gte=" +
        startDay +
        "&date_of_measurement__lte=" +
        endDay +
        "&ordering=date_of_measurement" +
        "&limit=" +
        pageLimit +
        "&offset=" +
        offset +
        // Here we only want data from the measurements files (Excel)
        "&" +
        originName +
        "=" +
        origin,
      {
        method: "GET",
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.results.length > 0) {
            let percentage = offset / fullLimit;
            setFetchProgress(percentage * 100);

            resultsArr.push(result.results);
            let tempArr = resultsArr;

            limit = Number(limit) - Number(pageLimit);
            offset = Number(offset) + Number(pageLimit);

            if (limit > 0) {
              setTimeout(() => {
                // Recursively fetch the rest of the data
                fetchMeasurements(
                  tempArr,
                  fullLimit,
                  limit,
                  offset,
                  individual,
                  origin
                );
              }, 1);
            } else {
              if (
                origin === "Withings" &&
                selectedMeasurements.includes("MindPoint")
              ) {
                fetchMeasurements(
                  tempArr,
                  fullLimit,
                  limit,
                  offset,
                  individual,
                  (origin = "DatawellApp")
                );
              } else {
                // All data has been fetched, now filter it
                filterDownload(tempArr);
              }
            }
          } else {
            // If the fetch for DatawellApp returned nothing, we still want to filter what we have
            if (resultsArr.length > 0) {
              // All data has been fetched, now filter it
              filterDownload(resultsArr);
            }
          }
        },
        (error) => {
          setError(true);
          setSnackbarOpen(true);
          setSnackbarMessage("Villa kom upp við að sækja valdar mælingar.");
          setSnackbarVariant("error");
          setDownloadFetching(false);
          setProgress(0);
          setDownloadProgress(<div></div>);
        }
      );
  };

  const fetchIndividual = (individual, origin, delay) => {
    // If only MindPoint was selected
    let mindPointTypeId;
    for (const [key, value] of Object.entries(measurementTypes)) {
      if (value.name === "MindPoint") {
        mindPointTypeId = value.id;
        break;
      }
    }
    if (
      selectedMeasurements.length === 1 &&
      mindPointTypeId > 0 &&
      selectedMeasurements.includes(mindPointTypeId)
    ) {
      origin = "DatawellApp";
    }

    fetch(
      process.env.REACT_APP_API +
        "api/measurements/?" +
        "athlete=" +
        individual +
        "&date_of_measurement__gte=" +
        startDay +
        "&date_of_measurement__lte=" +
        endDay +
        "&" +
        originName +
        "=" +
        origin,
      {
        method: "GET",
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          // Send a new request to get ALL measurements after getting the total number (count)
          // Loop through all the measurements and types and add the corresponding ones to a map
          // Add for the selected athletes and types

          var limit = result.count;
          var offset = 0;
          var resultsArr = [];

          // If there are measurements found, send a new request (recursive function) to get all the data
          if (result.count > 0) {
            fetchMeasurements(
              resultsArr,
              result.count,
              limit,
              offset,
              individual,
              origin
            );
          }
          // If the count is 0 then there are no measurements found from the query
          else {
            setError(true);
            setSnackbarOpen(true);
            setSnackbarMessage(
              "Engar mælingar fundust fyrir einstakling númer " +
                individual +
                " á þessu tímabili."
            );
            // setSnackbarVariant("error");
            setDownloadFetching(false);
            setProgress(0);
            setDownloadProgress(<div></div>);
          }
        },
        (error) => {
          setError(true);
          setSnackbarOpen(true);
          setSnackbarMessage("Villa kom upp við að sækja mælingar liðs.");
          setSnackbarVariant("error");
          setDownloadFetching(false);
          setProgress(0);
          setDownloadProgress(<div></div>);
        }
      );

    return new Promise((resolve) => setTimeout(resolve, delay));
  };

  const handleDownload = async () => {
    for (let i = 0; i < selectedIndividuals.length; i++) {
      setDownloadFetching(true);

      await fetchIndividual(selectedIndividuals[i], "Withings");
    }
  };

  const handleFileNameChange = (e) => {
    setFileNameSelection(e.target.value);
  };

  let club = (
    <TableRow>
      <TableCell component="th" scope="row">
        Enginn einstaklingur fannst í þessum hóp.
      </TableCell>
    </TableRow>
  );
  if (teamLoaded) {
    if (team[0] && team[0].athlete) {
      club = team.map((player, index) => {
        let profile_picture = null;
        profile_picture = player.athlete.profile_picture;
        const { first_name, last_name } = player.athlete;
        const { date_of_birth } = player.athlete;
        let year_of_birth = "";
        if (date_of_birth !== null) {
          year_of_birth = date_of_birth.substr(0, date_of_birth.indexOf("-"));
        }

        let pic = profile_picture;
        if (noValue(pic)) {
          pic = defaultUser;
        }

        var playerID = "individual" + player.athlete.id;
        return (
          <>
            <TableRow
              id={playerID}
              className={styles.tableColor1}
              key={"team" + index}
              onClick={() => handlePersonClick(player.athlete)}
            >
              <TableCell component="td" scope="row">
                <div>
                  <div className={styles.row}>
                    <div className={styles.athleteWrapper}>
                      <div className={styles.avatar}>
                        <Avatar
                          src={pic}
                          alt={`${first_name} ${last_name}`}
                          className={styles.avatar}
                        />
                      </div>
                      {`${first_name} ${last_name}`}
                    </div>
                  </div>
                </div>
              </TableCell>
              <TableCell>{year_of_birth}</TableCell>
            </TableRow>
          </>
        );
      });
    }
  } else {
    club = <CircularProgress />;
  }

  let measurements = (
    <TableRow>
      <TableCell component="th" scope="row">
        Engar mælingar fundust fyrir {name}.
      </TableCell>
    </TableRow>
  );
  if (measurementTypes[0]) {
    measurements = measurementTypes.map((type, index) => {
      return (
        <>
          <TableRow
            id={"type" + type.id}
            className={styles.tableColor1}
            key={"type" + index}
          >
            <TableCell
              component="td"
              scope="row"
              onClick={() => handleTypeClick(type)}
            >
              <div>
                <div className={styles.row}>
                  <div className={styles.athleteWrapper}>{`${type.name} `}</div>
                </div>
              </div>
            </TableCell>
          </TableRow>
        </>
      );
    });
  }

  return (
    <div className={styles.container}>
      <div className={styles.center}>
        <div className={styles.teamsList}>
          <div id="chart" className={styles.downloadData}>
            <div>
              {downloadFetching || progress > 0 ? (
                <>{downloadProgress}</>
              ) : (
                <p>
                  Smellið á einstaklinga úr listanum til þess að bæta þeim við.
                </p>
              )}
            </div>

            <div>
              <Button
                variant="contained"
                color="primary"
                className={styles.button}
                startIcon={<FileDownloadIcon />}
                onClick={() => handleDownload()}
              >
                Sækja gögn
              </Button>
              <CSVLink
                filename={athleteId + "_" + startDay + "_" + endDay + ".csv"}
                headers={headers}
                className={styles.hidden}
                data={csvData}
                ref={csvLink}
                target="_blank"
              >
                {" "}
                Download Me
              </CSVLink>
            </div>
            <div className={styles.nameSelection}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Nefna skrár eftir:</FormLabel>
                <RadioGroup
                  aria-label="position"
                  name="position"
                  value={fileNameSelection}
                  onChange={(e) => handleFileNameChange(e)}
                  row
                >
                  <FormControlLabel
                    value="id"
                    control={<Radio color="primary" />}
                    label="Númeri"
                    labelPlacement="bottom"
                  />
                  <FormControlLabel
                    value="name"
                    control={<Radio color="primary" />}
                    label="Nafni"
                    labelPlacement="bottom"
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
          <Paper className={styles.team}>
            <div className={styles.header}>
              {name}
              <div className={styles.button}>
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  component={Link}
                  onClick={() => handleSelectAllIndividuals()}
                >
                  {selectAllIndividuals ? "Endurstilla" : "Velja alla"}
                </Button>
              </div>
            </div>

            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Nafn</TableCell>
                  <TableCell>Fæðingarár</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{club}</TableBody>
            </Table>
          </Paper>
        </div>
        <div className={styles.button}>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            component={Link}
            to={{ pathname: teamPath }}
          >
            Til baka
          </Button>
        </div>
        <div className={styles.imageContainer}>
          <Paper>
            <div className={styles.subheader}>Veldu tímabil</div>
            <div className={styles.datePickerContainer}>
              <div className={styles.datePicker}>
                <InputLabel shrink>Upphafsdagsetning</InputLabel>
                <IcelandicDayPicker setDay={setStartDay} />
              </div>
              <div className={styles.datePicker}>
                <InputLabel shrink>Lokadagsetning</InputLabel>
                <IcelandicDayPicker setDay={setEndDay} />
              </div>
            </div>
          </Paper>
          <Paper>
            <div className={styles.measurementHeader}>
              <div className={styles.subheader}>Veldu mælingar</div>
              <div className={styles.button}>
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  component={Link}
                  onClick={() => handleSelectAllTypes()}
                >
                  {selectAllTypes ? "Endurstilla" : "Velja allt"}
                </Button>
              </div>
            </div>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Tegund</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{measurements}</TableBody>
            </Table>
          </Paper>
        </div>
      </div>

      <CustomizedSnackbars
        variant={snackbarVariant}
        message={snackbarMessage}
        open={snackbarOpen}
        closeSnackbar={() => closeSnackbar()}
      />
    </div>
  );
};

const mapStateToProps = (store) => {
  return {
    userType: store.auth.userType,
    userId: store.auth.id,
    name: store.auth.fullName,
    profilePicture: store.auth.profilePicture,
    athlete: store.auth.athlete,
  };
};

export default connect(mapStateToProps)(ExportWithings);
