import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { Menu} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import LinkMenuItem from "../UI/LinkMenuItem";
import { logout } from "../../actions/authActions";

const MeasurementDropDown = (props) => {
  const [dropdown, setDropdown] = useState(null);

  const handleMenu = (event) => {
    setDropdown(event.currentTarget);
  };

  const handleClose = () => {
    setDropdown(null);
  };

  const open = Boolean(dropdown);


  return (
    <div>
      <Button color="inherit" onClick={(e) => handleMenu(e)}>
          Mælingaskrár
        </Button>
      <Menu
        id="meas-appbar"
        anchorEl={dropdown}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={open}
        onClose={() => handleClose()}
      >
        <LinkMenuItem
          title="Íþróttamælingar"
          link="/physicalmeasurements"
          close={() => handleClose()}
        />
        <LinkMenuItem
          title="Sálfræðimælingar"
          link="/psychologicalmeasurements"
          close={() => handleClose()}
        />
      </Menu>
    </div>
  );
};

const mapStateToProps = ({ auth }) => {
  return {
    userType: auth.userType
  };
};

export default connect(mapStateToProps, { logout })(MeasurementDropDown);
