import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import Grid from "@material-ui/core/Grid";

import Team from "../Team/Team";
import CustomizedSnackbars from "../../UI/CustomizedSnackbars";

const Teams = (props) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("info");
  const [error, setError] = useState(false);
  const [teams, setTeams] = useState([]);

  const { updateTeams, teamsUpdated } = props;

  useEffect(() => {
    updateTeamsFunc();
    if (updateTeams) {
      updateTeamsFunc();
      teamsUpdated();
    }
  }, [updateTeams]); // Only render when updateTeams changes

  const updateTeamsFunc = () => {
    fetch(process.env.REACT_APP_API + "api/teams/", {
      method: "GET",
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    })
      .then(handleErrors)
      .then((res) => res.json())
      .then(
        (result) => {
          setTeams(result.results);
        },
        (error) => {
          setError(true);
          setSnackbarOpen(true);
          setSnackbarMessage("Villa kom upp við að sækja lið.");
          setSnackbarVariant("error");
        }
      );
  };

  const closeSnackbar = () => {
    setSnackbarOpen(false);
  };

  function handleErrors(response) {
    if (!response.ok) {
      setError(true);
    }
    return response;
  }

  return (
    <Grid
      container
      justify="center"
      spacing={3}
      style={{
        flexGrow: "1",
        marginTop: "10px",
        marginLeft: "5px",
        marginRight: "5px",
      }}
    >
      {teams.map((team) => {
        return <Team key={"teamInList" + team.id} team={team} />;
      })}
      <CustomizedSnackbars
        variant={snackbarVariant}
        message={snackbarMessage}
        open={snackbarOpen}
        closeSnackbar={() => closeSnackbar()}
      />
    </Grid>
  );
};

const mapStateToProps = (store) => ({});

export default connect(mapStateToProps)(Teams);
