const aboutPhysicalMeasurements = 
`• 5x30m Sprettur: 
Markmið prófsins er að meta hraðaþol þátttakandans. Þátttakandi hleypur eins hratt og mögulegt er 30 metra sprett, kemur sér til baka á upphafsreit og er tilbúinn fyrir næsta sprett á 30 sekúndum. Þetta ferli er endurtekið fimm sinnum. Þátttakandinn byrjar þegar hljóðmerki er gefið en eftir síðasta sprettinn þarf þátttakandinn ekki að koma sér til baka á upphafsreit. Tímahlið eru notuð til þess að mæla tímann sem það tekur þátttakandann að hlaupa 30 metra. Einnig er skráður millitími fyrir 10 metra í fyrsta sprettinum sem þátttakandinn hleypur en þannig er hröðun fyrir fyrstu 10 metra hlaupsins mæld.

• Spyrnuhraði: 
Markmið prófsins er að mæla skotkraft við framkvæmd vítaspyrnu annars vegar með hægri fæti og hins vegar með vinstri fæti. Hraðabyssu er komið fyrir fyrir aftan  markið og er hún notuð til þess að meta hraða boltans eftir að boltanum er spyrnt frá vítapunkti.

• Lóðrétt viðbragðshopp (e.Countermovement jump (CMJ)): 
Markmið prófsins er að meta stökkhæð þátttakandans með tveimur aðferðum af countermovement hoppi. Þátttakandi kemur sér fyrir á stökkmottu í uppréttri stöðu. Þegar þátttakandi er tilbúinn beygir hann sig niður í hnébeygju og hoppar strax upp annars vegar með hendur á mjöðmum og hins vegar með lausar hendur. Þátttakandinn framkvæmir hoppin á stökkmottu sem notuð er til þess að meta kraftinn sem myndaður er í neðri útlimum við hoppið.

• Illinois snerpu próf (e.Illinois agility test): 
Markmið prófsins er að meta snerpu þátttakandans. Þátttakandinn á að hlaupa eins hratt og hann getur í gegnum t-laga hlaupabraut án þess að fella keilur. Tímahlið eru notuð til þess að meta hraða þátttakandans í gegnum brautina.

• YoYo þolpróf (YoYo intermittent endurance test level II): 
Markmið prófsins er að meta þol þátttakandans. Þátttakandinn hleypur braut sem er 20 metrar með 2,5m hvíldarsvæði. Hlaupið er fram og til baka eftir hljóðmerkjum áður en 5 sekúndna hvíld er nýtt á hvíldarsvæði. Hraði hlaupsins er stigvaxandi þar sem að tíminn til að hlaupa vegalengdina styttist þegar á líður prófið. Þátttakendi hleypur þar til hann getur ekki meir eða nær ekki að hlaupa vegalengdina á þeim tíma sem gefinn er.
`;

export default aboutPhysicalMeasurements ;