import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import styles from "./NavBar.module.css";

import logoWhite from "../../../assets/logo_white.svg"; // Tell Webpack this JS file uses this image
import { ATHLETE, COACH, RESEARCHER } from "../../../actions/authTypes";
import NavBarDropDown from "../NavBarDropDown";
import MeasurementDropDown from "../MeasurementDropDown";
import UsersDrowDown from "../UsersDropDown";

const NavBar = (props) => {
  const { loggedIn, userType } = props;
  let login = null;
  if (loggedIn) {
    // conditional links
    let homePage = null;
    let teams = null;
    let addMeasurements = null;
    let usersLists = null;
    if (userType === ATHLETE) {
      homePage = (
        <Button color="inherit" component={Link} to="/">
          Gögnin mín
        </Button>
      );
    }
    if (userType === RESEARCHER) {
      homePage = (
        <Button color="inherit" component={Link} to="/">
          Yfirlit
        </Button>
      );
      teams = (
        <Button color="inherit" component={Link} to="/groups">
          Hópar
        </Button>
      );
      addMeasurements = <MeasurementDropDown />;
      usersLists = <UsersDrowDown />;
    }
    if (userType === COACH) {
      homePage = (
        <Button color="inherit" component={Link} to="/">
          Yfirlit
        </Button>
      );
    }
    return (
      <div>
        <div className={styles.spaceForNavBar}></div>
        <AppBar position="fixed" color="primary">
          <Toolbar>
            <Typography variant="h4" color="inherit" className={styles.divider}>
              <Link to="/">
                <img src={logoWhite} alt="DataWell" height={50} />
              </Link>
            </Typography>
            {homePage}
            {teams}
            {addMeasurements}
            {usersLists}
            <NavBarDropDown />
          </Toolbar>
        </AppBar>
      </div>
    );
  } else {
    login = (
      <Toolbar>
        <Button color="inherit" component={Link} to="/terms">
          Skilmálar
        </Button>
        <Button color="inherit" component={Link} to="/about">
          Um verkefnið
        </Button>
      </Toolbar>
    );
    return (
      <div>
        <AppBar position="static" color="primary">
          <Toolbar>
            <Typography variant="h4" color="inherit" className={styles.divider}>
              <Link to="/">
                <img src={logoWhite} alt="DataWell" height={50} />
              </Link>
            </Typography>
            {login}
          </Toolbar>
        </AppBar>
      </div>
    );
  }
};

const mapStateToProps = (state) => ({
  loggedIn: state.auth.loggedIn,
  userType: state.auth.userType,
});

export default connect(mapStateToProps, null)(NavBar);
