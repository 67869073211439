import React, { Component } from "react";
import { connect } from "react-redux";

import ReactApexChart from "react-apexcharts";

import styles from "./StatisticsGraph.module.css";
import CustomizedSnackbars from "../../UI/CustomizedSnackbars";
import { CircularProgress } from "@material-ui/core";

/* Bad coding style, might need to changa later. Saw it done this way for an example with another graph */
// let globalOriginArray = [];
var dates = [];
var totalResult = [];
var colors = ["#8442f5"];

class TeamStatisticsGraph extends Component {
  constructor(props) {
    super(props);
    let date = new Date();
    let yearAgo = new Date();

    yearAgo.setFullYear(date.getFullYear() - 1);
    date = date.getTime();
    yearAgo = yearAgo.getTime();
    this.state = {
      fetchDone: false,
      snackbarOpen: false,
      snackbarMessage: "INITAL",
      snackbarVariant: "info",
      error: false,
      loaded: false,
      options: {
        chart: {
          type: "line",
          shadow: {
            enabled: false,
            color: "#bbb",
            top: 3,
            left: 2,
            blur: 3,
            opacity: 1,
          },
        },
        stroke: {
          width: 7,
          curve: "smooth",
        },

        xaxis: {
          type: "datetime",
          min: yearAgo,
          max: date,
        },
        title: {
          // text: this.props.type,
          align: "left",
          style: {
            fontSize: "16px",
            color: "#666",
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            gradientToColors: colors,
            shadeIntensity: 1,
            type: "horizontal",
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100, 100, 100],
          },
        },
        markers: {
          size: 4,
          opacity: 0.9,
          colors: ["#f54242"],
          strokeColor: "#fff",
          strokeWidth: 2,

          hover: {
            size: 7,
          },
        },
        yaxis: {
          decimalsInFloat: 2,
          title: {
            // text: this.props.type,
          },
        },
      },
      series: [
        {
          //   name: "BMI",
          data: [],
        },
      ],
    };
  }

  closeSnackbar = () => {
    this.setState({
      snackbarOpen: false,
    });
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.type !== this.props.type ||
      prevProps.valueName !== this.props.valueName
    ) {
      this.setState({
        loaded: false,
      });
      this.getStatisticalData(dates);
    }
  }

  componentDidMount() {
    const id = this.props.teamId;
    fetch(process.env.REACT_APP_API + "api/events/?&team=" + id, {
      method: "GET",
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        result.results.forEach((item) => {
          var dateExists = false;

          for (let i = 0; i < dates.length; i++) {
            if (dates[i] === item.date_of_measurements) {
              dateExists = true;
            }
          }
          if (dateExists === false) {
            dates.push(item.date_of_measurements);
          }
        });

        dates = dates.sort((a, b) => {
          var dateA = new Date(a).getTime();
          var dateB = new Date(b).getTime();
          return dateA - dateB;
        });
      });
    this.getStatisticalData(dates);
  }
  getStatisticalData(dates) {
    totalResult = [];
    const id = this.props.teamId;
    const queryString = "?athlete=" + this.props.athleteId;
    var counter = 0;
    if (this.props.type !== " ") {
      for (let i = 0; i < dates.length; i++) {
        if (dates[i] !== null) {
          fetch(
            process.env.REACT_APP_API +
              "api/measurementseventsstatistics/?event__date_of_measurements=" +
              dates[i] +
              "&event__team=" +
              id +
              "&type__name=" +
              this.props.type,
            {
              method: "GET",
              headers: {
                Authorization: `JWT ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
              },
            }
          )
            .then((res) => res.json())
            .then((result) => {
              if (result.results !== undefined) {
                totalResult[i] = result.results;
                counter += 1;
                if (counter === dates.length) {
                  this.setState({ loaded: true });
                  this.saveData();
                }
              }
            });
        }
      }
    }
  }

  saveData = () => {
    let data = [];
    var i;
    totalResult.forEach((res) => {
      if (res.length != 0) {
        data.push({
          x: new Date(res[0].date_of_measurement).getTime(),
          y: res[0][this.props.valueName],
        });
      }
    });
    this.setState({ loaded: true });
    let series1 = { name: this.props.type, data: data };
    let seriesAll = [series1];
    this.setState({ series: seriesAll });
  };

  render() {
    if (this.state.loaded) {
      return (
        <div id="chart">
          {this.props.type}
          <ReactApexChart
            options={this.state.options}
            series={this.state.series}
            // text={this.state.title}
            type="line"
            height="200"
            width="300"
          />
          <CustomizedSnackbars
            variant={this.state.snackbarVariant}
            message={this.state.snackbarMessage}
            open={this.state.snackbarOpen}
            closeSnackbar={this.closeSnackbar}
          />
        </div>
      );
    } else if (this.props.type === " ") {
      return <div className={styles.noType}> Engin tegund valin</div>;
    } else {
      return (
        <p>
          {" "}
          Engar mælingar af þessari tegund eru skráðar á þetta lið.
          <br />
          Athugið að þjónustuþegi í liði gætu þó átt mælingar.
        </p>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, null)(TeamStatisticsGraph);
