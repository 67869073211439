import React from "react";
import { Link } from "react-router-dom";

import { Grid, Card } from "@material-ui/core";
import styles from "./Team.module.css";

const Team = (props) => {
  const { id, name, team_logo } = props.team;
  var img = null;
  if (team_logo!== null) {
    img = <img src={team_logo} alt="" className={styles.team_logo} />;
  }
  return (
    <Grid item>
      <Link
        to={{ pathname: `/group/${id}`}}
        style={{ textDecoration: "none" }}
      >
        <Card className={styles.card}>
          <div className={styles.positionLogo}>
            {img}
            <h3>{name}</h3>
          </div>
        </Card>
      </Link>
    </Grid>
  );
};

export default Team;
