import React from "react";
import styles from "./MentalOverview.module.css";
import { Card, Grid, Typography } from "@material-ui/core";
import MentalOverviewTable from "../../components/MentalOverviewTable/MentalOverviewTable";

const MentalOverview = () => {
  return (
    <div>
      <Grid container justify="center" alignItems="center" align="center">
        <MentalOverviewTable withOptions={true} />
      </Grid>
    </div>
  );
};

export default MentalOverview;
