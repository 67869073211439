import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import ReactTableContainer from "react-table-container";
import CustomizedSnackbars from "../../UI/CustomizedSnackbars";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import { withTheme } from "@material-ui/core/styles";
import styles from "./TopMeasurements.module.css";
import { CircularProgress } from "@material-ui/core";

var mapDataForChartImage = new Map();

class TeamMeasurements extends Component {
  constructor(props) {
    super(props);
    this.counter = 0;
    this.selectedTypes = new Map();
    this.selectedNum = 0;
    this.chartIndex = 0;
    this.toolTipCounter = 0;
    this.palette = props.theme.palette;
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      snackbarOpen: false,
      snackbarMessage: "INITAL",
      snackbarVariant: "info",
      error: false,
      width: 0,
      height: 0,
      loaded: false,
      data: [],
      headers: [],
      radarData: [],
      items: [],
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.getPhysicalData = this.getPhysicalData.bind(this);
  }

  closeSnackbar = () => {
    this.setState({
      snackbarOpen: false,
    });
  };

  handleChange(event) {
    this.setState({ value: event.target.value });
    this.getPhysicalData();
  }

  handleClick = (event, name) => {
    this.toolTipCounter = 0;
    this.chartIndex = 0;
    if (this.selectedTypes.get(name)) {
      this.selectedTypes.set(name, false);
      /* DESELECTING type */
      let radarData = this.state.radarData;

      radarData.forEach(function (item, index) {
        if (item.type === name) {
          radarData.splice(index, 1);
        }
      });
      this.selectedNum -= 1;
      this.setState({
        radarData: radarData,
      });
    }
  };

  isItemSelected(type) {
    return this.selectedTypes.get(type);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    this.getPhysicalData(this.props.type);
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.teamId !== prevProps.teamId) |
      (this.props.type !== prevProps.type)
    ) {
      this.setState({
        loaded: false,
      });
      // var table = <CircularProgress />;
      this.getPhysicalData(this.props.type);
    }
  }

  render() {
    const data = this.state.data;
    const headers = this.state.headers;
    this.counter += 1;
    var table = "";
    if (this.state.loaded) {
      if (data.length > 0) {
        table = <></>;
      } else {
        table = (
          <p>
            Engar mælingar af þessari tegund eru skráðar á þetta lið.
            <br />
            Athugið að þjónustuþegar í liði gætu þó átt mælingar.
          </p>
        );
      }
    }

    var tableWidth = this.state.width * 0.6 + "px";
    if (this.state.loaded && data.length) {
      table = (
        <ReactTableContainer
          width={350}
          height="400px"
          customHeader={[TableHead]}
          style={{ borderRadius: "4px" }}
        >
          <Table
            aria-label="customized table"
            style={{ borderRadius: "100px" }}
          >
            <TableHead
              style={{
                backgroundColor: this.palette.primary.light,
                color: "white",
              }}
            >
              {data[0].get("Tegund")}
            </TableHead>
            <TableBody>
              {data.map((row) =>
                headers.map((headerItem, n) =>
                  headerItem !== "Tegund" && n <= this.props.limit ? (
                    <TableRow key={headerItem}>
                      {/* <TableCell style={{ width: "2rem" }}>{n}</TableCell> */}
                      <TableCell component="th" scope="row">
                        {headerItem}
                      </TableCell>
                      <TableCell>{row.get(headerItem)}</TableCell>
                    </TableRow>
                  ) : (
                    <></>
                  )
                )
              )}
            </TableBody>
          </Table>
        </ReactTableContainer>
      );
    }
    if (this.state.loaded) {
      return (
        <div>
          <div className={styles.container}>
            <div className={styles.table}>{table}</div>
            <CustomizedSnackbars
              variant={this.state.snackbarVariant}
              message={this.state.snackbarMessage}
              open={this.state.snackbarOpen}
              closeSnackbar={this.closeSnackbar}
            />
          </div>
        </div>
      );
    } else if (this.props.type === " ")
      return <div className={styles.noType}> Engin tegund valin</div>;
    else {
      return <CircularProgress />;
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  getPhysicalData(type) {
    if (type !== undefined) {
      if (type.includes("5x30m sprettur") | type.includes("Snerpa")) {
        //To check if the data should be in ascending or descending order, since the backend does not say which at this time.
        var order = "data_value";
      } else {
        var order = "-data_value";
      }
    }

    const id = this.props.teamId;
    if (id !== 0 && id !== undefined && id !== "" && type !== " ") {
      this.selectedNum = 0;
      var selNum = this.selectedNum;

      fetch(
        process.env.REACT_APP_API +
          "api/measurements/?event__team=" +
          id +
          "&type__name=" +
          this.props.type +
          "&ordering=-date_of_measurement," +
          order +
          "&limit=" +
          this.props.limit +
          "&data_value__gt=0&event__origin__name=MeasurementsFile",
        {
          method: "GET",
          headers: {
            Authorization: `JWT ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      )
        .then(handleErrors)
        .then((res) => res.json())
        .then(
          (result) => {
            if (!this.state.error) {
              let dates = [];
              let measurementsByTypes = new Map();
              let selectedMap = this.selectedTypes;
              let radarData = [];
              result.results.forEach(function (item, index) {
                let athleteName = "";
                if (item.event.team !== undefined) {
                  athleteName =
                    index +
                    1 +
                    ". " +
                    item.athlete.first_name +
                    " " +
                    item.athlete.last_name +
                    "\n";
                }
                dates.push(athleteName);
                if (
                  !measurementsByTypes.has(item.type.name) &&
                  item.type.name !== "Height" &&
                  item.type.name !== "Weight" &&
                  item.type.name !== "BMI" &&
                  item.data_value > 0
                ) {
                  var measurementByDate = new Map();
                  measurementByDate.set(
                    athleteName,
                    Math.round(item.data_value * 1000) / 1000
                  );
                  measurementsByTypes.set(item.type.name, measurementByDate);
                  if (selNum < 6) {
                    selectedMap.set(item.type.name, true);
                    radarData.push({
                      type: item.type.name,
                    });
                    selNum += 1;
                  } else {
                    selectedMap.set(item.type.name, false);
                  }
                  mapDataForChartImage.set(item.type.name, [item.data_value]);
                } else {
                  if (
                    item.type.name !== "Height" &&
                    item.type.name !== "Weight" &&
                    item.type.name !== "BMI" &&
                    item.data_value > 0
                  ) {
                    measurementsByTypes.set(
                      item.type.name,
                      measurementsByTypes
                        .get(item.type.name)
                        .set(
                          athleteName,
                          Math.round(item.data_value * 1000) / 1000
                        )
                    );
                  }
                }
              });
              let headerNames = [...new Set(dates)];
              // headerNames = headerNames.reverse();
              headerNames = ["Tegund"].concat(headerNames);

              /* Adding the type to make it easier to render as a first column in table*/
              for (let key of measurementsByTypes.keys()) {
                measurementsByTypes.set(
                  key,
                  measurementsByTypes.get(key).set("Tegund", key)
                );
              }

              this.selectedNum = selNum;

              this.selectedTypes = selectedMap;

              let values = Array.from(measurementsByTypes.values());

              this.setState({
                headers: headerNames,
                data: values,
                radarData: radarData,
                loaded: true,
              });
            }
          },
          (error) => {
            this.setState({
              error: true,
              snackbarOpen: true,
              snackbarMessage: "Villa kom upp við að sækja mælingar",
              snackbarVariant: "error",
            });
          }
        );
    }
  }
}

function handleErrors(response) {
  if (!response.ok) {
    this.setState({
      error: true,
    });
  }
  return response;
}

const LabelComponent = ({ id, anchor }) => (
  <g
    transform={`translate(${
      anchor === "end" ? -65 : anchor === "middle" ? -40 : -15
    }, 0)`}
  >
    <text>{id.substring(0, 10) + "..."}</text>
  </g>
);

const mapStateToProps = (state) => ({});

export default withTheme(TeamMeasurements);
