import React, { Component } from "react";
import { connect } from "react-redux";
import CustomizedSnackbars from "../../../UI/CustomizedSnackbars";
import {
  CircularProgress,
  Grid,
  Card,
  CardContent,
  Typography,
} from "@material-ui/core";

import { ResponsivePie } from "@nivo/pie";
import styles from "./SleepInfoPie.module.css";
import { dayNameFromNumber, dateToIcelandicString } from "../../../../utils";
import CardTitleValue from "../../../UI/CardTitleValue/CardTitleValue";
import moment from "moment";

class AthleteCahrtSleepInfoPie extends Component {
  constructor(props) {
    super(props);

    let date = new Date();
    let today = new Date();

    let week = [today.toDateString()];
    let weekdays = [dayNameFromNumber(today.getDay())];

    this.state = {
      snackbarOpen: false,
      snackbarMessage: "INITAL",
      snackbarVariant: "info",
      error: false,
      loaded: false,
      week: week,
      weekdays: weekdays,

      lightSleep: 0,
      deepSleep: 0,
      remsleep: 0,
      totalDuration: 0,
      data: [
        {
          id: "",
          label: "",
          value: 0,
          color: "hsl(28, 70%, 50%)",
        },
      ],
    };
  }

  componentDidUpdate(prevProps) {
    //Typical usage, don't forget to compare the props
    if (this.props.day !== prevProps.day) {
      this.fetchData();
    }
  }

  componentDidMount() {
    this.fetchData();
  }
  fetchData() {
    const id = this.props.athleteId;
    if (id !== 0 && id !== undefined) {
      const queryString = "?athlete=" + this.props.athleteId;
      var yesterday = moment().subtract(1, "d").format("YYYY-MM-DD");
      var day = this.props.day;

      fetch(
        process.env.REACT_APP_API +
          "api/measurements/" +
          queryString +
          "&type__name=WithingsSleepSummary&date_of_measurement__gte=" +
          day +
          "&date_of_measurement__lte=" +
          day,
        {
          method: "GET",
          headers: {
            Authorization: `JWT ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      )
        .then(handleErrors)
        .then((res) => res.json())
        .then(
          (result) => {
            if (result.results.length !== 0) {
              this.setState({ loaded: true });
              var yesterday = moment().format("YYYY-MM-DD");

              let dataArray = [];
              let infoArray = [];
              var i;

              for (i = 0; i < result.results.length; i++) {
                let date = new Date(result.results[i].date_of_measurement);
                //if (this.state.week.includes(date.toDateString())) {
                let sleepData = result.results[i].data;
                /* Multiplying to get milliseconds instead of seconds */
                let startDate = new Date(sleepData.startdate * 1000);
                // Subtracting wakeupduration from endDate so timeSlept does not include time awake
                let endDate = new Date(
                  (sleepData.enddate - sleepData.data.wakeupduration) * 1000
                );
                let millisecondsInDay = 1000 * 60 * 60 * 24;
                let startTime = startDate.getTime() % millisecondsInDay;
                let endTime = endDate.getTime() % millisecondsInDay;
                let millisecondsInHour = 1000 * 60 * 60;

                startTime /= millisecondsInHour;
                endTime /= millisecondsInHour;

                if (startTime > endTime) {
                  startTime -= 24;
                }

                startTime = Math.round(startTime * 1000) / 1000;
                endTime = Math.round(endTime * 1000) / 1000;

                var timeSlept = totalSleepDuration(startTime, endTime);

                if (result.results[i].date_of_measurement === day) {
                  dataArray.push(result.results[i].data.data);
                  break;
                }
                //}
              }
              infoArray = dataArray[Object.keys(dataArray)[0]];

              var dataResults = [];
              var lightSleepTime = infoArray.lightsleepduration;
              var deepSleepTime = infoArray.deepsleepduration;
              var remSleepTime = infoArray.remsleepduration;

              var lightSleepDurationPercentage =
                ((lightSleepTime / 3600 / timeSlept) * 1000) / 1000;
              var deepSleepDurationPercentage =
                ((deepSleepTime / 3600 / timeSlept) * 1000) / 1000;
              var remSleepDurationPercentage =
                ((remSleepTime / 3600 / timeSlept) * 1000) / 1000;

              dataResults.push({
                id: "Remsvefn",
                label: "Remsvefn",
                value: Math.floor(remSleepDurationPercentage * 10000) / 100,
              });
              dataResults.push({
                id: "Laus svefn",
                label: "Laus svefn",
                value: Math.floor(lightSleepDurationPercentage * 10000) / 100,
              });
              dataResults.push({
                id: "Djúpsvefn",
                label: "Djúpsvefn",
                value: Math.floor(deepSleepDurationPercentage * 10000) / 100,
              });

              this.setState({
                data: dataResults,
                loaded: true,
                totalDuration: timeSlept,
                lightSleep: lightSleepTime,
                deepSleep: deepSleepTime,
                remSleep: remSleepTime,
              });
            }
          },
          (error) => {
            this.setState({
              error: true,
              snackbarOpen: true,
              snackbarMessage: "Villa kom upp við að sækja svefnupplýsingar.",
              snackbarVariant: "error",
            });
          }
        );
    }
  }
  closeSnackbar = () => {
    this.setState({
      snackbarOpen: false,
    });
  };

  render() {
    var data = this.state.data;
    var totalSleep = this.state.totalDuration;
    var lightSleepTime = this.state.lightSleep / 3600;
    var deepSleepTime = this.state.deepSleep / 3600;
    var remSleepTime = this.state.remSleep;

    totalSleep = toHoursAndMinutes(totalSleep);
    lightSleepTime = toHoursAndMinutes(lightSleepTime);
    deepSleepTime = toHoursAndMinutes(deepSleepTime);

    if (remSleepTime !== undefined) {
      remSleepTime = toHoursAndMinutes(remSleepTime);
    } else {
      remSleepTime = 0;
      remSleepTime = toHoursAndMinutes(0);
    }

    var pie = <CircularProgress className={styles.pieSize} />;
    if (this.state.loaded && data.length !== 0) {
      pie = (
        <ResponsivePie
          data={data}
          margin={{ top: 50, right: 20, bottom: 40, left: 20 }}
          innerRadius={0.5}
          padAngle={0.9}
          cornerRadius={6}
          borderWidth={1}
          colors={[
            "rgba(51, 51, 255,1)", //REM
            "rgba(51, 201, 255,1)", //lightsleep
            "rgba(51, 126, 255,1)", //deepsleep
            "rgba(51, 51, 255,1)",
          ]}
          borderColor={{ from: "color" }}
          radialLabelsSkipAngle={10}
          radialLabelsTextXOffset={6}
          radialLabelsTextColor={function (e) {
            if (window.outerWidth > 400) {
              return "black";
            } else {
              return "white";
            }
          }}
          radialLabelsLinkOffset={0}
          radialLabelsLinkDiagonalLength={16}
          radialLabelsLinkHorizontalLength={24}
          radialLabelsLinkStrokeWidth={1}
          // radialLabelsLinkColor={{ from: "color" }}
          radialLabelsLinkColor={function (e) {
            if (window.outerWidth > 400) {
              return "black";
            } else {
              return "white";
            }
          }}
          slicesLabelsSkipAngle={5}
          slicesLabelsTextColor="black"
          sliceLabel={function (e) {
            return e.value.toFixed(2) + " %";
          }}
          animate={true}
          motionStiffness={90}
          motionDamping={15}
          legends={[
            {
              anchor: "top-right",
              direction: "column",
              translateX: 10,
              translateY: -20,
              itemWidth: 80,
              itemHeight: 25,
              itemTextColor: "black",
              symbolSize: 18,
              symbolShape: "circle",
              effects: [
                {
                  on: "hover",
                  style: {
                    itemTextColor: "#000",
                    itemTextSize: "medium",
                  },
                },
              ],
            },
          ]}
        />
      );
    } else {
      pie = null;
    }

    return (
      <div>
        {lightSleepTime != "0 klst og 0 mín" && (
          <div className={styles.activities}>
            <div className={styles.pieSize}>{pie}</div>
            <div>
              <Grid className={styles.cardContainer} align="center">
                <Grid className={styles.cardContent}>
                  <CardTitleValue title="Laus svefn:" value={lightSleepTime} />
                </Grid>
                <Grid className={styles.cardContent}>
                  <CardTitleValue title="Djúpsvefn:" value={deepSleepTime} />
                </Grid>
                <Grid className={styles.cardContent}>
                  <CardTitleValue title="Remsvefn" value={remSleepTime} />
                </Grid>
              </Grid>
            </div>
          </div>
        )}
        <CustomizedSnackbars
          variant={this.state.snackbarVariant}
          message={this.state.snackbarMessage}
          open={this.state.snackbarOpen}
          closeSnackbar={this.closeSnackbar}
        />
      </div>
    );
  }
}

function totalSleepDuration(startTime, endTime) {
  let timeSlept = 0;
  if (startTime < 0) {
    timeSlept = startTime * -1 + endTime;
  } else {
    timeSlept = endTime - startTime;
  }
  return timeSlept;
}

function toHoursAndMinutes(sec) {
  let hours = Math.floor(sec);
  let minutes = Math.round((sec - hours) * 60);
  sec = hours + " klst og " + minutes + " mín";
  return sec;
}

function handleErrors(response) {
  if (!response.ok) {
    this.setState({ error: true });
  }
  return response;
}

const mapStateToProps = (store) => {
  if (store.auth.athlete) {
    var athlete = store.auth.athlete;
  } else {
    var athlete = store.router.location.state;
  }
  return {
    userType: store.auth.userType,
    athlete: athlete,
  };
};

export default connect(mapStateToProps, null)(AthleteCahrtSleepInfoPie);
