import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Avatar } from "@material-ui/core";
import UserInformation from "../../User/Information/Information";
import styles from "./Info.module.css";

const AthleteInfo = (props, match) => {
  /* These variables are for case when athlete doesn't have a user */

  const [athlete, setAthlete] = useState(null);
  const [athleteId, setAthleteId] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [profilePicture, setProfilePicture] = useState("");

  useEffect(() => {
    // athlete object available the athlete is logged in (for other users viewing ahtlete, have to fetch)
    if (props.athlete) {
      setAthlete(props.athlete);
      setAthleteId(props.athlete.id);
      setLoaded(true);
    } else {
      // Fetching athlete if coach or researcher opening link on their own
      var parts = window.location.href.split("/");
      var athleteId = parts[4];
      fetch(process.env.REACT_APP_API + "api/athletes/" + athleteId + "/", {
        method: "GET",
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((result) => {
          setAthlete(result);
          setAthleteId(result.id);
          setLoaded(true);
        })
        .catch((err) => {});
    }
  }, []);

  useEffect(() => {
    if (athlete!==null) {
      if (athlete.profile_picture==="") {
        setProfilePicture(athlete.first_name);
      } else {
        setProfilePicture(athlete.profile_picture);
      }
    }
  }, [athlete]);

  return (
      <div>
        {loaded && (
          <h3 className="flexContainerCenter"><Avatar src={profilePicture} alt={`${athlete.first_name} ${athlete.last_name}`} className={styles.avatar}/>{`${athlete.first_name} ${athlete.last_name}`}</h3>
        )}
        <div className={[styles.info, "flexContainerCenter"].join(' ')} >
          {loaded && <UserInformation user={athlete} userType={"athlete"} />}
        </div>
      </div>
    );
};

const mapStateToProps = (store) => {
  if (store.auth.athlete) {
    var athlete = store.auth.athlete;
  } else {
    var athlete = store.router.location.state;
  }
  return {
    userType: store.auth.userType,
    userId: store.auth.id,
    name: store.auth.fullName,
    profilePicture: store.auth.profilePicture,
    athlete: athlete,
  };
};

export default connect(mapStateToProps)(AthleteInfo);
