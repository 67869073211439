import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import Teams from "../../../components/Team/Teams/Teams";
import styles from "./Overview.module.css";
import { Paper } from "@material-ui/core";
import UserTeamTimeline from "../../../components/User/TeamTimeline/TeamTimeline";
import CustomizedSnackbars from "../../../components/UI/CustomizedSnackbars";
import MentalOverviewTable from "../../../components/MentalOverviewTable/MentalOverviewTable";

const CoachOverview = (props) => {
  const [coach, setCoach] = useState(null);
  const [coachId, setCoachId] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("INITIAL");
  const [snackbarVariant, setSnackbarVariant] = useState("info");

  useEffect(() => {
    if (error) {
      setSnackbarOpen(true);
      setSnackbarMessage("Villa kom upp við að sækja gögn.");
      setSnackbarVariant("error");
      setError(false);
      setError(false);
    }
  }, [error]);

  useEffect(() => {
    if (!loaded) {
      if (props.userType !== "coach") {
        let id = props.match.params.id;
        fetch(process.env.REACT_APP_API + "api/coaches/" + id + "/", {
          method: "GET",
          headers: {
            Authorization: `JWT ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        })
          .then((res) => res.json())
          .then((result) => {
            if (!result.id) {
              setError(true);
            } else {
              setCoach(result);
              setCoachId(result.id);
              setLoaded(true);
            }
          })
          .catch((err) => {
            setError(true);
          });
      } else {
        setCoachId(props.coach.id);
        setCoach(props.coach);
        setLoaded(true);
      }
    }
  }, []);

  const closeSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <div>
      <Paper className="fullWindowPaper">
        <h2>Yfirlit mælinga</h2>
        <MentalOverviewTable topOnly={true} limit={5} />

        <h2>Hóparnir mínir</h2>
        <Teams />

        <h2>Sagan mín</h2>
        {loaded && <UserTeamTimeline userType="coach" objectId={coachId} />}
      </Paper>
      <CustomizedSnackbars
        variant={snackbarVariant}
        message={snackbarMessage}
        open={snackbarOpen}
        closeSnackbar={closeSnackbar}
      />
    </div>
  );
};

const mapStateToProps = (store) => {
  if (store.auth.coach) {
    var coach = store.auth.coach;
  }
  return {
    userType: store.auth.userType,
    coach: coach,
  };
};

export default connect(mapStateToProps)(CoachOverview);
