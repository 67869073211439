import React, { Component } from "react";
import { connect } from "react-redux";

import { Typography, Paper } from "@material-ui/core";

import styles from "./NotFound.module.css";

class NotFound extends Component {
  render() {
    return (
      <Paper>
        <Typography component="div">
          <div>
            <h1 className={styles.title}>Því miður fannst ekki síðan sem þú varst að leita að</h1>
            <h2 className={styles.url}>{window.location.href}</h2>
            <br></br>
          </div>
        </Typography>
      </Paper>
    );
  }
}

const mapStateToProps = state => ({
});

export default connect(
  mapStateToProps
)(NotFound);