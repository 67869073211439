import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { Menu} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import LinkMenuItem from "./LinkMenuItem";
import { logout } from "../../actions/authActions";

const UsersDropDown = (props) => {
  const [dropdown, setDropdown] = useState(null);

  const handleMenu = (event) => {
    setDropdown(event.currentTarget);
  };

  const handleClose = () => {
    setDropdown(null);
  };

  const open = Boolean(dropdown);


  return (
    <div>
      <Button color="inherit" onClick={(e) => handleMenu(e)}>
          Notendur
        </Button>
      <Menu
        id="meas-appbar"
        anchorEl={dropdown}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={open}
        onClose={() => handleClose()}
      >
        <LinkMenuItem
          title="Rannsakendur"
          link="/researchers"
          close={() => handleClose()}
        />
        <LinkMenuItem
          title="Meðferðaraðilar"
          link="/advisers"
          close={() => handleClose()}
        />
        <LinkMenuItem
          title="Þjónustuþegar"
          link="/users"
          close={() => handleClose()}
        />
      </Menu>
    </div>
  );
};

const mapStateToProps = ({ auth }) => {
  return {
    userType: auth.userType,
    profilePicture: auth.profilePicture,
    name: auth.fullName,
  };
};

export default connect(mapStateToProps, { logout })(UsersDropDown);
