import React, { useState, useEffect } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  Button,
  Modal,
  Backdrop,
  Fade,
} from "@material-ui/core";
import ConfirmDialog from "../../UI/ConfirmDialog";
import CustomizedSnackbars from "../../UI/CustomizedSnackbars";
import styles from "./TeamSettings.module.css";
import UserTeamAccess from "../../User/TeamAccess/TeamAccess";

const UserTeamSettings = (props) => {
  const { id, userType } = props;
  const [myTeams, setMyTeams] = useState([]);
  const [updateTeams, setUpdateTeams] = useState(false);

  // Delete warning
  const [openDeleteWarning, setOpenDeleteWarning] = useState(false);
  const [deleteWarningMessage, setDeleteWarningMessage] = useState("");
  const [deleteId, setDeleteId] = useState(0);

  // Team Access modal
  const [openModal, setOpenModal] = useState(false);
  const [teamAccess, setTeamAccess] = useState(<></>);

  // Messages
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("INITIAL");
  const [snackbarVariant, setSnackbarVariant] = useState("info");
  const [error, setError] = useState(false);

  let queryString = "";

  useEffect(() => {
    if (userType === "athlete") {
      queryString = "api/athleteinteams/?athlete=" + id;
    } else {
      // user is a coach
      queryString = "api/coachofteams/?coach=" + id;
    }
    fetch(process.env.REACT_APP_API + queryString, {
      method: "GET",
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          let tmpTeams = [];
          for (let i = 0; i < result.results.length; i++) {
            tmpTeams.push(result.results[i]);
          }
          setMyTeams(tmpTeams);
        },
        (error) => {
          setError(true);
          setSnackbarOpen(true);
          setSnackbarMessage("Villa kom upp við að sækja hóp.");
          setSnackbarVariant("error");
        }
      );
  }, [id, updateTeams]);

  const handleDeleteClick = (name, id) => {
    setOpenDeleteWarning(true);
    setDeleteId(id);
    setDeleteWarningMessage(
      "Ertu viss um að þú viljir yfirgefa hópinn " + name + "?"
    );
  };

  const handleCloseDeleteWarning = (confirmed) => {
    setOpenDeleteWarning(false);
    if (confirmed) {
      handleLeaveTeam(deleteId);
    }
  };

  const handleLeaveTeam = (userId) => {
    if (userType === "athlete") {
      queryString = "api/athleteinteams/" + userId + "/";
    } else {
      // user is a coach
      queryString = "api/coachofteams/" + userId + "/";
    }
    fetch(process.env.REACT_APP_API + queryString, {
      method: "DELETE",
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        setUpdateTeams(!updateTeams);
      })
      .then(
        (result) => {},
        (error) => {
          setError(true);
          setSnackbarOpen(true);
          setSnackbarMessage("Villa kom upp við að yfirgefa hóp.");
          setSnackbarVariant("error");
        }
      );
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleOpenModal = (teamId) => {
    setOpenModal(true);
    setTeamAccess(
      <UserTeamAccess teamId={teamId} userType={userType} athleteId={id} />
    );
  };

  let teams = myTeams.map((team, index) => {
    var date = new Date(team.member_since);
    return (
      <>
        <div className={styles.paper}>
          <Paper>
            <Modal
              open={openModal}
              onClose={() => handleClose()}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={openModal}>
                <div className={styles.modalContent}>{teamAccess}</div>
              </Fade>
            </Modal>
          </Paper>
        </div>
        <TableRow key={"team" + index}>
          <TableCell>{team.team.name}</TableCell>
          <TableCell className={styles.show}>
            {date.toLocaleDateString()}
          </TableCell>
          <TableCell>
            {userType==="coach" && (
                <Button onClick={() => handleOpenModal(team.team.id)}>
                  Skoða
                </Button>
              )}
              {userType==="athlete" && (
                <Button onClick={() => handleOpenModal(team.team.id)}>
                  Breyta
                </Button>
              )}
          </TableCell>
          <TableCell>
            <Button onClick={() => handleDeleteClick(team.team.name, team.id)}>
              Fara úr hóp
            </Button>
          </TableCell>
        </TableRow>
      </>
    );
  });

  const closeSnackbar = () => {
    setSnackbarOpen(false);
  };

  let snackbar = (
    <CustomizedSnackbars
      variant={snackbarVariant}
      message={snackbarMessage}
      open={snackbarOpen}
      closeSnackbar={closeSnackbar}
    />
  );

  return (
    <div className={styles.display}>
      {snackbar}
      <Paper className={styles.display}>
        <h2 style={{paddingTop: "10px"}}>Hóparnir mínir</h2>
        <Table style={{ maxWidth: "100%" }}>
          <TableHead>
            <TableRow>
              <TableCell>Nafn hóps</TableCell>
              <TableCell className={styles.show}>Meðlimur síðan</TableCell>
                {userType==="coach" && (
                  <TableCell>Meðferðaraðilar</TableCell>
                )}
                {userType==="athlete" && (
                  <TableCell>Hópstillingar</TableCell>
                )}
              <TableCell>Aðgerðir</TableCell>
            </TableRow>
          </TableHead>
          {teams}
        </Table>
        <ConfirmDialog
          open={openDeleteWarning}
          onClose={(e) => handleCloseDeleteWarning(e)}
          message={deleteWarningMessage}
          title={"Yfirgefa hóp"}
          redButton={true}
          confirmText={"Yfirgefa"}
        />
      </Paper>
    </div>
  );
};

export default UserTeamSettings;
