import React from "react";
import { Link } from "react-router-dom";
import {Button, Avatar} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./IconTextButton.module.css";

const IconTextButton= ({ text, icon, color, link, active }) => {
  let style = {
    color: color,
    textDecoration: "none"
  };

  if (active) {
    style = {
      backgroundColor: color,
      color: "white",
      textDecoration: "none"
    };
  }

  
  
  return (
    <a className={[styles.container].join(" ")} style={style} href={link} >
      <Button
      style={style}
      component={Link}
      to={{ pathname: link }}
      className={[styles.button,].join(" ")}
      >
          <FontAwesomeIcon
          icon={icon}
          className={styles.icon}
          />
      </Button>
      <h4 className={styles.text} style={style}>
        {text}
      </h4>
    </a>
  );
};

export default IconTextButton;
