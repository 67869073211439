import {
  LOG_IN_SUCCESS,
  LOG_IN_FAILURE,
  LOG_OUT,
  SERVER_ERROR,
  RESET,
} from "./authTypes";
import { push } from "connected-react-router";
import { noValue } from "../utils";

export const loginSuccess = (loginData) => ({
  type: LOG_IN_SUCCESS,
  payload: loginData,
});

export const loginFailure = () => ({
  type: LOG_IN_FAILURE,
});

export const serverError = () => ({
  type: SERVER_ERROR,
});

export const logoutCleanUp = () => ({
  type: LOG_OUT,
});

export const login = (loginData) => (dispatch) => {
  fetch(process.env.REACT_APP_API + "token-auth/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(loginData),
  })
    .then((res) => res.json())
    .then(
      (credentials) => {
        if (!noValue(credentials) && !noValue(credentials.token)) {
          localStorage.setItem("token", credentials.token);
          dispatch(loginSuccess(credentials));
        } else {
          dispatch(loginFailure());
        }
      },
      (error) => {
        dispatch(serverError());
      }
    );
};

export const logout = () => (dispatch) => {
  localStorage.removeItem("token");
  dispatch(logoutCleanUp());
};

export const isLoggedIn = () => (dispatch) => {
  const token = {
    token: localStorage.getItem("token"),
  };

  if (token.token === null) {
    localStorage.removeItem("token");
    dispatch({
      type: LOG_OUT,
    });
  }

  fetch(process.env.REACT_APP_API + "token-verify/", {
    method: "post",
    body: JSON.stringify(token),
    headers: {
      "content-type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((credentials) => {
      if (credentials.token === token.token) {
        dispatch(loginSuccess(credentials));
      } else {
        localStorage.removeItem("token");
        dispatch({
          type: LOG_OUT,
        });
      }
    })
    .catch((err) => {
      localStorage.removeItem("token");
      dispatch({
        type: LOG_OUT,
      });
    });
};

export const resetError = () => (dispatch) => {
  dispatch({
    type: RESET,
  });
};
