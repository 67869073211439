import React, { useState, useEffect } from "react";
import CustomizedSnackbars from "../../UI/CustomizedSnackbars";
import { connect } from "react-redux";
import { Button, Modal, Backdrop, Fade } from "@material-ui/core";
import styles from "./ChangeTeamButton.module.css";
import EditIcon from "@material-ui/icons/Edit";
import TeamInformation from "../Information/TeamInformation";

const ChangeTeamButton = (props) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("INITIAL");
  const [snackbarVariant, setSnackbarVariant] = useState("info");
  const [openModal, setOpenModal] = useState(false);
  useEffect(() => {}, []);

  const closeSnackbar = () => {
    setSnackbarOpen(false);
  };

  let snackbar = (
    <CustomizedSnackbars
      variant={snackbarVariant}
      message={snackbarMessage}
      open={snackbarOpen}
      closeSnackbar={closeSnackbar}
    />
  );

  const handleTeamUpdate = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleUpdate = (text) => {
    setSnackbarOpen(true);
    setSnackbarMessage(text);
    setSnackbarVariant("success");
    setOpenModal(false);
  };
  if (props.userType === "researcher") {
    return (
      <div className={styles.updateTeam}>
        {snackbar}
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleTeamUpdate()}
          startIcon={<EditIcon />}
        >
          {props.text}
        </Button>
        <div className={styles.modal}>
          <Modal
            open={openModal}
            onClose={() => handleClose()}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={openModal}>
              <div className={styles.modalContent}>
                <h2 id="simple-modal-title">{props.text}</h2>
                <TeamInformation
                  team={props.team}
                  userType={props.userType}
                  edit={props.edit}
                  editToggle={props.editToggle}
                  setEditToggle={props.setEditToggle}
                  team_logo={props.team_logo}
                  infoChanged={props.infoChanged}
                  handleUpdate={handleUpdate}
                />
              </div>
            </Fade>
          </Modal>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

const mapStateToProps = (store) => {
  return {
    userType: store.auth.userType,
  };
};

export default connect(mapStateToProps)(ChangeTeamButton);
