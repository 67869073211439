const rules = 
`Skjölin þurfa að vera af tegundinni .csv eða .xlsx 

Hægt er að skoða eldri skjöl til þess að gera sér grein fyrir mögulegri uppsetningu mæliskjala en hér er listað það sem þarf að hafa í huga við smíði skjalanna.

Eftirfarandi gildi (óháð stórum/litum stöfum) eru skráðar sem upplýsingar um þjónustuþega, annað verður skráð sem mæling:
    nafn
    netfang / tölvupóstfang / tölvupóstur (ATH hver þjónustuþegi þarf að hafa sér netfang annars eru mælingar fyrir tvo aðila bara skráðar á annan hvorn.)
    Eitthvað sem inniheldur afmæli eða fæðingardagur
    félagslið / félag
    leikstaða
    kyn
    símanúmer / sími
    íþrótt
    ríkjandi fótur / ríkjandi hendi / ríkjandi hlið

    Ef fleiri en eitt af titlum fyrir sama hlut eru notaðir er það seinna sem verður lesið inn.
    Passið að hafa engin auka bil.

Reglur um gildi:
· Skráning fyrir kyn er 0 fyrir annað 1 fyrir kk og 2 fyrir kvk
· Ef ekkert gildi er fyrir mælingu þá á að skilja reitinn eftir tómann og fer þá engin mæling inn í kerfið.
· Fæðingardagar þurfa að vera á forminu dd/mm/áááá eða dd.mm.áááá

Reglur varðandi uppsetningu skjalanna:
· Það þarf að standa ,,númer”  eða ,,Númer’’ í dálki A (byrja mælingar þar sem stendur 1) fyrir neðan, má ekki koma bil í tölurnar því það er hætt að lesa eftir fyrsta tóma reit fyrir neðan.
· Einungis fyrsta síða er lesin. 
· Hægt er að bæta við ** fyrir framan titil á mælingu til þess að láta kerfið sleppa að lesa hana inn.
· Þegar tegund mælingu er í tveimur línum (yfirdálkur fyrir ofan og svo nokkrir undirdálkar fyrir neðan) þá þarf að passa að hafa yfirdálkinn merged (lárétt) alla leið.

Eftir að skjal hefur verið lesið inn er það merkt litum:
· Rautt: Það kom upp villa við að lesa inn skjal (smellt á talbólu fyrir nánari upplýsingar).
· Gult: Það tókst að lesa inn skjal (það geta þó verið athugasemdir ef smellt er á talbólu). Hægt er að sækja niðurstöður skjals með því að smella á mælistikuna en þannig munu mælingar munu fara inn í kerfið.
· Grænt: Allar mælingar úr skjalinu eru komnar inn í kerfið og eru sýnilegar viðeigandi aðilum.

Lesa úr niðurstöðum skjals:
· Tómar mælingar eru litaðar gráar
· Mælingategundir sem eru nýjar (hafa ekki verið settar inn í kerfið áður) eru litaðar gular.
· Þjónustuþegar sem eru ekki til í kerfinu (nýtt netfang) eru litaðir gulir og verða stofnaðir þegar niðurstöður skjals eru samþykktar.
· Ef það vantar netfang fyrir þjónustuþega er hann litaður rauður (því ekki verður hægt að stofna aðgang fyrir viðkomandi)+

Óvænt villa
· Það getur komið upp óvænt villa þegar reynt er að búa til nýja mælingu ef þjónustuþegi í skjalinu er þegar með aðgang að kerfinu sem leiðbeinandi eða rannsakandi þar sem ekki er hægt að vera með mismunandi aðgang að kerfinu undir sama netfangi.

`;

export default rules ;