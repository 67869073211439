import React, { useState, useEffect } from "react";
import {TableCell, TableRow, Button} from '@material-ui/core';
import withingsLogo from '../../../assets/withings.png'; // Tell Webpack this JS file uses this image
import MomentLocaleUtils, {
  formatDate
} from "react-day-picker/moment";
import CustomizedSnackbars from "../../UI/CustomizedSnackbars";
import styles from "./WithingsService.module.css"

const UserWithingsService = (props) => {
  const [status, setStatus] = useState("");
  const [statusClassName, setStatusClassName] = useState("");
  const [action, setAction] = useState( <Button onClick={() => connectToWithings()}> Tengjast </Button>);
  const [withingsUserId, setWithingsUserId] = useState("");
  const [withingsTokensId, setWithingsTokensId] = useState("");

  // Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("INITIAL");
  const [snackbarVariant, setSnackbarVariant] = useState("info");
  
  const closeSnackbar = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    if (!props.loaded) {
      fetch(process.env.REACT_APP_API + "api/withingstokens/", {
        method: "GET",
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
          "Content-Type": "application/json"
        },
      })
      .then(res => res.json())
      .then(
        (result) => {
          props.setLoaded(true)
          if (result.results.length == 1) {
            setWithingsUserId(result.results[0].withings_user_id)
            setWithingsTokensId(result.results[0].id)
            if (result.results[0].new_authentication_needed) {
              setStatus("Útrunnið")
              setAction(<Button onClick={() => connectToWithings()}> Endurtengja </Button>)
            }
            else {
              let timestamp = new Date(result.results[0].timestamp)
              let expire = new Date(timestamp.getFullYear() + 1, timestamp.getMonth(), timestamp.getDate());
              const diffTime = expire - new Date();
              const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
              if (diffDays < 7) {
                setStatusClassName(styles.danger)
              }
              setStatus("Tengt til " + formatDate(expire.toISOString().slice(0,10), "LL", "is" ))
              setAction(<Button onClick={() => disconnectFromWithings(result.results[0].id, result.results[0].withings_user_id)}> Aftengjast </Button>)
            }
            
          }
          else {
            setStatus("Ótengt")
            setAction(<Button onClick={() => connectToWithings()}> Tengjast </Button>)
          }
        },
        (error) => {
          props.setLoaded(true)
        }
      )
    }
  }, [props.loaded]);
  
  const connectToWithings = () => {
    window.location =
      "https://account.withings.com/oauth2_user/authorize2?response_type=code&client_id=" + process.env.REACT_APP_WITHINGS_CLIENT_ID +"&state=verifiedToken&scope=user.info,user.metrics,user.activity&redirect_uri=" + process.env.REACT_APP_WITHINGS_REDIRECT_URI;
  };

  const disconnectFromWithings = (withingstokensid, withingsuserid) => {
    fetch(process.env.REACT_APP_API + "api/withingstokens/" + withingstokensid + "/", {
      method: "DELETE",
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`,
        "Content-Type": "application/json"
      },
    })
    .then(res => res)
    .then(
      (res) => {
        if (res.ok) {
          // Not changing other variables since does automatically once the user returns.
          window.location = "https://account.withings.com/partner/disconnect_partner/oauth2/" + process.env.REACT_APP_WITHINGS_CLIENT_ID +"?selecteduser=" + withingsuserid
        }
        else {
          setSnackbarOpen(true)
          setSnackbarMessage('Það kom upp villa, vinsamlegast reynið aftur')
          setSnackbarVariant('error')
        }
      },
      (error) => {
        setSnackbarOpen(true)
        setSnackbarMessage('Það kom upp villa, vinsamlegast reynið aftur')
        setSnackbarVariant('error')
      }
    )
  };

  return (
    <TableRow key={"Withings"}>
      <TableCell>
          <img src={withingsLogo} alt="Withings" className={styles.logo}/>
      </TableCell>
      <TableCell className={statusClassName}>{status}</TableCell>
      <TableCell>
        {action}
      </TableCell>
      <CustomizedSnackbars
        variant={snackbarVariant}
        message={snackbarMessage}
        open={snackbarOpen}
        closeSnackbar={closeSnackbar}
      />
    </TableRow>
  );
};

export default UserWithingsService;
