import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import AthleteChartSleepWeekOverview from "../../../components/Athlete/Chart/SleepWeekOverview/SleepWeekOverview";
import AthleteChartSleepDetails from "../../../components/Athlete/Chart/SleepDetails/SleepDetails";
import AthleteChartSleepHeartRate from "../../../components/Athlete/Chart/SleepHeartRate/SleepHeartRate";
import AthleteChartSleepInfoPie from "../../../components/Athlete/Chart/SleepInfoPie/SleepInfoPie";
import AthleteChartSleepRespirationRate from "../../../components/Athlete/Chart/SleepRespirationRate/SleepRespirationRate.js";

import {
  Typography,
  Grid,
  InputLabel,
} from "@material-ui/core";
import moment from "moment";
import AthleteInfo from "../../../components/Athlete/Info/Info"
import styles from "./Sleep.module.css";
import AthleteDataCategories from "../../../components/Athlete/DataCategories/DataCategories";
import DataTab from "../../../components/UI/DataTab/DataTab";
import IcelandicDayPicker from "../../../components/UI/IcelandicDayPicker";

const AthleteSleep = (props) => {
  const [overviewDay, setOverviewDay] = useState(new Date().toISOString().substring(0,10));
  const [day, setDay] = useState(new Date().toISOString().substring(0,10));
  let athleteId = null;
  if (props.userType!=="athlete") {
    athleteId = props.match.params.id;
  } else {
    athleteId = props.athlete.id;
  }

  return (
    <Typography component="div">
      <AthleteInfo/>
      <AthleteDataCategories/>
      <DataTab icon="chart-bar" color="#54638f" text="Svefnyfirlit">
        <div className={styles.input}>
          <InputLabel shrink>Velja dagsetningu</InputLabel>
          <IcelandicDayPicker setDay={setOverviewDay}/>
        </div>
        <AthleteChartSleepWeekOverview
          athleteId={athleteId}
          date={new Date(overviewDay)}
          Page="Athlete"
        />
      </DataTab>
      <DataTab icon="bed" color="#658e96" text="Hvernig svaf ég í nótt?">
        <div className={styles.input}>
          <InputLabel shrink>Velja dagsetningu</InputLabel>
          <IcelandicDayPicker setDay={setDay}/>
        </div>
        <Grid container spacing={1} className={styles.justify}>
          <div className={styles.sleepNightCont}>
            <Grid item xs={10} className={styles.justify}>
              <AthleteChartSleepDetails
                athleteId={athleteId}
                day={day}
              />
              <div className={styles.heartrate}>
                <AthleteChartSleepHeartRate athleteId={athleteId} day={day} />
              </div>
            </Grid>
            <Grid item xs={10} className={styles.justify}>
              <AthleteChartSleepInfoPie
                athleteId={athleteId}
                // className={styles.lightDeepSleep}
                day={day}
              />
              <div className={styles.heartrate}>
                <AthleteChartSleepRespirationRate athleteId={athleteId} day={day} />
              </div>
            </Grid>
          </div>
        </Grid>
      </DataTab>
    </Typography>
  );
};

const mapStateToProps = (store) => {
  if (store.auth.athlete) {
    var athlete = store.auth.athlete;
  }
  return {
    userType: store.auth.userType,
    athlete: athlete,
  };
};

export default connect(mapStateToProps, null)(AthleteSleep);
