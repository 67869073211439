import React, { useState, Fragment } from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { Typography, Button, Paper} from "@material-ui/core";
import UserInformation from "../../components/User/Information/Information";
import styles from "./Account.module.css";
import UserPasswordChangeForm from "../../components/User/PasswordChange/PasswordChangeForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UserTeamsSettings from "../../components/User/TeamSettings/TeamSettings";
import UserChangeEmailButton from "../../components/User/ChangeEmailButton/ChangeEmailButton";
import UserTeamTimeline from "../../components/User/TeamTimeline/TeamTimeline";
import PasswordIcon from '@material-ui/icons/VpnKey';

const Account = (props) => {
  const { userType, userId } = props;
  const button = (
    <Button
        type="submit"
        variant="contained"
        color="secondary"
        startIcon={<PasswordIcon/>} 
        onClick={() => handlePassClick(passwordToggle)}
      >
        Breyta lykilorði
      </Button>
  )
  let info = <></>;
  const [passwordChangeForm, setPasswordChangeForm] = useState(<></>);
  const [infoChanged, setInfoChanged] = useState(false);
  const [passwordButton, setPasswordButton] = useState( button );
  const [passwordToggle, setPasswordToggle] = useState(false);
  const [editToggle, setEditToggle] = useState(false);

  let userEmail;
  let id;
  if (props.userType === "athlete") {
    userEmail = props.athlete.email;
    id = props.athlete.id;
  } else if (props.userType === "coach") {
    userEmail = props.coach.email;
    id = props.coach.id;
  } else {
    userEmail = props.researcher.email;
    id = props.researcher.id;
  }

  let edit = (
    <FontAwesomeIcon
      icon="edit"
      style={{
        color: "grey",
        float: "right",
        marginTop: "20px",
        marginRight: "15px",
        cursor: "pointer",
      }}
      className={styles.theicons}
      onClick={() => {
        setEditToggle(true);
      }}
    />
  );

  const handlePassClick = (closed) => {
    if (closed) {
      setPasswordToggle(false);
      setPasswordChangeForm(<></>);
      setPasswordButton(
        button
      );
    } else {
      setPasswordToggle(!passwordToggle);
      setPasswordChangeForm(
        <>
          <UserPasswordChangeForm
            handlePassClick={handlePassClick}
            email={userEmail}
            userId={props.userId}
          />
        </>
      );
      setPasswordButton(<></>);
    }
  };

  const handleEmailChanged = () => { 
    setInfoChanged(!infoChanged)
  }

  if (userType === "athlete") {
    const { athlete } = props;
    info = (
      <>
        <Typography component="div">
          <Grid container spacing={0} className={styles.containerAthlete}>
            <Grid item xs={6} className={styles.playerInfo}>
              <UserInformation
                user={athlete}
                userType={userType}
                id={userId}
                edit={edit}
                editToggle={editToggle}
                setEditToggle={setEditToggle}
                profilePic={props.profilePicture}
                infoChanged={infoChanged}
              />
            </Grid>
          </Grid>
        </Typography>
      </>
    );
  } else if (userType === "researcher") {
    const { researcher } = props;
    info = (
      <>
        <Typography component="div">
          <Grid container spacing={0} className={styles.containerAthlete}>
            <Grid item xs={6} className={styles.playerInfo}>
              <UserInformation
                user={researcher}
                userType={userType}
                edit={edit}
                editToggle={editToggle}
                setEditToggle={setEditToggle}
                profilePic={props.profilePicture}
              />
            </Grid>
          </Grid>
        </Typography>
      </>
    );
  } else if (userType === "coach") {
    const { coach } = props;

    info = (
      <>
        <Typography component="div">
          <Grid container spacing={0} className={styles.containerAthlete}>
            <Grid item xs={6} className={styles.playerInfo}>
              <UserInformation
                user={coach}
                userType={userType}
                edit={edit}
                editToggle={editToggle}
                setEditToggle={setEditToggle}
                profilePic={props.profilePicture}
              />
            </Grid>
          </Grid>
        </Typography>
      </>
    );
  }

  let objectId = 0;
  if (userType==="athlete") {
    objectId = props.athlete.id
  }
  else if (userType==="coach") {
    objectId = props.coach.id
  }
  else {
    objectId = props.researcher.id
  }
  return (
    <Fragment>
      <div className={styles.container}>
        <div className={styles.first}>{info}</div>
        <div className={styles.second}>
          <div className={styles.teams}>
            {userType!=="researcher" ? (
              <UserTeamsSettings id={id} userType={userType} />
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className={styles.third}>
          <div className={styles.passwordChange}>
            <UserChangeEmailButton handleChanged = {handleEmailChanged}/>
          </div>
          <div className={styles.passwordChange} style={{ marginTop: "20px"}}>
            {passwordButton}
            {passwordChangeForm}
          </div>
        </div>
      </div>
      {(userType!=="researcher") && (
          <Paper className="fullWindowPaper">
          <h2>Sagan mín</h2>
          <UserTeamTimeline userType={userType} objectId={objectId}/>
        </Paper>
      )}
      <Paper style={{padding: "5px", margin: "5px"}}>
        <p className={styles.info}>Persónuverndarstefna DataWell getur verið lesin <a className="smallLink" href="/terms">hér</a>. <br/> Fyrir hvers kyns fyrirspurnir vinsamlegast hafið samband við <a href="mailto: datawell@ru.is" className="smallLink">datawell@ru.is</a> .</p>
      </Paper>
    </Fragment>
  );
};

const mapStateToProps = (store) => {
  return {
    userType: store.auth.userType,
    userId: store.auth.id,
    name: store.auth.fullName,
    profilePicture: store.auth.profilePicture,
    athlete: store.auth.athlete,
    coach: store.auth.coach,
    researcher: store.auth.researcher,
  };
};

export default connect(mapStateToProps)(Account);
