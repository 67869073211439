import React, { Component } from "react";
import { connect } from "react-redux";
import CustomizedSnackbars from "../../../UI/CustomizedSnackbars";
import ReactApexChart from "react-apexcharts";
import Grid from "@material-ui/core/Grid";
import styles from "./OxygenLevelBar.module.css";
import {noValue, dayNameFromNumber, subtractDays, dateToIcelandicString} from "../../../../utils.js";

var colors = ["rgb(209, 163, 113)"];
let globalOriginArray = [];

class AthleteChartOxygenLevel extends Component {
  constructor(props) {
    super(props);
    let day = this.props.date;
    let week = [
      dateToIcelandicString(subtractDays(day, 6)),
      dateToIcelandicString(subtractDays(day, 5)),
      dateToIcelandicString(subtractDays(day, 4)),
      dateToIcelandicString(subtractDays(day, 3)),
      dateToIcelandicString(subtractDays(day, 2)),
      dateToIcelandicString(subtractDays(day, 1)),
      dateToIcelandicString(day)
    ];
    let weekdays = [
      dayNameFromNumber(subtractDays(day, 6).getDay()),
      dayNameFromNumber(subtractDays(day, 5).getDay()),
      dayNameFromNumber(subtractDays(day, 4).getDay()),
      dayNameFromNumber(subtractDays(day, 3).getDay()),
      dayNameFromNumber(subtractDays(day, 2).getDay()),
      dayNameFromNumber(subtractDays(day, 1).getDay()),
      dayNameFromNumber(day.getDay()),
    ];

    this.state = {
      snackbarOpen: false,
      snackbarMessage: "INITAL",
      snackbarVariant: "info",
      error: false,
      loaded: false,
      week: week,
      weekdays: weekdays,
      options: {
        plotOptions: {
          bar: {
            endingShape: "rounded",
            distributed: true,
            horizontal: true,
            columnWidth: "40%",
          },
        },
        colors: colors,
        fill: {
          colors: colors,
        },
        tooltip: {
          y: {
            formatter: function (value) {
              return (
                value + " % "
              );
            },
          },
        },
        chart: {
          type: "line",
          dropShadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2,
          },
          toolbar: {
            show: false,
          },
          tooltip: {
            enabled: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        grid: {
          borderColor: "#e7e7e7",
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        markers: {
          size: 1,
        },
        xaxis: {
          categories: 0,
          lables: {
            style: {
              fontSize: 14,
            },
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            formatter: (value) => {
              return value + " % ";
            },
            style: {
              fontWeight: "normal",
              fontSize: 14,
            },
          },
        },
      },
      series: [
        {
          name: "Súrefnismettun",
          data: [],
        },
      ],
    };
  }

  componentDidUpdate(prevProps) {
    //Typical usage, don't forget to compare the props

    if (this.props.date!==prevProps.date) {
      this.fetchData();
    }
  }
  componentDidMount() {
    this.fetchData();
  }
  fetchData() {
    let day = this.props.date;
    var sixBefore = subtractDays(day, 6).toISOString().substring(0, 10);
    let week = [
      dateToIcelandicString(subtractDays(day, 6)),
      dateToIcelandicString(subtractDays(day, 5)),
      dateToIcelandicString(subtractDays(day, 4)),
      dateToIcelandicString(subtractDays(day, 3)),
      dateToIcelandicString(subtractDays(day, 2)),
      dateToIcelandicString(subtractDays(day, 1)),
      dateToIcelandicString(day)
    ];
    let weekdays = [
      dayNameFromNumber(subtractDays(day, 6).getDay()),
      dayNameFromNumber(subtractDays(day, 5).getDay()),
      dayNameFromNumber(subtractDays(day, 4).getDay()),
      dayNameFromNumber(subtractDays(day, 3).getDay()),
      dayNameFromNumber(subtractDays(day, 2).getDay()),
      dayNameFromNumber(subtractDays(day, 1).getDay()),
      dayNameFromNumber(day.getDay()),
    ];
    this.setState({
      week: week,
      weekdays: weekdays
    });

    const id = this.props.athleteId;
    if (id!==0) {
      const queryString = "?athlete=" + this.props.athleteId;
      fetch(
        process.env.REACT_APP_API +
          "api/measurements/" +
          queryString +
          "&type__name=SPO2&date_of_measurement__gte=" +
          sixBefore +
          "&date_of_measurement__lte=" +
          day.toISOString().substring(0, 10),
        {
          method: "GET",
          headers: {
            Authorization: `JWT ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      )
        .then(handleErrors)
        .then((res) => res.json())
        .then((result) => {
            let data = [];
            var weekdaysWithData = new Map();
            var i;
            for (i = 0; i < this.state.weekdays.length; i++) {
              weekdaysWithData.set(this.state.weekdays[i], [0]);
            }
            let originArray = [];

            for (i = 0; i < result.results.length; i++) {
              let origin = "";
              if(!noValue(result.results[i].origin)) {
                origin = result.results[i].origin.name;
              }
              originArray.push(origin);
            }
            for (i = 0; i < result.results.length; i++) {
              let date = new Date(result.results[i].date_of_measurement);
              //if (this.state.week.includes(date.toDateString())) {
              let OxygenLevelData = result.results[i];
              let OxygenLevel;
              if (this.props.dataType === "SPO2") {
                OxygenLevel = OxygenLevelData.data_value;
              } 
  

              weekdaysWithData.set(dayNameFromNumber(date.getDay()), [
                OxygenLevel,
              ]);
            }

            for (i = 0; i < this.state.weekdays.length; i++) {
              data.push({
                x: this.state.weekdays[i],
                y: weekdaysWithData.get(this.state.weekdays[i]),
              });
            }
            globalOriginArray = originArray;
            let series1 = { data: [] };
            if (result.results.length > 0) {
              series1 = { data: data };
            }
            let seriesAll = [series1];
            this.setState({ 
              loaded: true,
              originArray: originArray,
              series: seriesAll  
            });
          },
          (error) => {
            this.setState({
              error: true,
              snackbarOpen: true,
              snackbarMessage:
                "Það kom upp villa við að sækja svefnupplýsingar.",
              snackbarVariant: "error",
            });
          }
        );
    }
  }

  closeSnackbar = () => {
    this.setState({
      snackbarOpen: false,
    });
  };

  render() {
    return (
      <div id="chart">
        {(this.state.series[0].data.length > 0) &&(
          <div>
            <div>{this.props.title}</div>
            <ReactApexChart
              options={this.state.options}
              series={this.state.series}
              type="line"
              className={styles.chart}
            />
          </div>
        )}
        {(this.state.loaded && this.state.series[0].data.length == 0) &&(
          <div>{this.props.emptyText}</div>
        )}
        <CustomizedSnackbars
          variant={this.state.snackbarVariant}
          message={this.state.snackbarMessage}
          open={this.state.snackbarOpen}
          closeSnackbar={this.closeSnackbar}
        />
      </div>
    );
  }
}

function getGlobalOriginArray() {
  return globalOriginArray;
}

function handleErrors(response) {
  if (!response.ok) {
    this.setState({ error: true });
  }
  return response;
}

const mapStateToProps = (store) => {
  if (store.auth.athlete) {
    var athlete = store.auth.athlete;
  } else {
    var athlete = store.router.location.state;
  }
  return {
    userType: store.auth.userType,
    userId: store.auth.id,
    athlete: athlete,
  };
};

export default connect(mapStateToProps, null)(AthleteChartOxygenLevel);