import React, { useEffect, useState, Fragment } from "react";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import CustomizedSnackbars from "../../UI/CustomizedSnackbars";
import { connect } from "react-redux";
import {
  Button,
  FormControlLabel,
  Checkbox,
  Avatar,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import styles from "./TeamInformation.module.css";
// Be aware that here user is used for athlete/coach/researcher object, depending on props
const TeamInformation = ({
  team,
  userType,
  edit,
  editToggle,
  setEditToggle,
  Teamlogo,
  infoChanged,
}) => {
  console.log(team);
  const [name, setName] = useState(team.name);
  // Team's Logo
  const [team_logo, setTeamLogo] = useState(Teamlogo);
  const [newTeamLogo, setNewTeamLogo] = useState([]);
  const [teamLogoChanged, setTeamLogoChanged] = useState(false);
  const [removeImage, setRemoveImage] = useState(false);
  const [currTeam, setCurrTeam] = useState(team);
  const [sport, setSport] = useState(team.sport);
  // Messages
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("INITIAL");
  const [snackbarVariant, setSnackbarVariant] = useState("info");
  const [error, setError] = useState(false);

  // Update info page
  const [update, setUpdate] = useState(true);

  // Wait while info is being fetched
  const [loading, setLoading] = useState(false);
  // Fetch user info, basteed on userType
  console.log(currTeam);
  useEffect(() => {
    const queryString = team.id;
    fetch(process.env.REACT_APP_API + "api/teams/" + queryString + "/", {
      method: "GET",
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setTeamLogo(result.team_logo);
        },
        (error) => {
          setError(true);
          setSnackbarOpen(true);
          setSnackbarMessage("Villa kom upp við að sækja mynd fyrir hóp.");
          setSnackbarVariant("error");
        }
      );
  }, [currTeam.id, userType]);

  // Reset the user's information when he cancels his changes
  useEffect(() => {
    // Set the info input fields for name
    setName(currTeam.name);
    // setNationalTeam(currTeam.nationalTeam);
    setSport(currTeam.sport);
    // setYouthTeam(currTeam.youthTeam)
  }, [update, infoChanged]);

  const onChangeName = (e) => {
    if (e.target.name === "name") {
      setName(e.target.value);
    }
  };

  const closeSnackbar = () => {
    setSnackbarOpen(false);
  };

  let timeoutValue = 0;

  // Send PATCH request to backend to change user's info
  const onSubmit = (e) => {
    e.preventDefault();
    // actually the id of ateam depending on props
    let teamId = team.id;
    console.log(teamId);
    let append = "";

    if (teamLogoChanged) {
      timeoutValue = 1000; // Time until page reload
      let form_data = new FormData();
      if (removeImage) {
        // If the team  should be removed
        form_data.append("team_logo", [null]);
      } else {
        form_data.append("team_logo", newTeamLogo);
      }

      let config = {
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
      };

      axios
        .patch(
          process.env.REACT_APP_API + "api/teams/" + team.id + "/",
          form_data,
          config
        )
        .then((response) => {})
        .catch((error) => {
          setError(true);
          setSnackbarOpen(true);
          setSnackbarMessage("Ekki tókst að breyta mynd.");
          setSnackbarVariant("error");
        });
    }

    if (userType === "coach" || userType === "researcher") {
      const data = {
        id: teamId,
        name: name,
        sport: sport,
      };
      fetch(process.env.REACT_APP_API + "api/teams/" + teamId + "/", {
        method: "PATCH",
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then(
          (result) => {
            let updatedTeam = {
              id: teamId,
              name: result.name,
            };
            setCurrTeam(updatedTeam);
            setTeamLogo(newTeamLogo);
            setLoading(true);
            // Reload page to fully update it
            if (result.id) {
              setTimeout(function () {
                window.location.reload();
              }, timeoutValue);
            }
          },
          (error) => {
            setError(true);
            setSnackbarOpen(true);
            setSnackbarMessage("Ekki tókst að breyta upplýsingum.");
            setSnackbarVariant("error");
          }
        );
    }
  };

  const onChangeTeamPicture = (e) => {
    setNewTeamLogo(e.target.files[0]);
    setTeamLogoChanged(true);
  };

  const onRemoveTeamPicture = () => {
    setRemoveImage(!removeImage);
    setTeamLogoChanged(true);
  };

  let teamImage = <></>;

  if (edit) {
    teamImage = (
      <>
        <TableRow>
          <TableCell style={{ borderBottom: "none" }}>
            <Typography variant="body2">
              <div
                className={styles.image}
                style={{
                  backgroundImage: `url("${team_logo}")`,
                }}
              ></div>
            </Typography>
          </TableCell>
          <TableCell style={{ borderBottom: "none" }}>
            <Typography>
              <div className={styles.changePicture}>
                <div>
                  <input type="file" onChange={(e) => onChangeTeamPicture(e)} />
                </div>
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={removeImage}
                        onChange={() => onRemoveTeamPicture()}
                        color="primary"
                        name="checkedF"
                      />
                    }
                    label="Fjarlægja mynd"
                  />
                </div>
              </div>
            </Typography>
          </TableCell>
        </TableRow>
      </>
    );
  }

  let snackbar = (
    <CustomizedSnackbars
      variant={snackbarVariant}
      message={snackbarMessage}
      open={snackbarOpen}
      closeSnackbar={closeSnackbar}
    />
  );

  if (loading) {
    return <CircularProgress />;
  }
  return (
    <>
      <div className={styles.container}>
        {snackbar}
        <form id="signUpForm" onSubmit={(e) => onSubmit(e)}>
          <div>
            <label className="label" htmlFor="team_logo">
              Mynd:
            </label>
            {teamImage}
          </div>
          <div className={styles.inputField}>
            <label className="label" htmlFor="Name">
              Nafn:
            </label>
            <input
              defaultValue={team.name}
              type={"name"}
              name="name"
              id="name"
              onChange={(e) => onChangeName(e)}
            />
          </div>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{
              marginTop: "20px",
            }}
          >
            Vista breytingar
          </Button>
        </form>
      </div>
    </>
  );
};

const mapStateToProps = (store) => {
  return {
    id: store.auth.id,
    name: store.auth.name,
    team_logo: store.auth.newTeamLogo,
  };
};

export default connect(mapStateToProps)(TeamInformation);
