import React, { useEffect, Fragment } from "react";
import { Switch, Route } from "react-router-dom";
import "./style.css";
import "./App.css";
import { connect } from "react-redux";
import { ATHLETE, COACH, RESEARCHER } from "./actions/authTypes";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { isLoggedIn } from "./actions/authActions";

import NavBar from "./components/UI/NavBar/NavBar";
import Footer from "./components/UI/Footer/Footer";

import PhysicalMeasurements from "./screens/Researcher/PhysicalMeasurements/PhysicalMeasurements";
import PsychologicalMeasurements from "./screens/Researcher/PsychologicalMeasurements/PsychologicalMeasurements";
import ResearcherTeams from "./screens/Researcher/Teams/Teams";
import ResearcherOverview from "./screens/Researcher/Overview/Overview";
import Researchers from "./screens/Researcher/Researchers/Researchers";
import ResearcherAthletes from "./screens/Researcher/Athletes/Athletes";
import ResearcherCoaches from "./screens/Researcher/Coaches/Coaches";

import CoachOverview from "./screens/Coach/Overview/Overview";

import AthleteOverview from "./screens/Athlete/Overview/Overview";
import AthleteActivity from "./screens/Athlete/Activity/Activity";
import AthleteMeasurements from "./screens/Athlete/Measurements/Measurements";
import AthletePsychology from "./screens/Athlete/Psychology/Psychology";
import AthleteSleep from "./screens/Athlete/Sleep/Sleep";

import TeamOverview from "./screens/Team/Overview/Overview";
import TeamComparison from "./screens/Team/Comparison/Comparison";
import TeamSettings from "./screens/Team/Settings/Settings";
import ExportData from "./screens/Team/Export/ExportData/ExportData";
import ExportWithings from "./screens/Team/Export/ExportWithings/ExportWithings";

import Connect from "./screens/Connect/Connect";
import Account from "./screens/Account/Account";

import NotFound from "./screens/NotFound/NotFound";
import Empty from "./components/Empty/Empty";

import SignUp from "./screens/SignUp/SignUp";
import Login from "./screens/Login/Login";
import About from "./screens/About/About";
import Terms from "./screens/Terms/terms";
import ResetPassword from "./screens/ResetPassword/ResetPassword";
import MentalOverview from "./screens/MentalOverview/MentalOverview";

library.add(fas, fab);

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#379683",
      light: "#80bfb2",
    },
    secondary: {
      main: "#05386B",
      light: "#a4cae0",
    },
    background: {
      default: "#F5F5F5",
    },
  },
  overrides: {
    MuiFormLabel: {
      root: {
        color: "black",
        fontSize: "1em",
      },
    },
    MuiFormControlLabel: {
      label: {
        fontSize: "1em",
      },
    },
  },
});

const loginTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#379683",
    },
    background: {
      default: "#F5F5F5",
    },
  },
});

const App = (props) => {
  useEffect(() => {
    const { isLoggedIn } = props;
    isLoggedIn();
  }, [props]);

  const { type, loggedIn, checkingToken } = props;
  const isReasearcher = type === RESEARCHER;
  const isCoach = type === COACH;
  const isAthlete = type === ATHLETE;
  /* Might not be logged in or might not be any type */
  const isNoneType = type !== COACH && type !== RESEARCHER && type !== ATHLETE;
  const noneTypeNotLoggedIn = (isNoneType || !loggedIn) && !checkingToken;

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <div style={{ height: "100%" }}>
        <NavBar />
        <div>
          {checkingToken && (
            <Switch>
              <Route component={Empty} />
            </Switch>
          )}
          {isReasearcher && (
            <Switch>
              <Route exact path="/" component={ResearcherOverview} />
              <Route exact path="/researchers" component={Researchers} />
              <Route exact path="/users" component={ResearcherAthletes} />
              <Route exact path="/advisers" component={ResearcherCoaches} />
              <Route exact path="/mentalOverview" component={MentalOverview} />
              <Route
                exact
                path="/physicalmeasurements"
                component={PhysicalMeasurements}
              />
              <Route
                exact
                path="/psychologicalmeasurements"
                component={PsychologicalMeasurements}
              />
              <Route exact path="/groups" component={ResearcherTeams} />
              <Route exact path="/group/:id" component={TeamOverview} />
              <Route
                exact
                path="/group/:id/comparison"
                component={TeamComparison}
              />
              <Route exact path="/group/:id/export" component={ExportData} />
              <Route
                exact
                path="/group/:id/exportactivity"
                component={ExportWithings}
              />
              <Route
                exact
                path="/group/:id/settings"
                component={TeamSettings}
                state={isReasearcher}
              />
              <Route exact path="/account" component={Account} />
              <Route exact path="/user/:id/" component={AthleteOverview} />
              <Route exact path="/adviser/:id/" component={CoachOverview} />
              <Route
                exact
                path="/user/:id/psychology"
                component={AthletePsychology}
              />
              <Route
                exact
                path="/user/:id/activity"
                component={AthleteActivity}
              />
              <Route exact path="/user/:id/sleep" component={AthleteSleep} />
              <Route
                exact
                path="/user/:id/measurements"
                component={AthleteMeasurements}
              />
              <Route exact path="/about" component={About} />
              <Route exact path="/terms" component={Terms} />
              <Route component={NotFound} />
            </Switch>
          )}
          {isCoach && (
            <Switch>
              <Route exact path="/" component={CoachOverview} />
              <Route exact path="/mentalOverview" component={MentalOverview} />
              <Route exact path="/group/:id" component={TeamOverview} />
              <Route
                exact
                path="/group/:id/comparison"
                component={TeamComparison}
              />
              <Route exact path="/group/:id/export" component={ExportData} />
              <Route
                exact
                path="/group/:id/exportactivity"
                component={ExportWithings}
              />
              <Route path="/group/:id/settings" component={TeamSettings} />
              <Route exact path="/account" component={Account} />
              <Route exact path="/user/:id/" component={AthleteOverview} />
              <Route
                exact
                path="/user/:id/psychology"
                component={AthletePsychology}
              />
              <Route
                exact
                path="/user/:id/activity"
                component={AthleteActivity}
              />
              <Route exact path="/user/:id/sleep" component={AthleteSleep} />
              <Route
                exact
                path="/user/:id/measurements"
                component={AthleteMeasurements}
              />
              <Route exact path="/about" component={About} />
              <Route exact path="/terms" component={Terms} />
              <Route component={NotFound} />
            </Switch>
          )}
          {isAthlete && (
            <Switch>
              <Route exact path="/" component={AthleteOverview} />
              <Route exact path="/connect" component={Connect} />
              <Route exact path="/account" component={Account} />
              <Route exact path="/activity" component={AthleteActivity} />
              <Route exact path="/sleep" component={AthleteSleep} />
              <Route
                exact
                path="/measurements"
                component={AthleteMeasurements}
              />
              <Route exact path="/psychology" component={AthletePsychology} />
              <Route exact path="/about" component={About} />
              <Route exact path="/terms" component={Terms} />
              <Route component={NotFound} />
            </Switch>
          )}
          {noneTypeNotLoggedIn && (
            <MuiThemeProvider theme={loginTheme}>
              <CssBaseline />
              <Switch>
                <Route exact path="/signup" component={SignUp} />
                <Route exact path="/forgotpassword" component={ResetPassword} />
                <Route exact path="/about" component={About} />
                <Route exact path="/terms" component={Terms} />
                <Route component={Login} />
              </Switch>
            </MuiThemeProvider>
          )}
        </div>
      </div>
      <Footer />
    </MuiThemeProvider>
  );
};

const mapStateToProps = (state) => ({
  type: state.auth.userType,
  checkingToken: state.auth.checkingToken,
  isLoggedIn: state.auth.isLoggedIn,
  loggedIn: state.auth.loggedIn,
});

export default connect(mapStateToProps, { isLoggedIn })(App);
