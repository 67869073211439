import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import defaultUser from "../../../assets/default_user.png"; // Tell Webpack this JS file uses this image
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import CustomizedSnackbars from "../../UI/CustomizedSnackbars";
import { noValue } from "../../../utils";
import { connect } from "react-redux";
import { formatDate } from "react-day-picker/moment";

import {
  Avatar,
  TextField,
  TableBody,
  Paper,
  Table,
  Button,
  Input,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";

import styles from "./Information.module.css";
import CellPair from "../../UI/CellPair";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Be aware that here user is used for athlete/coach/researcher object, depending on props
const UserInformation = ({
  user,
  userType,
  edit,
  editToggle,
  setEditToggle,
  profilePic,
  infoChanged,
  id,
}) => {
  // User info
  const [athleteHeight, setAthleteHeight] = useState("");
  const [athleteWeight, setAthleteWeight] = useState("");
  const [firstName, setFirstName] = useState(user.first_name);
  const [lastName, setLastName] = useState(user.last_name);
  const [birthYear, setBirthYear] = useState(1900); // Set the user's birth year
  const [birthMonth, setBirthMonth] = useState(1); // Set the user's birth month
  const [birthDate, setBirthDate] = useState(1); // Set the user's birth day
  const [ssn, setSsn] = useState(user.kennitala);
  const [about, setAbout] = useState(user.about);
  const [club, setClub] = useState(user.club);
  const [youthClub, setYouthClub] = useState(user.youth_club);
  const [phoneNumber, setPhoneNumber] = useState(user.phone_number);
  const [position, setPosition] = useState(user.position);
  const [sport, setSport] = useState(user.sport);
  const [dominantSide, setDominantSide] = useState(user.dominant_side);
  const [gender, setGender] = useState(user.gender);

  // User's profile image
  const [profilePicture, setProfilePicture] = useState(profilePic);
  const [newProfilePicture, setNewProfilePicture] = useState([]);
  const [profilePictureChanged, setProfilePictureChanged] = useState(false);
  const [removeImage, setRemoveImage] = useState(false);
  const [currUser, setCurrUser] = useState(user);

  // Messages
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("INITIAL");
  const [snackbarVariant, setSnackbarVariant] = useState("info");
  const [error, setError] = useState(false);

  // Update info page
  const [update, setUpdate] = useState(true);

  // Wait while info is being fetched
  const [loading, setLoading] = useState(false);

  // Fetch user info, based on userType
  useEffect(() => {
    if (noValue(profilePicture)) {
      setProfilePicture(defaultUser);
    }
    if (userType === "athlete") {
      const queryString = "?athlete=" + currUser.id;
      fetch(
        process.env.REACT_APP_API +
          "api/measurements/" +
          queryString +
          "&type__name=Height&ordering=-date_of_measurement",
        {
          method: "GET",
          headers: {
            Authorization: `JWT ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => res.json())
        .then(
          (result) => {
            let newest_date = "1900-01-01";
            let newest_date_index = 0;
            if (result.results != undefined) {
              for (let i = 0; i < result.results.length; i++) {
                if (
                  Date.parse(newest_date) >
                  Date.parse(result.results[i].date_of_measurement)
                ) {
                } else {
                  newest_date = result.results[i].date_of_measurement;
                  newest_date_index = i;
                }
                // Check if the height is the right format (cm instead of m)
                // Withings sends height data in m instead of cm
                if (result.results[newest_date_index].data_value < 10) {
                  setAthleteHeight(
                    (
                      result.results[newest_date_index].data_value * 100
                    ).toFixed(1)
                  );
                } else {
                  setAthleteHeight(
                    result.results[newest_date_index].data_value.toFixed(1)
                  );
                }
              }
            }
          },
          (error) => {
            setError(true);
            setSnackbarOpen(true);
            setSnackbarMessage(
              "Villa kom upp við að sækja mælingar fyrir hæð."
            );
            setSnackbarVariant("error");
          }
        );
      fetch(
        process.env.REACT_APP_API +
          "api/measurements/" +
          queryString +
          "&type__name=Weight&ordering=-date_of_measurement",
        {
          method: "GET",
          headers: {
            Authorization: `JWT ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => res.json())
        .then(
          (result) => {
            if (result.results !== undefined && result.results.length > 0) {
              setAthleteWeight(result.results[0].data.toFixed(1));
            }
          },
          (error) => {
            setError(true);
            setSnackbarOpen(true);
            setSnackbarMessage(
              "Villa kom upp við að sækja mælingar fyrir þyngd."
            );
            setSnackbarVariant("error");
          }
        );
    } else if (userType === "coach") {
      const queryString = "coaches/" + currUser.id + "/";
      fetch(process.env.REACT_APP_API + "api/" + queryString, {
        method: "GET",
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then(
          (result) => {
            if (noValue(result.profile_picture)) {
              setProfilePicture(defaultUser);
            } else {
              setProfilePicture(result.profile_picture);
            }
          },
          (error) => {
            setError(true);
            setSnackbarOpen(true);
            setSnackbarMessage("Villa kom upp við að sækja prófílmynd.");
            setSnackbarVariant("error");
          }
        );
    } else if (userType === "researcher") {
      const queryString = "researchers/" + currUser.id + "/";
      fetch(process.env.REACT_APP_API + "api/" + queryString, {
        method: "GET",
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then(
          (result) => {
            if (!noValue(result.profile_picture)) {
              setProfilePicture(result.profile_picture);
            } else {
              setProfilePicture(defaultUser);
            }
          },
          (error) => {
            setError(true);
            setSnackbarOpen(true);
            setSnackbarMessage("Villa kom upp við að sækja prófílmynd.");
            setSnackbarVariant("error");
          }
        );
    }
  }, [currUser.id, userType]);

  // Reset the user's information when he cancels his changes
  useEffect(() => {
    // Set the info input fields for name
    setFirstName(currUser.first_name);
    setLastName(currUser.last_name);

    if (userType === "athlete") {
      let birthdayArr = [];
      if (currUser.date_of_birth) {
        birthdayArr = currUser.date_of_birth.split("-");
      } else {
        birthdayArr = [0, 0, 0];
      }

      setBirthYear(birthdayArr[0]);
      setBirthMonth(birthdayArr[1]);
      setBirthDate(birthdayArr[2]);
      setSsn(currUser.kennitala);
      setClub(currUser.club);
      setYouthClub(currUser.youth_club);
      setAbout(currUser.about);
      setPhoneNumber(currUser.phone_number);
      setPosition(currUser.position);
      setSport(currUser.sport);
      setDominantSide(currUser.dominant_side);
      setGender(currUser.gender);
    }
  }, [update, infoChanged]);

  // Variables for birthday picker
  let years = [];
  for (let i = new Date().getFullYear(); i >= 1900; i--) {
    years.push(i);
  }

  let months = [];
  for (let i = 1; i <= 12; i++) {
    months.push(i);
  }

  let days = [];
  for (let i = 1; i <= 31; i++) {
    days.push(i);
  }

  let closeButton = (
    <FontAwesomeIcon
      icon="times"
      style={{
        color: "grey",
        float: "right",
        margin: "20px",
        cursor: "pointer",
      }}
      className={styles.theicons}
      onClick={() => onCancel()}
    />
  );

  let profileImage = <></>;

  if (edit) {
    profileImage = (
      <CellPair
        left={
          <div className={styles.image}>
            <Avatar
              src={profilePicture}
              alt={"Avatar"}
              className={styles.avatarImage}
            />
          </div>
        }
        right={<></>}
      />
    );
    if (editToggle) {
      profileImage = (
        <CellPair
          left={
            <div
              className={styles.image}
              style={{
                backgroundImage: `url("${profilePicture}")`,
              }}
            ></div>
          }
          right={
            <>
              <input type="file" onChange={(e) => onChangeProfilePicture(e)} />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={removeImage}
                    onChange={() => onRemoveProfilePicture()}
                    color="primary"
                    name="checkedF"
                  />
                }
                label="Fjarlægja mynd"
              />
            </>
          }
        />
      );
    }
  }

  const onChangeName = (e) => {
    if (e.target.name === "firstName") {
      setFirstName(e.target.value);
    } else if (e.target.name === "lastName") {
      setLastName(e.target.value);
    }
  };

  const onChangeProfilePicture = (e) => {
    setNewProfilePicture(e.target.files[0]);
    setProfilePictureChanged(true);
  };

  const onRemoveProfilePicture = () => {
    setRemoveImage(!removeImage);
    setProfilePictureChanged(true);
  };

  // ~~~~Athlete-specific handlers~~~~
  const onChangeBirthYear = (e) => {
    setBirthYear(e.target.value);
  };

  const onChangeBirthMonth = (e) => {
    setBirthMonth(e.target.value);
  };

  const onChangeBirthDate = (e) => {
    setBirthDate(e.target.value);
  };

  const onChangeSsn = (e) => {
    setSsn(e.target.value);
  };

  const onChangeClub = (e) => {
    setClub(e.target.value);
  };

  const onChangeYouthClub = (e) => {
    setYouthClub(e.target.value);
  };

  const onChangeAbout = (e) => {
    setAbout(e.target.value);
  };

  const onChangePhoneNumber = (e) => {
    setPhoneNumber(e.target.value);
  };

  const onChangePosition = (e) => {
    setPosition(e.target.value);
  };

  const onChangeSport = (e) => {
    setSport(e.target.value);
  };

  const onChangeDominantSide = (e) => {
    setDominantSide(e.target.value);
  };

  const onChangeGender = (e) => {
    setGender(e.target.value);
  };
  // ~~~~End of Athlete-specific handlers~~~~

  const closeSnackbar = () => {
    setSnackbarOpen(false);
  };

  let timeoutValue = 0;

  // Send PATCH request to backend to change user's info
  const onSubmit = (e) => {
    e.preventDefault();
    // actually the id of athlete/coach/researcher depending on props
    let userId = currUser.id;
    let append = "";
    if (userType == "coach") {
      append = "e";
    }

    if (profilePictureChanged) {
      timeoutValue = 1000; // Time until page reload
      let form_data = new FormData();
      if (removeImage) {
        // If the profile image should be removed
        form_data.append("profile_picture", [null]);
      } else {
        form_data.append("profile_picture", newProfilePicture);
      }

      let config = {
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
      };

      axios
        .patch(
          process.env.REACT_APP_API +
            "api/" +
            userType +
            append +
            "s/" +
            userId +
            "/",
          form_data,
          config
        )
        .then((response) => {})
        .catch((error) => {
          setError(true);
          setSnackbarOpen(true);
          setSnackbarMessage("Ekki tókst að breyta mynd.");
          setSnackbarVariant("error");
        });
    }

    if (userType === "athlete") {
      const data = {
        id: userId,
        date_of_birth: birthYear + "-" + birthMonth + "-" + birthDate,
        club: club,
        youth_club: youthClub,
        about: about,
        first_name: firstName,
        last_name: lastName,
        kennitala: ssn,
        position: position,
        phone_number: phoneNumber,
        sport: sport,
        dominant_side: dominantSide,
        gender: gender,
      };
      fetch(process.env.REACT_APP_API + "api/athletes/" + userId + "/", {
        method: "PATCH",
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then(
          (result) => {
            let updatedUser = {
              id: userId,
              email: currUser.email,
              user: currUser.user,
              date_of_birth: result.date_of_birth,
              club: result.club,
              youth_club: result.youth_club,
              first_name: result.first_name,
              last_name: result.last_name,
              kennitala: result.kennitala,
              position: result.position,
              phone_number: result.phone_number,
              sport: result.sport,
              dominant_side: result.dominant_side,
              gender: result.gender,
            };
            setCurrUser(updatedUser);
            setProfilePicture(newProfilePicture);
            handleEditClick(true);
            setLoading(true);
            // Reload page to fully update it
            if (result.id) {
              setTimeout(function () {
                window.location.reload();
              }, timeoutValue);
            }
          },
          (error) => {
            setError(true);
            setSnackbarOpen(true);
            setSnackbarMessage("Ekki tókst að breyta upplýsingum.");
            setSnackbarVariant("error");
          }
        );
    } else {
      const data = {
        first_name: firstName,
        last_name: lastName,
        about: about,
      };
      let apiUrl = "";
      if (userType === "coach") {
        apiUrl = "api/coaches/";
      } else {
        // If the user is not an athlete or coach, they're a researcher
        apiUrl = "api/researchers/";
      }
      fetch(process.env.REACT_APP_API + apiUrl + userId + "/", {
        method: "PATCH",
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then(
          (result) => {
            let updatedUser = {
              id: userId,
              first_name: result.first_name,
              last_name: result.last_name,
              email: currUser.email,
              user: currUser.user,
            };
            setCurrUser(updatedUser);
            handleEditClick(true);
            setLoading(true);
            // Reload page to fully update it
            if (result.id) {
              setTimeout(function () {
                window.location.reload();
              }, timeoutValue);
            }
          },
          (error) => {
            setError(true);
            setSnackbarOpen(true);
            setSnackbarMessage("Ekki tókst að breyta upplýsingum.");
            setSnackbarVariant("error");
          }
        );
    }
  };

  const onCancel = () => {
    handleEditClick(true);
    setUpdate(!update);
  };

  const handleEditClick = () => {
    setEditToggle(!editToggle);
  };

  let snackbar = (
    <CustomizedSnackbars
      variant={snackbarVariant}
      message={snackbarMessage}
      open={snackbarOpen}
      closeSnackbar={closeSnackbar}
    />
  );

  if (loading) {
    return <CircularProgress />;
  }
  if (userType === "athlete") {
    return (
      <div className={styles.display}>
        {snackbar}
        <Paper className={styles.display}>
          {editToggle ? (
            <>
              {closeButton}
              <form onSubmit={onSubmit}>
                <div className={styles.divider} />
                <Table>
                  <TableBody>
                    {profileImage}
                    <CellPair
                      left="Fornafn"
                      right={
                        <Input
                          className={styles.info}
                          type={"text"}
                          name="firstName"
                          value={firstName}
                          onChange={(e) => onChangeName(e)}
                        />
                      }
                    />
                    <CellPair
                      left="Eftirnafn"
                      right={
                        <Input
                          className={styles.info}
                          type={"text"}
                          name="lastName"
                          value={lastName}
                          onChange={(e) => onChangeName(e)}
                        />
                      }
                    />

                    <CellPair
                      left="Fæðingardagur"
                      right={
                        <>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={birthYear}
                            onChange={(e) => onChangeBirthYear(e)}
                          >
                            {years.map((item, i) => (
                              <MenuItem value={item}>{item}</MenuItem>
                            ))}
                          </Select>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={parseInt(birthMonth)}
                            onChange={(e) => onChangeBirthMonth(e)}
                          >
                            {months.map((item, i) => (
                              <MenuItem value={item}>{item}</MenuItem>
                            ))}
                          </Select>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={parseInt(birthDate)}
                            onChange={(e) => onChangeBirthDate(e)}
                          >
                            {days.map((item, i) => (
                              <MenuItem value={item}>{item}</MenuItem>
                            ))}
                          </Select>
                        </>
                      }
                    />
                    {/* 
                     <CellPair
                        left="Kennitala"
                        right={
                          <Input
                            className={styles.info}
                            type={"text"}
                            name="ssn"
                            value={ssn}
                            onChange={(e) => onChangeSsn(e)}
                          />
                        }
                      />
                    */}

                    <CellPair left="Netfang" right={currUser.email} />
                    <CellPair
                      left="Símanúmer"
                      right={
                        <Input
                          className={styles.info}
                          type={"tel"}
                          name="phoneNumber"
                          value={phoneNumber}
                          onChange={(e) => onChangePhoneNumber(e)}
                        />
                      }
                    />
                    <CellPair
                      left="Íþrótt"
                      right={
                        <Input
                          className={styles.info}
                          type={"text"}
                          name="sport"
                          value={sport}
                          onChange={(e) => onChangeSport(e)}
                        />
                      }
                    />

                    <CellPair
                      left="Félag"
                      right={
                        <Input
                          className={styles.info}
                          type={"text"}
                          name="club"
                          value={club}
                          onChange={(e) => onChangeClub(e)}
                        />
                      }
                    />
                    <CellPair
                      left="Uppeldisfélag"
                      right={
                        <Input
                          className={styles.info}
                          type={"text"}
                          name="youthClub"
                          value={youthClub}
                          onChange={(e) => onChangeYouthClub(e)}
                        />
                      }
                    />
                    <CellPair
                      left="Staða"
                      right={
                        <Input
                          className={styles.info}
                          type={"text"}
                          name="position"
                          value={position}
                          onChange={(e) => onChangePosition(e)}
                        />
                      }
                    />
                    <CellPair
                      left="Ríkjandi hlið"
                      right={
                        <>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={dominantSide}
                            onChange={(e) => onChangeDominantSide(e)}
                          >
                            <MenuItem value={"LEFT"}>Vinstri</MenuItem>
                            <MenuItem value={"RIGHT"}>Hægri</MenuItem>
                            <MenuItem value={"EQUAL"}>Jafnt</MenuItem>
                          </Select>
                        </>
                      }
                    />
                    <CellPair
                      left="Kyn"
                      right={
                        <>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={gender}
                            onChange={(e) => onChangeGender(e)}
                          >
                            <MenuItem value={"MALE"}>Karlkyn</MenuItem>
                            <MenuItem value={"FEMALE"}>Kvenkyn</MenuItem>
                            <MenuItem value={"OTHER"}>Annað</MenuItem>
                            <MenuItem value={"UNKNOWN"}>Óskráð</MenuItem>
                          </Select>
                        </>
                      }
                    />
                    <CellPair
                      left="Um"
                      right={
                        <Input
                          className={styles.info}
                          type={"text"}
                          name="about"
                          multiline={true}
                          value={about}
                          onChange={(e) => onChangeAbout(e)}
                        />
                      }
                    />
                    <CellPair left="Hæð" right={athleteHeight + " cm"} />
                    <CellPair left="Þyngd" right={athleteWeight + " kg"} />
                  </TableBody>
                </Table>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{
                    marginTop: "20px",
                  }}
                >
                  Vista breytingar
                </Button>
              </form>
            </>
          ) : (
            <>
              {edit}
              <br />
              <Table>
                <TableBody>
                  {profileImage}

                  <CellPair left="Nafn" right={firstName + " " + lastName} />
                  <CellPair
                    left="Fæðingardagur"
                    right={
                      !noValue(currUser.date_of_birth)
                        ? formatDate(currUser.date_of_birth, "LL", "is")
                        : ""
                    }
                  />
                  <CellPair left="Netfang" right={currUser.email} />
                  {/*<CellPair left="Kennitala" right={currUser.kennitala} />*/}
                  {!noValue(currUser.phone_number) && (
                    <CellPair left="Símanúmer" right={currUser.phone_number} />
                  )}
                  {!noValue(currUser.sport) && (
                    <CellPair left="Íþrótt" right={currUser.sport} />
                  )}

                  {!noValue(currUser.club) && (
                    <CellPair left="Félag" right={currUser.club} />
                  )}
                  {!noValue(currUser.youth_club) && (
                    <CellPair
                      left="Uppeldisfélag"
                      right={currUser.youth_club}
                    />
                  )}
                  {!noValue(currUser.position) && (
                    <CellPair left="Staða" right={currUser.position} />
                  )}
                  {currUser.dominant_side !== "UNKNOWN" && (
                    <CellPair
                      left="Ríkjandi hlið"
                      right={
                        currUser.dominant_side === "RIGHT"
                          ? "Hægri"
                          : currUser.dominant_side === "LEFT"
                          ? "Vinstri"
                          : currUser.dominant_side === "EQUAL"
                          ? "Jafnt"
                          : currUser.dominant_side === "UNKNOWN"
                          ? "Óþekkt"
                          : currUser.dominant_side
                      }
                    />
                  )}
                  {currUser.gender !== "UNKNOWN" && (
                    <CellPair
                      left="Kyn"
                      right={
                        currUser.gender === "MALE"
                          ? "Karlkyn"
                          : currUser.gender === "FEMALE"
                          ? "Kvenkyn"
                          : currUser.gender === "OTHER"
                          ? "Annað"
                          : currUser.gender === "UNKNOWN"
                          ? "Óskráð"
                          : currUser.gender
                      }
                    />
                  )}
                  <CellPair left="Um" right={currUser.about} />
                  <CellPair left="Hæð" right={athleteHeight + " cm"} />
                  <CellPair left="Þyngd" right={athleteWeight + " kg"} />
                </TableBody>
              </Table>
            </>
          )}
          <br />
        </Paper>
      </div>
    );
  } else if (userType === "coach") {
    let type = "Meðferðaraðili";
    return (
      <div className={styles.display}>
        {snackbar}
        <Paper className={styles.display}>
          {editToggle ? (
            <>
              {closeButton}
              <form onSubmit={onSubmit} className={styles.formLogin}>
                <div className={styles.divider} />
                <Table>
                  <TableBody>
                    {profileImage}
                    <CellPair
                      left="Fornafn"
                      right={
                        <Input
                          className={styles.info}
                          type={"text"}
                          name="firstName"
                          value={firstName}
                          onChange={(e) => onChangeName(e)}
                        />
                      }
                    />
                    <CellPair
                      left="Eftirnafn"
                      right={
                        <Input
                          className={styles.info}
                          type={"text"}
                          name="lastName"
                          value={lastName}
                          onChange={(e) => onChangeName(e)}
                        />
                      }
                    />
                    <CellPair left="Netfang" right={currUser.email} />
                    <CellPair
                      left="Um"
                      right={
                        <Input
                          className={styles.info}
                          type={"text"}
                          name="about"
                          value={about}
                          multiline={true}
                          onChange={(e) => onChangeAbout(e)}
                        />
                      }
                    />
                    <CellPair left="Tegund aðgangs" right={type} />
                  </TableBody>
                </Table>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{
                    marginTop: "20px",
                  }}
                >
                  Vista breytingar
                </Button>
              </form>
            </>
          ) : (
            <>
              {edit}
              <br />
              <Table>
                <TableBody>
                  {profileImage}
                  <CellPair left="Nafn" right={firstName + " " + lastName} />
                  <CellPair left="Netfang" right={currUser.email} />
                  <CellPair left="Um" right={currUser.about} />
                  <CellPair left="Tegund aðgangs" right={type} />
                </TableBody>
              </Table>
            </>
          )}
          <br />
        </Paper>
      </div>
    );
  } else if (userType === "researcher") {
    let type = "Rannsakandi";
    return (
      <div className={styles.display}>
        {snackbar}
        <Paper className={styles.display}>
          {editToggle ? (
            <>
              {closeButton}
              <form onSubmit={onSubmit} className={styles.formLogin}>
                <div className={styles.divider} />
                <Table>
                  <TableBody>
                    {profileImage}
                    <CellPair
                      left="Fornafn"
                      right={
                        <Input
                          className={styles.info}
                          type={"text"}
                          name="firstName"
                          value={firstName}
                          onChange={(e) => onChangeName(e)}
                        />
                      }
                    />
                    <CellPair
                      left="Eftirnafn"
                      right={
                        <Input
                          className={styles.info}
                          type={"text"}
                          name="lastName"
                          value={lastName}
                          onChange={(e) => onChangeName(e)}
                        />
                      }
                    />
                    <CellPair left="Netfang" right={currUser.email} />
                    <CellPair
                      left="Um"
                      right={
                        <Input
                          className={styles.info}
                          type={"text"}
                          multiline={true}
                          name="about"
                          value={about}
                          onChange={(e) => onChangeAbout(e)}
                        />
                      }
                    />
                    <CellPair left="Tegund aðgangs" right={type} />
                  </TableBody>
                </Table>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{
                    marginTop: "20px",
                  }}
                >
                  Vista breytingar
                </Button>
              </form>
            </>
          ) : (
            <>
              {edit}
              <br />
              <Table>
                <TableBody>
                  {profileImage}
                  <CellPair left="Nafn" right={firstName + " " + lastName} />
                  <CellPair left="Netfang" right={currUser.email} />
                  <CellPair left="Um" right={currUser.about} />
                  <CellPair left="Tegund aðgangs" right={type} />
                </TableBody>
              </Table>
            </>
          )}
          <br />
        </Paper>
      </div>
    );
  }
};

const mapStateToProps = (store) => {
  return {
    id: store.auth.id,
  };
};

export default connect(mapStateToProps)(UserInformation);
