import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import styles from "./DataCategories.module.css";
import IconTextButton from "../../UI/IconTextButton/IconTextButton"

const AthleteDataCategories = () => {
  var parts = window.location.href.split("/");
  var end = parts[parts.length - 1]

  return (
    <div className={[styles.container, "flexContainerCenter"].join(' ')}>
      <IconTextButton active={end ===""} color = "grey" text="Yfirlit" link="./" icon="chart-bar"/>
      <IconTextButton active={end ==="measurements"} color = "#8e7da8" text="Mælingar" link="measurements" icon="ruler"/>
      <IconTextButton active={end ==="psychology"} color = "#6DA58A" text="Sálfræði" link="psychology" icon="seedling"/>
      <IconTextButton active={end ==="activity"} color = "#d19171" text="Dagleg virkni" link="activity" icon="running"/>
      <IconTextButton active={end ==="sleep"} color = "#697CB5" text="Svefn" link="sleep" icon="bed"/>
    </div>
  );
};

export default AthleteDataCategories;
