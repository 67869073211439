import React, { useState, useEffect } from "react";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { connect } from "react-redux";
import {
    TextField,
  } from "@material-ui/core";

const TypeSelect = (props) => {
    // ATTENTION, calls typesused so for coach and athletes, only types from available measurements
    const [types, setTypes] = useState([]);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (!loaded) {
            fetch(process.env.REACT_APP_API + "api/typesused/?category=" + props.category + "&ordering=name", {
                method: "GET",
                headers: {
                  Authorization: `JWT ${localStorage.getItem("token")}`,
                  "Content-Type": "application/json",
                },
              })
            .then((res) => res.json())
            .then((result) => {
                setTypes(result.results)
            });
        }
    }, []);

    const onDropdownSelected = (event, newValue) => {
        if (newValue===null) {
            props.setType(" ");
        }
        else {
            props.setType(newValue.name);
        }
    };

  return (
        <Autocomplete
        onChange={onDropdownSelected}
        id="typesSelect"
        options={types}
        getOptionLabel={(option) => option.name}
        style={{ width: 300, margin: "auto" }}
        renderInput={(params) => <TextField {...params} label="Tegund" variant="outlined" />}
        />
  );
};

const mapStateToProps = ({ auth }) => {
  return {
    userType: auth.userType
  };
};

export default connect(mapStateToProps)(TypeSelect);
