import React, { useState, useEffect } from "react";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { connect } from "react-redux";
import {
    TextField,
  } from "@material-ui/core";

const TeamSelect = (props) => {

    const [teams, setTeams] = useState([]);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (!loaded) {
            fetch(process.env.REACT_APP_API + "api/teams/", {
                method: "GET",
                headers: {
                  Authorization: `JWT ${localStorage.getItem("token")}`,
                  "Content-Type": "application/json",
                },
              })
            .then((res) => res.json())
            .then((result) => {
                setTeams(result.results)
            });
        }
    }, []);

  const onDropdownSelected = (event, newValue) => {
    if (newValue===null) {
        props.setGroup("");
    }
    else {
        props.setGroup(newValue.id);
    }
  };

  return (
        <Autocomplete
        onChange={onDropdownSelected}
        id="teamsSelect"
        options={teams}
        getOptionLabel={(option) => option.name}
        style={{ width: 300}}
        renderInput={(params) => <TextField {...params} label="Hópur" variant="outlined" />}
        />
  );
};

const mapStateToProps = ({ auth }) => {
  return {
    userType: auth.userType
  };
};

export default connect(mapStateToProps)(TeamSelect);
