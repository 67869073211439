import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import Typography from '@material-ui/core/Typography';
import CustomizedSnackbars from "../../UI/CustomizedSnackbars";
import {
  formatDate,
} from "react-day-picker/moment";

const UserTeamTimeline = ({userType, objectId}) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("info");
  const [error, setError] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [teams, setTeams] = useState([]);
  const [updateTeams, setUpdateTeams] = useState(true);

  useEffect(() => {
    if (updateTeams) {
      updateTeamsFunc();
      setUpdateTeams(false);
    }
  }, [updateTeams]); // Only render when updateTeams changes

  useEffect(() => {
    if (updateTeams) {
      updateTeamsFunc();
      setUpdateTeams(false);
    }
  }, [updateTeams]); // Only render when updateTeams changes

  const updateTeamsFunc = () => {
    let queryString = ""
    if (userType==="coach") {
        queryString = process.env.REACT_APP_API + "api/coachsteamhistory/?coach=" + objectId;
    }
    else {
        // athlete
        queryString = process.env.REACT_APP_API + "api/athletesteamhistory/?athlete=" + objectId;
    }
    fetch(queryString + "&ordering=-joined", {
      method: "GET",
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    })
    .then(handleErrors)
    .then((res) => res.json())
    .then(
    (result) => {
        setLoaded(true)
        setTeams(result.results);
        setLoaded(true);
    },
    (error) => {
        setError(true);
        setSnackbarOpen(true);
        setSnackbarMessage("Villa kom upp við að sækja hóp.");
        setSnackbarVariant("error");
    }
    );
  };

  const closeSnackbar = () => {
    setSnackbarOpen(false);
  };

  function handleErrors(response) {
    if (!response.ok) {
      setError(true);
    }
    return response;
  }

  return (
    <div className="flexContainerCenter">
        <Timeline align="alternate">
        {teams.map((team) => {
            let start =""
            let end=""
            let color = "grey"
            
            if (team.joined) {
                start = team.joined.slice(0,10)
                start = formatDate( start, "LL", "is" )
            }
            if (team.left)  {
              end = team.joined.slice(0,10)
              end = formatDate( end, "LL", "is" )
            }
            else {
                color = "primary"
            }
            return (
                <TimelineItem>
                <TimelineOppositeContent>
                    <Typography >{team.team_name}</Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineDot color={color}/>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                    <Typography color="textSecondary">{start} - {end}</Typography>
                </TimelineContent>
                </TimelineItem>
            )
        })}
        {(loaded && (teams.length===0)) &&(
          <p>Ekkert skráð</p>
        )}
        </Timeline>
      <CustomizedSnackbars
        variant={snackbarVariant}
        message={snackbarMessage}
        open={snackbarOpen}
        closeSnackbar={() => closeSnackbar()}
      />
    </div>
  );
};

const mapStateToProps = (store) => ({});

export default connect(mapStateToProps)(UserTeamTimeline);
