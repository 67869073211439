import React, { useState, useEffect } from "react";
import styles from "./AddUserForm.module.css";
import Button from "@material-ui/core/Button";
import CustomizedSnackbars from "../../UI/CustomizedSnackbars";

const AddUserForm = (props) => {
  // User info
  const { createUserType, id } = props;
  const [email, setEmail] = useState("");
  const [emailRepeat, setEmailRepeat] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  // Messages
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("INITIAL");
  const [snackbarVariant, setSnackbarVariant] = useState("info");
  const [error, setError] = useState(false);

  var userId = 0;

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handleEmailRepeatChange = (e) => {
    setEmailRepeat(e.target.value);
  };
  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };
  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
  };

  const closeSnackbar = () => {
    setSnackbarOpen(false);
  };

  const addToTeam = () => {
    let userString = "";
    if (createUserType !== "researcher") {
      let data2 = {
        [createUserType]: userId,
        team: id,
      };
      if (createUserType === "coach") {
        userString = "coachofteams/";
      } else {
        userString = "athleteinteams/";
      }

      var formData2 = new FormData();
      for (var item in data2) {
        formData2.append(item, data2[item]);
      }

      fetch(process.env.REACT_APP_API + "api/" + userString, {
        method: "POST",
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
        body: formData2,
      })
        .then((res) => res.json())
        .then(
          (result) => {
            if (result.non_field_errors) {
              if (
                result.non_field_errors[0] ===
                  "The fields coach, team must make a unique set." ||
                result.non_field_errors[0] ===
                  "The fields athlete, team must make a unique set."
              ) {
                setError(true);
                setSnackbarOpen(true);
                setSnackbarMessage(firstName + " er nú þegar hluti af hópnum");
                setSnackbarVariant("error");
              }
            } else if (
              (result["coach"] !== undefined &&
                result["coach"][0] ===
                  "Incorrect type. Expected pk value, received str.") ||
              (result["athlete"] !== undefined &&
                result["athlete"][0] ===
                  "Incorrect type. Expected pk value, received str.")
            ) {
              setError(true);
              setSnackbarOpen(true);
              setSnackbarMessage(
                "Það kom upp villa við að bæta notanda í hópinn."
              );
              setSnackbarVariant("error");
            } else {
              // Success snackbar activated from button component so will not disappear with the modal/form
              props.handleCreated(firstName + " er núna hluti af hópnum!");
            }
          },
          (error) => {
            setError(true);
            setSnackbarOpen(true);
            setSnackbarMessage(
              "Ekki tókst að bæta " + firstName + " í hópinn."
            );
            setSnackbarVariant("error");
          }
        );
    }
  };

  const createUser = () => {
    const data = {
      email: email.toLowerCase(),
      email_repeat: emailRepeat.toLowerCase(),
      first_name: firstName,
      last_name: lastName,
      password: null,
      password_repeat: null,
    };

    var formData = new FormData();
    for (let name in data) {
      formData.append(name, data[name]);
    }

    let userString = "";
    if (createUserType === "athlete") {
      userString = "athletes/";
      const athleteData = {
        date_of_birth: "",
        club: "",
        youth_club: "",
        kennitala: "",
        position: "",
        phone_number: "",
        sport: "",
        dominant_side: "UNKNOWN",
        gender: "UNKNOWN",
      };
      for (var name in athleteData) {
        formData.append(name, athleteData[name]);
      }
    } else if (createUserType === "coach") {
      userString = "coaches/";
    } else {
      userString = "researchers/";
    }

    fetch(process.env.REACT_APP_API + "api/" + userString, {
      method: "POST",
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
      body: formData,
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (createUserType !== "researcher") {
            userId = result.id;
            addToTeam(userId);
          } else {
            if (result.id) {
              // Success snackbar activated from button component so will not disappear with the modal/form
              props.handleCreated(firstName + " er núna rannsakandi");
            } else {
              setError(true);
              setSnackbarOpen(true);
              setSnackbarMessage(
                "Ekki tókst að stofna " + firstName + " sem rannsakanda."
              );
              setSnackbarVariant("error");
            }
          }
        },
        (error) => {
          setError(true);
          setSnackbarOpen(true);
          setSnackbarMessage(
            "Ekki tókst að stofna aðgang fyrir " + firstName + "!"
          );
          setSnackbarVariant("error");
        }
      );
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let queryString = "";
    if (createUserType === "athlete") {
      queryString = "athletesmin/";
    } else if (createUserType === "coach") {
      queryString = "coachesmin/";
    } else {
      queryString = "researchers/";
    }

    // If the new user is an athlete or coach, find/create him and add to team
    if (createUserType !== "researcher") {
      fetch(
        process.env.REACT_APP_API +
          "api/" +
          queryString +
          "?email=" +
          email.toLowerCase(),
        {
          method: "GET",
          headers: {
            Authorization: `JWT ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => res.json())
        .then(
          (result) => {
            if (result.count !== 0) {
              userId = result.results[0].id;
              addToTeam(userId);
            } else {
              createUser();
            }
          },
          (error) => {
            createUser();
          }
        );
    } else {
      // If the new user is a researcher, just create him
      createUser();
    }
  };

  let snackbar = (
    <CustomizedSnackbars
      variant={snackbarVariant}
      message={snackbarMessage}
      open={snackbarOpen}
      closeSnackbar={closeSnackbar}
    />
  );

  return (
    <>
      <div className={styles.container}>
        {snackbar}
        <form
          id="signUpForm"
          className={styles.signInForm}
          onSubmit={(e) => handleSubmit(e)}
        >
          <div className={styles.inputField}>
            <label className="label" htmlFor="email">
              Netfang
            </label>
            <input
              type="email"
              id="email"
              onChange={(e) => handleEmailChange(e)}
              required
            />
          </div>
          <div className={styles.inputField}>
            <label className="label" htmlFor="emailRepeat">
              Staðfestu netfang
            </label>
            <input
              type="email"
              id="emailRepeat"
              onChange={(e) => handleEmailRepeatChange(e)}
              required
            />
          </div>
          <div className={styles.inputField}>
            <label className="label" htmlFor="firstName">
              Fornafn
            </label>
            <input
              type="firstName"
              id="firstName"
              onChange={(e) => handleFirstNameChange(e)}
            />
          </div>
          <div className={styles.inputField}>
            <label className="label" htmlFor="lastName">
              Eftirnafn
            </label>
            <input
              type="lastName"
              id="lastName"
              onChange={(e) => handleLastNameChange(e)}
            />
          </div>

          <Button type="submit" variant="contained" color="primary">
            Bæta við{" "}
          </Button>
        </form>
      </div>
    </>
  );
};

export default AddUserForm;
