//https://www.youtube.com/watch?v=f4Lg-nzE0u8
//breyta fæðingardagur í dropdown
import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { isLoggedIn, login, resetError } from "../../actions/authActions";
import { push } from "connected-react-router";
import styles from "./SignUp.module.css";
import { Link } from "react-router-dom";
import { Button, NativeSelect, Paper } from "@material-ui/core";
import CustomizedSnackbars from "../../components/UI/CustomizedSnackbars";

class SignUp extends Component {
  state = {
    email: "",
    email_repeat: "",
    password: "",
    password_repeat: "",
    first_name: "",
    last_name: "",
    profile_picture: null,
    has_logged_in: true,
    date_of_birth: "0-0-0",
    club: "",
    youth_club: "",
    kennitala: "",
    position: "",
    gender: "UNKNOWN",
    phone_number: "",
    sport: "",
    dominant_side: "UNKNOWN",
  };

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const {
      email,
      email_repeat,
      password,
      password_repeat,
      first_name,
      last_name,
      date_of_birth,
      club,
      youth_club,
      kennitala,
      position,
      gender,
      phone_number,
      sport,
      dominant_side,
    } = this.state;
    const data = {
      password: password,
      password_repeat: password_repeat,
      date_of_birth: date_of_birth,
      club: club,
      youth_club: youth_club,
      first_name: first_name,
      last_name: last_name,
      kennitala: kennitala,
      email: email.toLowerCase(),
      email_repeat: email_repeat.toLowerCase(),
      position: position,
      phone_number: phone_number,
      sport: sport,
      dominant_side: dominant_side,
      gender: gender,
    };

    var formData = new FormData();
    for (var name in data) {
      formData.append(name, data[name]);
    }

    fetch(process.env.REACT_APP_API + "create_athlete/", {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.id > 0) {
            this.setState({
              snackbarOpen: true,
              snackbarMessage: "Aðgangur hefur verið stofnaður.",
              snackbarVariant: "success",
            });
            setTimeout(() => {
              const credentials = {
                email: email,
                password: password,
              };

              this.props.login(credentials);
              this.props.push("/");
            }, 2000);
          } else if (
            (result.email[0] = "athlete with this email already exists.")
          ) {
            this.setState({
              snackbarOpen: true,
              snackbarMessage: "Aðgangur með þessu netfangi er nú þegar til.",
              snackbarVariant: "error",
            });
          }
        },
        (error) => {
          this.setState({
            error: true,
            snackbarOpen: true,
            snackbarMessage: "Ekki tókst að stofna aðgang.",
            snackbarVariant: "error",
          });
        }
      );
    if (password !== password_repeat) {
      this.setState({
        error: true,
        snackbarOpen: true,
        snackbarMessage: "Lykilorð passa ekki saman.",
        snackbarVariant: "error",
      });
    } else if (email.toLowerCase() !== email_repeat.toLowerCase()) {
      this.setState({
        error: true,
        snackbarOpen: true,
        snackbarMessage: "Netföng passa ekki saman.",
        snackbarVariant: "error",
      });
    }
  };
  closeSnackbar = () => {
    this.setState({
      snackbarOpen: false,
    });
  };
  render() {
    return (
      <Paper className={styles.paper}>
        <div className={styles.container}>
          <form
            id="signUpForm"
            className={styles.signUpForm}
            onSubmit={this.handleSubmit}
          >
            <h2 className={styles.signUp}>Nýskráning</h2>
            <div className={styles.inputField}>
              <label className="label" htmlFor="email">
                Netfang *
              </label>
              <input
                type="email"
                id="email"
                onChange={this.handleChange}
                required
              />
            </div>
            <div className={styles.inputField}>
              <label className="label" htmlFor="email">
                Staðfestu netfang *
              </label>
              <input
                type="email"
                id="email_repeat"
                onChange={this.handleChange}
                required
              />
            </div>
            <div className={styles.inputField}>
              <label htmlFor="password">Lykilorð *</label>
              <input
                type="password"
                id="password"
                onChange={this.handleChange}
                required
              />
            </div>
            <div className={styles.inputField}>
              <label htmlFor="repeatPassword">Endurtaktu lykilorðið *</label>
              <input
                type="password"
                id="password_repeat"
                onChange={this.handleChange}
                required
              />
            </div>
            <h3>Persónuupplýsingar</h3>
            <div className={styles.inputField}>
              <label htmlFor="first_name">Fornafn *</label>
              <input
                type="text"
                id="first_name"
                onChange={this.handleChange}
                required
              />
            </div>
            <div className={styles.inputField}>
              <label htmlFor="last_name">Eftirnafn *</label>
              <input
                type="text"
                id="last_name"
                onChange={this.handleChange}
                required
              />
            </div>
            {/* <div className={styles.inputField}>
              <label htmlFor="kennitala">Kennitala</label>
              <input
                type="text"
                id="kennitala"
                name="kennitala"
                onChange={this.handleChange}
                required
              />
            </div> */}
            <div className={styles.inputField}>
              <label htmlFor="date_of_birth">Fæðingardagur *</label>
              <input
                type="date"
                id="date_of_birth"
                name="date_of_birth"
                onChange={this.handleChange}
                required
              />
            </div>

            <div className={styles.inputField}>
              <label htmlFor="gender">Kyn</label>
              <div>
                <NativeSelect
                  id="gender"
                  name="gender"
                  value={this.state.gender}
                  onChange={this.handleChange}
                  className={styles.selectField}
                  disableUnderline={true}
                >
                  <option value="UNKNOWN"></option>
                  <option value={"MALE"}>Karlkyns</option>
                  <option value={"FEMALE"}>Kvenkyns</option>
                  <option value={"OTHER"}>Annað</option>
                  <option value={"UNKNOWN"}>Kýs að svara ekki</option>
                </NativeSelect>
              </div>
            </div>

            {/*<div className={styles.inputField}>
              <label htmlFor="phone_number">Símanúmer</label>
              <input
                type="phoneNumber"
                id="phone_number"
                onChange={this.handleChange}
              />
            </div>

            <div className={styles.inputField}>
              <label htmlFor="sport">Íþrótt</label>
              <input type="sport" id="sport" onChange={this.handleChange} />
            </div>

            <div className={styles.inputField}>
              <label htmlFor="club">Félag</label>
              <input type="text" id="club" onChange={this.handleChange} />
            </div>

            <div className={styles.inputField}>
              <label htmlFor="youth_club">Uppeldisfélag</label>
              <input type="text" id="youth_club" onChange={this.handleChange} />
            </div>

            <div className={styles.inputField}>
              <label htmlFor="position">Leikmannastaða (ef á við)</label>
              <input
                type="position"
                id="position"
                onChange={this.handleChange}
              />
            </div>
            <div className={styles.inputField}>
              <div className={styles.inputField}>
                <label htmlFor="dominant_side">Ríkjandi hlið</label>
                <div>
                  <NativeSelect
                    id="dominant_side"
                    name="dominant_side"
                    value={this.state.dominant_side}
                    onChange={this.handleChange}
                    className={styles.selectField}
                    disableUnderline={true}
                  >
                    <option value="UNKNOWN"></option>
                    <option value="LEFT">Vinstri</option>
                    <option value="RIGHT">Hægri</option>
                    <option value="EQUAL">Báðar</option>
                  </NativeSelect>
                </div>
              </div>
            </div>*/}
            <div className={styles.buttons}>
              Vinsamlegast kynnið ykkur persónuvernd verkefnisins undir
              Skilmálar <br /> <br />
              <Button
                variant="outlined"
                color="primary"
                style={{ marginRight: "10px" }}
                component={Link}
                to="/"
              >
                Hætta við{" "}
              </Button>
              <Button type="submit" variant="contained" color="primary">
                Nýskrá{" "}
              </Button>
            </div>
          </form>
          <CustomizedSnackbars
            variant={this.state.snackbarVariant}
            message={this.state.snackbarMessage}
            open={this.state.snackbarOpen}
            closeSnackbar={this.closeSnackbar}
          />
        </div>
      </Paper>
    );
  }
}

SignUp.propTypes = {
  login: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  loggedIn: state.auth.loggedIn,
  error: state.auth.error,
  serverError: state.auth.serverError,
  pathname: state.router.location.pathname,
});

export default connect(mapStateToProps, {
  isLoggedIn,
  login,
  resetError,
  push,
})(SignUp);
