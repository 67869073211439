import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
import createRootReducer from './reducers/index'

const initialState = {};

const middleWare = [thunk];
export const history = createBrowserHistory()


/*const store = createStore(
  rootReducer,
  initialState,
  compose(
    applyMiddleware(...middleWare),
  )
);
export default store;*/

export default function configureStore(preloadedState) {
  const store = createStore(
    createRootReducer(history), // root reducer with router state
    preloadedState,
    compose(
        applyMiddleware(
          routerMiddleware(history),
          ...middleWare
      ),
      /*window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()*/
    ),
  )
  return store
}
